import serverError from '../../assets/images/t.png'
import { Link } from 'react-router-dom'
import FIconWrapper from '../../components/FIconWrapper'
import { HiOutlineMail, HiRefresh } from 'react-icons/hi'

const InternalServerError = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${serverError})`,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
      }}
      className={
        'flex h-screen w-screen flex-col items-center justify-center  bg-gray-50 dark:bg-gray-700 '
      }
    >
      <div className={'grid grid-cols-2'}>
        <div></div>
        <div className={'flex flex-col gap-2   p-5'}>
          <span className={'text-9xl font-bold text-orient-500'}>500</span>
          <span
            className={
              'my-3 text-5xl font-bold  text-gray-800 dark:text-white '
            }
          >
            حسنا، هذا أمر محرج!
          </span>
          <span className={'text-lg'}>
            عذرا ، هذا لا يعمل بشكل صحيح. نحن نعرف عن هذا الخطأ ونعمل على
            إصلاحه.
          </span>
          <FIconWrapper>
            <HiRefresh style={{ minWidth: '20px' }} width={30} />
            <span className={'font-semibold'}>
              قم بتحديث الصفحة (هذا يساعد في بعض الأحيان).
            </span>
          </FIconWrapper>
          <FIconWrapper>
            <HiOutlineMail style={{ minWidth: '20px' }} width={30} />
            <span className={'font-semibold'}>
              راسلنا على
              <a
                className={'text-orient-500 underline'}
                href='mailto: abc@example.com'
              >
                {` support@fawry.org `}
              </a>
              وأخبرنا بما حدث.
            </span>
          </FIconWrapper>

          <Link
            to={'/'}
            className={
              'mt-5 flex w-fit justify-center  rounded bg-orient-500 p-1.5 text-sm text-gray-600 text-white shadow-sm ring-orient-500 hover:bg-orient-700 focus:ring-2 focus:ring-offset-2 disabled:bg-orient-300'
            }
          >
            الرجوع إلى الصفحة الرئيسية
          </Link>
        </div>
      </div>
    </div>
  )
}
export default InternalServerError
