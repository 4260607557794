import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { FaRegFileAlt } from 'react-icons/fa'
import { FaRegFileLines } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import FButton from '../../../components/FButton'
import FRow from '../../../components/FRow'
import { useSearchParams } from 'react-router-dom'
import { axiosInstance } from '../../../api/requister'

const ListTasks = () => {
  const reader = {
    CUSTOMER_TRANSACTION_ORACLE_FILE: 'ملف الدفع',
    UPLOAD_GIG_RESPONSE: 'ملف رد التامين',
    UPLOAD_CUSTOMER: 'ملف العملاء',
  }

  const [searchParams, setSearchParams] = useSearchParams()

  // Get current page from the URL, default to 0 if not found
  const currentPage = parseInt(searchParams.get('page')) || 0
  const pageSize = parseInt(searchParams.get('size')) || 25

  const [selectedTasks, setSelectedTasks] = useState([])

  const fetchTasks = async () => {
    const response = await axiosInstance(
      '/FailedRowsUploadReport/listAllFailedRows',
      {
        method: 'GET',
        params: {
          page: currentPage,
          size: pageSize,
          status: 'OPEN',
        },
      },
    )
    return response.data
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['tasks', currentPage, pageSize], // include pagination parameters in queryKey
    queryFn: fetchTasks,
  })

  const totalCount = data?.totalCount

  const handlePageChange = newPage => {
    setSearchParams({ page: newPage, size: pageSize })
  }

  if (isLoading) {
    return <>loading ...</>
  }

  return (
    <>
      <h1 className={'text-lg m-3'}>جميع المهام</h1>
      <div
        className={
          'grid grid-cols-9 sticky top-0 z-20 items-center border shadow-sm p-3 rounded gap-2 bg-white border-gray-100'
        }
      >
        <div className={'col-span-4'}>
          <span>الوصف</span>
        </div>
        <span>كود الملف</span>
        <span>الرد</span>
        <span>الملف</span>
        <span>النوع</span>
        <span>الحالة</span>
      </div>
      <div className={'flex  flex-col bg-gray-50'}>
        {data?.results?.map(task => (
          <div
            className={`grid grid-cols-9 items-center border-b p-3 gap-2 ${selectedTasks.includes(task.id) ? 'bg-gray-100 border-gray-300' : 'bg-white border-gray-100'}`}
            key={task.id}
          >
            <div className={'flex gap-2 col-span-4 items-center'}>
              <input
                type='checkbox'
                onChange={() => {
                  setSelectedTasks(prevSelected =>
                    prevSelected.includes(task.id)
                      ? prevSelected.filter(id => id !== task.id)
                      : [...prevSelected, task.id],
                  )
                }}
              />

              <span className={'text-sm'}>{task.description}</span>
            </div>
            <span>{task.exportFileHistoryId}</span>
            <a
              className={'flex gap-1 items-center underline'}
              href={task.responseUri}
            >
              <FaRegFileAlt />
              Response
            </a>
            <a className={'flex gap-1 items-center underline'} href={task.uri}>
              <FaRegFileLines />
              File
            </a>
            {task.exportExcelType && (
              <div className={'flex flex-col'}>
                <span>
                  {reader[task.exportExcelType] || task.exportExcelType}
                </span>
                <small>صف رقم {task.rowNumberInSheet}</small>
              </div>
            )}
            <div>
              <span>
                <select
                  value={task.status}
                  onChange={async e => {
                    await axiosInstance.post(
                      `FailedRowsUploadReport/updateFailedRow`,
                      {
                        status: e.target.value,
                        rowId: task.id,
                      },
                    )
                    toast.success('Task updated successfully')
                    await refetch()
                  }}
                >
                  <option value={'OPEN'}>Open</option>
                  <option value={'RESOLVED'}>RESOLVED</option>
                  <option value={'NOT_AN_ISSUE'}>NOT_AN_ISSUE</option>
                </select>
              </span>
            </div>
          </div>
        ))}
      </div>
      <FRow
        className={
          'justify-between p-3 bg-gray-100 border-y sticky z-20 bottom-0 flex w-full items-center'
        }
      >
        <FRow>
          <FButton
            onClick={() => handlePageChange(Math.max(currentPage - 1, 0))}
            disabled={currentPage === 0}
          >
            السابق
          </FButton>
          <FButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= Math.ceil(totalCount / pageSize) - 1}
          >
            التالي
          </FButton>
        </FRow>
        {`عرض ${pageSize} من ${totalCount}`}
      </FRow>
    </>
  )
}

export default ListTasks
