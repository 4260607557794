import React from 'react'
import classNames from 'classnames'

const FButton = ({ children, type, className, onClick, btnType, ...props }) => {
  const btnClass = classNames(
    className,
    {
      'bg-red-600 text-white disabled:bg-red-300': btnType === 'danger',
      'border border-green-500 bg-green-600 text-white disabled:bg-green-300':
        btnType === 'success',
      'hover:border-gray-300 bg-gray-50 border bg-gray-100 text-gray-500 disabled:bg-gray-300 dark:bg-gray-600 dark:bg-gray-900 dark:text-gray-100':
        btnType === 'secondary',
      'border  bg-orient-500 text-white ring-orient-500 hover:bg-orient-700 focus:ring-2 focus:ring-offset-1 disabled:border-orient-900 disabled:bg-orient-800':
        !btnType || ['primary', ''].includes(btnType),
    },
    'flex justify-center rounded-lg h-9 items-center px-2 font-medium disabled:cursor-not-allowed dark:border-gray-900 dark:bg-gray-900 dark:text-gray-200',
  )

  return (
    <button type={type} onClick={onClick} className={btnClass} {...props}>
      {children}
    </button>
  )
}

export default FButton
