import FModal from '../../../components/FModal'
import React from 'react'
import { useFormik } from 'formik'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import EWarning from '../../../components/EWarning'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'

const MassUpdateModal = ({
  isMassUpdateModalOpen,
  setIsMassUpdateModalOpen,
  subscriptions,
}) => {
  const Formik = useFormik({
    initialValues: {
      customerCompletedTeam: '',
    },
    onSubmit: values => {
      axiosInstance
        .post('customersubscription/bulkUpdateCustomerSubscription', {
          customerSubscriptionObject: {
            customerCompletedTeam: values.customerCompletedTeam,
          },
          customerSubscriptionIds: subscriptions.map(sub => sub.id),
        })
        .then(res => {
          toast.success('تم تعديل الاشتراكات بنجاح')
          setIsMassUpdateModalOpen(false)
        })
        .catch(err => {
          toast.error('حدث خطأ ما')
        })
    },
  })
  return (
    <>
      <FModal
        isOpen={isMassUpdateModalOpen}
        setIsOpen={setIsMassUpdateModalOpen}
        title={`تعديل جماعي لـ ${subscriptions.length} اشتراك`}
      >
        <form onSubmit={Formik.handleSubmit}>
          <EWarning msg={' سوف يتم تعديل جميع الاشتراكات المحددة '} />
          <EFormWrapper>
            <FLabel htmlFor='customerCompletedTeam'>فريق اكمال البيانات</FLabel>
            <FInputField
              id='customerCompletedTeam'
              name='customerCompletedTeam'
              type='text'
              value={Formik.values.customerCompletedTeam}
              onChange={Formik.handleChange}
            />
          </EFormWrapper>
          <div className={'flex   gap-2'}>
            <FButton type={'submit'}>حفظ</FButton>
            <FButton
              type={'button'}
              btnType={'secondary'}
              onClick={() => setIsMassUpdateModalOpen(false)}
            >
              الغاء
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default MassUpdateModal
