import React from 'react'
import { useFormik } from 'formik'
import EFormWrapper from '../../../components/EFormWrapper'
import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import FRow from '../../../components/FRow'
import FButton from '../../../components/FButton'
import { toast } from 'react-toastify'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import ExpireModal from './ExpireModal'
import RejectModal from './RejectModal'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as Yup from 'yup'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import FCol from '../../../components/FCol'

const EditClaim = () => {
  const { id } = useParams()

  const [isExpireModalOpen, setIsExpireModalOpen] = React.useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = React.useState(false)
  const { data, isLoading } = useQuery({
    queryKey: ['getClaim'],
    queryFn: () =>
      axiosInstance
        .get('/Compensation/listAllCompensationTypes?page=0&size=10')
        .then(res => res.data),
  })

  const claimData = useQuery({
    queryKey: ['getClaimData'],
    queryFn: () =>
      axiosInstance
        .get(`/Compensation/findCompensation/${id}`)
        .then(res => res.data.bean),
  })

  const mutation = useMutation({
    mutationFn: values =>
      axiosInstance
        .post('Compensation/editCompensation', values)
        .then(res => res.data),

    onSuccess: async () => {
      await toast.success('تم تعديل التعويض بنجاح')
      await claimData.refetch()
    },
    onError: () => {
      toast.error('حدث خطأ ما')
    },
  })

  const Form = useFormik({
    initialValues: {
      name: claimData?.data?.customer?.name || '',
      nationalId: claimData?.data?.customer?.nationalId || '',
      phoneNumber: claimData?.data?.customer?.phoneNumber || '',
      insuranceDocumentNumber: claimData?.data?.documentNumber || '',
      claimType: claimData?.data?.compensationType?.id || '',
      claimConfirmed: claimData?.data?.claimConfirmed || '',
      insuranceAmount: claimData?.data?.insuranceAmount || '',
      insuranceAmountPaid: claimData?.data?.insuranceAmountPaid || '',
      notes: claimData?.data?.notes || '',
      insuranceCompany: claimData?.data?.insuranceCompany || '',
      product: claimData?.data?.productId || '',
      claimAmount: claimData?.data?.claimAmount || '',
      claimRejected: claimData?.data?.claimRejected || '',
      claimExpired: claimData?.data?.claimExpired || '',
      claimRejectReason: claimData?.data?.claimRejectReason || '',
      claimExpireReason: claimData?.data?.claimExpireReason || '',
      closeData: claimData?.data?.closeData || '',
      claimTypeDocument:
        claimData?.data?.compensationType?.compensationTypeRequirements?.map(
          item => ({
            compensationTypeRequirementId: item.id,
            isExists: item.compensationTypeRequirementAnswer.isDataExists,
            note: item.compensationTypeRequirementAnswer.reasonOfNotExists,
            date: item.compensationTypeRequirementAnswer.dateOfData,
          }),
        ),
    },
    enableReinitialize: true,
    onSubmit: values => {
      const requestBody = {
        compensation: {
          id: parseInt(id),
          compensationTypeId: values.claimType,
          customer: {
            name: values.name,
            nationalId: values.nationalId,
            phoneNumber: values.phoneNumber,
          },
          productId: values.product,
          claimAmount: values.claimAmount,
          insuranceAmount: values.insuranceAmount,
          insuranceAmountPaid: values.insuranceAmountPaid,
          insuranceCompany: values.insuranceCompany,
          notes: values.notes,
          documentNumber: values.insuranceDocumentNumber,
          claimExpired: values.claimExpired,
          claimRejectReason: values.claimRejectReason,
          claimExpireReason: values.claimExpireReason,
          claimConfirmed: values.claimConfirmed,
          claimRejected: values.claimRejected,
        },
        answers: values.claimTypeDocument.map(item => ({
          compensationTypeRequirementId: item.compensationTypeRequirementId,
          isDataExists: item.isExists,
          dateOfData: item.date,
          reasonOfNotExists: item.note,
        })),
      }

      mutation.mutate(requestBody)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('الاسم مطلوب'),
      nationalId: Yup.string().required('الرقم القومي مطلوب'),
      phoneNumber: Yup.string().required('رقم الهاتف مطلوب'),
      insuranceDocumentNumber: Yup.string().required('رقم وثيقة التأمين مطلوب'),
      claimType: Yup.string().required('نوع التعويض مطلوب'),
      insuranceAmountPaid: Yup.string(),
    }),
  })
  if (isLoading) return <div>loading</div>
  else
    return (
      <div className={'p-4'}>
        {claimData?.data?.claimRejected && (
          <div className={' w-full rounded-md border bg-gray-50 p-4 shadow-sm'}>
            <FCol className={'f-col-center'}>
              <img
                width='100'
                height='100'
                src='https://img.icons8.com/fluency/100/cancel-2.png'
                alt='cancel-2'
              />
              <span className={'font-semibold'}>تم رفض هذا التعويض </span>
              <span>{claimData?.data?.claimRejectReason}</span>
            </FCol>
          </div>
        )}
        {claimData?.data?.claimConfirmed && (
          <div className={' w-full rounded-md border bg-gray-50 p-4 shadow-sm'}>
            <FCol className={'f-col-center'}>
              <img
                width='100'
                height='100'
                src='https://img.icons8.com/fluency/100/checked-2.png'
                alt='checked-2'
              />
              <span className={'font-semibold'}>تم صرف هذا التعويض </span>
            </FCol>
          </div>
        )}
        {claimData?.data?.claimExpired && (
          <div className={' w-full rounded-md border bg-gray-50 p-4 shadow-sm'}>
            <FCol className={'f-col-center'}>
              <img
                width='100'
                height='100'
                src='https://img.icons8.com/fluency/100/clock.png'
                alt='clock'
              />
              <span className={'font-semibold'}>
                تم انتهاء صلاحية هذا التعويض
              </span>
              <span>{claimData?.data?.claimExpireReason}</span>
            </FCol>
          </div>
        )}
        <div
          className={`flex p-4 ${
            (claimData?.data?.claimRejected ||
              claimData?.data?.claimConfirmed ||
              claimData?.data?.claimExpired) &&
            'event-none pointer-events-none cursor-not-allowed opacity-50'
          }`}
        >
          <div className={'w-full'}>
            <form className={'p-10'} onSubmit={Form.handleSubmit}>
              <ExpireModal
                isOpen={isExpireModalOpen}
                setIsOpen={setIsExpireModalOpen}
                Form={Form}
              />
              <RejectModal
                isOpen={isRejectModalOpen}
                setIsOpen={setIsRejectModalOpen}
                Form={Form}
                refetch={claimData.refetch}
              />

              <span className={'font-semibold'}>المعلومات الشخصية</span>

              <div className={'grid grid-cols-4 gap-3'}>
                <EFormWrapper>
                  <FLabel htmlFor={'name'}>الاسم</FLabel>
                  <FInputField
                    id={'name'}
                    label={'الاسم'}
                    name={'name'}
                    placeholder={'الاسم'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.name}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'name'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel id={'nationalId'}>الرقم القومي</FLabel>
                  <FInputField
                    id={'nationalId'}
                    label={'الرقم القومي'}
                    name={'nationalId'}
                    placeholder={'الرقم القومي'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.nationalId}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'nationalId'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'phoneNumber'}>رقم الهاتف</FLabel>
                  <FInputField
                    id={'phoneNumber'}
                    label={'رقم الهاتف'}
                    name={'phoneNumber'}
                    placeholder={'رقم الهاتف'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.phoneNumber}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'phoneNumber'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'insuranceDocumentNumber'}>
                    رقم وثيقة التأمين
                  </FLabel>
                  <FInputField
                    id={'insuranceDocumentNumber'}
                    label={'رقم وثيقة التأمين'}
                    name={'insuranceDocumentNumber'}
                    placeholder={'رقم وثيقة التأمين'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.insuranceDocumentNumber}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'insuranceDocumentNumber'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'insuranceCompany'}>شركة التأمين</FLabel>
                  <FInputField
                    name={'insuranceCompany'}
                    id={'insuranceCompany'}
                    placeholder={'رقم وثيقة التأمين'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.insuranceCompany}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'insuranceCompany'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'insuranceAmount'}>مبلغ التأمين</FLabel>
                  <FInputField
                    id={'insuranceAmount'}
                    name={'insuranceAmount'}
                    type={'number'}
                    min={0}
                    placeholder={'رقم وثيقة التأمين'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.insuranceAmount}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'insuranceAmount'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'claimAmount'}>قيمة المطالبة</FLabel>
                  <FInputField
                    id={'claimAmount'}
                    name={'claimAmount'}
                    type={'number'}
                    min={0}
                    placeholder={'المطالبة'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.claimAmount}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'claimAmount'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'insuranceAmountPaid'}>مبلغ التعويض </FLabel>
                  <FInputField
                    id={'insuranceAmountPaid'}
                    type={'number'}
                    min={0}
                    name={'insuranceAmountPaid'}
                    placeholder={'رقم وثيقة التأمين'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.insuranceAmountPaid}
                  />
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'insuranceAmountPaid'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'product'}>المنتج</FLabel>
                  <select
                    className={'input-style'}
                    value={Form.values.product}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    name={'product'}
                    id={'product'}
                  >
                    <option value={''}>اختر المنتج</option>
                    {localStorage.getItem('products') &&
                      JSON.parse(localStorage.getItem('products')).map(
                        (item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ),
                      )}
                  </select>
                  <EFormInvalidInput
                    errors={Form.errors}
                    FieldName={'product'}
                    touched={Form.touched}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel htmlFor={'closeData'}>تاريخ غلق الملف</FLabel>
                  <FInputField
                    id={'closeData'}
                    type={'date'}
                    name={'closeData'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.closeData}
                  />
                </EFormWrapper>
              </div>
              <EFormWrapper>
                <FLabel htmlFor={'notes'}>ملاحظات</FLabel>
                <textarea
                  className={'input-style'}
                  name={'notes'}
                  id={'notes'}
                  rows={5}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.notes}
                  placeholder={'ملاحظات'}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={'claimType'}>نوع التعويض</FLabel>
                <select
                  onChange={e => {
                    Form.setFieldValue('claimType', e.target.value)
                    let claimType =
                      data?.results[e.target.value].compensationTypeRequirements
                    claimType.map(item => {
                      item['isExists'] = false
                      item['note'] = ''
                      item['date'] = ''
                    })
                    Form.setFieldValue('claimTypeDocument', claimType)
                  }}
                  onBlur={Form.handleBlur}
                  value={Form.values.claimType}
                  name={'claimType'}
                  id={'claimType'}
                >
                  <option>اختر نوع التعويض</option>
                  {data?.results.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'claimType'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel>المرفقات</FLabel>
                {Form.values.claimTypeDocument?.map((item, index) => (
                  <div
                    key={index}
                    className={' rounded-md border bg-gray-50 p-3 shadow-sm'}
                  >
                    <span className={'mb-2 block font-medium'}>
                      {item.name}
                    </span>
                    <FRow className={'items-center gap-4'}>
                      <FRow>
                        <div className={'flex items-center gap-1'}>
                          <FLabel
                            htmlFor={`claimTypeDocument[${index}].isExists`}
                          >
                            تمت رفعه
                          </FLabel>
                          <FInputField
                            id={`claimTypeDocument[${index}].isExists`}
                            className={'h-4 w-4'}
                            type={'radio'}
                            name={`claimTypeDocument[${index}].isExists`}
                            value={true}
                            checked={
                              Form.values.claimTypeDocument[index].isExists ===
                              true
                            }
                            onChange={() =>
                              Form.setFieldValue(
                                `claimTypeDocument[${index}].isExists`,
                                true,
                              )
                            }
                            onBlur={Form.handleBlur}
                            label={'موجود'}
                          />
                        </div>
                        <div className={'flex items-center gap-1'}>
                          <FLabel
                            htmlFor={`claimTypeDocument[${index}].notExists`}
                          >
                            لم يتم تقديمه
                          </FLabel>
                          <FInputField
                            id={`claimTypeDocument[${index}].notExists`}
                            className={'h-4 w-4'}
                            type={'radio'}
                            name={`claimTypeDocument[${index}].isExists`}
                            value={false}
                            checked={
                              Form.values.claimTypeDocument[index].isExists ===
                              false
                            }
                            onChange={() =>
                              Form.setFieldValue(
                                `claimTypeDocument[${index}].isExists`,
                                false,
                              )
                            }
                            onBlur={Form.handleBlur}
                            label={'غير موجود'}
                          />
                        </div>
                      </FRow>

                      <FInputField
                        type={'date'}
                        max={moment().format('YYYY-MM-DD')}
                        name={`claimTypeDocument[${index}].date`}
                        onChange={Form.handleChange}
                        onBlur={Form.handleBlur}
                        value={moment(
                          Form.values.claimTypeDocument[index].date,
                        ).format('YYYY-MM-DD')}
                        label={'تاريخ'}
                      />
                      <textarea
                        rows={1}
                        className={'input-style'}
                        name={`claimTypeDocument[${index}].note`}
                        onChange={Form.handleChange}
                        onBlur={Form.handleBlur}
                        value={Form.values.claimTypeDocument[index].note}
                        placeholder={'ملاحظات بخصوص الملف'}
                      />
                    </FRow>
                  </div>
                ))}
              </EFormWrapper>

              <FRow className={'gap-2'}>
                <FButton
                  onClick={() => {
                    Form.setFieldValue('claimConfirmed', true)
                    Form.setFieldValue('claimRejected', false)
                    Form.setFieldValue('claimExpired', false)
                    Form.handleSubmit()
                  }}
                  type={'button'}
                  btnType={'success'}
                >
                  تم صرف التعويض
                </FButton>
                <FButton
                  type={'button'}
                  onClick={() => setIsRejectModalOpen(true)}
                  btnType={'danger'}
                >
                  تم رفض التعويض
                </FButton>
                <FButton
                  type={'button'}
                  onClick={() => {
                    Form.setFieldValue('claimConfirmed', false)
                    Form.setFieldValue('claimRejected', false)
                    Form.setFieldValue('claimExpired', false)
                    Form.setFieldValue('claimExpireReason', '')
                    Form.setFieldValue('claimRejectReason', '')
                    Form.handleSubmit()
                    setIsRejectModalOpen(false)
                  }}
                >
                  <span className={'mr-1 ml-1 text-sm'}>تعديل</span>
                </FButton>
                <FButton
                  type={'button'}
                  onClick={() => setIsExpireModalOpen(true)}
                  btnType={'secondary'}
                >
                  تم انتهاء صلاحية التعويض
                </FButton>
                <Link className={'mr-auto'} to={'/claim'}>
                  <FButton btnType={'secondary'}>رجوع</FButton>
                </Link>
              </FRow>
            </form>
          </div>
        </div>
      </div>
    )
}
export default EditClaim
