import ECard from '../../components/ECard'
import { Formik } from 'formik'
import SFormWrapper from '../../components/EFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import EFormInvalidInput from '../../components/EFormInvalidInput'
import FButton from '../../components/FButton'
import FFlexWrapper from '../../components/FFlexWrapper'
import { HiOutlinePlusSm } from 'react-icons/hi'
import * as Yup from 'yup'

const SecuritySettings = () => {
  const handleFormSubmission = vales => {}

  const ValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(),
    currentPassword: Yup.string()
      .oneOf([Yup.ref('oldPassword'), null], 'Passwords must match')
      .required(),
  })

  return (
    <>
      <ECard>
        <ECard.Header>
          <span>Security and Authentication </span>
        </ECard.Header>
        <ECard.Body>
          <Formik
            validationSchema={ValidationSchema}
            initialValues={{
              oldPassword: '',
              currentPassword: '',
            }}
            onSubmit={handleFormSubmission}
          >
            {({
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={'  rounded-md  '}
              >
                <div className={'flex flex-col  gap-2'}>
                  <SFormWrapper className={'w-full'}>
                    <FLabel htmlFor={'oldPassword'}> Current password</FLabel>
                    <FInputField
                      type='password'
                      name={'oldPassword'}
                      id={'oldPassword'}
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <EFormInvalidInput
                      touched={touched}
                      errors={errors}
                      FieldName={'oldPassword'}
                    />
                  </SFormWrapper>
                  <SFormWrapper className={'w-full'}>
                    <FLabel htmlFor={'currentPassword'}> New password</FLabel>
                    <FInputField
                      type='password'
                      name={'currentPassword'}
                      id={'currentPassword'}
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <EFormInvalidInput
                      touched={touched}
                      errors={errors}
                      FieldName={'currentPassword'}
                    />
                  </SFormWrapper>
                  <SFormWrapper className={'w-fit'}>
                    <FButton type={'submit '}>
                      <FFlexWrapper>
                        <HiOutlinePlusSm />
                        <span> Add New</span>
                      </FFlexWrapper>
                    </FButton>
                  </SFormWrapper>
                </div>
              </form>
            )}
          </Formik>
        </ECard.Body>
      </ECard>
    </>
  )
}
export default SecuritySettings
