import { NavLink, Outlet } from 'react-router-dom'
import { t } from 'i18next'

const FNavLink = ({ to, children }) => (
  <>
    <NavLink
      to={to}
      className={navData =>
        'flex items-center justify-start gap-2 rounded-md py-2 px-3 text-sm font-medium  ' +
        (navData.isActive
          ? 'border  bg-orient-500  text-white '
          : ' text-gray-700 dark:text-gray-100  ')
      }
    >
      {children}
    </NavLink>
  </>
)

const Settings = () => (
  <>
    <div className={'flex min-h-full gap-3'}>
      <div
        className={
          'flex w-60 flex-col gap-2 border-r bg-white p-1 dark:bg-gray-900'
        }
      >
        <FNavLink className={'text-sm'} to={'details'}>
          Account Details
        </FNavLink>
        <FNavLink className={'text-sm'} to={'security'}>
          Security
        </FNavLink>
      </div>
      <div className={'w-full p-3'}>
        <span className={'text-2xl font-medium'}>{t('settings')}</span>
        <Outlet />
      </div>
    </div>
  </>
)
export default Settings
