import FModal from '../../../components/FModal'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FAvatar from '../../../components/FAvatar'
import FProductTag from '../../../components/FProductTag'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { toast } from 'react-toastify'

const DistributionModal = ({ isOpen, setIsOpen, Refetch }) => {
  const [maxAgents, setMaxAgents] = useState('')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [resetOldAssignedCalls, setResetOldAssignedCalls] = useState(false)
  const [isDistributionLoading, setIsDistributionLoading] = useState(false)
  const [automaticAgents, setAutomaticAgents] = useState([])

  const productResponse = useCustomAxios({
    method: 'GET',
    url: `/product/listAllProducts?page=${0}&size=50`,
  })
  const { data, isLoading } = useQuery({
    queryKey: ['products'],
    queryFn: () =>
      axiosInstance
        .get(`/user/?page=${0}&size=10&authority=AGENT_AUTHORITY`)
        .then(res => res.data.userDtoList),
  })
  const toggleAddAndRemoveAgent = e => {
    const agent = e.target.name

    if (automaticAgents.includes(agent)) {
      const newAgents = automaticAgents.filter(item => item !== agent)
      setAutomaticAgents(newAgents)
    } else {
      setAutomaticAgents([...automaticAgents, agent])
    }
  }

  const handleCallDistribution = () => {
    setIsDistributionLoading(true)
    const payLoad = {
      agents: automaticAgents,
      maxNumberOfCallsPerAgent: maxAgents,
      productId: selectedProduct,
      resetOldAssignation: resetOldAssignedCalls,
    }
    axiosInstance
      .post('call/distributeCustomers', payLoad)
      .then(res => {
        setIsDistributionLoading(false)
        setIsOpen(false)
        toast.success('تم توزيع المكالمات تلقائيا بنجاح')
        Refetch()
      })
      .catch(err => {
        setIsDistributionLoading(false)
        toast.error('حدث خطأ ما')
      })
  }

  if (isLoading) return <span>Loading</span>
  else
    return (
      <>
        <FModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          title={'توزيع المكالمات أوتوماتيكياً'}
          isAutoWidth
        >
          <EFormWrapper>
            <FLabel> موظفي خدمة العملاء</FLabel>
            <div className={'mt-2 grid grid-cols-4 gap-2'}>
              {data.map(agent => {
                return (
                  <div className={'flex'} key={agent.id}>
                    <label
                      className={
                        ' flex w-full cursor-pointer  flex-row-reverse items-center justify-end gap-2  rounded border border-gray-300 bg-gray-100 px-4 py-2 shadow-sm dark:bg-gray-600'
                      }
                      htmlFor={agent.email}
                    >
                      <div>
                        <div className={'flex select-none gap-2 text-right'}>
                          <FAvatar name={agent.name} />
                          <div className={'flex flex-col'}>
                            <span
                              className={
                                'flex items-center gap-2 text-sm font-semibold leading-3'
                              }
                            >
                              {agent.name ?? 'N/A'}
                              {agent.holiday && (
                                <FProductTag
                                  productName={'اجازة'}
                                  color={'red'}
                                />
                              )}
                            </span>
                            <span
                              className={
                                'text-xs  text-gray-500 dark:text-gray-100  '
                              }
                            >
                              {agent.email}
                            </span>
                          </div>
                        </div>
                      </div>

                      <FInputField
                        className={'h-4  w-4 rounded-sm border-gray-400'}
                        type={'checkbox'}
                        id={agent.email}
                        name={agent.email}
                        value={agent.email}
                        onChange={e => {
                          toggleAddAndRemoveAgent(e)
                        }}
                      />
                    </label>
                  </div>
                )
              })}
            </div>
          </EFormWrapper>

          <EFormWrapper>
            <FLabel htmlFor={'callsMax'}>
              الحد الأقصى لعدد العملاء لكل موظف خدمة عملاء
            </FLabel>
            <FInputField
              name={'callsMax'}
              id={'callsMax'}
              type={'number'}
              min={1}
              placeholder={'أقصى عدد من المكالمات لكل موظف'}
              value={maxAgents}
              onChange={e => setMaxAgents(e.target.value)}
            />
          </EFormWrapper>
          <EFormWrapper>
            <FLabel htmlFor={'product'}> المنتج التأميني</FLabel>
            <select
              value={selectedProduct}
              onChange={e => setSelectedProduct(e.target.value)}
              id={'product'}
              className={'input-style'}
            >
              <option value=''>أختر منتج</option>
              {productResponse.response?.products?.map(product => (
                <option value={product.id}>{product.name}</option>
              ))}
            </select>
          </EFormWrapper>
          <div className={'  flex items-center gap-2'}>
            <FInputField
              className={'h-4  w-4 rounded-sm border-gray-400'}
              type={'checkbox'}
              name={'resetOldAssignedCalls'}
              id={'resetOldAssignedCalls'}
              label={'إعادة توزيع المكالمات القديمة'}
              value={resetOldAssignedCalls}
              checked={resetOldAssignedCalls}
              onChange={e => setResetOldAssignedCalls(!resetOldAssignedCalls)}
            />
            <FLabel htmlFor={'resetOldAssignedCalls'}>
              مسح المكالمات القديمة
            </FLabel>
          </div>
          <div className={'mt-3 flex gap-2 '}>
            <FButton onClick={() => handleCallDistribution()}>
              <FIconWrapper>
                <ESpinner isVisible={isDistributionLoading} />
                <span>توزيع</span>
              </FIconWrapper>
            </FButton>
            <FButton btnType={'secondary'} onClick={() => setIsOpen(false)}>
              <span>إلغاء</span>
            </FButton>
          </div>
        </FModal>
      </>
    )
}
export default DistributionModal
