import { useCustomAxios } from '../../../Hooks/useAxios'
import React, { useState } from 'react'
import FButton from '../../../components/FButton'
import AddCallType from './AddCallType'

const ListCallTypes = () => {
  const [page, setPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { response, error, isLoading } = useCustomAxios({
    method: 'GET',
    url: `call/subType?page=${page}&size=10`,
  })

  return (
    <div>
      <AddCallType isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <h1>ListCallTypes</h1>
      <FButton
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Add Call Type
      </FButton>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {response?.callSchedules?.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ListCallTypes
