import FModal from '../../../components/FModal'
import { useFormik } from 'formik'
import React from 'react'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'

const AddCallType = ({ isModalOpen, setIsModalOpen }) => {
  const Form = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: ({ name }) => {
      axiosInstance
        .post('/call/subType', {
          name: name,
        })
        .then(res => {
          setIsModalOpen(false)
          toast.success('Call Type Added Successfully')
        })
        .catch(err => {
          toast.error('Something went wrong')
        })
    },
  })
  return (
    <>
      <FModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title='Add Call Type'
      >
        <form onSubmit={Form.handleSubmit}>
          <EFormWrapper>
            <FLabel htmlFor={'name'}>Name</FLabel>
            <FInputField
              id='name'
              type={'text'}
              value={Form.values.name}
              onChange={Form.handleChange}
            />
          </EFormWrapper>
          <div className={'mt-2 flex items-center gap-2'}>
            <FButton type={'submit'}>Submit</FButton>
            <FButton>Cancel</FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default AddCallType
