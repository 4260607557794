//todo:: Review adding extra blocks for all sections because of id
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useCustomAxios } from '../../../Hooks/useAxios'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import React, { useEffect, useState } from 'react'
import { getNested } from '../../../helpers/utils'
import FButton from '../../../components/FButton'
import { IoMdRemoveCircleOutline } from 'react-icons/io'
import FIconWrapper from '../../../components/FIconWrapper'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import { FiPrinter } from 'react-icons/fi'
import ProductCompletionTrails from './ProductCompletionTrails'
import AgentDetails from './AgentDetails'
import ESpinner from '../../../components/ESpinner'
import { HiDownload } from 'react-icons/hi'
import ProductTrades from './ProductTrades'
import ProductPackages from './ProductPackages'

const ProductDetails = () => {
  const { id } = useParams()
  const navigation = useNavigate()

  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `/product/getProduct?id=${id}`,
  })
  const [__productBasicInfo, __setProductBasicInfo] = useState({
    productBasicInfo: {
      productName: {
        value: '',
        errors: '',
      },
      productBTC: {
        value: '',
        errors: '',
      },
    },
  })
  const [tradeInfo, setTradeInfo] = useState([])
  const [productDetails, setProductDetails] = useState([])
  const [agentInfoPackage, setAgentInfoPackage] = useState({
    agentPackage: [
      {
        filedName: '',
        reportFiledName: '',
        isNullable: false,
        includeNumberOnly: false,
        includeTextOnly: false,
        isLengthRequired: false,
        minLength: 0,
        maxLength: 0,
      },
    ],
  })
  const [trailsBlock, setTrailsBlock] = useState({
    trails: [
      {
        trialCount: '',
        trialGap: '',
        trialTimes: [],
      },
    ],
  })

  const mapProductInstallmentsintoAnotherKeys = productInstallments => {
    return productInstallments.map(productInstallment => {
      return {
        installmentPrice: {
          value: productInstallment.installmentCost,
          errors: '',
        },
        installmentDetails: {
          value: productInstallment.installmentDesc,
          errors: '',
        },
        installmentDuration: {
          value: productInstallment.typeForView,
          errors: '',
        },
        installmentCoverage: {
          value: productInstallment.installmentCoverage,
          errors: '',
        },
        id: {
          value: productInstallment.id,
          errors: '',
        },
      }
    })
  }
  const mapCustomerInfoPackageintoAnotherKeys = customerInfoPackage => {
    return customerInfoPackage.map(customerInfo => {
      return {
        filedName: customerInfo.inputName,
        reportFiledName: customerInfo.inputNameInReport,
        isNullable: customerInfo.canBeEmpty,
        includeNumberOnly: customerInfo.containsOnlyNumbers,
        includeTextOnly: customerInfo.containsOnlyLetters,
        isLengthRequired: customerInfo.allowCountChars,
        minLength: customerInfo.minChars,
        maxLength: customerInfo.maxChars,
        id: customerInfo.id,
      }
    })
  }
  const productRetrialDateIntoAnotherKeys = productRetrialDate => {
    return productRetrialDate.map(productRetrial => {
      return {
        trailTime:
          productRetrial.dialDate !== null ? productRetrial.dialDate : '',
        id: productRetrial.id,
      }
    })
  }
  const mapTrailsIntoAnotherKeys = trails => {
    return trails.map(trail => {
      return {
        trialCount: trail.retrialCount,
        trialGap: trail.day,
        trialTimes: productRetrialDateIntoAnotherKeys(trail.productRetrialDate),
        id: trail.id,
      }
    })
  }

  const mapProductTradeInfoIntoAnotherKeys = productTradeInfo => {
    console.log(productTradeInfo)
    return productTradeInfo.map(productTrade => {
      return {
        tradeName: productTrade.tradeName,
        gigProductName: productTrade.gigProductName,
      }
    })
  }

  useEffect(() => {
    __setProductBasicInfo(prevState => ({
      productBasicInfo: {
        ...prevState.productBasicInfo,
        productName: {
          value: getNested(response, 'product', 'name'),
          errors: '',
        },
        productBTC: {
          value: getNested(response, 'product', 'btc'),
          errors: '',
        },
      },
    }))

    if (
      typeof getNested(response, 'product', 'productInstallments') === 'object'
    ) {
      setProductDetails(prevState => [
        ...mapProductInstallmentsintoAnotherKeys(
          getNested(response, 'product', 'productInstallments'),
        ),
        ...prevState,
      ])
    }
    //needed to be fixed
    if (
      typeof getNested(response, 'product', 'productCustomerInfos') === 'object'
    ) {
      setAgentInfoPackage(prevState => ({
        ...prevState,
        agentPackage: mapCustomerInfoPackageintoAnotherKeys(
          getNested(response, 'product', 'productCustomerInfos'),
        ),
      }))
    }
    if (typeof getNested(response, 'product', 'productRetrials') === 'object') {
      setTrailsBlock(prevState => ({
        ...prevState,
        trails: mapTrailsIntoAnotherKeys(
          getNested(response, 'product', 'productRetrials'),
        ),
      }))
    }
    if (
      typeof getNested(response, 'product', 'productTradeInfo') === 'object'
    ) {
      setTradeInfo(prevState => [
        ...mapProductTradeInfoIntoAnotherKeys(
          getNested(response, 'product', 'productTradeInfo'),
        ),
        ...prevState,
      ])
    }
  }, [response])

  const handleProductBasicInfoChange = e => {
    const { name, value } = e.target
    __setProductBasicInfo({
      ...__productBasicInfo,
      productBasicInfo: {
        ...__productBasicInfo.productBasicInfo,
        [name]: { value: value, errors: '' },
      },
    })
  }

  const handleProductDeactivation = () => {
    axiosInstance
      .post('/product/deActivateProduct', { id: id })
      .then(res => {
        toast.success('تم تعطيل المنتج')
        navigation('/product')
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const handleProductTemplateDownload = () => {
    axiosInstance
      .get(
        `/report/download?excelReportType=PRODUCT_TEMPLATE&productID=${id}`,
        {
          responseType: 'blob',
        },
      )
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${response.product.name}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const handleFormSubmission = e => {
    e.preventDefault()
    const data = {
      productName: __productBasicInfo.productBasicInfo.productName.value,
      btc: __productBasicInfo.productBasicInfo.productBTC.value,
      productInstallments: productDetails.map(productDetail => {
        console.log(productDetail)
        return {
          id: productDetail.id.value,
          installmentCost: productDetail.installmentPrice.value,
          installmentDesc: productDetail.installmentDetails.value,
          installmentPeriod: productDetail.installmentDuration.value,
          installmentCoverage: productDetail.installmentCoverage.value,
        }
      }),
      productCustomerInfos: agentInfoPackage.agentPackage.map(agentInfo => {
        return {
          inputName: agentInfo.filedName,
          canBeEmpty: agentInfo.isNullable,
          containsOnlyNumbers: agentInfo.includeNumberOnly,
          containsOnlyLetters: agentInfo.includeTextOnly,
          allowCountChars: agentInfo.isLengthRequired,
          minChars: agentInfo.minLength,
          maxChars: agentInfo.maxLength,
          id: agentInfo.id,
        }
      }),
      productRetrials: trailsBlock.trails.map(trail => {
        return {
          id: trail.id,
          retrialCount: trail.trialCount,
          day: trail.trialGap,
          productRetrialDate: trail.trialTimes.map(trialTime => {
            return {
              dialDate: trialTime.trailTime + ':00',
              id: trialTime.id,
              deleted: trialTime.deleted,
            }
          }),
        }
      }),
      productType: 'PRODUCT',
      id: id,
    }
    axiosInstance
      .post('/product/editProduct', data)
      .then(res => {
        toast.success('تم تعديل المنتج')
        navigation('/product')
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  function PrintElem() {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600')

    mywindow.document.write('<html><head><title>' + document.title + '</title>')
    mywindow.document.write('</head><body >')
    mywindow.document.write('<h1>' + document.title + '</h1>')
    mywindow.document.write(
      document.getElementById('product_preview').innerHTML,
    )
    mywindow.document.write('</body></html>')
    mywindow.print()
    return true
  }

  if (loading) return <div>Loading...</div>
  else
    return (
      <>
        <div className={'flex-grow'}>
          <div
            className={
              'flex items-center justify-between border-b bg-white px-2 dark:bg-gray-900'
            }
          >
            <div className={'flex gap-2 p-3'}>
              <NavLink className={'text-gray-400'} to={'/product'}>
                المنتجات
              </NavLink>
              <span className={'text-gray-400'}>/</span>
              <NavLink className={'font-semibold text-orient-500'} to={'#'}>
                {getNested(response, 'product', 'name')}
              </NavLink>
            </div>
            <div className={'flex flex-row-reverse gap-2'}>
              <FButton
                onClick={() => handleProductDeactivation()}
                btnType={'danger'}
              >
                <FIconWrapper>
                  <IoMdRemoveCircleOutline />
                  تعطيل المنتج
                </FIconWrapper>
              </FButton>

              <FButton onClick={() => handleProductTemplateDownload()}>
                <FIconWrapper>
                  <HiDownload />
                  تنزيل القالب
                </FIconWrapper>
              </FButton>
            </div>
          </div>
          <div className={'flex '}>
            <div
              id={'product_preview'}
              className={'block  bg-white py-5  pr-5 dark:bg-gray-900'}
            >
              <div
                className={
                  'sticky top-24 rounded-md border bg-white shadow-sm  dark:bg-gray-900'
                }
              >
                <div
                  className={
                    'f-row-between rounded-md border-b bg-white p-3 dark:bg-gray-900'
                  }
                >
                  <span>معاينة المنتج</span>
                  <FButton btnType={'secondary'} onClick={PrintElem}>
                    <FiPrinter size={20} />
                  </FButton>
                </div>
                <div
                  className={'mt-3 flex flex-col items-center justify-center '}
                >
                  <div
                    style={{
                      backgroundImage: `url(${require('../../../assets/images/Prviewlogo.png')})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    className={
                      'h-24 w-24  rounded-full bg-white dark:bg-gray-900'
                    }
                  ></div>
                  <span className={'block text-center font-bold'}>
                    {__productBasicInfo.productBasicInfo.productName.value}
                  </span>
                  <span className={'block text-center text-sm'}>
                    {__productBasicInfo.productBasicInfo.productBTC.value}
                  </span>
                </div>
                <div className={'mt-5 p-1'}>
                  <span className={'text-xs font-bold'}>تفاصيل القسط</span>
                  <table className='mt-2 w-full table-auto rounded  bg-gray-100 text-right dark:bg-gray-600'>
                    <thead className={' '}>
                      <tr>
                        <th
                          className={'border p-0.5 text-xs print:text-red-800'}
                        >
                          تكلفة القسط
                        </th>
                        <th
                          className={'border p-0.5 text-xs print:text-red-800'}
                        >
                          التفاصيل
                        </th>
                        <th
                          className={'border p-0.5 text-xs print:text-red-800'}
                        >
                          المدة
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.map(
                        (productDetail, index) =>
                          (productDetail.installmentPrice.value !== '' ||
                            productDetail.installmentDetails.value !== '' ||
                            productDetail.installmentDuration.value !== '') && (
                            <tr key={index}>
                              <td
                                className={
                                  'border-b border-r px-2 py-0.5 text-xs'
                                }
                              >
                                {productDetail.installmentPrice.value}
                              </td>
                              <td
                                className={
                                  'border-b border-r px-2 py-0.5 text-xs'
                                }
                              >
                                {productDetail.installmentDetails.value}
                              </td>
                              <td
                                className={
                                  'border-b border-r px-2 py-0.5 text-xs'
                                }
                              >
                                {productDetail.installmentDuration.value}
                              </td>
                            </tr>
                          ),
                      )}
                    </tbody>
                  </table>
                  <span className={'text-xs font-bold'}>
                    البيانات المطلوبة من العميل لأستخراج الوثيقة
                  </span>
                  {agentInfoPackage.agentPackage.map((agentInfo, index) => {
                    return (
                      <div key={index}>
                        <span className={'block text-right text-xs'}>
                          {`${index + 1}. ${agentInfo.filedName}`}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={'flex-grow'}>
              <form className={'bg-white p-5 dark:bg-gray-900 '}>
                <span className={' block font-medium '}>تفاصيل المنتج</span>
                <div className={'flex gap-2 '}>
                  <SFormWrapper className={'w-6/12'}>
                    <FLabel htmlFor={'productName'}> أسم المنتج </FLabel>
                    <FInputField
                      type='text'
                      name={'productName'}
                      id={'productName'}
                      value={
                        __productBasicInfo.productBasicInfo.productName.value
                      }
                      onChange={e => handleProductBasicInfoChange(e)}
                      placeholder={'أدخل أسم المنتج'}
                    />
                    <small className={'text-right font-medium text-red-700'}>
                      {' '}
                      {
                        __productBasicInfo.productBasicInfo.productName.errors
                      }{' '}
                    </small>
                  </SFormWrapper>
                  <SFormWrapper className={'w-6/12'}>
                    <FLabel htmlFor={'productBTC'}> BTC المنتج </FLabel>
                    <FInputField
                      type='text'
                      name={'productBTC'}
                      id={'productBTC'}
                      value={
                        __productBasicInfo.productBasicInfo.productBTC.value
                      }
                      onChange={e => handleProductBasicInfoChange(e)}
                      placeholder={'أدخل بي تي سي المنتج'}
                    />
                    <small className={'text-right font-medium text-red-700'}>
                      {' '}
                      {
                        __productBasicInfo.productBasicInfo.productBTC.errors
                      }{' '}
                    </small>
                  </SFormWrapper>
                </div>
                <ProductTrades
                  tradeInfo={tradeInfo}
                  setTradeInfo={setTradeInfo}
                />
                <ProductPackages
                  productDetails={productDetails}
                  setProductDetails={setProductDetails}
                />
                <ProductCompletionTrails
                  trailsBlock={trailsBlock}
                  setTrailsBlock={setTrailsBlock}
                />
                <AgentDetails
                  agentInfoPackage={agentInfoPackage}
                  setAgentInfoPackage={setAgentInfoPackage}
                />
                <FButton type={'submit'} onClick={handleFormSubmission}>
                  <FIconWrapper>
                    <ESpinner isVisible={false} />
                    تعديل
                  </FIconWrapper>
                </FButton>
              </form>
            </div>
          </div>
        </div>
      </>
    )
}
export default ProductDetails
