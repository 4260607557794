import FModal from '../../../components/FModal'
import React from 'react'
import FButton from '../../../components/FButton'
import FRow from '../../../components/FRow'

const ExpireModal = ({ isOpen, setIsOpen, Form }) => {
  return (
    <>
      <FModal
        title={'انتهاء صلاحية التعويض'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <textarea
          className={'input-style'}
          name={'claimExpireReason'}
          value={Form.values.claimExpireReason}
          onChange={Form.handleChange}
          onBlur={Form.handleBlur}
          placeholder={'سبب انتهاء صلاحية التعويض'}
        />
        <FRow className={'mt-4'}>
          <FButton
            type={'button'}
            onClick={() => {
              Form.setFieldValue('claimConfirmed', null)
              Form.setFieldValue('claimExpired', true)
              Form.setFieldValue('claimRejected', null)
              Form.handleSubmit()
              setIsOpen(false)
            }}
          >
            حفظ الرفض
          </FButton>
          <FButton onClick={() => setIsOpen(false)} btnType={'secondary'}>
            الغاء
          </FButton>
        </FRow>
      </FModal>
    </>
  )
}
export default ExpireModal
