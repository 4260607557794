import React from 'react'

const FLabel = ({ isRequired, htmlFor, children, ...props }) => (
  <div className={'flex gap-1'}>
    <label
      htmlFor={htmlFor}
      {...props}
      className={'w-full whitespace-nowrap  cursor-pointer text-sm'}
    >
      {children}
    </label>
    {isRequired === true && <span className={'font-bold text-red-500'}>*</span>}
  </div>
)
export default FLabel
