import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import moment from 'moment'
import FProductTag from '../../../components/FProductTag'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'

const ResidualsList = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const pageQueryParam = parseInt(queryParams.get('page')) || 0

  const [page, setPage] = useState(pageQueryParam)

  const { data, refetch, error, isLoading } = useQuery({
    queryKey: ['agents', page],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/ResidualFees/findAllResidualFees?page=${page}&size=50`,
      )
      return data
    },
    cacheTime: 3000,
  })

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    queryParams.set('page', page)
    navigate(`?${queryParams.toString()}`)
  }, [page])

  const cols = [
    {
      Header: 'بي تي سي',
      accessor: 'btc', // String-based value accessors!
    },
    {
      Header: 'كود عملية الدفع',
      accessor: 'transactionCode', // String-based value accessors!
    },
    {
      Header: 'الكمية',
      accessor: 'amount', // String-based value accessors!
    },
    {
      Header: 'رقم الهاتف',
      accessor: 'phoneNumber', // String-based value accessors!
    },
    {
      Header: 'أسم العميل',
      accessor: 'customerSubscription', // String-based value accessors!
      cell: value => {
        return value.customer.name
      },
    },
    {
      Header: 'تاريخ المعاملة',
      accessor: 'transactionDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return moment(value).format(' HH:mm:ss ⏰  YYYY-MM-DD 📅')
      },
    },
    {
      Header: 'تاريخ الإضافة',
      accessor: 'creationDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return moment(value).format(' HH:mm:ss ⏰  YYYY-MM-DD 📅')
      },
    },
    {
      Header: 'حالة',
      accessor: 'active',
      cell: value =>
        !value ? (
          <FProductTag productName={'تم التسوية'} color='green' />
        ) : (
          <FProductTag productName={'معلق'} color='red'>
            غير مفعل
          </FProductTag>
        ),
    },
  ]

  return (
    <>
      <div className={'f-row-between  flex items-center p-3'}>
        <span className={'text-xl font-medium'}>المعلقات</span>
      </div>

      <FCustomDataTable
        cols={cols}
        data={data?.residualsFees || []}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        totalNumberOfPages={data?.totalNumberOfPages}
        rowFunction={row => {
          navigate(`/settle/${row.phoneNumber}/${row.id}`)
        }}
        Refetch={refetch}
      />
    </>
  )
}
export default ResidualsList
