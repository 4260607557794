import fawryGig from '../../../assets/images/fawry-gig.png'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai'
import React from 'react'
import { axiosInstance } from '../../../api/requister'
import FModal from '../../../components/FModal'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'
import FInputField from '../../../components/FInputField'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import ElectronicResponseModal from './ElectronicResponseModal'
import FRow from '../../../components/FRow'
import { logFormData } from '../../../helpers/utils'
import moment from 'moment/moment'

const OutputReport = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false)
  const [file, setFile] = React.useState(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState('')
  const [reportStartDate, setReportStartDate] = React.useState('')
  const [reportEndDate, setReportEndDate] = React.useState('')
  const [isDownloading, setIsDownloading] = React.useState(false)
  const handleDocumentedFeesReportsDownload = () => {
    axiosInstance
      .get(
        '/report/download?excelReportType=DOCUMENTS_AND_COMMISSIONS_RECORDS',
        {
          responseType: 'blob',
        },
      )
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/product/listAllProducts?page=0&size=10000`,
    })
  const handleDownloadExtractReport = () => {
    setIsDownloading(true)
    let url = `/report/downloadProductReleaseExcel?productID=${selectedProduct}`
    if (reportStartDate !== '') {
      url += `&startDate=${reportStartDate}`
    }
    if (reportEndDate !== '') {
      url += `&endDate=${reportEndDate}`
    }
    if (includeLoadedExtractions) {
      url += `&forceDownload=${includeLoadedExtractions}`
    }
    if (useCompletionDate) {
      url += `&filterByCompleteDate=true`
    }
    axiosInstance
      .get(
        `${url}`,

        {
          responseType: 'blob',
        },
      )
      .then(res => {
        setIsDownloading(false)
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute(
          'download',
          `استخراج منتج ${new Date().toISOString().split('T')[0]}.xlsx`,
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        setIsDownloading(false)
        console.log(err)
      })
  }

  const handleUploadExtractReport = () => {
    setIsUploading(true)
    const formData = new FormData()
    formData.append('file', file)
    logFormData(formData)
    axiosInstance
      .post('/upload/gigExtractionResponseFile', formData, {
        responseType: 'blob', // Set the response type to blob,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        const modifiedFile = response.data
        // Create a URL for the modifiedFile blob
        const fileUrl = URL.createObjectURL(modifiedFile)

        // Create a temporary anchor element
        const downloadLink = document.createElement('a')
        downloadLink.href = fileUrl
        const fileNameArrayConstruct = [
          'رد ملف تاكيد الاصدار - ',
          moment().format('MMMM Do YYYY, h:mm:ss a'),
        ]
        downloadLink.download = fileNameArrayConstruct.join('').concat('.xlsx') // Set the desired file name with .xlsx extension
        // Append the anchor element to the document body
        document.body.appendChild(downloadLink)

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        // Clean up by removing the anchor element and revoking the URL
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(fileUrl)
        setIsUploading(false)
        toast.success('تم رفع الملف بنجاح')
      })
      .catch(err => {
        setIsUploading(false)
        toast.error(err.response.data.message)
      })
  }
  const [isElectronicResponseModalOpen, setIsElectronicResponseModalOpen] =
    React.useState(false)

  const [includeLoadedExtractions, setIncludeLoadedExtractions] =
    React.useState(false)

  const [useCompletionDate, setUseCompletionDate] = React.useState(true)
  return (
    <>
      <ElectronicResponseModal
        isOpen={isElectronicResponseModalOpen}
        setIsOpen={setIsElectronicResponseModalOpen}
      />
      {!loading && (
        <FModal
          callbackFunction={() => {
            setSelectedProduct('')
            setReportStartDate('')
            setReportEndDate('')
          }}
          setIsOpen={setShowModal}
          isOpen={showModal}
          title={'تنزيل تقرير الأستخراج'}
        >
          <FRow>
            <FLabel>
              <input
                className={'ml-2 h-4 w-4 rounded border-gray-300'}
                type={'checkbox'}
                checked={includeLoadedExtractions}
                onChange={e => setIncludeLoadedExtractions(e.target.checked)}
              />
              تضمين الاستخراجات المحملة سابقا
            </FLabel>
          </FRow>

          <FRow>
            <FLabel>
              <input
                className={'ml-2 h-4 w-4 rounded border-gray-300'}
                type={'checkbox'}
                checked={useCompletionDate}
                onChange={e => setUseCompletionDate(e.target.checked)}
              />
              استخدام تاريخ اكتمال الاشتراك بدل عن تاريخ انشاء الاشتراك
            </FLabel>
          </FRow>

          <div className={'grid grid-cols-2 gap-2'}>
            <EFormWrapper>
              <FLabel htmlFor={'reportStartDate'}>تاريخ البداية</FLabel>
              <FInputField
                max={new Date().toISOString().split('T')[0]}
                id='reportStartDate'
                type={'date'}
                value={reportStartDate}
                onChange={e => setReportStartDate(e.target.value)}
              />
            </EFormWrapper>
            <EFormWrapper>
              <FLabel htmlFor={'reportEndDate'}>تاريخ النهاية</FLabel>
              <FInputField
                type={'date'}
                max={new Date().toISOString().split('T')[0]}
                id='reportEndDate'
                value={reportEndDate}
                onChange={e => setReportEndDate(e.target.value)}
                label={'تاريخ النهاية'}
              />
            </EFormWrapper>
          </div>

          <select
            value={selectedProduct}
            onChange={e => setSelectedProduct(e.target.value)}
            className={'input-style'}
          >
            <option value=''>حدد المنتج التأميني</option>
            {response?.products?.map(product => (
              <option value={product.id}>{product.name}</option>
            ))}
          </select>
          <div className={'mt-2 flex items-center gap-2'}>
            <FButton
              onClick={() => handleDownloadExtractReport()}
              disabled={selectedProduct === '' || isDownloading}
            >
              <FIconWrapper>
                <AiOutlineDownload />
                تنزيل
                <ESpinner isVisible={isDownloading} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                setSelectedProduct('')
                setShowModal(false)
              }}
            >
              إلغاء
            </FButton>
          </div>
        </FModal>
      )}

      {!loading && (
        <FModal
          callbackFunction={() => {
            setSelectedProduct('')
          }}
          setIsOpen={setShowConfirmationModal}
          isOpen={showConfirmationModal}
          title={'رفع تقرير تأكيد'}
        >
          <FInputField
            type={'file'}
            onChange={e => setFile(e.target.files[0])}
          />

          <div className={'mt-2 flex items-center gap-2'}>
            <FButton onClick={() => handleUploadExtractReport()}>
              <FIconWrapper>
                <AiOutlineDownload />
                رفع
                <ESpinner isVisible={isUploading} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                setSelectedProduct('')
                setShowConfirmationModal(false)
              }}
            >
              إلغاء
            </FButton>
          </div>
        </FModal>
      )}

      <div className={'flex h-full flex-col items-start gap-2 p-5'}>
        <span
          className={
            'text-lg font-semibold  text-gray-700 dark:text-gray-100  '
          }
        >
          تقارير الأصدار
        </span>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGig} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تقرير الأصدار
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              يمكنك تنزيل التقرير الخاص باصدار الوثائق المرسل من فوري لجي أي جي
            </span>
          </div>
          <FButton onClick={() => setShowModal(true)} className={'mr-auto'}>
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}>تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGig} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تقرير تأكيد الأصدار
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              يمكنك تنزيل التقرير الخاص باصدار الوثائق المرسل من فوري لجي أي جي
            </span>
          </div>
          <FButton
            onClick={() => setShowConfirmationModal(true)}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineUpload />
              <span className={'text-sm'}>رفع التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img
            src={
              'https://d2tm09s6lgn3z4.cloudfront.net/2017/02/R4yzRept-1487162897_674_80601_.jpg'
            }
            width={90}
            alt='fawryGig'
          />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تقرير تأكيد الأصدار منتج الكتروني
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              يمكنك تنزيل التقرير الخاص باصدار الوثائق المرسل من شركة المهندس
              الي فوري
            </span>
          </div>
          <FButton
            onClick={() => setIsElectronicResponseModalOpen(true)}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineUpload />
              <span className={'text-sm'}>رفع التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
      </div>
    </>
  )
}
export default OutputReport
