import FModal from '../../components/FModal'
import EWarning from '../../components/EWarning'
import { Formik } from 'formik'
import EFormWrapper from '../../components/EFormWrapper'
import SFormWrapper from '../../components/EFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import EFormInvalidInput from '../../components/EFormInvalidInput'
import FButton from '../../components/FButton'
import ESpinner from '../../components/ESpinner'
import * as Yup from 'yup'
import { axiosInstance } from '../../api/requister'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const EditMember = ({ id, getUsersInfo, isModalOpen, setIsModalOpen }) => {
  const [userInfo, setUserInfo] = useState({})
  const [isEditFinished, setIsEditFinished] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    role: Yup.string().required(),
  })

  useEffect(() => {
    if (id !== null) {
      setUserInfo({})
      axiosInstance
        .get(`/admin/users/${id}`)
        .then(response => {
          setUserInfo(response.data.data.doc)
          setIsDataLoaded(true)
        })
        .catch(error => console.log(error))
    }
  }, [id])

  const notify = () => toast.success('Edited Successfully')
  const handleEditFormSubmission = values => {
    setIsEditFinished(true)
    axiosInstance
      .patch(`/admin/users/${values.id}/edit/data/`, values)
      .then(response => {
        setUserInfo({})
        getUsersInfo()
        setIsModalOpen(false)
        setIsDataLoaded(true)
        setIsEditFinished(false)
        notify()
      })
      .catch(error => {
        setIsEditFinished(false)
        console.log(error)
      })
  }

  return (
    <>
      <FModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        title={'Update Member data'}
      >
        <EWarning
          msg={
            'By changing the email, the user name used for login will be affected which means the login conditional in the next login'
          }
        />
        {isDataLoaded && userInfo && userInfo._id ? (
          <Formik
            enableReinitialize={true}
            validationSchema={ValidationSchema}
            initialValues={{
              id: userInfo._id ? userInfo._id : '',
              role: userInfo.role ? userInfo.role : '',
              name: userInfo.name ? userInfo.name : '',
              email: userInfo.email ? userInfo.email : '',
            }}
            onSubmit={handleEditFormSubmission}
          >
            {({
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={
                  'grid   grid-cols-2 gap-3 rounded-md bg-white dark:bg-gray-900  '
                }
              >
                <EFormWrapper>
                  <FLabel>Name</FLabel>
                  <FInputField
                    type='text'
                    name={'name'}
                    id={'name'}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'name'}
                  />
                </EFormWrapper>
                <EFormWrapper>
                  <FLabel>email</FLabel>
                  <FInputField
                    type='text'
                    name={'email'}
                    id={'email'}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'email'}
                  />
                </EFormWrapper>
                <SFormWrapper className={'w-6/12'}>
                  <div className={'flex gap-2'}>
                    <FButton type={'submit'} className={'mt-3 w-full'}>
                      <ESpinner isVisible={isEditFinished} />
                      Edit
                    </FButton>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      type={'button'}
                      className={
                        'mt-3 w-full rounded border  bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 '
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </SFormWrapper>
              </form>
            )}
          </Formik>
        ) : (
          <div className={'f-col-center-center   w-full'}>
            <ESpinner size={30} isVisible={true} />
          </div>
        )}
      </FModal>
    </>
  )
}
export default EditMember
