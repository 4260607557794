import FModal from '../../../components/FModal'
import FButton from '../../../components/FButton'
import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import { getProductById } from '../../../helpers/utils'
import { Link } from 'react-router-dom'
import FCol from '../../../components/FCol'
import FProductTag from '../../../components/FProductTag'
import moment from 'moment'
import { useLocalStorage } from 'usehooks-ts'
import CallLogs from './CallLogs'
import { toast } from 'react-toastify'
import FCustomPopOver from '../../../components/FCustomPopOver'
import { ChevronDown, PhoneMissed } from 'lucide-react'
import FIconWrapper from '../../../components/FIconWrapper'
import FPopOver from '../../../components/FPopOver'

const CallModal = ({ setIsOpen, isOpen, phone }) => {
  const { data, loading, refetch } = useQuery({
    queryKey: ['callDetails'],
    queryFn: () =>
      axiosInstance.get(
        `/call?page=0&phoneNumber=${phone}&size=10&callStatus=NEW&callStatus=ASSIGNED`,
      ),
  })

  const reasonMap = {
    MISSING_DATA: 'بيانات ناقصة',
    SUSPICIOUS: 'مشبوه',
    RENEWAL: 'تجديد',
  }

  const handleReschedule = callId => {
    axiosInstance
      .post(`call/${callId}/unreachable`, {
        callId: data?.data?.callSchedules[0]?.id,
      })
      .then(res => {
        toast.success('تم الحفظ بنجاح')
      })
      .catch(err => {
        toast.error('حدث خطأ')
      })
  }

  const mutation = useMutation({
    queryKey: ['editCallMutations'],
    mutationFn: data => axiosInstance.post(`call/editCallSchedule`, data),
    onSuccess: async () => {
      await toast.success('تم الحفظ بنجاح')
      await refetch()
    },
    onError: () => {
      toast.error('حدث خطأ')
    },
  })

  const [agents, setAgents] = useLocalStorage('users', [])
  if (loading) return <p>loading</p>
  else
    return (
      <FModal
        isAutoWidth
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={'المكالمة'}
      >
        <FCol>
          {data?.data?.callSchedules?.map(schedule => (
            <>
              <div className={'flex items-start gap-2'}>
                <FButton
                  type={'button'}
                  btnType={'danger'}
                  className={'whitespace-nowrap'}
                  onClick={() =>
                    handleReschedule(data?.data?.callSchedules[0]?.id)
                  }
                >
                  <FIconWrapper>
                    <PhoneMissed size={15} />
                    لا يوجد رد
                  </FIconWrapper>
                </FButton>

                <FCustomPopOver
                  callId={'10'}
                  callBack={() => {
                    refetch()
                  }}
                  button={
                    <FButton btnType={'success'}>
                      <FIconWrapper>
                        <ChevronDown />
                        <span className={'whitespace-nowrap text-white'}>
                          تم الرد
                        </span>
                      </FIconWrapper>
                    </FButton>
                  }
                />
                <FPopOver
                  callId={schedule.id}
                  callBack={refetch}
                  button={
                    <FButton btnType={'secondary'}>
                      <FIconWrapper>
                        <ChevronDown />
                        <span className={'whitespace-nowrap'}>
                          تغير معاد المكالمة
                        </span>
                      </FIconWrapper>
                    </FButton>
                  }
                />
              </div>
              <div className={'flex flex-col items-start justify-start gap-2'}>
                <span className={'text-left'}>النشاط السابقة</span>
                <CallLogs callId={schedule.id} />
              </div>

              <div className={'flex flex-col items-start justify-start gap-2'}>
                <span>الأسباب التي تم إنشاء المكالمة من أجلها</span>
                {schedule.callIssues.map((issue, index) => (
                  <div
                    key={index}
                    className={
                      'flex w-full items-center justify-between rounded-lg border border-gray-300 p-3 shadow-sm'
                    }
                  >
                    <FProductTag
                      productName={reasonMap[issue.callType]}
                      color={'red'}
                    />
                    {moment(issue.creationDate).format('DD/MM/YYYY') +
                      ' ' +
                      moment(issue.creationDate).format('hh:mm')}

                    <Link to={`/subscriptionDup/${issue.subscriptionId}`}>
                      <FButton>{getProductById(issue.productId).name}</FButton>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ))}
        </FCol>
      </FModal>
    )
}
export default CallModal
