import React from 'react'
import { useCustomAxios } from '../../../Hooks/useAxios'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getProductByBTC } from '../../../helpers/utils'
import Switch from 'react-switch'
import FRow from '../../../components/FRow'
import FLabel from '../../../components/FLabel'
import { useLocalStorage } from 'usehooks-ts'

export default function CustomerResiduals({ customerPhoneNumber }) {
  const navigate = useNavigate()
  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `ResidualFees/findAllResidualFees?customerPhoneNumber=${customerPhoneNumber}&page=${0}&size=1000`,
  })
  const [isNonActiveResidualsVisible, setIsNonActiveResidualsVisible] =
    useLocalStorage('isNonActiveResidualsVisible', false)
  const columns = [
    {
      wrap: true,
      name: 'btc',
      selector: row => getProductByBTC(row.btc)?.name,
      sortable: true,
    },
    {
      name: 'كود العمليه',
      selector: row => row.transactionCode,
      width: '200px',
    },
    {
      name: 'المبلغ',
      selector: row => row.amount,
      width: '200px',
    },
    {
      width: '200px',
      name: 'الحاله',
      selector: row => (row.active ? 'Active' : 'Inactive'),
    },
    {
      width: '200px',
      name: 'تاريخ العمليه',
      selector: row => moment(row.transactionDate).format('DD/MM/YYYY HH:mm'),
      sortable: true,
    },
  ]

  if (loading) return <div>loading...</div>
  else
    return (
      <div>
        <DataTable
          striped
          highlightOnHover
          title={
            <FRow className={'justify-between items-center'}>
              <span className='text-center'>العمليات المعلقه</span>
              <FRow>
                <FLabel
                  className={'cursor-pointer'}
                  htmlFor={'isNonActiveResidualsVisible'}
                >
                  {!isNonActiveResidualsVisible ? 'اظهار' : 'اخفاء'} العمليات
                  المعلقه
                </FLabel>
                <Switch
                  id={'isNonActiveResidualsVisible'}
                  offColor='#f00'
                  checkedIcon={false}
                  onColor='#11a511'
                  height={20}
                  width={30}
                  onChange={e => {
                    setIsNonActiveResidualsVisible(e)
                  }}
                  checked={isNonActiveResidualsVisible}
                />
              </FRow>
            </FRow>
          }
          resizable // Enable column resizing
          noDataComponent={
            <span className='text-center'>لا يوجد عمليات معلقه</span>
          }
          columns={columns}
          pointerOnHover
          responsive
          direction='auto'
          data={response.residualsFees.filter(
            residual => residual.active || isNonActiveResidualsVisible,
          )}
          pagination
          onRowClicked={row => {
            navigate(`/settle/${row.phoneNumber}/${row.id}`)
          }}
        />
      </div>
    )
}
