import React from 'react'
import moment from 'moment'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { getProductByBTC } from '../../../helpers/utils'
import DataTable from 'react-data-table-component'

const Transactions = ({ phoneNumber }) => {
  const { response, loading, error } = useCustomAxios({
    method: 'GET',
    url: `/customer/${phoneNumber}/transaction`,
  })

  const columns = [
    {
      wrap: true,
      name: 'btc',
      selector: row => getProductByBTC(row.btc)?.name,
      sortable: true,
    },
    {
      wrap: true,
      width: '200px',
      name: 'كود العمليه',
      selector: row => row.transactionCode,
    },
    {
      wrap: true,
      grow: 3,
      name: 'machineId',
      selector: row => row.machineId,
    },
    {
      wrap: true,
      name: 'المبلغ',
      selector: row => row.amount,
    },
    {
      wrap: true,
      name: 'channel',
      selector: row => row.channel,
    },
    {
      wrap: true,
      width: '200px',
      name: 'code',
      selector: row => row.code,
    },
    {
      name: 'الحاله',
      selector: row => (row.active ? 'Active' : 'Inactive'),
    },
    {
      width: '200px',
      name: 'تاريخ العمليه',
      selector: row => moment(row.transactionDate).format('DD/MM/YYYY HH:mm'),
      sortable: true,
    },
    {
      name: 'تاريخ الرفع',
      width: '200px',
      selector: row => moment(row.uploadDate).format('DD/MM/YYYY HH:mm'),
      sortable: true,
    },
    {
      width: '200px',
      name: 'الموظف',
      selector: row => row.source,
    },
  ]

  if (loading) return <div>loading...</div>
  else
    return (
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <DataTable
          responsive
          direction='rtl'
          resizable={true} // Enable column resizing
          title={<span className='text-center'>العمليات المالية</span>}
          columns={columns}
          data={response.list}
          pagination
        />
      </div>
    )
}

export default Transactions
