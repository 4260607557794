import { axiosInstance } from '../../../api/requister'
import FButton from '../../../components/FButton'

const ReportsTemplates = () => {
  const REPORTS_TEMPLATES = [
    { name: 'CUSTOMER_UPLOAD_TEMPLATE', label: 'Customer Upload Template' },
    {
      name: 'GIG_CANCELLATION_CONFIRM_TEMPLATE',
      label: 'Gig Cancellation Confirm Template',
    },
    {
      name: 'GIG_EXTRACTION_RESPONSE_TEMPLATE',
      label: 'Gig Extraction Response Template',
    },
    { name: 'ORACLE_TEMPLATE', label: 'Oracle Template' },
    {
      name: 'SUSPENDED_MACHINES_TEMPLATE',
      label: 'Suspended Machines Template',
    },
    {
      name: 'SUSPENDED_MACHINES_TEMPLATE',
      label: 'Suspended Machines Template',
    },
    {
      name: 'GIG_EXTRACTION_RESPONSE_TEMPLATE_FOR_COVER',
      label: 'Extraction Response Template For Cover',
    },
  ]

  const handleDownload = template => {
    axiosInstance
      .get(`report/downloadTemplate?fileTemplateType=${template}`, {
        responseType: 'blob',
      })
      .then(response => {
        const modifiedFile = response.data
        // Create a URL for the modifiedFile blob
        const fileUrl = URL.createObjectURL(modifiedFile)
        // Create a temporary anchor element
        const downloadLink = document.createElement('a')
        downloadLink.href = fileUrl
        downloadLink.download = template + '.xlsx' // Set the desired file name with .xlsx extension
        // Append the anchor element to the document body
        document.body.appendChild(downloadLink)

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        // Clean up by removing the anchor element and revoking the URL
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(fileUrl)
      })
      .catch(err => {})
  }

  return (
    <div
      className={
        'flex h-full flex-grow flex-col items-center justify-center bg-white'
      }
    >
      <div className={'flex flex-col items-center justify-center'}>
        <h1 className={'text-lg font-semibold'}>قوالب التقارير</h1>
        <div className='flex flex-col gap-2'>
          {REPORTS_TEMPLATES.map(template => (
            <div className='mt-2 flex gap-2'>
              <FButton
                onClick={() => handleDownload(template.name)}
                className='w-full rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700'
              >
                {template.label}
              </FButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default ReportsTemplates
