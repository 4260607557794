import ECard from '../../../components/ECard'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { HiTrash } from 'react-icons/hi'
import React from 'react'
import FIconWrapper from '../../../components/FIconWrapper'

const FamilyMembers = ({ Formik }) => {
  const handleAddRelative = () => {
    const arr = Formik.values.customerSubscriptionRelatives
    arr.push({
      name: '',
      nationalId: '',
      dateOfBirth: '',
      gender: '',
      relationship: '',
      relativePhoneNumber: '',
    })
    Formik.setFieldValue('customerSubscriptionRelatives', arr)
  }
  const handleRemoveRelative = index => {
    const arr = Formik.values.customerSubscriptionRelatives
    arr.splice(index, 1)
    Formik.setFieldValue('customerSubscriptionRelatives', arr)
  }

  return (
    <>
      <ECard>
        <ECard.Header>
          <div className={'flex items-center justify-between'}>
            <span className={'font-medium '}>أفراد العائلة</span>
            <FButton
              className={'col-span-1'}
              onClick={() => {
                handleAddRelative()
              }}
            >
              اضافة فرد
            </FButton>
          </div>
        </ECard.Header>
        <ECard.Body>
          {Formik.values.customerSubscriptionRelatives?.length === 0 && (
            <div className={'my-12'}>
              <span className={'block text-center text-xl'}>
                لا يوجد أفراد في العائلة للمشترك
              </span>
              <p className={'text-center text-gray-500'}>
                يمكنك اضافة أفراد للعائلة للمشترك من خلال الضغط على زر اضافة فرد
              </p>
            </div>
          )}

          {Formik.values.customerSubscriptionRelatives.map((item, index) => (
            <div
              className={'grid grid-cols-3 items-end justify-center gap-3'}
              key={index}
            >
              <div className='relative col-span-3'>
                <div
                  className='absolute inset-0 flex items-center'
                  aria-hidden='true'
                >
                  <div className='w-full border-t border-gray-300' />
                </div>
                <div className='relative flex justify-center'>
                  <span className='bg-white px-2 text-sm text-gray-500'>
                    الفرد {index + 1}
                  </span>
                </div>
              </div>
              <EFormWrapper>
                <FLabel htmlFor={`name${index}`}>الاسم الكامل للمشترك</FLabel>
                <FInputField
                  id={`name${index}`}
                  name='customerSubscriptionRelatives[index].name'
                  type='text'
                  placeholder='الاسم الكامل للمشترك'
                  value={
                    Formik.values.customerSubscriptionRelatives[index].name
                  }
                  onChange={e => {
                    const arr = Formik.values.customerSubscriptionRelatives
                    arr[index].name = e.target.value
                    Formik.setFieldValue('customerSubscriptionRelatives', arr)
                  }}
                />

                {Formik.errors.customerSubscriptionRelatives?.[index]?.name &&
                  Formik.touched.customerSubscriptionRelatives?.[index]
                    ?.name && (
                    <span className={'text-xs text-red-500'}>
                      {
                        Formik.errors.customerSubscriptionRelatives?.[index]
                          ?.name
                      }
                    </span>
                  )}
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={`nationalId${index}`}>الرقم القومي</FLabel>
                <FInputField
                  id={`nationalId${index}`}
                  name='customerSubscriptionRelatives[index].nationalId'
                  type='text'
                  placeholder='الرقم القومي'
                  value={
                    Formik.values.customerSubscriptionRelatives[index]
                      .nationalId
                  }
                  onChange={e => {
                    const arr = Formik.values.customerSubscriptionRelatives
                    arr[index].nationalId = e.target.value
                    Formik.setFieldValue('customerSubscriptionRelatives', arr)
                  }}
                />
                {Formik.errors.customerSubscriptionRelatives?.[index]
                  ?.nationalId &&
                  Formik.touched.customerSubscriptionRelatives?.[index]
                    ?.nationalId && (
                    <span className={'text-xs text-red-500'}>
                      {
                        Formik.errors.customerSubscriptionRelatives?.[index]
                          ?.nationalId
                      }
                    </span>
                  )}
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={`gender${index}`}>النوع</FLabel>
                <select
                  className={'input-style'}
                  id={`gender${index}`}
                  name={`gender${index}`}
                  value={
                    Formik.values.customerSubscriptionRelatives[index].gender
                  }
                  onChange={e => {
                    const arr = Formik.values.customerSubscriptionRelatives
                    arr[index].gender = e.target.value
                    Formik.setFieldValue('customerSubscriptionRelatives', arr)
                  }}
                >
                  <option value={''}>غير محدد</option>
                  <option value={'MALE'}>ذكر</option>
                  <option value={'FEMALE'}>أنثى</option>
                </select>
                {Formik.errors.customerSubscriptionRelatives?.[index]?.gender &&
                  Formik.touched.customerSubscriptionRelatives?.[index]
                    ?.gender && (
                    <span className={'text-xs text-red-500'}>
                      {
                        Formik.errors.customerSubscriptionRelatives?.[index]
                          ?.gender
                      }
                    </span>
                  )}
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={`dateOfBirth${index}`}>تاريخ الميلاد</FLabel>

                <FInputField
                  id={`dateOfBirth${index}`}
                  name='dateOfBirth'
                  type='date'
                  max={new Date().toISOString().split('T')[0]}
                  placeholder='تاريخ الميلاد'
                  value={
                    new Date(
                      Formik.values.customerSubscriptionRelatives[index]
                        .dateOfBirth || '',
                    )
                      ?.toISOString()
                      ?.split('T')[0]
                  }
                  onChange={e => {
                    const arr = Formik.values.customerSubscriptionRelatives
                    arr[index].dateOfBirth = e.target.value
                    Formik.setFieldValue('customerSubscriptionRelatives', arr)
                  }}
                />
                {Formik.errors.customerSubscriptionRelatives?.[index]
                  ?.dateOfBirth &&
                  Formik.touched.customerSubscriptionRelatives?.[index]
                    ?.dateOfBirth && (
                    <span className={'text-xs text-red-500'}>
                      {
                        Formik.errors.customerSubscriptionRelatives?.[index]
                          ?.dateOfBirth
                      }
                    </span>
                  )}
              </EFormWrapper>

              <EFormWrapper>
                <FLabel htmlFor={`reasonOfJoining${index}`}>
                  العلاقة بالمشترك
                </FLabel>
                <select
                  id={`reasonOfJoining${index}`}
                  className={'input-style'}
                  name='relativeType'
                  value={Formik.values.customerSubscriptionRelatives[
                    index
                  ].relationship.toUpperCase()}
                  onChange={e => {
                    const arr = Formik.values.customerSubscriptionRelatives
                    arr[index].relationship = e.target.value
                    Formik.setFieldValue('customerSubscriptionRelatives', arr)
                  }}
                >
                  <option value={''}>غير محدد</option>
                  <option value={'SPOUSE'}>زوج</option>
                  <option value={'WIFE'}>زوجة</option>
                  <option value={'FATHER'}>أب</option>
                  <option value={'MOTHER'}>أم</option>
                  <option value={'BROTHER'}>أخ</option>
                  <option value={'SISTER'}>أخت</option>
                  <option value={'SON'}>إبن</option>
                  <option value={'DAUGHTER'}>إبنة</option>
                </select>
                {Formik.errors.customerSubscriptionRelatives?.[index]
                  ?.relationship &&
                  Formik.touched.customerSubscriptionRelatives?.[index]
                    ?.relationship && (
                    <span className={'text-xs text-red-500'}>
                      {
                        Formik.errors.customerSubscriptionRelatives?.[index]
                          ?.relationship
                      }
                    </span>
                  )}
              </EFormWrapper>
              {Formik.values.customerSubscriptionRelatives?.[
                index
              ].relationship.toLowerCase() === 'wife' && (
                <EFormWrapper>
                  <FLabel>رقم هاتف الزوجة</FLabel>
                  <FInputField
                    id={`relativePhoneNumber${index}`}
                    name='relativePhoneNumber'
                    type='text'
                    placeholder='رقم هاتف الزوجة'
                    value={
                      Formik.values.customerSubscriptionRelatives[index]
                        .relativePhoneNumber
                    }
                    onChange={e => {
                      const arr = Formik.values.customerSubscriptionRelatives
                      arr[index].relativePhoneNumber = e.target.value
                      Formik.setFieldValue('customerSubscriptionRelatives', arr)
                    }}
                  />
                  {Formik.errors.customerSubscriptionRelatives?.[index]
                    ?.relativePhoneNumber &&
                    Formik.touched.customerSubscriptionRelatives?.[index]
                      ?.relativePhoneNumber && (
                      <span className={'text-xs text-red-500'}>
                        {
                          Formik.errors.customerSubscriptionRelatives?.[index]
                            ?.relativePhoneNumber
                        }
                      </span>
                    )}
                </EFormWrapper>
              )}

              <EFormWrapper>
                <FButton
                  className={'w-fit'}
                  btnType='danger'
                  onClick={() => handleRemoveRelative(index)}
                >
                  <FIconWrapper>
                    <HiTrash />
                    مسح
                  </FIconWrapper>
                </FButton>
              </EFormWrapper>
            </div>
          ))}
        </ECard.Body>
      </ECard>
    </>
  )
}

export default FamilyMembers
