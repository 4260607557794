import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const ReportNavLink = ({ to, children }) => {
  return (
    <>
      <NavLink
        className={navData =>
          'flex items-center justify-start gap-2 py-2 px-3  ' +
          (navData.isActive
            ? 'shadow-s border-b-2 border-orient-500 bg-white text-orient-500   dark:bg-gray-900  '
            : ' text-gray-800 dark:text-white ')
        }
        to={to}
      >
        {children}
      </NavLink>
    </>
  )
}
const Reports = () => {
  return (
    <>
      <div className={'flex min-h-full flex-col gap-3'}>
        <div
          className={
            ' sticky top-0  flex w-full gap-2 border-l border-b bg-white dark:bg-gray-900'
          }
        >
          <ReportNavLink to={'report'}>تقارير الاستخراج</ReportNavLink>
          {/*          <ReportNavLink to={"gig"}> تقارير تأكيد الأستخراج</ReportNavLink>*/}
          <ReportNavLink to={'internal'}> تقارير داخليه</ReportNavLink>
        </div>
        <div className={'w-full'}>
          <Outlet />
        </div>
      </div>
    </>
  )
}
export default Reports
