import FModal from '../FModal'
import { Link } from 'react-router-dom'
import { BiUser } from 'react-icons/bi'
import FButton from '../FButton'
import { HiOutlineExternalLink, HiOutlineLink } from 'react-icons/hi'
import loader from '../../assets/images/icons8-loading-infinity.gif'
import React, { useEffect, useState } from 'react'
import { useCustomAxios } from '../../Hooks/useAxios'

const CustomerSearchModal = ({
  isSearchModalOpen,
  setIsSearchModalOpen,
  inputRef,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const isNumberPatters = () => {
    const numberPattern = /^\d+$/
    return numberPattern.test(searchValue)
  }

  const clients = useCustomAxios({
    method: 'GET',
    url: `/customer?page=0&size=20&${
      isNumberPatters(searchValue) ? 'phoneNumber' : 'name'
    }=${searchValue}`,
  })

  useEffect(() => {
    clients.Refetch()
  }, [searchValue])

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'ArrowUp') {
        event.preventDefault()
        setSelectedIndex(prevIndex =>
          prevIndex === 0
            ? clients?.response?.customers?.length - 1
            : prevIndex - 1,
        )
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        setSelectedIndex(prevIndex =>
          prevIndex === clients?.response?.customers?.length - 1
            ? 0
            : prevIndex + 1,
        )
      } else if (event.key === 'Enter') {
        event.preventDefault()
        handleItemClick(selectedIndex)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [clients.response])

  const handleItemClick = index => {}

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'k' && event.ctrlKey) {
        event.preventDefault()
        setIsSearchModalOpen(true)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedIndex, clients.response])

  return (
    <>
      <FModal
        isOpen={isSearchModalOpen}
        setIsOpen={setIsSearchModalOpen}
        title={'بحث عن عميل'}
      >
        <form className={'reset-form'}>
          <input
            ref={inputRef}
            className={`resetable-field w-full rounded border border-gray-300 p-1.5 text-sm  shadow-sm ring-orient-400 focus:border focus:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2`}
            placeholder={'أدخل الإسم'}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </form>

        <div
          className={'my-2'}
          style={{
            maxHeight: '400px',
            minHeight: '400px',
            overflowY: 'auto',
          }}
        >
          {selectedIndex}
          {!clients.loading ? (
            clients?.response?.customers?.map((client, clientIndex) => {
              return (
                <Link
                  to={`/customers/details/${client.phoneNumber}`}
                  key={client.phoneNumber}
                  className={'cursor-pointer'}
                  onClick={() => {
                    setIsSearchModalOpen(false)
                  }}
                >
                  <div
                    className={`hover: flex items-center gap-2 rounded bg-gray-100 p-2 dark:bg-gray-600 ${
                      selectedIndex === clientIndex
                        ? ' bg-gray-100 dark:bg-gray-600'
                        : ''
                    }`}
                  >
                    <div
                      className={
                        'rounded border  bg-white p-1  dark:bg-gray-900'
                      }
                    >
                      <BiUser
                        size={30}
                        name={client.name}
                        className={'text-gray-600'}
                      />
                    </div>
                    <div className={'flex flex-col items-start gap-1 '}>
                      <span
                        className={
                          'text-sm font-semibold  text-gray-800 dark:text-white '
                        }
                      >
                        {' '}
                        {client.name ?? 'أسم العميل غير متوفر'}
                      </span>
                      <span
                        className={
                          'text-sm leading-3  text-gray-500 dark:text-gray-100   '
                        }
                      >
                        {' '}
                        {client.phoneNumber}
                      </span>
                    </div>
                    <div className={'mr-auto flex items-center gap-1'}>
                      <FButton btnType={'secondary'}>
                        <HiOutlineExternalLink />
                      </FButton>
                      <FButton btnType={'secondary'}>
                        <HiOutlineLink />
                      </FButton>
                    </div>
                  </div>
                </Link>
              )
            })
          ) : (
            <div
              style={{
                minHeight: 'inherit',
              }}
              className={
                'flex h-full flex-grow flex-col items-center justify-center '
              }
            >
              <div
                className={'flex flex-col items-center justify-center gap-3'}
              >
                <img className={'w-fit'} src={loader} />
                <span>جاري البحث عن العميل</span>
              </div>
            </div>
          )}
        </div>
      </FModal>
    </>
  )
}
export default CustomerSearchModal
