import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'

const Dashboard = () => {
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    const socket = io('http://localhost:3001') // Replace with your server URL

    // Listen for customer updates from the server
    socket.on('customer', customer => {
      setCustomers(prevCustomers => [...prevCustomers, customer])
    })

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect()
    }
  }, [])

  return (
    <div>
      <h1>Customer List</h1>
      <ul>{JSON.stringify(customers)}</ul>
    </div>
  )
}

export default Dashboard
