import axios from 'axios'
import { BASE_URL } from '../env'

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'ngrok-skip-browser-warning': '69420',
  },
})

/*
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Check if the error is related to CORS
    if (error.response && error.response.status === 0) {
      localStorage.clear()
      window.location.replace('/login')
      // You can handle the CORS error here or perform any necessary actions
    }
    return Promise.reject(error)
  },
)
*/

/*
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    console.log(error.response)
    console.log(error.response.status)
    if (error.response.status === 401 || error.response.status === 0) {
      console.log("token expired")
      localStorage.removeItem("access_token")
      window.location.replace("/login")
    }

    if (error.response.status === 403) {
      console.log("Access Denied")
      localStorage.removeItem("access_token")
      window.location.replace("/forbidden")
    }
    /!* if (error.response.status === 500) {
      console.log("Access Denied")
      // localStorage.removeItem('access_token')
      window.location.replace("/servererror")
    }*!/
    if (error.response.status === 400) {
      console.log("Redirect to 400 must happen")
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)*/
