import React, { useEffect, useState } from 'react'
import { useCustomAxios } from '../../../Hooks/useAxios'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import FAvatar from '../../../components/FAvatar'
import Flyout from '../../../components/Flyout'
import { axiosInstance } from '../../../api/requister'
import FRow from '../../../components/FRow'
import FCol from '../../../components/FCol'
import { BsArrowRightShort } from 'react-icons/bs'

const ListCall = () => {
  const startDate = new Date()
  const endDate = new Date()
  endDate.setDate(startDate.getDate() + 6) // Add 6 days to the start date
  const [page, setPage] = useState(0)
  const [agentMail, setAgentMail] = useState('')
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    },
  ])

  const numberOfDays = 30
  startDate.setDate(startDate.getDate() - numberOfDays) // Subtract 30 days from the current date

  const { response, loading, Refetch, totalNumberOfPages } = useCustomAxios({
    method: 'GET',
    url: `/call/archive?page=${page}&size=500&userEmail=${agentMail}&interactionTypes=SOLD&interactionTypes=UNREACHABLE&interactionTypes=MISSING_DATA_DONE&interactionTypes=REFUSE&interactionTypes=DONE&interactionTypes=RESCHEDULE
   `,
  })

  let downladerStartDate = new Date(selectedDates[0]?.startDate).toISOString()
  let downladerEndDate = new Date(selectedDates[0]?.endDate).toISOString()
  useEffect(() => {
    Refetch()
  }, [page, agentMail])

  /*  useEffect(() => {
    Refetch()
  }, [selectedDates])*/

  const [filter, setFilter] = useState([])

  const ARCHIVESOURCE = {
    UNREACHABLE: 'لا يمكن الوصول إليه',
    RESCHEDULE: 'إعادة جدولة',
    UPDATE_CALL: 'تحديث المكالمة',
  }

  const cols = [
    {
      Header: 'Reference Call',
      accessor: 'callId',
      cell: (col, row) => (
        <div
          onClick={() => {
            getArchieveCalls(col)
            setIsFlyoutOpen(true)
          }}
          className={
            'flex w-fit cursor-pointer  items-center gap-2 rounded border border bg-gray-50'
          }
        >
          {JSON.stringify(col)}
        </div>
      ),
    },
    {
      Header: 'Assigned To',
      accessor: 'user',
      cell: (col, row) => (
        <div className={'flex items-center gap-2'}>
          <FAvatar width={20} name={col?.name} />
          <span>{col?.name}</span>
        </div>
      ),
    },
    {
      Header: 'archiveDate',
      accessor: 'archiveDate',
      cell: (col, row) => <div>{moment(col).format('DD/MM/YYYY')}</div>,
    },
    {
      Header: 'customer',
      accessor: 'customer',
      cell: (col, row) => (
        <NavLink
          to={`/customers/details/${col?.phoneNumber}`}
          className='flex w-fit cursor-pointer flex-col rounded   border-dashed px-3 py-0.5 hover:bg-gray-100'
        >
          <span className='text-sm underline'> {col?.name}</span>
          <span className='text-xs'>{col?.phoneNumber}</span>
        </NavLink>
      ),
    },
    {
      Header: 'callStatus',
      accessor: 'callStatus',
    },
    {
      Header: 'archiveSource',
      accessor: 'archiveSource',
      cell: (col, row) => <div>{ARCHIVESOURCE[col] ?? col}</div>,
    },
  ]

  const [selectedProduct, setSelectedProduct] = useState('')

  const [archieveCallData, setArchieveCallData] = useState([])
  const getArchieveCalls = callId => {
    axiosInstance
      .get(
        `call/archive?callId=${callId}&interactionTypes=&interactionTypes=DONE&interactionTypes=MISSING_DATA_DONE&interactionTypes=NOT_NEEDED&interactionTypes=REFUSE&interactionTypes=RESCHEDULE&interactionTypes=SOLD&interactionTypes=UNREACHABLE&interactionTypes=WRONG_NUMBER&page=0&size=10`,
      )
      .then(res => {
        setArchieveCallData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const calculateNumberOfDays = (startDate, endDate) => {
    const start = moment(startDate)
    const end = moment(endDate)
    return end.diff(start, 'days') + 1
  }
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)
  return (
    <div className={'bg-white dark:bg-gray-900'}>
      <div className='m-3 flex items-center gap-2 bg-white dark:bg-gray-900'>
        <span className={'whitespace-nowrap'}>المكالمات المنتهية</span>

        {/* <AgentPicker agent={agentMail} setAgent={setAgentMail} />
        <Downloader
          user={agentMail}
          startDate={downladerStartDate}
          endDate={downladerEndDate}
          tnop={totalNumberOfPages}
        />
        <CallReport />*/}
      </div>

      {/*  <PerformanceHeatMap
        numberOfDays={calculateNumberOfDays(
          new Date(selectedDates[0]?.startDate),
          new Date(selectedDates[0]?.endDate)
        )}
        startDate={new Date(selectedDates[0]?.startDate).toISOString()}
        endDate={new Date(selectedDates[0]?.endDate).toISOString()}
      />*/}

      <Flyout open={isFlyoutOpen} setOpen={setIsFlyoutOpen} title={'تفاصيل'}>
        <FCol className={'gap-3'}>
          {archieveCallData?.archiveCallsSchedule?.map((call, index) => {
            return (
              <div
                key={index}
                className={'flex flex-col gap-2 rounded-md border p-2'}
              >
                <FRow>
                  <FAvatar name={call?.user?.name} />
                  <FCol>
                    <span> {call?.user?.email}</span>
                    <span> {call?.user?.name}</span>
                  </FCol>
                  <span className={'text-xs'}>
                    {`${moment(call?.callDate).format('DD/MM/YYYY')} at ${
                      call?.callTime
                    }`}
                  </span>
                </FRow>

                <FRow className={'items-center'}>
                  <span>{call.callAgentInteractionType}</span>
                  <BsArrowRightShort /> <span>{call.callType}</span>
                </FRow>
              </div>
            )
          })}
        </FCol>
      </Flyout>

      <FCustomDataTable
        cols={cols}
        data={response ? response.archiveCallsSchedule : []}
        isLoading={loading}
        page={page}
        setPage={setPage}
        size={2}
        Refetch={Refetch}
        setFilter={setFilter}
        filter={filter}
        totalNumberOfPages={totalNumberOfPages}
      />
    </div>
  )
}

export default ListCall
