import { NavLink } from 'react-router-dom'

const FNavLink = ({ to, children, setIsSideBarOpen }) => (
  <>
    <NavLink
      onClick={() => setIsSideBarOpen(false)}
      to={to}
      className={navData =>
        'my1- flex items-center justify-start gap-2 rounded-md py-1 px-3 font-medium hover:bg-orient-700  ' +
        (navData.isActive
          ? 'primary-shadow hover:bg-orient- my-2  border-b border-gray-400 bg-white text-xs font-bold text-orient-500 hover:text-white dark:bg-gray-900  '
          : 'text-white')
      }
    >
      {children}
    </NavLink>
  </>
)
export default FNavLink
