import FModal from '../../../components/FModal'
import React from 'react'
import StyledDropzone from './StyledDropzone'
import FButton from '../../../components/FButton'
import axios from 'axios'
import { MICROSERVICE_URL } from '../../../env'
import { ulid } from 'ulid'

const MapOracleFileModal = ({ isOpen, setIsOpen }) => {
  const [file, setFile] = React.useState(null)
  const handleUpload = async () => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post(
        `${MICROSERVICE_URL}/api/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        },
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${ulid()}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <FModal isOpen={isOpen} setIsOpen={setIsOpen} title={'تهيئة البيانات'}>
        <StyledDropzone setFile={setFile} />
        <div className={'mt-2 flex items-center gap-2'}>
          <FButton
            onClick={() => {
              handleUpload()
            }}
          >
            رفع الملف
          </FButton>
          <FButton btnType={'secondary'}>الغاء</FButton>
        </div>
      </FModal>
    </>
  )
}
export default MapOracleFileModal
