import * as Yup from 'yup'

export const BasicInfoValidationSchema = Yup.object().shape({
  productBasicInfo: Yup.object().shape({
    productName: Yup.object().shape({
      value: Yup.string().required('أدخل إسم المنتج'),
    }),
    productBTC: Yup.object().shape({
      value: Yup.string().required(' أدخل رمز المنتج'),
    }),
  }),
})
