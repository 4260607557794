import FModal from '../../../components/FModal'
import FButton from '../../../components/FButton'
import { useState } from 'react'
import { useFormik } from 'formik'
import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import EFormWrapper from '../../../components/EFormWrapper'
import FRow from '../../../components/FRow'
import { useMutation } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'

const DisableCallsModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const disableCalls = async values => {
    const response = await axiosInstance.post('/call/disableBulkCallsAsyn', {
      startDateRange: values.startDateRange,
      endDateRange: values.endDateRange,
    })
    return response.data
  }
  const mutation = useMutation({
    queryKey: ['disableCalls'],
    mutationFn: disableCalls,
  })

  const form = useFormik({
    initialValues: {
      endDateRange: '',
      startDateRange: '',
      disableCalls: true,
    },
    onSubmit: values => {
      mutation.mutate(values)
    },
  })
  return (
    <>
      <FButton onClick={() => setIsOpen(true)}>Disable Calls</FButton>
      <FModal title={'الغاء المكالمات'} isOpen={isOpen} setIsOpen={setIsOpen}>
        <form onSubmit={form.handleSubmit} className={'grid grid-cols-2 gap-2'}>
          <EFormWrapper>
            <FLabel htmlFor={'startDateRange'}>من</FLabel>
            <FInputField
              type='date'
              max={new Date().toISOString().split('T')[0]}
              name='startDateRange'
              id='startDateRange'
              value={form.values.startDateRange}
              onChange={form.handleChange}
              label='Start Date Range'
            />
          </EFormWrapper>
          <EFormWrapper>
            <FLabel htmlFor={'endDateRange'}>الى</FLabel>
            <FInputField
              type='date'
              max={new Date().toISOString().split('T')[0]}
              name='endDateRange'
              id='endDateRange'
              value={form.values.endDateRange}
              onChange={form.handleChange}
              label='End Date Range'
            />
          </EFormWrapper>
          <FRow>
            <FButton type={'submit'}>
              {mutation.isLoading ? 'جاري الالغاء' : 'تعطيل المكالمات'}
            </FButton>
            <FButton btnType={'secondary'} onClick={() => setIsOpen(false)}>
              الغاء
            </FButton>
          </FRow>
        </form>
      </FModal>
    </>
  )
}
export default DisableCallsModal
