import { useCustomAxios } from '../../../Hooks/useAxios'
import { Link, NavLink, useParams } from 'react-router-dom'
import FLabel from '../../../components/FLabel'
import FButton from '../../../components/FButton'
import EFormWrapper from '../../../components/EFormWrapper'
import React, { useEffect, useState } from 'react'
import EWarning from '../../../components/EWarning'
import {
  getProductById,
  getProductInstallmentById,
  getRoutePathByName,
} from '../../../helpers/utils'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import FAvatar from '../../../components/FAvatar'
import ECard from '../../../components/ECard'
import { useMutation } from '@tanstack/react-query'

const SettleResidual = () => {
  // remove first litter from the id
  const { id, residual } = useParams()
  const [selectedSubscription, setSelectedSubscription] = useState('')
  useEffect(() => {
    setSelectedSubscription('')
  }, [])
  // get the subscriptions of the users
  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `/customersubscription?page=0&phoneNumber=${id}&size=25`,
  })

  const residualsResponse = useCustomAxios({
    method: 'GET',
    url: `/ResidualFees/${residual}`,
  })

  const handleSettleResidual = () => {
    axiosInstance
      .post(`/ResidualFees/${residual}/settle/${selectedSubscription}`)
      .then(() => {
        residualsResponse.Refetch()
        toast.success('تم تسديد المتبقي بنجاح')
      })
      .catch(err => {
        residualsResponse.Refetch()
        toast.error(err.response.data.message)
      })
  }

  const refundMutation = useMutation({
    mutationFn: () =>
      axiosInstance.post(`/ResidualFees/refundResidualFee/${residual}/`),
    onSuccess: () => {
      residualsResponse.Refetch()
      toast.success('تم استرجاع المتبقي بنجاح')
    },
  })

  if (loading) {
    return <div>loading...</div>
  } else {
    return (
      <>
        <div
          className={
            'primary-shadow flex justify-between border-b bg-white p-3 dark:bg-gray-900'
          }
        >
          <span>القيم المعلقة</span>
          <Link to={getRoutePathByName('subscriptionsAdd')}></Link>
        </div>

        <div className={'flex  justify-center gap-1'}>
          <div className={' w-8/12 p-5'}>
            {residualsResponse?.response?.residualsFees?.active === true ? (
              <EWarning
                msg={
                  'يمكنك تسوية القيم المالية المعلقة بأختيار الاشتراك المناسب'
                }
              />
            ) : (
              <EWarning msg={'تم تسوية القيم المالية المعلقة بنجاح'} />
            )}
            <div
              className={
                'primary-shadow mt-3 rounded border bg-white p-5 dark:bg-gray-900'
              }
            >
              <div className={'flex gap-4 p-3'}>
                <div className={'flex flex-col'}>
                  <span className={'font-semibold '}>المنتج</span>
                  <span
                    className={
                      'font-medium  text-gray-700 dark:text-gray-100  '
                    }
                  >
                    {
                      getProductById(
                        residualsResponse?.response?.residualsFees?.productId,
                      )?.name
                    }
                  </span>
                </div>
                <div className={'flex flex-col'}>
                  <span className={'font-semibold '}>كود العملية</span>
                  <span
                    className={
                      'font-medium  text-gray-700 dark:text-gray-100  '
                    }
                  >
                    {
                      residualsResponse?.response?.residualsFees
                        ?.transactionCode
                    }
                  </span>
                </div>
              </div>
              <NavLink
                className={
                  'flex rounded-md border border-dashed p-1 hover:bg-gray-50'
                }
                to={`/customers/details/${residualsResponse?.response?.residualsFees?.phoneNumber}`}
              >
                <div className={'flex items-start gap-2   '}>
                  <FAvatar
                    name={
                      residualsResponse?.response?.residualsFees?.phoneNumber
                    }
                    className={'rounded-full'}
                  />
                  <div className={'flex flex-col '}>
                    <span className='text-sm font-semibold text-gray-800 dark:text-white'>
                      {response?.customerSubscriptions?.[0]?.customer?.name ||
                        'اسم العميل غير محدد'}{' '}
                      :{' '}
                      {residualsResponse?.response?.residualsFees?.amount || 0}{' '}
                      جنيه
                    </span>

                    <span
                      className={
                        'text-sm font-medium  text-gray-500 dark:text-gray-100  '
                      }
                    >
                      {
                        response?.customerSubscriptions?.[0]?.customer
                          ?.phoneNumber
                      }
                    </span>
                  </div>
                </div>
              </NavLink>
              <EFormWrapper>
                <FLabel htmlFor={'sub'}>الاشتراكات المتاحة للعميل</FLabel>
                <select
                  id={'sub'}
                  className={'input-style'}
                  onChange={e => setSelectedSubscription(e.target.value)}
                  value={selectedSubscription}
                >
                  <option value={''}>اختر الاشتراك</option>

                  {response?.customerSubscriptions?.map(item => {
                    const product = getProductById(item.productId)
                    const installment = getProductInstallmentById(
                      item.productInstallmentId,
                    )

                    const productName = product?.name ?? ''
                    const installmentDesc = installment?.installmentDesc ?? ''
                    const installmentCost = installment?.installmentCost ?? ''

                    const optionValue = item.id
                    const optionText = `${productName}${
                      productName && (installmentDesc || installmentCost)
                        ? ' |'
                        : ''
                    } ${installmentDesc}${
                      installmentDesc && installmentCost ? ' |' : ''
                    } قسط ${installmentCost} جنيه`

                    return (
                      <option key={optionValue} value={optionValue}>
                        {optionText}
                      </option>
                    )
                  })}
                </select>
              </EFormWrapper>
            </div>
            <div className={'mt-2 flex items-center gap-2'}>
              <FButton
                disabled={
                  selectedSubscription === '' ||
                  residualsResponse?.response?.residualsFees?.active === false
                }
                onClick={handleSettleResidual}
              >
                <span>سدد القسط</span>
              </FButton>
              <NavLink to={'/residual'}>
                <FButton btnType={'secondary'}>
                  <span>رجوع</span>
                </FButton>
              </NavLink>
            </div>
          </div>
          <div className={'flex w-4/12 flex-col items-start gap-2 p-5'}>
            <FButton
              onClick={() => refundMutation.mutate()}
              disabled={
                refundMutation.isLoading ||
                residualsResponse?.response?.residualsFees?.active === false
              }
            >
              استرجاع للعميل
            </FButton>
            <ECard>
              <ECard.Header>
                <span>التحديثات</span>
              </ECard.Header>

              <ECard.Body>
                {residualsResponse?.response?.residualsFees?.updates ??
                  ''.split('$$').map(item => {
                    return (
                      <span className={'block   flex-col gap-2'}>* {item}</span>
                    )
                  })}
              </ECard.Body>
            </ECard>
            {residualsResponse?.response?.residualsFees?.oracleFileUrl && (
              <a
                href={residualsResponse?.response?.residualsFees?.oracleFileUrl}
                target={'_blank'}
                className={'text-blue-500 underline'}
              >
                <FButton>رابط الملف المسؤول عن القيم المالية المعلقة</FButton>
              </a>
            )}
          </div>
        </div>
      </>
    )
  }
}
export default SettleResidual
