import { Link, NavLink, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchData } from './api/api'
import { useFormik } from 'formik'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { produce } from 'immer'
import EFormWrapper from '../../../components/EFormWrapper'
import React from 'react'
import {
  HiOutlineAnnotation,
  HiOutlineClipboardCheck,
  HiOutlineEmojiSad,
} from 'react-icons/hi'
import ActivityLog from './ActivityLog'
import ECard from '../../../components/ECard'
import FAvatar from '../../../components/FAvatar'
import { toast } from 'react-toastify'
import FIconWrapper from '../../../components/FIconWrapper'
import EWarning from '../../../components/EWarning'
import { getProductById, validURL } from '../../../helpers/utils'
import moment from 'moment/moment'
import FamilyMembers from './FamilyMembers'
import FProductTag from '../../../components/FProductTag'
import { axiosInstance } from '../../../api/requister'
import * as Yup from 'yup'
import UpgradeSubscriptionModal from './UpgradeSubscriptionModal'
import FFlexWrapper from '../../../components/FFlexWrapper'
import CancelSubModal from './CancelSubModal'
import pdf from '../../../assets/images/pdf.png'
import Flyout from '../../../components/Flyout'
import { JsonToTable } from 'react-json-to-table'
import { BiCodeAlt } from 'react-icons/bi'
import JsonView from 'react18-json-view'
import FRow from '../../../components/FRow'
import { BsCheck } from 'react-icons/bs'
import SubscriptionNote from './SubscriptionNote'
import FCol from '../../../components/FCol'

const ViewSubs = () => {
  const { id } = useParams()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['subscriptionDetails'],
    queryFn: () => fetchData(id),
    refetchOnWindowFocus: false,
  })

  const BooleanTagReader = ({ value }) => {
    if (value === true) {
      return <FProductTag color={'green'} productName={'نعم'} />
    }
    return <FProductTag color={'red'} productName={'لا'} />
  }
  const customerSubscription = data?.customerSubscription
  const customerSubscriptionDocuments = data?.customerSubscriptionDocuments

  const handleRequestBody = values => {
    return {
      answers: values.customerInfoAnswers.map(answer => ({
        productCustomerInfoId: answer.productCustomerInfo.id,
        answer: answer.inputAnswerValue,
      })),
      productInstallmentId: parseInt(
        values.customerSubscription?.productInstallmentId,
      ),
      customerSubscriptionDocumentDtos: [],
      nationality: values.nationality,
      dateOfBirth: values.dateOfBirth,
    }
  }

  const boolenTags = [
    {
      id: 1,
      accessor: 'answersCompletedFromCustomerUpload',
      name: 'تم اكتمال البيانات من رفع ملف العميل',
    },
    {
      id: 198324,
      accessor: 'dateOFLastPaidInstallment',
      name: 'مدفوع',
    },

    {
      id: 3,
      accessor: 'needsSMS',
      name: 'يحتاج إلى رسالة نصية',
    },
    {
      id: 3425,
      accessor: 'attachmentNumberForCover',
      name: 'الرفم الملحق',
    },
    {
      id: 4,
      accessor: 'completed',
      name: 'مكتمل',
    },
    {
      id: 6,
      accessor: 'cancelled',
      name: 'تم إلغاؤه',
    },
  ]

  const handleAddMedicalSubscriptionRelatives = insuredMembers => {
    axiosInstance
      .post(`/customersubscription/relative`, {
        customerSubscriptionId: id,
        customerSubscriptionRelatives: insuredMembers.map(insuredMember => {
          return {
            id: insuredMember.id,
            name: insuredMember.name,
            nationalId: insuredMember.nationalId,
            gender: insuredMember.gender,
            relationship: insuredMember.relationship.toLowerCase(),
            birthDate: insuredMember.dateOfBirth,
            relativePhoneNumber: insuredMember.relativePhoneNumber,
          }
        }),
      })
      .then(() => {
        toast.success('Relatives added successfully')
      })
      .catch(() => {
        toast.error('Something went wrong')
      })
  }

  const Formik = useFormik({
    initialValues: {
      customerInfoAnswers: data?.customerInfoAnswers || [],
      nationality: data?.customerSubscription?.customer?.nationality || '',
      dateOfBirth: data?.customerSubscription?.customer?.dateOfBirth || '',
      customerSubscriptionRelatives: data?.customerSubscriptionRelatives || [],
      customerSubscriptionDocuments,
      customerSubscription: {
        ...customerSubscription,
        dateOFLastPaidInstallment:
          customerSubscription?.dateOFLastPaidInstallment,
        insuranceExpireDate: new Date(
          customerSubscription?.insuranceExpireDate,
        ),
        insuranceStartDate: new Date(customerSubscription?.insuranceStartDate),
        machineId: customerSubscription?.machineId || '',
        productInstallmentId: customerSubscription?.productInstallmentId || '',
      },
    },
    onSubmit: values => {
      axiosInstance
        .post(`customersubscription/${id}`, handleRequestBody(values))
        .then(() => {
          if (data?.customerSubscription?.productId === 224) {
            handleAddMedicalSubscriptionRelatives(
              values.customerSubscriptionRelatives,
            )
          }
          toast.success('Updated Successfully')
        })
        .catch(() => {
          toast.error('Something went wrong')
        })
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      customerSubscriptionRelatives: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('الاسم مطلوب'),
          nationalId: Yup.string().required('الرقم القومي مطلوب'),
          dateOfBirth: Yup.string().required('تاريخ الميلاد مطلوب'),
          gender: Yup.string().required('النوع مطلوب'),
          relationship: Yup.string().required('العلاقة مطلوبة'),
        }),
      ),
    }),
  })

  const product = getProductById(customerSubscription?.productId)

  const handleRemoveRelative = index => {
    const removedRelative = produce(
      Formik.values.customerSubscriptionRelatives,
      draft => {
        draft.splice(index, 1)
      },
    )
    Formik.setFieldValue('customerSubscriptionRelatives', removedRelative)
  }

  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false)
  const [isFlyoutOpen, setIsFlyoutOpen] = React.useState(false)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false)

  const handlePerformReview = () => {
    axiosInstance
      .post(`customersubscription/${id}`, {
        needsReview: false,
      })
      .then(() => {
        toast.success('تمت المراجعة')
        refetch()
      })
      .catch(() => {
        toast.error('Something went wrong')
      })
  }

  if (isLoading) {
    return <div>Loading...</div>
  } else
    return (
      <>
        <Flyout title={'تفاصيل'} open={isFlyoutOpen} setOpen={setIsFlyoutOpen}>
          <JsonToTable json={data} />
          <JsonView src={data} />
        </Flyout>
        <UpgradeSubscriptionModal
          subscriptionObject={data?.customerSubscription}
          isOpen={isUpgradeModalOpen}
          setIsOpen={setIsUpgradeModalOpen}
        />
        <CancelSubModal
          id={id}
          setIsCancelModalOpen={setIsCancelModalOpen}
          isCancelModalOpen={isCancelModalOpen}
        />

        <div
          className={
            'sticky top-0 z-10 flex items-center justify-between border-b bg-white p-3  dark:bg-gray-900'
          }
        >
          <span>الاشتراكات</span>
          <div
            className={`flex gap-3 ${
              data?.customerSubscription?.cancelled &&
              'pointer-events-none opacity-50'
            }`}
          >
            <FButton onClick={() => setIsFlyoutOpen(true)}>
              <BiCodeAlt />
            </FButton>

            <FButton
              onClick={() =>
                handleFlag(
                  { needsSMS: true },
                  'تم أضافة العميل في سجل الرسائل القصيرة',
                )
              }
              btnType={'secondary'}
            >
              <FIconWrapper>
                <HiOutlineAnnotation />
                <span>رسالة نصية</span>
              </FIconWrapper>
            </FButton>
            <FButton
              onClick={() =>
                handleFlag(
                  { sendTheGIGDocumentBasedOnCustomerRequest: true },
                  'تم أضافة العميل في سجل الشكاوي',
                )
              }
              btnType={'secondary'}
            >
              <FIconWrapper>
                <HiOutlineEmojiSad />
                <span>تسجيل شكوي</span>
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'danger'}
              onClick={() => setIsCancelModalOpen(true)}
            >
              <FFlexWrapper>
                <span className={'  mr-1 ml-1'}>إلغاء الأشتراك</span>
              </FFlexWrapper>
            </FButton>
            <FButton onClick={() => setIsUpgradeModalOpen(true)}>
              <FFlexWrapper>
                <span className={'  mr-1 ml-1'}>ترقية الأشتراك</span>
              </FFlexWrapper>
            </FButton>
          </div>
        </div>
        {data?.customerSubscription?.cancelled && (
          <div
            className={
              'f-col-center m-3 rounded-md border border-dashed border-red-800 bg-red-100 p-3 text-red-600 dark:bg-gray-900'
            }
          >
            <img
              width='100'
              height='100'
              src='https://img.icons8.com/arcade/100/delete-forever.png'
              alt='delete-forever'
            />
            <span className={'font-bold text-red-700'}>
              تم إلغاء الأشتراك بنجاح و لا يمكنك تعديل أي من البيانات الخاصة به
            </span>
            {data?.customerSubscription?.cancellationReason && (
              <span>
                سبب الالغاء : {customerSubscription?.cancellationReason}
              </span>
            )}
            {data?.customerSubscription?.cancellationNotes && (
              <span>ملاحظات : {customerSubscription?.cancellationNotes}</span>
            )}
          </div>
        )}
        <div
          className={
            data?.customerSubscription?.cancelled &&
            'pointer-events-none opacity-50'
          }
        >
          <FCol className={'m-3 gap-5'}>
            {!customerSubscription?.completed && (
              <EWarning msg={'بيانات العميل غير مكتملة'} />
            )}

            {!customerSubscription?.customer?.name && (
              <EWarning
                msg={
                  'أسم العميل غير محدد و بالتالي فلن تسطيع استخراج اي وثيقة تأمنيه لهذا العميل يرجي'
                }
              >
                <Link
                  to={`/customers/details/${customerSubscription?.customer?.phoneNumber}`}
                >
                  <FButton btnType={'secondary'}>
                    <span className={'underline'}>
                      تحديث بيانات العميل من هنا
                    </span>{' '}
                  </FButton>
                </Link>
              </EWarning>
            )}
          </FCol>
          <div className={'flex items-center gap-3 p-3'}>
            <NavLink
              className={
                '  block  flex-grow rounded-md  border border-dashed p-1.5 hover:bg-gray-50  dark:bg-gray-700 '
              }
              to={`/customers/details/${customerSubscription?.customer?.phoneNumber}`}
            >
              <div className={'flex gap-2'}>
                <FAvatar name={'Abdelrahman Reda'} />
                <div className={'flex flex-col'}>
                  <span
                    className={
                      'ml-2 cursor-pointer font-semibold  text-gray-800 underline  dark:text-white'
                    }
                  >
                    {customerSubscription?.customer?.name ??
                      'أسم العميل غير محدد'}
                  </span>
                  <span className={'ml-2 text-xs leading-3'}>
                    {customerSubscription?.customer?.phoneNumber}
                  </span>
                </div>
              </div>
            </NavLink>
            <FButton
              onClick={() => {
                copy(customerSubscription?.customer?.phoneNumber)
                toast.success('تم نسخ رقم الهاتف')
              }}
            >
              <FIconWrapper>
                <HiOutlineClipboardCheck />
                <span className={'whitespace-nowrap'}> نسخ رقم الهاتف</span>
              </FIconWrapper>
            </FButton>
            {customerSubscription?.customer?.name && (
              <FButton
                onClick={() => {
                  copy(customerSubscription?.customer?.name)
                  toast.success('تم نسخ الاسم')
                }}
              >
                <FIconWrapper>
                  <HiOutlineClipboardCheck />
                  <span className={'whitespace-nowrap'}> نسخ الاسم</span>
                </FIconWrapper>
              </FButton>
            )}
          </div>
          <div className={'flex items-center  gap-1'}></div>
          <div className={'m-5 flex gap-4 '}>
            <div className={'sticky top-0 flex w-8/12 flex-col gap-3 '}>
              <ECard>
                <ECard.Header>
                  <FRow className={'items-center justify-between'}>
                    <span className={'font-medium '}>
                      البيانات الأساسية للعميل لاستخراج البوليصة
                    </span>
                    {customerSubscription?.needsReview && (
                      <FButton
                        btnType={'success'}
                        onClick={() => toast.success('تمت المراجعة')}
                      >
                        <FIconWrapper>
                          <BsCheck />
                          تمت المراجعة
                        </FIconWrapper>
                      </FButton>
                    )}
                  </FRow>
                </ECard.Header>
                <ECard.Body>
                  <div className={'grid grid-cols-2 gap-3'}>
                    {Formik.values.customerInfoAnswers.map((item, index) => (
                      <div key={index}>
                        <EFormWrapper>
                          <FLabel htmlFor='name'>
                            {item.productCustomerInfo.inputNameInReport}
                          </FLabel>
                          <FInputField
                            name={item.productCustomerInfo.inputNameInReport}
                            type='text'
                            placeholder={
                              item.productCustomerInfo.inputNameInReport
                            }
                            value={item.inputAnswerValue}
                            onChange={e => {
                              const arr = Formik.values.customerInfoAnswers
                              arr[index].inputAnswerValue = e.target.value
                              Formik.setFieldValue('customerInfoAnswers', arr)
                            }}
                          />
                          {validURL(item.inputAnswerValue) && (
                            <a
                              className={'cursor-pointer underline'}
                              href={item.inputAnswerValue}
                              target={'_blank'}
                            >
                              <img
                                className={'underline'}
                                src={item.inputAnswerValue}
                              />
                            </a>
                          )}
                        </EFormWrapper>
                      </div>
                    ))}
                    <EFormWrapper>
                      <FLabel htmlFor='nationality'>الجنسية</FLabel>
                      <FInputField
                        id={'nationality'}
                        name={'nationality'}
                        type='text'
                        placeholder={'الجنسية'}
                        value={Formik.values.nationality}
                        onChange={Formik.handleChange}
                      />
                    </EFormWrapper>

                    <EFormWrapper>
                      <FLabel htmlFor='dateOfBirth'>
                        تاريخ الميلاد (YYYY-MM-DD)
                      </FLabel>
                      <FInputField
                        id={'dateOfBirth'}
                        name={'dateOfBirth'}
                        type={'date'}
                        max={new Date().toISOString().split('T')[0]}
                        placeholder={'الجنسية'}
                        value={moment(Formik.values.dateOfBirth).format(
                          'YYYY-MM-DD',
                        )}
                        onChange={Formik.handleChange}
                      />
                    </EFormWrapper>
                  </div>
                </ECard.Body>
              </ECard>
              <ECard>
                <ECard.Header>
                  <span className={'text-sm font-bold'}>
                    {product?.name ?? 'اسم المنتج غير محدد'}
                  </span>
                </ECard.Header>
                <ECard.Body>
                  <div className={'f-col-center-center  my-3 '}>
                    <span className={'block text-center font-bold'}></span>
                  </div>
                  <div className={'grid grid-cols-2  justify-between gap-1'}>
                    {boolenTags.map(tag => (
                      <div
                        key={tag.id}
                        className={'flex items-center justify-between gap-2'}
                      >
                        <span className={'text-sm'}>{tag.name}</span>
                        <BooleanTagReader
                          value={
                            Formik.values.customerSubscription[tag.accessor]
                          }
                        />
                      </div>
                    ))}
                    <EFormWrapper>
                      <FLabel htmlFor={'lastPaidInstallmentDate'}>
                        تاريخ أخر قسط مدفوع
                      </FLabel>
                      <FInputField
                        id={'lastPaidInstallmentDate'}
                        value={moment(
                          Formik.values.customerSubscription
                            .dateOFLastPaidInstallment,
                        ).format('YYYY-MM-DD')}
                        name={'customerSubscription.dateOFLastPaidInstallment'}
                        type={'date'}
                        onChange={Formik.handleChange}
                      />
                    </EFormWrapper>
                    <EFormWrapper>
                      <FLabel htmlFor={'insuranceStartDate'}>
                        تاريخ بدايه التأمين
                      </FLabel>
                      <FInputField
                        id={'insuranceStartDate'}
                        value={moment(
                          Formik.values.customerSubscription.insuranceStartDate,
                        ).format('YYYY-MM-DD')}
                        name={'customerSubscription.insuranceStartDate'}
                        type={'date'}
                        onChange={Formik.handleChange}
                      />
                    </EFormWrapper>
                    <EFormWrapper>
                      <FLabel htmlFor={'insuranceExpireDate'}>
                        تاريخ نهاية التأمين
                      </FLabel>
                      <FInputField
                        id={'insuranceExpireDate'}
                        value={moment(
                          Formik.values.customerSubscription
                            .insuranceExpireDate,
                        ).format('YYYY-MM-DD')}
                        name={'customerSubscription.insuranceExpireDate'}
                        type={'date'}
                        onChange={Formik.handleChange}
                      />
                    </EFormWrapper>
                    <EFormWrapper>
                      <span className={'whitespace-nowrap text-sm'}></span>

                      <FLabel htmlFor={'productInstallmentId'}>
                        الباقة التأمينية
                      </FLabel>
                      <select
                        className={'input-style'}
                        name={'customerSubscription.productInstallmentId'}
                        id={'productInstallmentId'}
                        value={
                          Formik.values.customerSubscription
                            .productInstallmentId
                        }
                        onChange={e => {
                          Formik.setFieldValue(
                            'customerSubscription.productInstallmentId',
                            e.target.value,
                          )
                        }}
                      >
                        <option> غير محدد</option>
                        {product.productInstallments.map(item => (
                          <option value={item.id} key={item.id}>
                            {item.installmentDesc} - تغطية{' '}
                            {item.installmentCoverage} بسعر -
                            {item.installmentCost} جنيه
                          </option>
                        ))}
                      </select>
                    </EFormWrapper>
                    <EFormWrapper className={'col-span-2'}>
                      {Formik.values.customerSubscriptionDocuments?.map(
                        item => (
                          <div key={item.id} className={'grid grid-cols-3'}>
                            <a
                              className={
                                'f-col-center-center rounded-md border bg-white p-3 shadow-sm'
                              }
                              href={item.documentUrl}
                              target={'_blank'}
                            >
                              <>
                                <img src={pdf} alt='' />

                                <span className={'text-sm font-medium'}>
                                  {item.documentNumber}
                                </span>

                                <span className={'text-xs'}>
                                  {
                                    item.productInstallmentTradeInfoDocument
                                      .gigProductName
                                  }
                                </span>
                              </>
                            </a>
                          </div>
                        ),
                      )}
                    </EFormWrapper>
                    <EFormWrapper>
                      <FLabel htmlFor={'machineId'}>رقم الماكينة</FLabel>
                      <FInputField
                        placeholder={'رقم الماكينة'}
                        name={'customerSubscription.machineId'}
                        id={'machineId'}
                        value={Formik.values.customerSubscription.machineId}
                        onChange={Formik.handleChange}
                        type={'text'}
                      />
                    </EFormWrapper>
                    <EFormWrapper>
                      <FLabel htmlFor={'fawryCode'}>
                        كود الدفع الالكتروني
                      </FLabel>
                      <FInputField
                        placeholder={'كود الدفع الالكتروني'}
                        name={'customerSubscription.fawryCode'}
                        id={'fawryCode'}
                        value={Formik.values.customerSubscription.fawryCode}
                        onChange={Formik.handleChange}
                        type={'text'}
                      />
                    </EFormWrapper>
                  </div>
                </ECard.Body>
              </ECard>
              {data?.customerSubscription?.productId === 224 && (
                <FamilyMembers Formik={Formik} />
              )}
              <FButton
                className={'w-fit'}
                type={'button'}
                onClick={() => {
                  Formik.submitForm()
                }}
              >
                تحديث البيانات
              </FButton>
            </div>

            <div className={'sticky top-0 flex w-4/12 flex-col gap-2'}>
              <SubscriptionNote
                id={customerSubscription.id}
                phoneNumber={customerSubscription?.customer?.phoneNumber}
              />
              <ActivityLog id={customerSubscription.id} />
            </div>
          </div>
        </div>
      </>
    )
}
export default ViewSubs
