import FButton from '../../../components/FButton'
import FModal from '../../../components/FModal'
import FRow from '../../../components/FRow'
import { useState } from 'react'
import { useFormik } from 'formik'
import EFormWrapper from '../../../components/EFormWrapper'
import FInputField from '../../../components/FInputField'
import { useMutation } from '@tanstack/react-query'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'
import { axiosInstance } from '../../../api/requister'
import * as Yup from 'yup'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import FLabel from '../../../components/FLabel'
import { toast } from 'react-toastify'

const CallReportBeta = () => {
  const [isCallReportModalOpen, setIsCallReportModalOpen] = useState(false)

  const mutation = useMutation({
    mutationFn: async values => {
      const fromCallDate = new Date(values.startDate).toISOString()
      const toCallDate = new Date(values.endDate).toISOString()
      let urlParams = `fromCallDate=${fromCallDate}&toCallDate=${toCallDate}&callStatuss=DONE&callStatuss=OUT_OF_TRIALS`
      values.userEmail && (urlParams += `&userEmail=${values.userEmail}`)
      values.emailToReceiveTheReport &&
        (urlParams += `&emailToReceiveTheReport=${values.emailToReceiveTheReport}`)
      axiosInstance
        .get(
          `call/downloadAgentStatsReport?fromDate=2024-08-05T08%3A43%3A15.856Z&toDate=2024-08-05T08%3A43%3A15.856Z&userEmail=Agent2%40Fawry.com`,
        )
        .then(() => {
          toast.success('تم تحميل التقرير بنجاح')
        })

      const { date } = await axiosInstance.get(
        `call/downloadReport?${urlParams}`,
        {
          responseType: 'blob',
        },
      )

      const url = window.URL.createObjectURL(new Blob([date.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${values.startDate} - ${values.endDate}.xlsx`,
      )
      document.body.appendChild(link)
      link.click()
    },
  })

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      userEmail: '',
      emailToReceiveTheReport: '',
    },
    validationSchema: Yup.object({
      startDate: Yup.string().required('تاريخ البداية مطلوب'),
      endDate: Yup.string()
        .required('تاريخ النهاية مطلوب')
        .test(
          'is-greater',
          'تاريخ النهاية يجب ان يكون بعد تاريخ البداية',
          function (value) {
            return new Date(value) > new Date(this.parent.startDate)
          },
        ),
    }),
    onSubmit: values => {
      mutation.mutate(values)
    },
  })

  return (
    <>
      <FModal
        title={'تقرير المكالمات'}
        isOpen={isCallReportModalOpen}
        setIsOpen={setIsCallReportModalOpen}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className={'grid grid-cols-2 gap-2'}>
            <EFormWrapper>
              <FLabel htmlFor='startDate'>تاريخ البداية</FLabel>
              <FInputField
                max={new Date().toISOString().split('T')[0]}
                id='startDate'
                name='startDate'
                type='date'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.startDate}
              />
              <EFormInvalidInput
                FieldName={'startDate'}
                errors={formik.errors}
                touched={formik.touched}
              />
            </EFormWrapper>
            <EFormWrapper>
              <FLabel htmlFor='endDate'>تاريخ النهاية</FLabel>
              <FInputField
                max={new Date().toISOString().split('T')[0]}
                id='endDate'
                name='endDate'
                type='date'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.endDate}
              />

              <EFormInvalidInput
                FieldName={'endDate'}
                errors={formik.errors}
                touched={formik.touched}
              />
            </EFormWrapper>
          </div>
          <EFormWrapper>
            <FRow className={'items-center'}>
              <FLabel htmlFor='userEmail'>موظف</FLabel>
              <span className={'text-xs text-gray-500'}>(اخياري)</span>
            </FRow>
            <select
              id='userEmail'
              name='userEmail'
              onChange={formik.handleChange}
              value={formik.values.userEmail}
            >
              <option value=''>اختر موظف لعرض تقرير المكالمات الخاص به</option>
              {localStorage.getItem('users') &&
                JSON.parse(localStorage.getItem('users')).map(user => (
                  <option key={user.email} value={user.email}>
                    {user.email}
                  </option>
                ))}
            </select>
          </EFormWrapper>
          <EFormWrapper>
            <FRow className={'items-center'}>
              <FLabel htmlFor='emailToReceiveTheReport'>
                ايميل لاستقبال التقرير
              </FLabel>
              <span className={'text-xs text-gray-500'}>(اخياري)</span>
            </FRow>
            <FInputField
              placeholder={'ايميل لاستقبال التقرير'}
              id='emailToReceiveTheReport'
              name='emailToReceiveTheReport'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.emailToReceiveTheReport}
            />
          </EFormWrapper>

          <FRow className={'mt-1'}>
            <FButton type='submit' btnType={'primary'}>
              <FIconWrapper>
                تحميل
                <ESpinner isVisible={mutation.isLoading} />
              </FIconWrapper>
            </FButton>
            <FButton
              type={'button'}
              onClick={() => setIsCallReportModalOpen(false)}
              btnType={'secondary'}
            >
              الغاء
            </FButton>
          </FRow>
        </form>
      </FModal>

      <FButton onClick={() => setIsCallReportModalOpen(true)}>
        تقرير المكالمات Beta
      </FButton>
    </>
  )
}
export default CallReportBeta
