import React from 'react'
import { MICROSERVICE_URL } from '../../../env'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { useFormik } from 'formik'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { BsArrowLeftShort } from 'react-icons/bs'
import { FaPlusCircle, FaRegTrashAlt } from 'react-icons/fa'
import { produce } from 'immer'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { axiosInstance } from '../../../api/requister'
import FIconWrapper from '../../../components/FIconWrapper'

const OracelBTCProductMapModal = ({ isOpen, setIsOpen }) => {
  const { response, loading } = useCustomAxios({
    method: 'GET',
    url: `${MICROSERVICE_URL}product-map`,
  })

  const [parent, enableAnimations] = useAutoAnimate({
    // Animation duration in milliseconds (default: 250)
    duration: 250,
    // Easing for motion (default: 'ease-in-out')
    easing: 'ease-in-out',
    // When true, this will enable animations even if the user has indicated
    // they don’t want them via prefers-reduced-motion.
    disrespectUserMotionPreference: false,
  })

  const formik = useFormik({
    initialValues: {
      productMap: response || [],
    },
    onSubmit: values => {
      axiosInstance
        .put(`${MICROSERVICE_URL}/oracle/btc/product-map`, {
          productMap: values.productMap,
        })
        .then(res => {
          console.log(res)
          setIsOpen(false)
        })
        .catch(err => {
          console.log(err)
        })
    },
    enableReinitialize: true,
  })

  const Divider = () => {
    return (
      <div className='relative my-3'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-300' />
        </div>
        <div className='relative flex justify-center'>
          <span className='bg-white px-2 text-sm  text-gray-500 dark:bg-gray-900   dark:text-gray-100'>
            اعدادات التحويل
          </span>
        </div>
      </div>
    )
  }

  if (loading) {
    return <div>Loading...</div>
  } else {
    return (
      <>
        <div
          title={'Oracle BTC Product Map'}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        >
          <Divider />
          <div className={'mb-3 flex flex-row-reverse justify-between'}>
            <FButton
              type={'button'}
              btnType={'success'}
              onClick={() => {
                const newProductMap = produce(
                  formik.values.productMap,
                  draft => {
                    draft.push({
                      name: '',
                      btc: '',
                    })
                  },
                )
                formik.setFieldValue('productMap', newProductMap)
              }}
            >
              <FIconWrapper>
                <FaPlusCircle size={20} />
                اضافة منتج
              </FIconWrapper>
            </FButton>

            <FButton
              disabled={!formik.dirty}
              btnType={'primary'}
              type={'submit'}
            >
              حفظ
            </FButton>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className={'grid grid-cols-1 gap-2'}
          >
            {formik?.values?.productMap?.map((product, index) => (
              <div
                key={index}
                className={'flex items-center gap-1'}
                ref={parent}
              >
                <FInputField
                  name={`productMap[${index}].name`}
                  type={'text'}
                  placeholder={'Product Name'}
                  value={formik?.values?.productMap[index]?.name}
                  onChange={e => {
                    formik.setFieldValue(
                      `productMap[${index}].name`,
                      e.target.value,
                    )
                  }}
                />
                <BsArrowLeftShort
                  size={20}
                  style={{
                    minWidth: '30px',
                  }}
                />
                <FInputField
                  name={`productMap[${index}].btc`}
                  type={'text'}
                  placeholder={'BTC'}
                  value={formik?.values?.productMap[index]?.btc}
                  onChange={e => {
                    formik.setFieldValue(
                      `productMap[${index}].btc`,
                      e.target.value,
                    )
                  }}
                />
                <FButton
                  type={'button'}
                  btnType={'danger'}
                  onClick={() => {
                    const newProductMap = produce(
                      formik.values.productMap,
                      draft => {
                        draft.splice(index, 1)
                      },
                    )
                    formik.setFieldValue('productMap', newProductMap)
                  }}
                >
                  <FaRegTrashAlt />
                </FButton>
              </div>
            ))}
          </form>
        </div>
      </>
    )
  }
}

export default OracelBTCProductMapModal
