import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Login from './views/authentication/Login'
import MembersList from './views/Members/MembersList'
import Settings from './views/Settings/Settings'
import ProtectedRoute from './Routes/ProtectedRoute'
import SecuritySettings from './views/Settings/SecuritySettings'
import ChangePassword from './views/Settings/ChangePassword'
import CustomerAdd from './views/Admin/Customers/CustomerAdd'
import CustomerList from './views/Admin/Customers/CustomerList'

import './i18n/i18n'
import CustomerEdit from './views/Admin/Customers/CustomerEdit'
import AgentBasicInfo from './views/Agent/AgentBasicInfo'
import CustomerDetails from './views/Admin/Customers/CustomerDetails'
import ProductAdd from './views/Admin/Products/ProductAdd'
import AgentDashboard from './views/Agent/Dashbaord/AgentDashboard'
import ProductList from './views/Admin/Products/ProductList'
import FCustomDataTable from './components/FCustomDataTable/FCustomDataTable'
import AgentList from './views/Admin/Agents/AgentList'
import AgentEdit from './views/Admin/Agents/AgentEdit'
import ProductDetails from './views/Admin/Products/ProductDetails'
import OracleList from './views/Admin/Oracle/OracleList'
import ForbiddenPage from './views/Static/ForbiddenPage'
import Distribution from './views/Admin/Distribution/Distribution'
import AddProductCancelation from './views/Admin/ProductCanelation/AddProductCancelation'
import ListProductCancelation from './views/Admin/ProductCanelation/ListProductCancelation'
import InternalServerError from './views/Static/InternalServerError'
import EditProductCancelation from './views/Admin/ProductCanelation/EditProductCancelation'
import { AppRoutes } from './Routes/AppRoutes'
import Reports from './views/Admin/Reports'
import OutputReport from './views/Admin/Reports/OutputReport'
import ResponseReports from './views/Admin/Reports/ResponseReports'
import InternalReports from './views/Admin/Reports/InternalReports'
import SalesBasicInfo from './views/Agent/SalesBasicInfo'
import EditProductVersionTwo from './views/Admin/Products/ProductV2/EditProductVersionTwo'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'react18-json-view/src/style.css'
import Dashboard from './views/Admin/Dashboard/Dashboard'

const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient()

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/table' element={<FCustomDataTable />} />
        <Route
          path='/accept-registration-invitation/:uuid'
          element={<AgentBasicInfo />}
        />
        <Route
          path='/accept-registration-invitation/sales/:uuid'
          element={<SalesBasicInfo />}
        />
        <Route element={<ProtectedRoute />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/distribution' element={<Distribution />} />
          <Route path='/members' element={<MembersList />} />
          <Route
            path='/'
            element={
              JSON.parse(localStorage.getItem('authority'))?.includes(
                'ADMIN_AUTHORITY',
              ) ? (
                <CustomerList />
              ) : (
                <AgentDashboard />
              )
            }
          />

          <Route path={'settings'} element={<Settings />}>
            <Route index path='details' element={<ChangePassword />} />
            <Route path='security' element={<SecuritySettings />} />
          </Route>
          <Route path={'reports'} element={<Reports />}>
            <Route index element={<OutputReport />} />
            <Route path={'report'} element={<OutputReport />} />
            <Route path={'gig'} element={<ResponseReports />} />
            <Route path={'internal'} element={<InternalReports />} />
          </Route>
          <Route path='/customer/add' element={<CustomerAdd />} />
          <Route path='/customer' element={<CustomerList />} />
          <Route path='/customer/:phoneNumber' element={<CustomerEdit />} />
          <Route
            path='/customers/details/:phoneNumber'
            element={<CustomerDetails />}
          />
          {AppRoutes.map(route => (
            <Route key={route.name} path={route.path} element={route.element} />
          ))}
          <Route path='/oracle' element={<OracleList />} />
          <Route path='/product/add' element={<ProductAdd />} />
          <Route path='/product' element={<ProductList />} />
          <Route
            path='/product/cancel/add'
            element={<AddProductCancelation />}
          />
          <Route
            path='/product/cancel/edit'
            element={<AddProductCancelation />}
          />
          <Route
            path='/productCancelList'
            element={<ListProductCancelation />}
          />
          <Route
            path='/productCancelEdit/:id'
            element={<EditProductCancelation />}
          />
          <Route path='/product/:id' element={<ProductDetails />} />
          <Route path='/productV2/:id' element={<EditProductVersionTwo />} />
          <Route path='/agent' element={<AgentList />} />
          <Route path='/agent/:email' element={<AgentEdit />} />
        </Route>
        <Route path={'agent/register'} element={<AgentBasicInfo />} />
        <Route path={'/forbidden'} element={<ForbiddenPage />} />
        <Route path={'/servererror'} element={<InternalServerError />} />
        <Route
          path={'*'}
          element={
            <div
              className={'flex justify-center items-center h-screen flex-col'}
            >
              <h1>404</h1>
              <h2>Page Not Found</h2>
            </div>
          }
        />
      </Routes>
    </QueryClientProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
