import FCollapse from '../../../components/FCollapse'
import FProductTag from '../../../components/FProductTag'
import FIconWrapper from '../../../components/FIconWrapper'
import { MdHeadsetMic } from 'react-icons/md'
import { FiChevronLeft } from 'react-icons/fi'
import React, { useEffect, useState } from 'react'
import CustomerModal from './CustomerModal'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { UseLocalStorageContent } from '../../../Hooks/useLocalStorageContent'
import CustomerRecord from '../../Admin/Customers/CustomerRecord'
import FButton from '../../../components/FButton'
import FInputField from '../../../components/FInputField'
import CustomerSearchModal from '../../../components/Layout/CustomerSearchModal'
import moment from 'moment-timezone'

moment.tz.setDefault('Africa/Cairo')

function localDateToISO(date) {
  const isoDateString =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2) +
    'T' +
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2) +
    ':' +
    ('0' + date.getSeconds()).slice(-2) +
    '.' +
    ('00' + date.getMilliseconds()).slice(-3) +
    'Z'

  return isoDateString
}

const AgentDashboard = () => {
  const { email } = UseLocalStorageContent()
  const [activeCallsPage, setActiveCallsPage] = useState(0)
  const [archieveCallsPage, setArchieveCallsPage] = useState(0)
  const [searchActiveCalls, setSearchActiveCalls] = useState('')

  const inputRef = React.useRef(null)

  const [callType, setCallType] = useState('ALL')
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/call/?page=${activeCallsPage}&size=10&userEmail=${email}&callStatus=ASSIGNED${
        searchActiveCalls !== ''
          ? `&phoneNumber=${encodeURIComponent(searchActiveCalls)}`
          : ''
      }&endDate=${encodeURIComponent(
        localDateToISO(new Date()),
      )}&endDateCompareOperator=%3C%3D`,
    })

  const url = `/call/archive/?page=${archieveCallsPage}&size=10&userEmail=${encodeURIComponent(
    email,
  )}&archiveDate=${encodeURIComponent(
    localDateToISO(new Date()),
  )}&interactionTypes=REFUSE&interactionTypes=RESCHEDULE&interactionTypes=SOLD&interactionTypes=UNREACHABLE&interactionTypes=MISSING_DATA_DONE&interactionTypes=DONE&interactionTypes=NOT_NEEDED`

  const archiveResponse = useCustomAxios({
    method: 'GET',
    url: url,
  })
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [scheduleInstance, setScheduleInstance] = useState(null)

  useEffect(() => {
    Refetch()
  }, [activeCallsPage, searchActiveCalls])

  useEffect(() => {
    archiveResponse.Refetch()
  }, [archieveCallsPage])

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)

  const CarouselTitle = ({ Collapse, title, color, count }) => (
    <>
      <div className={'flex items-center gap-2'}>
        <div className={`bg-${color}-500  rounded-t p-1 text-sm text-white`}>
          <FIconWrapper>
            <FiChevronLeft
              className={`transform text-sm  ${
                Collapse ? 'rotate-90' : '-rotate-90'
              }`}
            />
            <span> {title} </span>
          </FIconWrapper>
        </div>
        <div className={'text-gray-400'}>
          <FIconWrapper>
            <MdHeadsetMic className={'text-sm'} />
            <span className={' text-xs'}> {`${count} عملاء`} </span>
          </FIconWrapper>
        </div>
      </div>
    </>
  )
  const CallHourSection = ({ children, title, color }) => {
    const [isOpen, setIsOpen] = useState(false)
    const collapseCallback = state => setIsOpen(state)
    return (
      <>
        <FCollapse
          callback={collapseCallback}
          title={
            <CarouselTitle
              color={color}
              title={title}
              Collapse={isOpen}
              count={React.Children.toArray(children).length - 1}
            />
          }
        >
          {children}
        </FCollapse>
      </>
    )
  }
  if (loading || archiveResponse.loading) return <div>loading</div>
  else
    return (
      <>
        <CustomerSearchModal
          isSearchModalOpen={isSearchModalOpen}
          setIsSearchModalOpen={setIsSearchModalOpen}
          inputRef={inputRef}
        />

        <CustomerModal
          type={callType}
          isModalOpen={isCustomerModalOpen}
          setIsModalOpen={setIsCustomerModalOpen}
          customer={customer}
          scheduleInstance={scheduleInstance}
          callback={() => {
            Refetch()
            archiveResponse.Refetch()
          }}
        />
        <div className={'flex flex-col gap-5 rounded p-20'}>
          <div
            className={
              'flex flex-col gap-6 rounded border  bg-gray-50 p-5  dark:bg-gray-700'
            }
          >
            <span className={'text-lg  text-gray-500 dark:text-gray-100  '}>
              مكالمات اليوم{' '}
            </span>
            <div className='grid grid-cols-4 gap-2'>
              {response?.callsCount?.map((item, index) => (
                <div
                  key={index}
                  className={
                    'flex flex-col gap-2 rounded-md border bg-white p-3 dark:bg-gray-900'
                  }
                >
                  <div className={'flex gap-2'}>
                    <FProductTag
                      productName={item.productName}
                      color={'blue'}
                    />
                    <span className={' text-gray-500 dark:text-gray-100  '}>
                      {item.count}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <FInputField
              placeholder={'بحث عن رقم العميل'}
              value={searchActiveCalls}
              onChange={e => setSearchActiveCalls(e.target.value)}
            />

            <CallHourSection color={'gray'} title={'مكالمات متبقية'}>
              {response?.callSchedules
                ?.filter(item => !item.disabled)
                .map((item, index) => (
                  <div key={index}>
                    <CustomerRecord
                      setCallType={setCallType}
                      type={'active'}
                      scheduleInstance={item}
                      setCustomer={setCustomer}
                      setIsCustomerModalOpen={setIsCustomerModalOpen}
                      setScheduleInstance={setScheduleInstance}
                      className={'border-x border-b'}
                      bulletColor={'bg-gray-500'}
                      phone={item.customer.phoneNumber}
                      name={item.customer.name}
                    />
                  </div>
                ))}
              <div className='flex items-center justify-between'>
                <div className={'mt-2 flex items-center  gap-2'}>
                  <FButton
                    disabled={totalNumberOfPages - 1 === activeCallsPage}
                    onClick={() => {
                      setActiveCallsPage(activeCallsPage + 1)
                    }}
                  >
                    الصفحة التالية
                  </FButton>
                  <FButton
                    disabled={activeCallsPage === 0}
                    onClick={() => {
                      setActiveCallsPage(activeCallsPage - 1)
                    }}
                  >
                    الصفحة السابقة
                  </FButton>
                </div>
                <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
                  عرض الصفحة {activeCallsPage + 1} من {totalNumberOfPages}
                </span>
              </div>
            </CallHourSection>

            <CallHourSection color={'red'} title={'مكالمات منهية'}>
              {archiveResponse?.response?.archiveCallsSchedule?.map(
                (item, index) => (
                  <div key={index}>
                    <CustomerRecord
                      setCallType={setCallType}
                      type={'archive'}
                      scheduleInstance={item}
                      className={'border-x border-b'}
                      bulletColor={'bg-gray-500'}
                      phone={item.customer.phoneNumber}
                      name={item.customer.name}
                      setCustomer={setCustomer}
                      setIsCustomerModalOpen={setIsCustomerModalOpen}
                      setScheduleInstance={setScheduleInstance}
                    />
                  </div>
                ),
              )}

              <div className={'mt-2 flex items-center  gap-2'}>
                <FButton
                  disabled={
                    archiveResponse.totalNumberOfPages - 1 === archieveCallsPage
                  }
                  onClick={() => {
                    setArchieveCallsPage(archieveCallsPage + 1)
                  }}
                >
                  الصفحة التالية
                </FButton>
                <FButton
                  disabled={archieveCallsPage === 0}
                  onClick={() => {
                    setArchieveCallsPage(archieveCallsPage - 1)
                  }}
                >
                  الصفحة السابقة
                </FButton>
              </div>
            </CallHourSection>
          </div>
        </div>
      </>
    )
}
export default AgentDashboard
