import { Link, useNavigate } from 'react-router-dom'
import FButton from '../../../components/FButton'
import React, { useEffect, useState } from 'react'
import FRow from '../../../components/FRow'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import { staticProducts } from '../../../helpers/utils'
import FInputField from '../../../components/FInputField'
import { useLocalStorage } from 'usehooks-ts'
import FCol from '../../../components/FCol'
import ClaimReport from './ClaimReport'
import moment from 'moment/moment'

const ListClaim = () => {
  const navigate = useNavigate()
  const initialProducts = staticProducts.map((item, index) => ({
    id: item.id,
    name: item.name,
    selectedTab: index === 0,
  }))
  const [products, setProducts] = useLocalStorage(
    'claimListTabs',
    initialProducts,
    [],
  )
  const [filteredPhone, setFilteredPhone] = useState('')

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['getClaimsLists'],
    queryFn: () =>
      axiosInstance
        .get(
          `Compensation/listAllCompensations?page=0&size=10${
            products.find(item => item.selectedTab)?.id
              ? `&productId=${products.find(item => item.selectedTab).id}`
              : ''
          }${
            filteredPhone ? `&customerPhoneNumber=${filteredPhone}` : ''
          }&claimHandled=false`,
        )
        .then(res => res.data),
  })

  useEffect(() => {
    refetch()
  }, [products, filteredPhone])

  const [page, setPage] = React.useState(0)
  const claimsTypes = ['قرشك الأبيض', 'باب رزقك', 'الطبي', 'الكتروني', 'أخرى']
  const cols = [
    {
      Header: 'رقم التعويض',
      accessor: 'id', // String-based value accessors!
    },
    {
      Header: 'العميل',
      accessor: 'customer', // String-based value accessors!
      cell: (cell, row) => (
        <FCol>
          <span className={'text-sm'}>{cell?.name}</span>
          <span className={'text-xs'}>{cell?.phoneNumber}</span>
        </FCol>
      ),
    },
    {
      Header: 'نوع التعويض',
      accessor: 'compensationType.name', // String-based value accessors!
    },
    {
      Header: 'تاريخ الأضافة',
      accessor: 'creationDate', // String-based value accessors!
      cell: (cell, row) => <span>{moment(cell).format('DD-MM-YYYY')}</span>,
    },
    {
      Header: 'اخر تحديث',
      accessor: 'lastUpdateDate', // String-based value accessors!
      cell: (cell, row) => <span>{moment(cell).format('DD-MM-YYYY')}</span>,
    },
  ]

  if (isLoading) return <div>loading</div>
  else
    return (
      <div>
        <FRow className={'items-center  justify-between p-3'}>
          <span className={'text-lg font-medium'}>التعويضات</span>
          <FRow>
            <Link to={'/claim/add'}>
              <FButton>اضافة مطالبة</FButton>
            </Link>
            <ClaimReport />
          </FRow>
        </FRow>
        <div
          className={'grid grid-cols-2 items-center gap-1 border-y bg-gray-50'}
        >
          <div className={'flex gap-1 '}>
            {products
              .filter(item => item.name !== 'Merchant Cancellation')
              .map((item, index) => (
                <span
                  key={index}
                  className={`${
                    item.selectedTab
                      ? 'bg-primary border-b-2 border-y-orient-500'
                      : 'bg-secondary hover:bg-bg-gray-100 text-gray-500 '
                  }  cursor-pointer whitespace-nowrap  p-2 `}
                  onClick={() => {
                    setProducts(
                      products.map(product => ({
                        ...product,
                        selectedTab: product.id === item.id,
                      })),
                    )
                  }}
                >
                  {item.name}
                </span>
              ))}
          </div>
          <FInputField
            placeholder={'بحث عن رقم العميل'}
            value={filteredPhone}
            onChange={e => setFilteredPhone(e.target.value)}
          />
        </div>

        <FCustomDataTable
          cols={cols}
          data={data?.results}
          isLoading={isRefetching}
          page={page}
          setPage={setPage}
          size={2}
          totalNumberOfPages={data?.totalCount}
          rowFunction={row => {
            return navigate(`/claim/${row.id}`)
          }}
          Refetch={refetch}
        />
      </div>
    )
}
export default ListClaim
