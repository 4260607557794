import FTable from '../../components/FTable'
import React, { useEffect, useState } from 'react'
import FButton from '../../components/FButton'
import FFlexWrapper from '../../components/FFlexWrapper'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { axiosInstance } from '../../api/requister'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import FAvatar from '../../components/FAvatar'
import TableLoader from '../../components/Loader/TableLoader'
import AddMember from './AddMember'
import EditMember from './EditMember'

const MembersList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen_, setIsEditModalOpen_] = useState(false)
  const [membersList, setMembersList] = useState([])
  const [isMemberDataLoaded, setIsMemberDataLoaded] = useState(false)
  const [userId, setUserId] = useState(null)

  useEffect(() => getUsersInfo(), [])

  const getUsersInfo = () =>
    axiosInstance
      .get('/admin/users')
      .then(response => {
        if (response) {
          setIsMemberDataLoaded(true)
          setMembersList(response.data.data.docs)
        }
      })
      .catch(error => console.log(error))

  function MyCell({ value }) {
    return value === 'true' ? (
      <span
        className={
          'rounded-full border border-green-500 bg-green-100 py-0.5 px-2 text-xs font-bold text-green-500'
        }
      >
        Active
      </span>
    ) : (
      <span
        className={
          'rounded-full border border-red-500 bg-red-100 py-0.5 px-2 text-xs font-bold text-red-500'
        }
      >
        Not active
      </span>
    )
  }

  function NameCell({ value, ...props }) {
    return (
      <div
        className={'flex gap-2 hover:cursor-pointer hover:underline'}
        onClick={() => {
          setIsEditModalOpen_(true)
          setUserId(props.row.original._id)
        }}
      >
        <FAvatar name={value} />
        <div>
          <span className={'block text-sm font-bold'}>{value}</span>
          <span className={'block text-xs leading-3'}>
            {props.row.original.email}
          </span>
        </div>
      </div>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableGroupBy: true,
        Cell: NameCell,
      },
      {
        Header: 'Role',
        accessor: 'role',
        disableGroupBy: true,
      },
      {
        Header: 'userName',
        accessor: 'userName',
        disableGroupBy: true,
      },
      {
        Header: 'createdAt',
        accessor: 'createdAt',
        disableGroupBy: true,
      },
      {
        Header: 'updatedAt',
        accessor: 'updatedAt',
        disableGroupBy: true,
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        disableGroupBy: true,
        Cell: MyCell,
      },
    ],
    [],
  )
  return (
    <>
      <ToastContainer
        position='bottom-right'
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AddMember
        getUsersInfo={getUsersInfo}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <EditMember
        id={userId}
        getUsersInfo={getUsersInfo}
        isModalOpen={isEditModalOpen_}
        setIsModalOpen={setIsEditModalOpen_}
      />

      <div className={'mb-5  flex items-center  justify-between'}>
        <span className={'text-2xl font-medium'}> Member</span>
        <FButton onClick={() => setIsModalOpen(true)}>
          <FFlexWrapper>
            <HiOutlinePlusSm />
            <span> Add New</span>
          </FFlexWrapper>
        </FButton>
      </div>
      {isMemberDataLoaded ? (
        <FTable columns={columns} data={membersList} />
      ) : (
        <TableLoader />
      )}
      {/*<embed*/}
      {/*    src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"*/}
      {/*    type="application/pdf"*/}
      {/*    frameBorder="0"*/}
      {/*    scrolling="auto"*/}
      {/*    height="1000px"*/}
      {/*    width="100%"*/}
      {/*></embed>*/}
      <></>
    </>
  )
}

export default MembersList
