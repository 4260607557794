import fawryGig from '../../../assets/images/fawry-gig.png'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlineUpload } from 'react-icons/ai'
import React from 'react'
import FModal from '../../../components/FModal'
import { axiosInstance } from '../../../api/requister'

const ResponseReports = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const handleUploadGigResponseFile = () => {
    const formData = new FormData()
    formData.append('file', file)
    axiosInstance
      .post('/report/gigExtractionResponseFile', formData)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }
  return (
    <>
      <FModal
        setIsOpen={setShowModal}
        isOpen={showModal}
        title={'رفع ملف تأكيد الأستخراجات من جي أي جي'}
      >
        <label>
          <input type='file' onChange={e => setFile(e.target.files[0])} />
        </label>
        <div className={'mt-3 flex gap-3'}>
          <FButton onClick={handleUploadGigResponseFile}>
            <FIconWrapper>
              <AiOutlineUpload />
              <span>رفع</span>
            </FIconWrapper>
          </FButton>
          <FButton btnType={'secondary'} onClick={() => setShowModal(false)}>
            <FIconWrapper>
              <span>إلغاء</span>
            </FIconWrapper>
          </FButton>
        </div>
      </FModal>
      <div className={'flex h-full flex-col items-start gap-2 p-5'}>
        <span
          className={
            'text-lg font-semibold  text-gray-700 dark:text-gray-100  '
          }
        >
          تقارير تأكيد الاستخراج من جي أي جي
        </span>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGig} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تقرير الأصدار
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              يمكنك تنزيل التقرير الخاص باصدار الوثائق المرسل من فوري لجي أي جي
            </span>
          </div>

          <FButton onClick={() => setShowModal(true)} className={'mr-auto'}>
            <FIconWrapper>
              <AiOutlineUpload />
              <span className={'text-sm'}>رفع التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
      </div>
    </>
  )
}
export default ResponseReports
