export const arabic = {
  settings: 'الاعدادات',
  no: 'لا',
  bio: 'السيرة الذاتية',
  yes: 'نعم',
  home: 'الصفحة الرئيسية',
  save: 'حفظ',
  clear: 'مسح',
  email: 'البريد الالكتروني',
  ielts: 'IELTS',
  login: 'تسجيل الدخول',
  score: 'نتيجة',
  toefl: 'TOEFL',
  delete: 'حذف',
  filter: 'منقي',
  logout: 'تسجيل خروج',
  'rank %': 'مرتبة ٪',
  search: 'بحث',
  single: 'عازب',
  account: 'حسابي',
  address: 'عنوان',
  college: 'كلية',
  country: 'دولة',
  intakes: 'الفصول الدراسية',
  married: 'متزوج \\ متزوجة',
  profile: 'الملف الشخصي',
  schools: 'المدارس',
  zipcode: 'رمز بريدي',
  password: 'كلمة المرور',
  programs: 'البرامج',
  city_town: 'المدينة / البلدة',
  countries: 'بلدان',
  dashboard: 'الرئيسية',
  firstname: 'الاسم الاول',
  gmat_date: 'معاد أختار  GMAT',
  last_name: 'الأسم الأخير',
  provinces: 'المقاطعات / الولاية',
  first_name: 'الاسم الأول',
  my_profile: 'ملفي الشخصي',
  test_score: 'لغات ومهارات',
  university: 'جامعة',
  eligibility: 'جدارة - أهلية',
  high_school: 'المدرسة الثانوية',
  middle_name: 'الاسم الأوسط',
  nationality: 'جنسية',
  test_scores: 'درجات الاختبار',
  tuition_fee: 'مصاريف',
  apply_filter: 'تصفية',
  availability: 'التوفر',
  date_of_exam: 'تاريخ الامتحان',
  new_password: 'كلمة المرور الجديدة',
  phone_number: 'رقم الهاتف',
  school_types: 'أنواع المدارس',
  date_of_birth: 'تاريخ الميلاد',
  go_to_schools: 'اذهب إلى المدارس',
  grading_scale: 'مقياس الدرجات',
  gre_exam_date: 'تاريخ أمتحان الخريجيين',
  provide_later:
    'ملاحظة: سوف تكون قادرًا على التقدم للقبول المشروط من خلال التسجيل في برنامج ESL إذا كان البرنامج الأكاديمي لا يقبل التأخير في تقديم درجات إتقان اللغة الإنجليزية.',
  school_filter: 'مرشح المدرسة',
  address_detail: 'تفاصيل العنوان',
  create_account: 'إنشاء حساب',
  first_language: 'اللغة الأولي',
  grading_scheme: 'مخطط الدرجات',
  i_don_not_have: 'ليس لدي',
  marital_status: 'الحالة الحالة الإجتماعية',
  my_application: 'طلباتي',
  program_filter: 'مرشح البرنامج',
  program_levels: 'مستويات البرنامج',
  province_state: 'المقاطعة / الولاية',
  reading_scores: 'درجات القراءة',
  select_country: 'حدد الدولة',
  writing_scores: 'درجات الكتابة',
  application_fee: 'رسوم أستمارة التقديم',
  education_level: 'مستوى التعليم',
  grading_average: 'متوسط الدرجات',
  gre_verbal_rank: 'الرتبة اللفظية GRE',
  if_you_answered:
    'إذا كانت إجابتك "نعم" على أي من الأسئلة أعلاه ، فيرجى تقديم مزيد من التفاصيل أدناه',
  passport_number: 'رقم جواز السفر',
  postal_zip_code: 'الرمز البريدي',
  save_to_profile: 'حفظ في الملف الشخصي',
  speaking_scores: 'درجات التحدث',
  upload_document: 'تحميل الوثيقة',
  account_settings: 'إعدادت الحساب',
  current_password: 'كلمة المرور الحالية',
  gmat_verbal_rank: 'رتبة اللفظية GMAT',
  gre_verbal_score: 'النتيجة اللفظية GRE',
  gre_writing_rank: 'رتبة الكتابة GRE',
  listening_scores: 'درجات الاستماع',
  education_country: 'بلد التعليم',
  education_history: 'التعليم',
  education_summary: 'ملخص تعليمي',
  email_is_required: 'البريد الالكتروني مطلوب',
  english_exam_type: 'نوع اختبار اللغة الإنجليزية',
  english_institute: 'مدرسة اللغة الإنجليزية',
  gmat_verbal_score: 'النتيجة اللفظية GMAT',
  gmat_writing_rank: 'رتبة الكتابة GMAT',
  gre_writing_score: 'درجة كتابة GRE',
  wrong_mail_format: 'تنسيق بريد خاطئ',
  gmat_writing_score: 'درجة كتابة GMAT',
  please_enter_email: 'الرجاء إدخال البريد الإلكتروني',
  server_unreachable: 'تعذر الوصول للخادم, حاول لاحقاً',
  upload_new_picture: 'تحميل صورة جديدة',
  account_information: 'معلومات الحساب',
  general_information: 'بيانات الهوية',
  country_of_education: 'بلد التعليم',
  forgot_your_password: 'نسيت كلمة المرور ؟',
  include_living_costs: 'قم بتضمين تكاليف المعيشة',
  passport_expiry_date: 'تاريخ نفاذ صلاحيه جواز السفر',
  personal_information: 'معلومات شخصية',
  please_enter_address: 'الرجاء إدخال العنوان',
  please_enter_country: 'الرجاء إدخال الدولة',
  please_enter_zipcode: 'الرجاء إدخال الرمز البريدي',
  programs_and_schools: 'البرامج والمدارس',
  gre_quantitative_rank: 'المرتبة الكمية GRE',
  invalid_email_address: 'عنوان البريد الإلكتروني غير صالح',
  last_name_is_required: 'إسم العائلة مطلوب',
  background_information: 'معلومات اضافية',
  country_of_citizenship: 'بلد المواطنة',
  first_name_is_required: 'الإسم الأول مطلوب',
  gmat_quantitative_rank: 'رتبة GMAT الكمية',
  gre_quantitative_score: 'النتيجة الكمية GRE',
  i_have_gre_exam_scores: 'لدي درجات امتحان GRE',
  i_have_gmat_exam_scores: 'لدي درجات امتحان GMAT',
  please_enter_city_town: 'الرجاء إدخال المدينة / المدينة',
  please_enter_last_name: 'الرجاء إدخال الاسم الأخير',
  please_provide_details: 'يرجى تقديم تفاصيل',
  gmat_quantitative_score: 'النتيجة الكمية GMAT',
  please_enter_first_name: 'الرجاء إدخال الاسم الأول',
  profile_complete_status: 'حالة إكتمال الملف الشخصي',
  passport_expiration_date: 'تاريخ الأنتهاء',
  please_enter_middle_name: 'الرجاء إدخال الاسم الأوسط',
  additional_qualifications: 'مؤهلات إضافية',
  email_is_a_required_field: 'البريد الإلكتروني مطلوب',
  i_will_provide_this_later: 'سأقدم هذا لاحقًا',
  please_enter_phone_number: 'الرجاء إدخال رقم الهاتف',
  passport_number_is_required: 'رقم جواز السفر مطلوب',
  please_enter_first_language: 'الرجاء إدخال اللغة الأولى',
  please_enter_passport_number: 'الرجاء إدخال رقم جواز السفر',
  please_enter_postal_zip_code: 'الرجاء إدخال الرمز البريدي',
  as_indicated_on_your_passport: 'كما هو مبين في جواز سفرك',
  post_secondary_sub_categories: 'الفئات الفرعية لما بعد الثانوية',
  post_secondary_sub_discipline: 'التخصص بعد الثانوية',
  select_country_of_citizenship: 'حدد بلد الجنسية',
  'what_would_you_like_to_study?': 'ماذا تود ان تدرس؟',
  please_enter_province_or_state: 'الرجاء إدخال المقاطعة أو الولاية',
  'where__school_name_or_location?':
    'أين؟ على سبيل المثال اسم المدرسة أو الموقع',
  do_you_have_a_valid_study_permit: 'هل لديك تصريح دراسة / تأشيرة صالحة؟',
  passport_expiry_date_is_required: 'تاريخ انتهاء صلاحية جواز السفر مطلوب',
  post_graduation_work_permit_available: 'تصريح عمل بعد التخرج متاح',
  have_you_been_refused_a_visa_from_canada:
    'هل تم رفض تأشيرة دخول من كندا أو الولايات المتحدة أو المملكة المتحدة أو نيوزيلندا أو أستراليا؟',
  search_for_school_and_apply_for_any_programs:
    'ابحث عن المدرسة وتقدم بطلب للحصول على أي برامج',
  it_looks_like_you_do_not_have_any_applications_yet:
    'يبدو أنه ليس لديك أي برامج حتى الآن',
  please_enter_your_information_accurately_and_correctly:
    'الرجاء إدخال المعلومات الخاصة بك بدقة وبشكل صحيح. سيتم إرسال جميع الحقول إلى المدرسة المختارة عند تقديم طلبك على لوحة التقديم',
  all_amounts_are_listed_in_the_currency_charged_by_the_school:
    'يتم سرد جميع المبالغ بالعملة التي تتقاضاها المدرسة. للحصول على أفضل النتائج ، يرجى تحديد بلد المدرسة.',
  'to_upload_documents_to_your_profile, please_complete_your_profile_first':
    'لتحميل المستندات إلى ملف التعريف الخاص بك ، يرجى إكمال ملف التعريف الخاص بك أولاً',
  already_not_have_an_account: 'لا تمتلك حساب؟',
  'Can Not find user with this email':
    'لا يمكن العثور على مستخدم بهذا البريد الإلكتروني',
  'Wrong password': 'كلمة المرور غير صحيحة',
  comment_is_required: 'التعليق مطلوب',
  permits_are_required: 'التأشيرات مطلوبة',
  province: 'المحافظة',
  zip_code: 'الرمز البريدي',
  terms_consent:
    'بالنقر فوق "إنشاء حساب" ، فإنك تقر بأنك قد قرأت وقبلت شروط الخدمة وسياسة الخصوصية.',
  city_is_required: 'المدينة مطلوبة',
  confirm_password: 'تأكيد كلمة المرور',
  enter_your_email: 'أدخل بريدك الإلكتروني',
  level_of_education: 'مستوى التعليم',
  address_is_required: 'العنوان مطلوب',
  country_is_required: 'الدولة مطلوبة',
  enter_your_password: 'ادخل رقمك السري',
  name_of_institution: 'اسم المدرسة',
  password_is_required: 'كلمة المرور مطلوبة',
  passwords_must_match: 'يجب ان تتطابق كلمة المرور',
  birth_date_is_required: 'تاريخ الميلاد مطلوب',
  country_of_institution: 'بلد المدرسة',
  already_have_an_account: 'هل لديك حساب',
  attended_institution_to: 'حضر المدرسة إلى',
  phone_number_is_required: 'رقم الهاتف مطلوب',
  attended_institution_from: 'حضر المدرسة من',
  'this email already exists': 'هذا البريد الإلكتروني موجود بالفعل',
  first_language_is_required: 'اللغة الأولى مطلوبة',
  highest_level_of_education: 'أعلى مستوى تعليمي',
  marital_status_is_required: 'الحالة الاجتماعية مطلوبة',
  make_your_dream_become_true: 'اجعل حلمك حقيقة',
  citizenship_country_is_required: 'بلد الجنسية مطلوب',
  primary_language_of_instruction: 'لغة التدريس الأساسية',
  enter_your_password_confirmation: 'أدخل تأكيد كلمة المرور الخاصة بك',
  password_confirmation_is_required: 'تأكيد كلمة المرور مطلوب ',
  i_have_graduated_from_this_institution: 'لقد تخرجت من هذه المدرسة',
  create_an_account_to_start_searching_universities:
    'قم بإنشاء حساب لبدء البحث عن الجامعات',
  your_personal_information_has_been_updated_successfully:
    'تم تحديث معلوماتك الشخصية بنجاح',
  i_do_not_have_this: 'لا أمتلك',
  uk_student_visa: 'تأشيرة طالب المملكة المتحدة',
  canadian_study_permit_or_visitor_visa: 'تصريح دراسة كندي أو تأشيرة زائر',
  australian_student_visa: 'تأشيرة طالب أسترالية',
  usa_f_1_visa: 'تأشيرة الولايات المتحدة الأمريكية F1',
  this_field_is_required: 'هذا الحقل مطلوب',
  exam_date: 'تاريخ الامتحان',
  enter_school_zipcodeenter_school_zipcode: '',
  enter_school_zipcode: 'أدخل الرمز البريدي للمدرسة',
  enter_school_province: 'أدخل مقاطعة المدرسة',
  enter_school_address: 'أدخل عنوان المدرسة',
  enter_school_city: 'ادخل مدينة المدرسة',
  degree_name: 'اسم الدرجة',
  attended_primary_school: 'بيانات المدرسة الابتدائية',
  attended_high_school: ' بيانات المدرسة الثانوية',
  high_school_graduation_date: 'تاريخ التخرج',
  enter_high_school_graduation_date: 'ادخل تاريخ التخرج',
  i_have_graduated_from_this_high_school: 'لقد تخرجت من هذه المدرسة الثانوية',
  i_have_graduated_from_this_primary_school:
    'لقد تخرجت من هذه المدرسة الابتدائية',
  under_review: 'تحت المراجعة',
  show_details: 'أظهار تفاصيل',
  accepted: 'تم القبول',
  prepare_applications: 'الطلبات المقدمة',
  apply_for_new_application: 'تقدم بطلب جديد',
  explore_programs: 'استكشف المزيد من البرامج',
  primary_school_graduation_date: 'تاريخ التخرج من المدرسة الإبتدائية',
  enter_primary_school_instruction_primary_language:
    'ادخل تعليمات المدرسة الابتدائية',
  enter_primary_school_instruction_attend_from_date: 'أدخل بداية تاريخ التعليم',
  enter_primary_school_institution_name: 'أدخل اسم المدرسة',
  complete_profile: 'أكمل ملفك الشخصي',
  to_upload_documents_to_your_profile_please_complete_your_profile_first:
    'لتحميل المستندات إلى ملف التعريف الخاص بك ، يرجى إكمال ملفك الشخصي أولاً',
  select_english_exam_type: 'قم بأختيار نواع ألأختبار',
  french_baccalaureate: 'البكالوريا الفرنسية',
  edit_your_personal_info: 'تحرير بياناتك الشخصية',
  contact_support: 'تواصل مع الدعم',
  verify_you_email_account: 'قم بتأكيد بريدك الإلكتروني',
  you_need_to_verify_your_email_to_complete_registration:
    'يجب عليك تأكيد بريدك الالكتروني لأكمال ملفك الشخصي',
  resend_email: 'أعد الإرسال',
  we_have_sent_to: 'قد قمنا بإرسال رسالة تأكيد الي البريد الإلكرتوني',
  with_a_link_to_verify_your_account: 'برابط تفعيل حسابك الشخصي',
  if_you_dot_not_reciev:
    ' إذا لم تستلم البريد الإلكتروني بعد بضع دقائق ، يرجى التحقق من مجلد البريد العشوائي',
  //------------------------
  next: 'التالي',
  website: 'موقع إلكتروني',
  enter_email: 'أدخل البريد الإلكتروني',
  company_name: 'اسم الشركة',
  linkedin_url: 'لينكد إن URL',
  enter_website: 'أدخل الموقع',
  twitter_handle: 'أسم مستخدم تويتر',
  instagram_handle: 'أسم مستخدم أنستجرام',
  enter_company_name: 'أدخل اسم الشركة',
  enter_linkedin_url: 'أدخل عنوان url المرتبط',
  facebook_page_name: 'اسم صفحة الفيسبوك',
  company_information: 'معلومات الشركة',
  contact_information: 'معلومات التواصل',
  recruitment_details: 'تفاصيل التوظيف',
  enter_twitter_handle: 'أدخل عنوان تويتر',
  enter_instagram_handle: 'أدخل أسم مستخدم أنستجرام',
  company_name_is_required: 'اسم الشركة مطلوب',
  enter_facebook_page_name: 'أدخل اسم صفحة فيسبوك',
  upload_photo: 'أرفع صورة شخصية',
  'user with this Email address already exists.':
    'هذا البريد الالكتروني مسجل من قبل',
  the_field_should_have_digits_only: 'يجب أن يحتوي هذا الحقل على أرقام فقط',
  select_first_language: 'حدد اللغة الأولي',
  faculty_graduation_date: 'تاريخ التخرج من الجامعة',
  studied_at: 'درس في',
  select: 'أختر',
  forgot_password: 'نسيت كلمة السر؟',
}
