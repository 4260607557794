import ECard from '../../components/ECard'
import * as Yup from 'yup'

const ChangePassword = () => {
  const name = 'Abdelrahman Reda'
  Yup.object().shape({
    oldPassword: Yup.string().required(),
    currentPassword: Yup.string()
      .oneOf([Yup.ref('oldPassword'), null], 'Passwords must match')
      .required(),
  })
  return (
    <>
      <ECard>
        <ECard.Header>
          <span>BASICS </span>
        </ECard.Header>
        <ECard.Body>
          <div className={'grid grid-cols-3'}>
            <div className={'flex gap-2'}>
              <div>
                <div
                  style={{ width: '40px', height: '40px' }}
                  className={
                    'flex items-center justify-center rounded-full bg-orient-700 font-medium text-white'
                  }
                >
                  <span>{name.split(' ')[0][0]}</span>
                  <span>{name.split(' ')[1][0]}</span>
                </div>
              </div>
              <div>
                <span className={'block text-sm font-semibold'}>
                  Abdelrahman Reda
                </span>
                <span
                  className={
                    'block text-xs font-medium  text-gray-500 dark:text-gray-100  '
                  }
                >
                  boodycat09@gmail.com
                </span>
              </div>
            </div>
            <div className={'flex flex-col'}>
              <span className={'text-sm font-semibold'}>Status</span>
              <span
                className={
                  'text-xs font-medium  text-gray-500 dark:text-gray-100  '
                }
              >
                Active
              </span>
            </div>
            <div className={'flex flex-col'}>
              <span className={'text-sm font-semibold'}>Added</span>
              <span
                className={
                  'text-xs font-medium  text-gray-500 dark:text-gray-100  '
                }
              >
                Dec 11, 2021
              </span>
            </div>
          </div>
        </ECard.Body>
      </ECard>
    </>
  )
}
export default ChangePassword
