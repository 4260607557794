import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import { useState } from 'react'
import { useCustomAxios } from '../../../Hooks/useAxios'
import ProductCompletionTrails from '../Products/ProductCompletionTrails'
import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'

const AddProductCancelation = () => {
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: '/product/listAllProducts?page=0&size=1000000000',
    })
  const [trailsBlock, setTrailsBlock] = useState({
    trails: [
      {
        trialCount: '',
        trialGap: '',
        trialTimes: [],
      },
    ],
  })

  const [formState, setFormState] = useState({
    form: {
      cancellationName: '',
      cancellationBTC: '',
      product: '',
    },
  })
  const handleBasicCancelationInfo = e => {
    const { name, value } = e.target
    setFormState({
      form: {
        ...formState.form,
        [name]: value,
      },
    })
  }
  const handleRequestBody = () => {
    let requestBody = {
      productName: formState.form.cancellationName,
      btc: formState.form.cancellationBTC,
      productType: 'CANCELLATION',
      idOFCancelledProduct: parseInt(formState.form.product),
      productRetrials: trailsBlock.trails.map(trail => ({
        retrialCount: trail.trialCount,
        day: trail.trialGap,
        productRetrialDate: trail.trialTimes.map(time => ({
          dialDate: time.trailTime + ':00',
        })),
      })),
    }
    return requestBody
  }
  const handleAddProductCancelation = () =>
    axiosInstance
      .post('/product/createNewCancellationForProduct', handleRequestBody())
      .then(res => toast.success('تم إضافة الإلغاء بنجاح'))
      .catch(err => toast.error(err.response.data.message))

  if (loading) return <div>loading</div>
  else
    return (
      <div className={'p-5'}>
        {JSON.stringify(formState)}
        <div className={'grid grid-cols-3 gap-2   '}>
          <SFormWrapper>
            <FLabel>الأسم</FLabel>
            <FInputField
              value={formState.form.cancellationName}
              name={'cancellationName'}
              onChange={handleBasicCancelationInfo}
              type={'text'}
              placeholder={'مثال: إلغاء الأشتراك في منتج التجار'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FLabel>بي تي سي الألغاء</FLabel>
            <FInputField
              value={formState.form.cancellationBTC}
              name={'cancellationBTC'}
              onChange={handleBasicCancelationInfo}
              type={'text'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FLabel>المنتج</FLabel>
            <select
              value={formState.form.product}
              onChange={handleBasicCancelationInfo}
              name={'product'}
              className={'input-style'}
            >
              <option value={''}>أختر منتج</option>
              {response.products.map(product => (
                <option value={product.id}>{product.name}</option>
              ))}
            </select>
          </SFormWrapper>
        </div>
        <ProductCompletionTrails
          setTrailsBlock={setTrailsBlock}
          trailsBlock={trailsBlock}
        />
        <SFormWrapper>
          <FButton onClick={handleAddProductCancelation}>إضافة</FButton>
        </SFormWrapper>
      </div>
    )
}

export default AddProductCancelation
