import React, { Fragment, useState } from 'react'
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import ECard from '../../../components/ECard'
import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import moment from 'moment'
import ESpinner from '../../../components/ESpinner'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import FRow from '../../../components/FRow'
import FAvatar from '../../../components/FAvatar'

const moods = [
  {
    name: 'Excited',
    value: 'excited',
    icon: FireIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Loved',
    value: 'loved',
    icon: HeartIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-400',
  },
  {
    name: 'Happy',
    value: 'happy',
    icon: FaceSmileIcon,
    iconColor: 'text-white',
    bgColor: 'bg-green-400',
  },
  {
    name: 'Sad',
    value: 'sad',
    icon: FaceFrownIcon,
    iconColor: 'text-white',
    bgColor: 'bg-yellow-400',
  },
  {
    name: 'Thumbsy',
    value: 'thumbsy',
    icon: HandThumbUpIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
  {
    name: 'I feel nothing',
    value: null,
    icon: XMarkIcon,
    iconColor: 'text-gray-400',
    bgColor: 'bg-transparent',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SubscriptionNote({ id, phoneNumber }) {
  const [selected, setSelected] = useState(moods[5])

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['getSubNotes'],
    queryFn: () =>
      axiosInstance
        .get(
          `/CustomerSubscriptionComment/listAllCustomerSubscriptionComments?page=0&size=100&subscriptionId=${id}`,
        )
        .then(res => res.data),
  })

  const addMutation = useMutation({
    mutationFn: () =>
      axiosInstance.post(
        '/CustomerSubscriptionComment/editCustomerSubscriptionComment',
        {
          comment: Form.values.comment,
          subscriptionId: id,
          phoneNumber: phoneNumber,
        },
      ),
    onSuccess: () => {
      refetch().then(() => {})
      Form.resetForm()
    },
  })

  const Form = useFormik({
    initialValues: {
      comment: '',
    },
    onSubmit: values => {
      addMutation.mutate()
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string().required('من فضلك ادخل ملاحظه'),
    }),
  })

  const deleteMutation = useMutation({
    mutationFn: noteId =>
      axiosInstance.delete(
        `CustomerSubscriptionComment/deleteCustomerSubscriptionComment/${noteId}`,
      ),
    onSuccess: () => {
      refetch().then(() => {})
    },
  })

  if (isLoading) return <div>loading</div>
  else
    return (
      <ECard>
        <ECard.Header>
          <span>
            <b>ملاحظات </b>
          </span>
        </ECard.Header>
        <ECard.Body>
          <>
            <ul
              className={'flex flex-col gap-4'}
              role='list'
              style={{
                direction: 'ltr',
              }}
            >
              {data?.results?.map(activityItem => {
                const { id, createdBy, creationDate, comment } =
                  activityItem || {}

                return (
                  <li
                    key={id}
                    className='relative flex gap-x-4 rounded border p-1'
                  >
                    <button
                      onClick={() => deleteMutation.mutate(id)}
                      className='absolute top-1 right-1 rounded-full p-1 text-red-500 hover:bg-gray-100 hover:text-red-600'
                    >
                      {console.log(deleteMutation.data)}
                      {deleteMutation.isLoading &&
                      deleteMutation.data === id ? (
                        <ESpinner isVisible={true} />
                      ) : (
                        <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                      )}
                    </button>

                    <div className='flex flex-col gap-2'>
                      <div className='flex gap-2 '>
                        <FRow>
                          <FAvatar name={createdBy} />
                          <div className={'flex flex-col'}>
                            <span className='text-sm font-medium'>
                              {createdBy}
                            </span>
                            <span className='text-xs'>
                              {moment(creationDate).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </span>
                          </div>
                        </FRow>
                      </div>
                      <span className={'ml-10'}>{comment}</span>
                    </div>
                  </li>
                )
              })}
            </ul>

            <div className='mt-6 flex gap-x-3'>
              <form onSubmit={Form.handleSubmit} className='relative flex-auto'>
                <div>
                  <label htmlFor='comment' className='sr-only'>
                    اضافه ملاحظه جديده
                  </label>
                  <textarea
                    className={'input-style'}
                    rows={2}
                    value={Form.values.comment}
                    onChange={Form.handleChange}
                    id='comment'
                    placeholder='اضافه ملاحظه جديده'
                    defaultValue={''}
                  />
                  <EFormInvalidInput
                    touched={Form.touched}
                    errors={Form.errors}
                    FieldName={'comment'}
                  />
                </div>

                <div className=' '>
                  <FButton type='submit'>
                    <FIconWrapper>
                      اضافه ملاحظه
                      <ESpinner isVisible={addMutation.isLoading} />
                    </FIconWrapper>
                  </FButton>
                </div>
              </form>
            </div>
          </>
        </ECard.Body>
      </ECard>
    )
}
