import FFlexWrapper from '../../../components/FFlexWrapper'
import { HiOutlineUserAdd } from 'react-icons/hi'
import React, { useEffect, useState } from 'react'
import { useCustomAxios } from '../../../Hooks/useAxios'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import { useNavigate } from 'react-router-dom'
import CustomerAdd from './CustomerAdd'
import FButton from '../../../components/FButton'
import UploadCustomerSheet from './UploadCustomerSheet'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlineUpload } from 'react-icons/ai'
import { convertDateIntoReadable } from '../../../helpers/utils'

const CustomerList = () => {
  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState([])
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    Refetch()
  }, [page])

  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/customer?page=${page}&size=20`,
    })
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])
  const [cols, setCols] = useState([
    {
      Header: 'رقم الهاتف',
      accessor: 'phoneNumber', // String-based value accessors!
    },
    {
      Header: 'الأسم',
      accessor: 'name', // String-based value accessors!
    },
    {
      Header: 'تاريخ الأضافة',
      accessor: 'creationDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return convertDateIntoReadable(value)
      },
    },
    {
      Header: 'اخر تحديث',
      accessor: 'lastUpdateDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return convertDateIntoReadable(value)
      },
    },
  ])

  const initialQuery = {
    combinator: 'and',
    rules: [],
  }

  const [query, setQuery] = useState(initialQuery)

  const fields = [
    { name: 'name', label: 'الاسم ' },
    { name: 'phone ', label: 'الهاتف' },
  ]

  return (
    <>
      <CustomerAdd
        callBackFunction={Refetch}
        setIsModalOpen={setIsCustomerModalOpen}
        isModalOpen={isCustomerModalOpen}
      />
      <UploadCustomerSheet
        callBackFunction={Refetch}
        setIsModalOpen={setIsUploadModalOpen}
        isModalOpen={isUploadModalOpen}
      />
      <div className={'flex justify-between p-3'}>
        <span className={'text-lg font-semibold'}>العملاء</span>
        <div className={'flex gap-2'}>
          <FButton
            onClick={() => setIsUploadModalOpen(true)}
            btnType={'secondary'}
          >
            <FIconWrapper>
              <AiOutlineUpload />
              توريد ملف
            </FIconWrapper>
          </FButton>
          <FButton onClick={() => setIsCustomerModalOpen(true)}>
            <FFlexWrapper>
              <HiOutlineUserAdd />
              <span className={'  mr-1 ml-1'}>إضافة عميل</span>
            </FFlexWrapper>
          </FButton>
        </div>
      </div>
      <FCustomDataTable
        cols={cols}
        data={response ? response.customers : []}
        isLoading={loading}
        page={page}
        setPage={setPage}
        size={2}
        setFilter={setFilter}
        filter={filter}
        totalNumberOfPages={totalNumberOfPages}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        Refetch={Refetch}
        rowFunction={row => {
          return navigate(`/customers/details/${row.phoneNumber}`)
        }}
      />

      <div></div>
    </>
  )
}
export default CustomerList
