import ECard from '../../../components/ECard'
import moment from 'moment/moment'
import React from 'react'
import { useCustomAxios } from '../../../Hooks/useAxios'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlineEye } from 'react-icons/ai'
import sheetImage from '../../../assets/images/sheets.png'
import oracleImage from '../../../assets/images/upload.png'
import downloadExtractionSheet from '../../../assets/images/download.png'
import uploadExtractionSheet from '../../../assets/images/up-arrow.png'
import schedulePhoneCall from '../../../assets/images/phone.png'
import completeSubs from '../../../assets/images/check.png'
import pencil from '../../../assets/images/pencil.png'
import docuemntsAndCommisions from '../../../assets/images/docuemntsAndCommisions.png'
import pen from '../../../assets/images/pen.png'
import trashcan from '../../../assets/images/trashcan.png'
import form from '../../../assets/images/form.png'
import user from '../../../assets/images/user.png'
import { formatDateWithArabicDayName } from '../../../helpers/utils'
import { BiDownload } from 'react-icons/bi'
import { JsonToTable } from 'react-json-to-table'
import FModal from '../../../components/FModal'
import FButton from '../../../components/FButton'

const ActivityLog = ({ id }) => {
  const logs = useCustomAxios({
    method: 'GET',
    url: `customersubscription/${id}/updates`,
  })

  const iconWrapper = {
    UploadCustomers: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={sheetImage}
      />
    ),
    'Add Cover Form': (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={form}
      />
    ),
    addCustomerSubscriptionRelatives: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={user}
      />
    ),
    cancelCustomerSubscription: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={trashcan}
      />
    ),

    addCustomerSubscriptionDto: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={pen}
      />
    ),
    DOCUMENTS_AND_COMMISSIONS: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={docuemntsAndCommisions}
      />
    ),
    UPLOAD_CUSTOMERS: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={sheetImage}
      />
    ),
    editCustomerSubscription: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={pencil}
      />
    ),
    ORACLE_FILE: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={oracleImage}
      />
    ),
    PRODUCT_RELEASE_EXCEL: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={downloadExtractionSheet}
      />
    ),
    'Download DOCUMENTS_AND_COMMISSIONS': (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={downloadExtractionSheet}
      />
    ),
    GIG_RESPONSE_EXCEL: (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={uploadExtractionSheet}
      />
    ),
  }

  const incrementTimeByHour = (time, hours) => {
    return moment(time).add(hours, 'hours')
  }

  const ActionsMap = {
    CREATED: 'تم انشاء الاشتراك',
    SUB_DATA: 'تم تحديث بيانات الاشتراك',
    'Download DOCUMENTS_AND_COMMISSIONS': 'تم تحديث الوثائق والعمولات',
    UPLOAD_CUSTOMERS: 'تم رفع العملاء',
    'Exported response from GIG': 'تم تصدير الرد من GIG',
    'Exported to GIG': 'تم تصدير الى GIG',
    'Cancel Customer Subscription': 'تم الغاء الاشتراك',
    'Exported to GIG - Force Download': 'تم تصدير الى GIG - تحميل قسري',
  }
  const DESCRIPTION = {
    'Missing Data Call Scheduled': (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={schedulePhoneCall}
      />
    ),
    'Future Renewal calls updated': (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={schedulePhoneCall}
      />
    ),
    'Future Renewal calls updatedcompleteSubs': (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={schedulePhoneCall}
      />
    ),
    'Customer Subscription Completed': (
      <img
        style={{
          minWidth: 30,
        }}
        width={30}
        src={completeSubs}
      />
    ),
  }
  const DESCRIPTION_TEXT = {
    'Missing Data Call Scheduled': 'تم تحديد موعد للاتصال',
    'Future Renewal calls updated': 'تم تحديد موعد للاتصال',
    'Customer Subscription Completed': 'تم اكمال الاشتراك',
    'Download documents and commissions sheet':
      'تم التحميل في سجل الوثائق والعمولات',
    'Exported response from GIG in': 'تم رفع الاستجابة من GIG',
    'Upload Customers Sheet': 'تم رفع العملاء',
    'Download documents and commissions': 'تم التحميل في سجل الوثائق والعمولات',
    'Exported response from GIG': 'تم رفع الاستجابة من GIG',
    'Exported to GIG': 'تم تصدير الى GIG',
    'Update Customer Subscription': 'تم تحديث الاشتراك',
    'Paid Amount differ than installment Amount, money went to residual':
      'دفع المبلغ يختلف عن مبلغ القسط, تم تحويل المبلغ الى المعلقات',
    'Subscription paid': 'تم دفع الاشتراك',
    addCustomerSubscriptionDto: 'تم اضافة الاشتراك',
    'Cancel Customer Subscription': 'تم الغاء الاشتراك',
    'Exported to GIG - Force Download': 'تم تصدير الى GIG - تحميل قسري',
    'Oracle Sheet Payment': 'تم دفع الاشتراك',
  }

  function extractUrlFromString(inputString) {
    const urlRegex = /(https?:\/\/[^\s]+)/
    const matches = inputString.match(urlRegex)
    return matches ? matches[0] : null
  }

  function extractStringWithoutUrl(inputString) {
    const urlRegex = /(https?:\/\/[^\s]+)/
    const urlMatch = inputString.match(urlRegex)

    if (urlMatch) {
      const urlStartIndex = inputString.indexOf(urlMatch[0])
      const extractedString =
        inputString.slice(0, urlStartIndex) +
        inputString.slice(urlStartIndex + urlMatch[0].length)
      return extractedString.replace(/:/g, '').trim()
    }

    return inputString.replace(/:/g, '').trim()
  }

  const addThreeHours = timeString => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    let newHours = (hours + 3) % 24
    let period = 'AM'
    if (newHours >= 12) {
      period = 'PM'
      if (newHours > 12) {
        newHours -= 12
      }
    }
    return `${String(newHours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0',
    )}:${String(seconds).padStart(2, '0')} ${period}`
  }

  const [isHistDataModalOpen, setIsHistDataModalOpen] = React.useState(false)
  const [update, setUpdate] = React.useState(null)
  if (logs?.loading) {
    return <div>Loading...</div>
  } else {
    return (
      <>
        <FModal
          title={'تفاصيل الاشتراك'}
          isOpen={isHistDataModalOpen}
          setIsOpen={setIsHistDataModalOpen}
        >
          <JsonToTable json={JSON.parse(update?.value ?? '{}')} />
        </FModal>

        <ECard>
          <ECard.Header>
            <span>
              <b> النشاطات</b>
            </span>
          </ECard.Header>
          <ECard.Body>
            {logs?.response?.updates?.map(update => {
              return (
                <div
                  className={
                    'flex flex-col gap-2 border-b border-dashed border-gray-700 p-2 last:border-0'
                  }
                >
                  <div className={'flex flex-col justify-between'}>
                    <div className={'flex flex-col   justify-between  p-1'}>
                      <div className={'flex items-center gap-1  '}>
                        <div
                          className={
                            'flex flex-row-reverse items-center gap-1 text-xs'
                          }
                        >
                          {iconWrapper[update.eventSource] ??
                            update.eventSource}
                          {DESCRIPTION[
                            extractStringWithoutUrl(update?.description ?? '')
                          ] ?? null}
                        </div>
                        <div className={'flex flex-col   p-1 '}>
                          <span
                            style={{
                              minWidth: 100,
                            }}
                            className={'text-xs font-bold'}
                          >
                            {ActionsMap[update.type] ??
                              DESCRIPTION_TEXT[
                                extractStringWithoutUrl(
                                  update?.description ?? '',
                                )
                              ] ??
                              extractStringWithoutUrl(
                                update?.description ?? '',
                              )}
                          </span>
                          {update?.link && (
                            <a
                              href={update?.link}
                              className={'text-xs  underline'}
                            >
                              <button
                                className={
                                  'my-2 rounded-full border border-emerald-800 bg-emerald-600 py-0.5 px-2 text-white   '
                                }
                              >
                                <FIconWrapper>
                                  <BiDownload />
                                  <span className={'whitespace-nowrap'}>
                                    تحميل الملف
                                  </span>
                                </FIconWrapper>
                              </button>
                            </a>
                          )}
                        </div>
                      </div>

                      <div className={'flex flex-col'}>
                        <div className={'flex items-center gap-2'}>
                          <div
                            className={
                              'flex flex-col items-start justify-start'
                            }
                          >
                            <span className={'text-xs'}>
                              {update.eventByEmail}
                            </span>
                            <div className={'flex items-center gap-1'}>
                              <span className={'whitespace-nowrap text-xs'}>
                                {addThreeHours(update.eventTime)}
                              </span>
                              <span className={'text-xs'}> | </span>
                              <span className={'whitespace-nowrap text-xs'}>
                                {formatDateWithArabicDayName(update.eventDate)}
                              </span>
                            </div>
                          </div>

                          {/*<FAvatar*/}
                          {/*  name={findAgenTByEmail(update.eventByEmail)?.name}*/}
                          {/*/>*/}
                        </div>
                      </div>
                    </div>
                    <FButton
                      btnType={'secondary'}
                      className={'w-fit  rounded-md border  p-1'}
                      onClick={() => {
                        setIsHistDataModalOpen(true)
                        setUpdate(update)
                      }}
                    >
                      <FIconWrapper>
                        <AiOutlineEye />
                        <span className={'text-xs'}>اظهار بيانات الاشتراك</span>
                      </FIconWrapper>
                    </FButton>
                  </div>
                </div>
              )
            })}
          </ECard.Body>
        </ECard>
      </>
    )
  }
}
export default ActivityLog
