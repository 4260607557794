import FModal from '../../components/FModal'
import EWarning from '../../components/EWarning'
import { Formik } from 'formik'
import SFormWrapper from '../../components/EFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
import EFormInvalidInput from '../../components/EFormInvalidInput'
import FButton from '../../components/FButton'
import * as Yup from 'yup'
import { axiosInstance } from '../../api/requister'
import ESpinner from '../../components/ESpinner'
import { useState } from 'react'

const AddMember = ({ getUsersInfo, isModalOpen, setIsModalOpen }) => {
  const [isAddFinished, setIsAddFinished] = useState(false)

  const handleAddFormSubmission = values => {
    setIsAddFinished(true)
    axiosInstance
      .post('/admin/users/create', values)
      .then(response => {
        setIsAddFinished(false)
        setIsModalOpen(false)
        getUsersInfo()
      })
      .catch(error => setIsAddFinished(true))
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    role: Yup.string().required(),
  })

  return (
    <>
      <FModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'Add New Member'}
      >
        <EWarning
          msg={
            'The password of the newly created member is auto generated and can be changed in each account personal settings'
          }
        />
        <Formik
          validationSchema={ValidationSchema}
          initialValues={{
            name: '',
            email: '',
            role: '',
          }}
          onSubmit={handleAddFormSubmission}
        >
          {({
            handleSubmit,
            handleBlur,
            values,
            errors,
            touched,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit} noValidate className={'rounded-md'}>
              <div className={'grid grid-cols-1  gap-2'}>
                <SFormWrapper>
                  <FLabel htmlFor={'name'}> Name</FLabel>
                  <FInputField
                    type='text'
                    name={'name'}
                    id={'name'}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'name'}
                  />
                </SFormWrapper>
                <SFormWrapper>
                  <FLabel htmlFor={'email'}> Email</FLabel>
                  <FInputField
                    type='text'
                    name={'email'}
                    id={'email'}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'email'}
                  />
                </SFormWrapper>
                <SFormWrapper>
                  <FLabel htmlFor={'role'}> Roles</FLabel>
                  <select
                    className={'w-full rounded-md border border-gray-300 p-2'}
                    name={'role'}
                    id={'role'}
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={''}>Select Role</option>
                    <option value={'admin'}>Admin</option>
                  </select>
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'role'}
                  />
                </SFormWrapper>
                <SFormWrapper className={'w-6/12'}>
                  <div className={'flex gap-2'}>
                    <FButton type={'submit'} className={'mt-3 w-full'}>
                      <ESpinner isVisible={isAddFinished} />
                      <span> Add New</span>
                    </FButton>
                    <button
                      type={'button'}
                      onClick={() => setIsModalOpen(false)}
                      type={'button'}
                      className={
                        'mt-3 w-full rounded border  bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 '
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </SFormWrapper>
              </div>
            </form>
          )}
        </Formik>
      </FModal>
    </>
  )
}
export default AddMember
