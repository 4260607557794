import FModal from '../../../components/FModal'
import React, { useEffect, useState } from 'react'
import FButton from '../../../components/FButton'
import pdfImage from '../../../assets/images/pdf.png'
import {
  HiOutlineCheck,
  HiOutlinePhone,
  HiOutlineViewGrid,
  HiPhoneMissedCall,
} from 'react-icons/hi'
import FIconWrapper from '../../../components/FIconWrapper'
import { axiosInstance } from '../../../api/requister'
import FPopOver from '../../../components/FPopOver'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'
import { UseLocalStorageContent } from '../../../Hooks/useLocalStorageContent'
import { Link } from 'react-router-dom'
import { VscError } from 'react-icons/vsc'
import { getProductById } from '../../../helpers/utils'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import moment from 'moment'

const FFile = () => (
  <>
    <div
      className={'flex flex-col rounded border  bg-gray-100 dark:bg-gray-600'}
    >
      <div
        className={'f-col-center-center rounded bg-white p-3 dark:bg-gray-900'}
      >
        <img src={pdfImage} width={25} alt='' />
        <span
          className={
            'mt-2 text-center text-xs  text-gray-700 dark:text-gray-100  '
          }
        >
          Cam scanner 20220511_21854
        </span>
      </div>
      <button className={'f-col-center-center border-t p-2'}>تنزيل</button>
    </div>
  </>
)
const ActivityLog = ({ color, text, time }) => (
  <div className={'flex items-center justify-center gap-2 '}>
    <div
      className={`f-row-between items-center justify-center  rounded-full p-2 bg-${color}-600  border text-white border-${color}-800`}
    >
      <HiPhoneMissedCall className={''} />
    </div>
    <div
      className={
        'f-row-between flex-grow rounded border border-gray-300 bg-white px-2 py-3 dark:bg-gray-900'
      }
    >
      <span className={'text-sm font-medium'}> {text} </span>
      <span className={'text-sm font-bold  text-gray-500 dark:text-gray-100  '}>
        {' '}
        {time}{' '}
      </span>
    </div>
  </div>
)

const CustomerModal = ({
  isModalOpen,
  setIsModalOpen,
  scheduleInstance,
  callback,
  type,
}) => {
  const { email } = UseLocalStorageContent()
  const [isUnreachableRequestLoading, setIsUnreachableRequestLoading] =
    useState(false)
  const handleUnreachableCustomer = callId => {
    setIsUnreachableRequestLoading(true)
    axiosInstance
      .post(`call/${callId}/unreachable`)
      .then(res => {
        setIsUnreachableRequestLoading(false)
        callback()
        setIsModalOpen(false)
        toast.success('تم تحديد العميل كغير متواجد')
      })
      .catch(err => {
        setIsUnreachableRequestLoading(false)
      })
  }

  const handleCall = (callId, callAgentInteractionType, successMessage) => {
    const requestBody = {
      callId,
      callStatus: 'DONE',
      userEmail: email,
      callAgentInteractionType: callAgentInteractionType,
    }

    axiosInstance
      .post(`call/${callId}`, requestBody)
      .then(() => {
        setIsUnreachableRequestLoading(false)
        callback()
        setIsModalOpen(false)
        toast.success(successMessage)
      })
      .catch(() => {
        setIsUnreachableRequestLoading(false)
      })
  }

  const handleDoneCustomer = callId => {
    const requestBody = {
      callId,
      callStatus: 'DONE',
      userEmail: email,
      callAgentInteractionType: 'MISSING_DATA_DONE',
    }
    axiosInstance
      .post(`call/${callId}`, requestBody)
      .then(() => {
        setIsUnreachableRequestLoading(false)
        callback()
        setIsModalOpen(false)
        toast.success('تم تحديد العميل كمتواجد')
      })
      .catch(() => {
        setIsUnreachableRequestLoading(false)
      })
  }

  const reasonMap = {
    MISSING_DATA: 'بيانات ناقصة',
    SUSPICIOUS: 'مشبوه',
    RENEWAL: 'تجديد',
  }
  const handleWrong = (
    callId,
    callAgentInteractionType,
    successMsgResponse,
  ) => {
    const requestBody = {
      callId,
      callStatus: 'DONE',
      userEmail: email,
      callAgentInteractionType: callAgentInteractionType,
    }
    axiosInstance
      .post(`call/${callId}`, requestBody)
      .then(() => {
        toast.success(successMsgResponse)
        setIsModalOpen(false)
        callback()
      })
      .catch(() => {
        toast.error('حدث خطأ ما')
      })
  }

  const [transaction, setTransaction] = useState(null)

  useEffect(() => {
    axiosInstance
      .get(
        `/customer/CustomerTransaction/?transactionCode=${scheduleInstance?.transactionCode}`,
      )
      .then(res => {
        setTransaction(res?.data?.bean)
      })
      .catch(err => {
        console.log(err)
      })
  }, [scheduleInstance])

  const renderTransactionField = fieldName => {
    const fieldConfig = {
      amount: {
        label: 'المبلغ',
        readerFunction: transaction => transaction?.amount + ' جنيه',
      },
      btc: {
        label: 'BTC',
        readerFunction: transaction => transaction?.btc,
      },
      channel: {
        label: 'القناة',
        readerFunction: transaction => transaction?.channel,
      },
      code: {
        label: 'الكود',
        readerFunction: transaction => transaction?.code,
      },
      fileName: {
        label: 'اسم الملف',
        readerFunction: transaction => transaction?.fileName,
      },
      machineId: {
        label: 'معرّف الجهاز',
      },
      merchant: {
        label: 'تاجر',
        readerFunction: transaction => (transaction?.merchant ? 'نعم' : 'لا'),
      },
      merchantArea: {
        label: 'منطقة التاجر',
        readerFunction: transaction => transaction?.merchantArea,
      },
      merchantPersonName: {
        label: 'اسم شخص التاجر',
      },
      merchantSupervisor: {
        label: 'مشرف التاجر',
      },
      source: {
        label: 'المصدر',
      },
      merchantInfo: {
        label: 'معلومات التاجر',
      },
      merchantSalesRep: {
        label: 'مندوب المبيعات',
      },
      transactionCode: {
        label: 'كود المعاملة',
      },
      uploadDate: {
        label: 'تاريخ الرفع',
        readerFunction: transaction =>
          moment(transaction?.uploadDate).format('YYYY-MM-DD HH:mm:ss'),
      },
      merchantTypeName: {
        label: 'نوع التاجر',
      },
      transactionDate: {
        label: 'تاريخ المعاملة',
        readerFunction: transaction =>
          moment(transaction?.transactionDate).format('YYYY-MM-DD HH:mm:ss'),
      },
      uploadedBy: {
        label: 'المرفوع من قبل',
        readerFunction: transaction => transaction?.uploadedBy?.email,
      },
    }

    const fieldValue = transaction[fieldName]

    if (fieldConfig[fieldName] && fieldConfig[fieldName].readerFunction) {
      return (
        <div className={'flex flex-col items-end justify-end'}>
          <span className={'font-semibold'}>
            {fieldConfig[fieldName].label}{' '}
          </span>
          {fieldConfig[fieldName].readerFunction(transaction)}
        </div>
      )
    } else if (fieldConfig[fieldName]) {
      return (
        <div className={'flex flex-col items-end justify-end'}>
          <span className={'font-semibold'}>
            {fieldConfig[fieldName].label}{' '}
          </span>
          {fieldValue}
        </div>
      )
    } else {
      return (
        <div className={'flex flex-col items-end justify-end'}>
          <span className={'font-semibold'}>{fieldName} </span>
          {fieldValue}
        </div>
      )
    }
  }
  return (
    <>
      <FModal
        type={'primary'}
        title={`مكالمة جديدة/ ${scheduleInstance?.customer?.phoneNumber}`}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        disableBodyPadding
        isAutoWidth
        width={'w-8/12'}
        color={' bg-gray-100 dark:bg-gray-600'}
        callbackFunction={() => {
          setTransaction(null)
        }}
      >
        <div className={'grid grid-cols-1'}>
          <div>
            <div
              className={
                'flex items-center justify-between gap-2 border-t  bg-gray-50 p-3  dark:bg-gray-700'
              }
            >
              <div className={' flex gap-1'}>
                <Link
                  to={`/customers/details/${scheduleInstance?.customer.phoneNumber}`}
                >
                  <FButton>
                    <FIconWrapper>
                      <HiOutlineViewGrid />
                      <FIconWrapper>تفاصيل العميل</FIconWrapper>
                    </FIconWrapper>
                  </FButton>
                </Link>

                <FButton
                  disabled={type === 'archive'}
                  btnType={'danger'}
                  onClick={() =>
                    handleWrong(
                      scheduleInstance.id,
                      'REFUSE',
                      'رفض العميل الاشتراك',
                    )
                  }
                >
                  <FIconWrapper>
                    <VscError />
                    رفض العميل
                  </FIconWrapper>
                </FButton>
              </div>
              <div className={' flex gap-2'}>
                <FButton
                  disabled={type === 'archive'}
                  btnType={'success'}
                  onClick={() =>
                    handleWrong(
                      scheduleInstance.id,
                      'SOLD',
                      'تم تحديد العميل كمباع',
                    )
                  }
                >
                  <FIconWrapper>
                    <HiOutlineCheck />
                    تم البيع
                  </FIconWrapper>
                </FButton>
                <FButton
                  disabled={type === 'archive'}
                  onClick={() =>
                    handleWrong(
                      scheduleInstance.id,
                      'WRONG_NUMBER',
                      'تم تحديد العميل كرقم خاطئ',
                    )
                  }
                  btnType={'danger'}
                >
                  <FIconWrapper>
                    <VscError />
                    رقم غير صحيح
                  </FIconWrapper>
                </FButton>
              </div>
            </div>
          </div>
          <div className={'border-r'}>
            <div
              className={
                'flex gap-2 border-y  bg-gray-50 p-3  dark:bg-gray-700'
              }
            >
              {scheduleInstance?.callType === 'MISSING_DATA' && (
                <FButton
                  disabled={type === 'archive'}
                  onClick={() => handleDoneCustomer(scheduleInstance.id)}
                  btnType={'success'}
                >
                  <FIconWrapper>
                    <HiOutlineCheck />
                    تم اكتمال البيانات
                  </FIconWrapper>
                </FButton>
              )}

              {scheduleInstance?.callType !== 'MISSING_DATA' && (
                <FButton
                  disabled={type === 'archive'}
                  onClick={() =>
                    handleCall(scheduleInstance.id, 'DONE', 'تمت المكالمة')
                  }
                  btnType={'success'}
                >
                  <FIconWrapper>
                    <HiOutlineCheck />
                    تمت المكالمة
                  </FIconWrapper>
                </FButton>
              )}

              <FButton
                disabled={type === 'archive'}
                onClick={() =>
                  handleCall(
                    scheduleInstance.id,
                    'NOT_NEEDED',
                    'تم الغاء المكالمة',
                  )
                }
                btnType={'danger'}
              >
                <FIconWrapper>
                  <MdOutlineDeleteOutline />
                  الغاء المكالمة
                </FIconWrapper>
              </FButton>
              <FButton
                disabled={type === 'archive'}
                btnType={'danger'}
                className={
                  'mr-auto flex items-center justify-center gap-2 px-2'
                }
                onClick={() => handleUnreachableCustomer(scheduleInstance.id)}
              >
                <ESpinner isVisible={isUnreachableRequestLoading} />
                <span>لا يمكن الوصول إليه</span>
                <HiOutlinePhone />
              </FButton>
              <FPopOver
                callBack={() => {
                  callback()
                  setIsModalOpen(false)
                }}
                callId={scheduleInstance?.id}
                button={
                  <>
                    <FButton disabled={type === 'archive'}>
                      تغير موعد المكالمة
                    </FButton>
                  </>
                }
              />
            </div>
            <div>
              <div className={'ml-auto flex gap-3 p-5'}>
                <div>
                  <div
                    className={
                      ' grid grid-cols-5 gap-3 text-sm  text-gray-800 dark:text-white '
                    }
                  >
                    {Object.keys(transaction ?? {}).map(fieldName => (
                      <div key={fieldName}>
                        <span>{renderTransactionField(fieldName)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={'flex flex-col gap-1 p-5'}>
              {scheduleInstance?.callIssues?.map((item, index) => (
                <div
                  className={`flex items-center gap-2 rounded-lg border border-gray-300 p-3 shadow-sm ${
                    item.notNeededCallIssue ? 'hidden' : 'bg-white'
                  }`}
                  key={index}
                >
                  <span>{reasonMap[item.callType] ?? item.callType} /</span>
                  <Link to={`/subscriptionDup/${item.subscriptionId}`}>
                    <FButton>
                      <span>{getProductById(item.productId).name}</span>
                    </FButton>
                  </Link>
                  <span>رقم المعاملة: {item.transactionCode}</span>

                  <span>
                    تاريخ المعاملة:{' '}
                    {moment(item.transactionDate).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </FModal>
      <div></div>
    </>
  )
}
export default CustomerModal
