import { Navigate, Outlet } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import moment from 'moment-timezone'
import { useEffect } from 'react'

const ProtectedRoute = () => {
  const token = localStorage.getItem('access_token')
  if (!token) {
    return <Navigate to='/login' />
  }

  useEffect(() => {
    moment.tz('Africa/Cairo').format('ha z')
  }, [])
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
export default ProtectedRoute
