import React from 'react'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import SFormWrapper from '../../../components/EFormWrapper'
import FButton from '../../../components/FButton'
import { FiMinus, FiPlus } from 'react-icons/fi'

const AgentDetails = ({ agentInfoPackage, setAgentInfoPackage }) => {
  const handleAgentInfoPackage = (e, index) => {
    let { name, value } = e.target
    //change state of isNullable
    if (name === 'isNullable') {
      value = !agentInfoPackage.agentPackage[index].isNullable
    }
    if (name === 'includeNumberOnly') {
      value = !agentInfoPackage.agentPackage[index].includeNumberOnly
    }
    if (name === 'includeTextOnly') {
      value = !agentInfoPackage.agentPackage[index].includeTextOnly
    }

    let newAgentInfoPackage = { ...agentInfoPackage }
    newAgentInfoPackage.agentPackage[index][name] = value
    setAgentInfoPackage(newAgentInfoPackage)
  }

  function handleAddExtraBlock() {
    let newAgentInfoPackage = { ...agentInfoPackage }
    newAgentInfoPackage.agentPackage.push({
      filedName: '',
      reportFiledName: '',
      isNullable: false,
      includeNumberOnly: false,
      includeTextOnly: false,
      isLengthRequired: false,
      minLength: 0,
      maxLength: 0,
    })
    setAgentInfoPackage(newAgentInfoPackage)
  }

  function handleRemoveExtraBlock() {
    let newAgentInfoPackage = { ...agentInfoPackage }
    newAgentInfoPackage.agentPackage.pop()
    setAgentInfoPackage(newAgentInfoPackage)
  }

  return (
    <>
      <span className={'my-5 block font-medium'}>
        البيانات المطلوبة من العميل لأستخراج الوثيقة___
      </span>
      <div className='row'>
        {agentInfoPackage.agentPackage.map((item, index) => (
          <div
            key={index}
            className={' mb-3 flex items-end  justify-end  gap-5 rounded '}
          >
            <div
              className={
                ' flex-grow  items-end justify-end rounded border    bg-gray-100 p-5 dark:bg-gray-600 '
              }
            >
              <div className={'grid w-full grid-cols-2 gap-2'}>
                <SFormWrapper>
                  <FLabel htmlFor={`filedName${index}`}>أسم الحقل </FLabel>
                  <FInputField
                    type='text'
                    name={'filedName'}
                    id={`filedName${index}`}
                    value={item.filedName}
                    onChange={e => handleAgentInfoPackage(e, index)}
                    placeholder={'أدخل أسم الحقل الذي تريد إضافته'}
                  />
                </SFormWrapper>
                {console.log(item)}
                <SFormWrapper>
                  <FLabel htmlFor={`reportFiledName${index}`}>
                    أسم الحقل في التقرير
                  </FLabel>
                  <FInputField
                    type='text'
                    name={'reportFiledName'}
                    id={`reportFiledName${index}`}
                    value={item.reportFiledName}
                    onChange={e => handleAgentInfoPackage(e, index)}
                    placeholder={'أدخل أسم الحقل في التقرير'}
                  />
                </SFormWrapper>
              </div>

              <div className={'grid grid-cols-3'}>
                <SFormWrapper className={'w-full '}>
                  <div className={'flex items-center gap-3'}>
                    <FLabel htmlFor={`isNullable${index}`}>
                      يمكن أن يكون فارغ
                    </FLabel>
                    <input
                      type='checkbox'
                      name={'isNullable'}
                      id={`isNullable${index}`}
                      checked={item.isNullable}
                      onChange={e => handleAgentInfoPackage(e, index)}
                    />
                  </div>
                </SFormWrapper>
                <SFormWrapper className={'w-full '}>
                  <div className={'flex items-center gap-3'}>
                    <FLabel htmlFor={`includeNumberOnly${index}`}>
                      يحتوي على أرقام فقط
                    </FLabel>
                    <input
                      type='checkbox'
                      name={'includeNumberOnly'}
                      id={`includeNumberOnly${index}`}
                      checked={item.includeNumberOnly}
                      onChange={e => handleAgentInfoPackage(e, index)}
                    />
                  </div>
                </SFormWrapper>
                <SFormWrapper className={'w-full '}>
                  <div className={'flex items-center gap-3'}>
                    <FLabel htmlFor={`includeTextOnly${index}`}>
                      يحتوي على نص فقط
                    </FLabel>
                    <input
                      type='checkbox'
                      name={'includeTextOnly'}
                      id={`includeTextOnly${index}`}
                      checked={item.includeTextOnly}
                      onChange={e => handleAgentInfoPackage(e, index)}
                    />
                  </div>
                </SFormWrapper>
              </div>
            </div>
            <div>
              <div className={'flex gap-1'}>
                {
                  <FButton
                    type={'button'}
                    onClick={() => handleAddExtraBlock()}
                  >
                    <FiPlus size={20} />
                  </FButton>
                }
                {index !== agentInfoPackage.agentPackage.length - 1 && (
                  <FButton
                    type={'button'}
                    onClick={() => handleRemoveExtraBlock()}
                  >
                    <FiMinus size={20} />
                  </FButton>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
export default AgentDetails
