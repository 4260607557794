import { fetchCoverProductAgents } from './lib'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'

const Stores = () => {
  const [page, setPage] = React.useState(0)
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['stores'],
    queryFn: () => fetchCoverProductAgents(0), // Wrap the function
  })

  const columns = [
    {
      Header: 'الاسم',
      accessor: 'name',
    },
    {
      Header: 'الهاتف',
      accessor: 'storePhones',
    },

    {
      Header: ' تاريخ التعديل',
      accessor: 'lastUpdateDate',
    },
    {
      Header: 'تاريخ الانشاء',
      accessor: 'creationDate',
    },
  ]

  if (isLoading) return <div>Loading...</div>
  else
    return (
      <>
        <div className={' border-b bg-gray-50 p-3'}>
          <span>الوكلاء</span>
        </div>
        <FCustomDataTable
          data={data.data.results}
          page={0}
          size={10}
          cols={columns}
          Refetch={refetch}
          totalNumberOfPages={data.data.totalNumberOfPages}
        />
      </>
    )
}

export default Stores
