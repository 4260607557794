import FModal from '../../../components/FModal'
import FButton from '../../../components/FButton'
import React, { useState } from 'react'
import FInputField from '../../../components/FInputField'
import FCol from '../../../components/FCol'
import FRow from '../../../components/FRow'
import { useMutation } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'
import moment from 'moment'
import FLabel from '../../../components/FLabel'

const confirmationRandomNumber = Math.floor(10000 + Math.random() * 90000)

const Dividers = () => {
  return (
    <div className='relative my-2'>
      <div className='absolute inset-0 flex items-center' aria-hidden='true'>
        <div className='w-full border-t border-gray-300' />
      </div>
      <div className='relative flex justify-center'>
        <span className='bg-gray-50 px-2 text-sm text-gray-500'>
          او يمكنك اختيار موظف معين
        </span>
      </div>
    </div>
  )
}
const RemoveAssignationModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [value, setValue] = useState('')
  const [agent, setAgent] = useState('')
  const [resetTranferedCalls, setResetTranferedCalls] = useState(false)
  const mutation = useMutation({
    mutationFn: async () => {
      await axiosInstance.post(`call/resetCalls`, {
        date: moment().format(),
        resetTranferedCalls,
        ...(agent && { agentEmail: agent }),
      })
    },
    onSuccess: () => {
      toast.success('تم إلغاء تعيين المكالمة بنجاح')
    },
    onError: () => {
      toast.error('حدث خطأ أثناء إلغاء تعيين المكالمة')
    },
  })
  return (
    <>
      <FModal
        isAutoWidth
        title='الغاء تعيين المكالمة'
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      >
        <div className='flex p-3 bg-gray-50 shadow-sm rounded border border-gray-300 flex-col'>
          <FCol>
            <span className={'text-sm'}>
              هل أنت متأكد من رغبتك في إلغاء تعيين المكالمة لجيع المستخدمين
            </span>
          </FCol>
          <Dividers />
          <FCol className={'my-1'}>
            <select
              className={'my-2'}
              value={agent}
              onChange={e => setAgent(e.target.value)}
            >
              <option value=''>اختر موظف</option>
              {JSON.parse(localStorage.getItem('users'))
                .filter(user => user.authorities[0] !== 'ADMIN_AUTHORITY')
                .map(user => (
                  <option value={user.email}>{user.name}</option>
                ))}
            </select>
          </FCol>
        </div>

        <FLabel>
          <input
            type='checkbox'
            checked={resetTranferedCalls}
            onChange={e => setResetTranferedCalls(e.target.checked)}
            className={'w-5 h-5 my-3 ml-2'}
          />
          قم باختيار جميع المكالمات التم تم تعيينها لهذا الموظف بالاضافة الى
          المكالمات المحولة من ايام سابقة
        </FLabel>

        <p className={'my-2 text-xs'}>
          <span> للتأكيد الرجاء كتابة الرقم العشوائي التالي </span>
          <span className={'rounded border bg-gray-50 px-2 font-bold'}>
            {confirmationRandomNumber}
          </span>
        </p>
        <FInputField
          label='الرقم العشوائي'
          placeholder='الرقم العشوائي'
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <FRow className={'mt-2'}>
          <FButton
            disabled={value != confirmationRandomNumber}
            btnType={'danger'}
            onClick={() => mutation.mutate()}
            className='bg-red-500'
          >
            <FIconWrapper>
              تأكيد
              <ESpinner isVisible={mutation.isLoading} />
            </FIconWrapper>
          </FButton>
          <FButton onClick={() => setIsModalOpen(false)}>الغاء </FButton>
        </FRow>
      </FModal>

      <FButton
        btnType={'danger'}
        onClick={() => setIsModalOpen(true)}
        className='bg-red-500'
      >
        الغاء تعيين المكالمة
      </FButton>
    </>
  )
}
export default RemoveAssignationModal
