import { useCustomAxios } from '../../../Hooks/useAxios'
import React, { useEffect, useState } from 'react'
import FButton from '../../../components/FButton'
import FFlexWrapper from '../../../components/FFlexWrapper'
import { Link } from 'react-router-dom'
import {
  convertDateIntoReadable,
  formatDateWithArabicDayName,
  getProductInstallmentById,
} from '../../../helpers/utils'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import FProductTag from '../../../components/FProductTag'
import moment from 'moment'
import { formatQuery } from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.css'
import usePersistedState from '../../../helpers/usePersistedState'
import { HiOutlineFilter } from 'react-icons/hi'
import { BiMessageRoundedEdit } from 'react-icons/bi'
import FIconWrapper from '../../../components/FIconWrapper'
import MassUpdateModal from './MassUpdateModal'
import { useLocalStorage } from 'usehooks-ts'
import FilterModal from '../Distribution/FilterModal'
import SwitchToggle from '../../../components/SwitchToggle'
import FRow from '../../../components/FRow'

const ListSubscriptions = () => {
  const [query, setQuery] = usePersistedState('query', {
    combinator: 'and',
    rules: [],
  })

  const [page, setPage] = useState(0)

  const str = formatQuery(query, 'sql')
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/customersubscription/findAllWithQueryParam?page=${page}&size=50&queryParam=${str.substring(
        1,
        str.length - 1,
      )}&orderByParam=ORDER BY c.creationDate DESC`,
    })
  useEffect(() => {
    Refetch()
  }, [page, query])

  useEffect(() => {
    Refetch()
  }, [page])
  const cols = [
    {
      Header: 'العميل',
      accessor: 'customer', // String-based value accessors!
      cell: (value, row) => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return (
          <Link
            to={`/subscriptionDup/${row.id}`}
            className={'my-0.5 flex flex-col rounded  border bg-gray-100 px-2'}
          >
            {!isNamesVisible && (
              <span className={'text-xs'}> {value.name} </span>
            )}
            <span className={'text-xs'}> {value.phoneNumber} </span>
          </Link>
        )
      },
      sticky: 'right',
    },
    {
      Header: 'الحالة',
      accessor: 'cancelled', // String-based value accessors!
      cell: (value, row) => {
        if (row.cancelled) {
          return <FProductTag productName={'ملغي'} color={'red'} />
        } else if (!row.completed && !row.documentNumber) {
          return <FProductTag productName='غير مكتمل البيانات' color='blue' />
        } else if (row.completed && !row.documentNumber) {
          return (
            <FProductTag
              productName={'مكتمل البيانات بدون وثيقة'}
              color='orange'
            />
          )
        } else if (row.completed && row.documentNumber) {
          return (
            <FProductTag
              productName={'مكتمل البيانات و الوثائق'}
              color='green'
            />
          )
        } else if (!row.completed && row.documentNumber) {
          return (
            <FProductTag
              productName={'غير مكتمل البيانات مكتمل الوثائق'}
              color='indigo'
            />
          )
        }
      },
    },
    {
      Header: 'حالة البيانت',
      accessor: 'completed',
      cell: value => {
        if (!value) return <FProductTag productName={'غير مكتمل'} color='red' />
        else return <FProductTag productName={'مكتمل'} color='green' />
      },
    },
    {
      Header: 'حالة الوثائق',
      accessor: 'documentNumber',
      cell: value => {
        if (!value)
          return <FProductTag productName={'لا يوجد وثية'} color='red' />
        else return <FProductTag productName={'يوجد وثيقة'} color='green' />
      },
    },

    {
      Header: 'حالة الدفع',
      accessor: 'dateOFLastPaidInstallment', // String-based value accessors!
      skipNull: true,
      cell: value => {
        if (value === null)
          return <FProductTag productName={'غير مدفوع'} color='red' />
        else return <FProductTag productName={'مدفوع'} color='green' />
      },
    },
    {
      Header: 'مكتمل من رفع البيانات',
      accessor: 'answersCompletedFromCustomerUpload', // String-based value accessors!
      cell: value => {
        if (value) return <FProductTag productName={' مكتمل'} color='green' />
        else return <FProductTag productName={'غير مكتمل'} color='red' />
      },
    },
    {
      Header: 'تم الارسال للتأمين',
      accessor: 'isExportedToIssuerReportBefore', // String-based value accessors!
      cell: value => {
        if (value)
          return <FProductTag productName={' تم الارسال'} color='green' />
        else return <FProductTag productName={'لم يتم الارسال'} color='red' />
      },
    },
    {
      Header: 'تم انشاءه من رفع البيانات',
      accessor: 'createdFromCustomerUpload', // String-based value accessors!
      cell: value => {
        if (value) return <FProductTag productName={'✅'} color='green' />
        else return <FProductTag productName={'❌'} color='red' />
      },
    },
    {
      Header: 'تم انشاءه من الاوراكل',
      accessor: 'createdFromOracle',
      cell: value => {
        if (!value) return <FProductTag productName={'❌'} color='red' />
        else return <FProductTag productName={'✅'} color='green' />
      },
    },
    {
      Header: 'يحتاج مراجعة',
      accessor: 'needsReview',
      cell: value => {
        if (!value) return <FProductTag productName={'❌'} color='red' />
        else return <FProductTag productName={'✅'} color='green' />
      },
    },
    {
      Header: 'عدد الاقارب',
      accessor: 'numberOfRelatives', // String-based value accessors!
    },
    {
      Header: 'رقم الملحق (خاص بالتامين الالكتروني)',
      accessor: 'attachmentNumberForCover', // String-based value accessors!
    },
    {
      Header: 'fawryCode',
      accessor: 'fawryCode', // String-based value accessors!
    },
    {
      Header: 'machine',
      accessor: 'machineId', // String-based value accessors!
    },
    ,
    {
      Header: 'team',
      accessor: 'customerCompletedTeam', // String-based value accessors!
    },

    {
      Header: 'حالة الاشتراك',
      accessor: 'cancelled',
      cell: value => {
        if (!value) return <FProductTag productName={'نشط'} color='green' />
        else return <FProductTag productName={'ملغي'} color='red' />
      },
    },

    {
      Header: 'المنتج',
      accessor: 'productId', // String-based value accessors!
      cell: value => {
        const products = JSON.parse(localStorage.getItem('products'))
        const product = products.find(product => product.id === value)
        return product.name
        //convert date format to be yyyy-mm-dd hh:mm:ss
      },
    },
    {
      Header: 'الباقة التأمينية',
      accessor: 'productInstallmentId', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return getProductInstallmentById(value)?.installmentDesc
      },
    },
    {
      Header: 'تاريخ الاضافة',
      accessor: 'creationDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return convertDateIntoReadable(value)
      },
    },
    {
      Header: 'تاريخ اخر قسط',
      accessor: 'dateOFLastPaidInstallment', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return formatDateWithArabicDayName(value)
      },
    },
    {
      Header: 'تاريخ اكتمال البيانات',
      accessor: 'completeCustomerDataDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return formatDateWithArabicDayName(value)
      },
    },
    {
      Header: 'تاريخ إصدار الوثيقة',
      accessor: 'insuranceStartDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return moment(value).format('YYYY-MM-DD')
      },
    },
    {
      Header: 'تاريخ إنتهاء الوثيقة',
      accessor: 'insuranceExpireDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return moment(value).format('YYYY-MM-DD')
      },
    },
  ]

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [isNamesVisible, setIsNamesVisible] = useLocalStorage(
    'isNamesVisible',
    true,
  )
  const [checkedRowsOuterProp, setCheckedRowsOuterProp] = useState([])
  const [isMassUpdateModalOpen, setIsMassUpdateModalOpen] = useState(false)

  return (
    <div className={'h-full grow'}>
      <MassUpdateModal
        isMassUpdateModalOpen={isMassUpdateModalOpen}
        setIsMassUpdateModalOpen={setIsMassUpdateModalOpen}
        subscriptions={checkedRowsOuterProp}
      />
      <FilterModal
        query={query}
        setQuery={setQuery}
        isOpen={isFilterModalOpen}
        setIsOpen={setIsFilterModalOpen}
      />

      <div>
        <div className={'flex items-center justify-between p-3'}>
          <span className={'text-lg font-semibold'}>الاِشتراكات</span>
          <div className={'flex gap-2'}>
            <div className={'py-1 px-3'}>
              <span>تم العثور على {response?.totalCount} نتيجة</span>
            </div>

            <Link to={'/subscription/add'}>
              <FButton>اضافة اِشتراك</FButton>
            </Link>
          </div>
        </div>
        {/*<RSelectPopUP />*/}
        <div className={'flex items-center gap-2 border-t p-3'}>
          <button
            onClick={() => {
              setIsMassUpdateModalOpen(true)
            }}
            className={
              'rounded border border-gray-300  bg-gray-100  p-2 leading-3 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2'
            }
          >
            <FIconWrapper>
              <BiMessageRoundedEdit />
              تعديل ({checkedRowsOuterProp.length} اشتراك)
            </FIconWrapper>
          </button>
          <button
            className={
              'rounded border border-gray-300 bg-gray-100 p-2 leading-3 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2'
            }
            onClick={() => setIsFilterModalOpen(true)}
          >
            <FFlexWrapper>
              <HiOutlineFilter />
              <span className={'mr-1 ml-1'}>بحــث</span>
              {query?.rules?.length > 0 && (
                <span
                  className={
                    'w-5 rounded-full bg-red-500 py-0.5 text-xs leading-3 text-white'
                  }
                >
                  {query?.rules?.length}
                </span>
              )}
            </FFlexWrapper>
          </button>
          <span>تم العثور على {response?.totalCount} نتيجة</span>

          <FRow className={'mr-auto'}>
            <small className={'font-medium text-gray-700'}>
              {!isNamesVisible ? 'إخفاء الأسماء' : 'إظهار الأسماء'}
            </small>
            <SwitchToggle
              enabled={isNamesVisible}
              setEnabled={setIsNamesVisible}
            />
          </FRow>
        </div>
        <FCustomDataTable
          setCheckedRowsOuterProp={setCheckedRowsOuterProp}
          cols={cols}
          data={response ? response.customerSubscriptions : []}
          isLoading={loading}
          page={page}
          setPage={setPage}
          size={2}
          Refetch={Refetch}
          totalNumberOfPages={totalNumberOfPages}
        />
      </div>
    </div>
  )
}
export default ListSubscriptions
