import { useCustomAxios } from '../../../../Hooks/useAxios'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import EFormWrapper from '../../../../components/EFormWrapper'
import FInputField from '../../../../components/FInputField'
import FLabel from '../../../../components/FLabel'
import FButton from '../../../../components/FButton'
import { produce } from 'immer'
import { BiPlusCircle, BiTrash } from 'react-icons/bi'
import FIconWrapper from '../../../../components/FIconWrapper'
import { IoMdRemoveCircleOutline } from 'react-icons/io'
import { HiDownload } from 'react-icons/hi'
import { axiosInstance } from '../../../../api/requister'
import { toast } from 'react-toastify'
import ECard from '../../../../components/ECard'

const EditProductVersionTwo = () => {
  const id = useParams().id
  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `/product/getProduct?id=${id}`,
  })

  const handleFormSubmission = values => {
    const data = {
      productName: values.name,
      btc: values.btc,
      productCustomerInfos: values.productCustomerInfos,
      productInstallments: values.productInstallments.map(installment => {
        return {
          deleted: installment.isDeleted,
          installmentCost:
            installment.productInstallmentTradeInfos[0].installmentCost,
          installmentDesc: installment.installmentDesc,
          installmentType: installment.installmentType,
          installmentCoverage: installment.installmentCoverage,
          maxNumberOfRelatives: installment.maxNumberOfRelatives,
          productInstallmentTradeInfos:
            installment.productInstallmentTradeInfos.map(tradeInfo => {
              return {
                id: tradeInfo.id,
                tradeName: tradeInfo.tradeName,
                installmentCoverage: tradeInfo.installmentCoverage,
                installmentCost: tradeInfo.installmentCost,
                commission: tradeInfo.commission,

                deleted: tradeInfo.deleted,
                productInstallmentTradeInfoDocuments:
                  tradeInfo.productInstallmentTradeInfoDocuments.map(
                    document => {
                      return {
                        id: document.id,
                        gigProductName: document.gigProductName,
                        deleted: document.deleted,
                      }
                    },
                  ),
              }
            }),
          id: installment.id,
          productCustomerInfos: values.productCustomerInfos,
        }
      }),

      productType: 'PRODUCT',
      id: id,
    }
    axiosInstance
      .post('/product/editProduct', data)
      .then(res => {
        toast.success('تم تعديل المنتج')
        navigation('/product')
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const Formik = useFormik({
    initialValues: {
      name: response?.product?.name || '',
      btc: response?.product?.btc || '',
      productInstallments: response?.product?.productInstallments || [],
      productCustomerInfos: response?.product?.productCustomerInfos || [],
    },
    enableReinitialize: true,
    onSubmit: handleFormSubmission,
  })

  const handleAddExtraProductInstallmentTradeInfoDocuments = (
    index,
    tradeInfoIndex,
  ) => {
    const updatedProductInstallments = produce(
      Formik.values.productInstallments,
      draft => {
        draft[index].productInstallmentTradeInfos[
          tradeInfoIndex
        ].productInstallmentTradeInfoDocuments.push({
          gigProductName: '',
        })
      },
    )
    Formik.setFieldValue('productInstallments', updatedProductInstallments)
  }
  const handleRemoveProductInstallmentTradeInfoDocuments = (
    index,
    tradeInfoIndex,
    documentIndex,
  ) => {
    const updatedProductInstallments = produce(
      Formik.values.productInstallments,
      draft => {
        draft[index].productInstallmentTradeInfos[
          tradeInfoIndex
        ].productInstallmentTradeInfoDocuments[documentIndex].deleted = true
      },
    )
    Formik.setFieldValue('productInstallments', updatedProductInstallments)
  }

  const handleAddExtraProductInstallmentTradeInfo = index => {
    const updatedProductInstallments = produce(
      Formik.values.productInstallments,
      draft => {
        draft[index].productInstallmentTradeInfos.push({
          productInstallmentTradeInfoDocuments: [
            {
              gigProductName: '',
            },
          ],
        })
      },
    )
    Formik.setFieldValue('productInstallments', updatedProductInstallments)
  }

  const handleRemoveInstallment = index => {
    const updatedProductInstallments = produce(
      Formik.values.productInstallments,
      draft => {
        draft.splice(index, 1)
      },
    )
    Formik.setFieldValue('productInstallments', updatedProductInstallments)
  }

  const handleAddInstallment = () => {
    const updatedProductInstallments = produce(
      Formik.values.productInstallments,
      draft => {
        draft.push({
          productInstallmentTradeInfos: [
            {
              productInstallmentTradeInfoDocuments: [
                {
                  gigProductName: '',
                },
              ],
            },
          ],
        })
      },
    )
    Formik.setFieldValue('productInstallments', updatedProductInstallments)
  }

  const handleProductTemplateDownload = () => {}
  const handleProductDeactivation = () => {}
  const handleDeleteProductInstallment = (index, tradeInfoIndex) => {
    const updatedProductInstallments = produce(
      Formik.values.productInstallments,
      draft => {
        draft[index].productInstallmentTradeInfos.splice(tradeInfoIndex, 1)
      },
    )
    Formik.setFieldValue('productInstallments', updatedProductInstallments)
  }

  const handleRemoveProductCustomerInfo = index => {
    const updatedProductCustomerInfos = produce(
      Formik.values.productCustomerInfos,
      draft => {
        draft[index].deleted = true
      },
    )
    Formik.setFieldValue('productCustomerInfos', updatedProductCustomerInfos)
  }

  const handleMaxNumberOfRelatives = (index, value) => {}
  if (loading) return <p>Loading...</p>
  else {
    return (
      <div>
        <div
          className={
            'flex items-center justify-between border-b bg-white px-2 dark:bg-gray-900'
          }
        >
          <div className={'flex gap-2 p-3'}>
            <NavLink className={'text-gray-400'} to={'/product'}>
              المنتجات
            </NavLink>
            <span className={'text-gray-400'}>/</span>
          </div>
          <div className={'flex flex-row-reverse gap-2'}>
            <FButton
              onClick={() => handleProductDeactivation()}
              btnType={'danger'}
            >
              <FIconWrapper>
                <IoMdRemoveCircleOutline />
                تعطيل المنتج
              </FIconWrapper>
            </FButton>

            <FButton onClick={() => handleProductTemplateDownload()}>
              <FIconWrapper>
                <HiDownload />
                تنزيل القالب
              </FIconWrapper>
            </FButton>
          </div>
        </div>
        <div className={'flex gap-2 p-3'}>
          <div id={'product_preview'} className={'hidden w-4/12 lg:block  '}>
            <div className={'sticky top-24 rounded-md border  shadow-sm'}>
              <div
                className={
                  'f-row-between rounded-md border-b bg-white p-3 dark:bg-gray-900'
                }
              >
                <span>معاينة المنتج</span>
              </div>
              <div
                className={'mt-3 flex flex-col items-center justify-center '}
              >
                <div
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  className={
                    'h-24 w-24  rounded-full bg-white dark:bg-gray-900'
                  }
                ></div>
                <span className={'block text-center font-bold'}>
                  {/*{__productBasicInfo.productBasicInfo.productName.value}*/}
                </span>
                <span className={'block text-center text-sm'}>
                  {/*{__productBasicInfo.productBasicInfo.productBTC.value}*/}
                </span>
              </div>
              <div className={'mt-5 p-1'}>
                <span className={'text-xs font-bold'}>تفاصيل القسط</span>
                <table className='mt-2 w-full table-auto rounded  bg-gray-100 text-right dark:bg-gray-600'>
                  <thead className={' '}>
                    <tr>
                      <th className={'border p-0.5 text-xs print:text-red-800'}>
                        تكلفة القسط
                      </th>
                      <th className={'border p-0.5 text-xs print:text-red-800'}>
                        التفاصيل
                      </th>
                      <th className={'border p-0.5 text-xs print:text-red-800'}>
                        المدة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Formik.values.productInstallments.map(
                      (productInstallment, index) => {
                        return (
                          <tr key={index}>
                            <td className={'border p-0.5 text-xs'}>
                              {productInstallment.installmentCost}
                            </td>
                            <td className={'border p-0.5 text-xs'}>
                              {productInstallment.installmentDesc}
                            </td>
                            <td className={'border p-0.5 text-xs'}>
                              {productInstallment.installmentType}
                            </td>
                          </tr>
                        )
                      },
                    )}
                  </tbody>
                </table>
                <span className={'text-xs font-bold'}>
                  البيانات المطلوبة من العميل لأستخراج الوثيقة
                </span>
              </div>
            </div>
          </div>
          <div className={'flex w-full flex-col gap-1 lg:w-8/12 '}>
            <FButton
              type={'button'}
              onClick={() => {
                handleAddInstallment()
              }}
            >
              Add
            </FButton>
            <form
              onSubmit={Formik.handleSubmit}
              className={'flex flex-col gap-3'}
            >
              {Formik.values.productInstallments.map((installment, index) => (
                <div
                  key={index}
                  className={
                    ' flex w-full flex-col gap-2 rounded-md border  bg-gray-100 p-3 dark:bg-gray-600'
                  }
                >
                  <FButton
                    className={'w-fit'}
                    type={'button'}
                    btnType={'danger'}
                    onClick={() => {
                      handleRemoveInstallment(index)
                    }}
                  >
                    <FIconWrapper>
                      <IoMdRemoveCircleOutline />
                      Remove
                    </FIconWrapper>
                  </FButton>

                  <div className={' grid grid-cols-1 gap-2 lg:grid-cols-2'}>
                    <div>
                      <EFormWrapper>
                        <FLabel
                          isRequired={true}
                          htmlFor={`productInstallments[${index}].installmentDesc`}
                        >
                          تفاصيل القسط
                        </FLabel>
                        <FInputField
                          type='text'
                          id={`productInstallments[${index}].installmentDesc`}
                          name={`productInstallments[${index}].installmentDesc`}
                          value={installment.installmentDesc}
                          onChange={Formik.handleChange}
                        />
                      </EFormWrapper>
                    </div>

                    <EFormWrapper>
                      <FLabel
                        isRequired={true}
                        htmlFor={`productInstallments[${index}].installmentType`}
                      >
                        مدة تسديد القسط
                      </FLabel>

                      <select
                        id={`productInstallments[${index}].installmentType`}
                        name={`productInstallments[${index}].installmentType`}
                        value={installment.installmentType}
                        onChange={Formik.handleChange}
                      >
                        <option value={''}>اختر مدة القسط</option>
                        <option value={'MONTHLY'}>شهري</option>
                        <option value={'QUARTERLY'}>ربع سنوي</option>
                        <option value={'BIANNUAL'}>نص سنوي</option>
                        <option value={'ANNUAL'}>سنوي</option>
                      </select>
                    </EFormWrapper>
                  </div>
                  <div className={'col-span-5 '}>
                    <div className={'mb-2 flex items-center justify-between'}>
                      <span className={'font-semibold'}>أنواع الوثائق</span>
                      <FButton
                        type={'button'}
                        onClick={() => {
                          handleAddExtraProductInstallmentTradeInfo(index)
                        }}
                      >
                        <FIconWrapper>
                          اضافة نوع وثيقة
                          <BiPlusCircle />
                        </FIconWrapper>
                      </FButton>
                    </div>

                    <div className={'flex flex-col gap-2'}>
                      {Formik.values.productInstallments[
                        index
                      ].productInstallmentTradeInfos.map(
                        (tradeInfo, tradeInfoIndex) => (
                          <div
                            key={tradeInfoIndex}
                            className={
                              ' flex w-full flex-col items-end gap-1 rounded border border-dashed border-gray-400 bg-gray-200 p-2'
                            }
                          >
                            <div className={'flex w-full items-end gap-2'}>
                              {console.log(
                                Formik.values.productInstallments[index],
                              )}
                              <EFormWrapper>
                                <FButton
                                  disabled={
                                    Formik.values.productInstallments[index]
                                      .productInstallmentTradeInfos.length === 1
                                  }
                                  onClick={() => {
                                    handleDeleteProductInstallment(
                                      index,
                                      tradeInfoIndex,
                                    )
                                  }}
                                >
                                  <FIconWrapper>
                                    <BiTrash />
                                  </FIconWrapper>
                                </FButton>
                              </EFormWrapper>

                              <EFormWrapper>
                                <FLabel
                                  isRequired={true}
                                  htmlFor={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].tradeName`}
                                >
                                  الاسم التجاري
                                </FLabel>

                                <FInputField
                                  value={tradeInfo.tradeName}
                                  type='text'
                                  id={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].tradeName`}
                                  name={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].tradeName`}
                                  onChange={Formik.handleChange}
                                />
                              </EFormWrapper>
                              <EFormWrapper>
                                <FLabel
                                  isRequired={true}
                                  htmlFor={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].installmentCost`}
                                >
                                  تكلفة القسط
                                </FLabel>
                                <FInputField
                                  value={tradeInfo.installmentCost}
                                  type='number'
                                  id={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].installmentCost`}
                                  name={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].installmentCost`}
                                  onChange={Formik.handleChange}
                                />
                              </EFormWrapper>
                              <EFormWrapper>
                                <FLabel
                                  isRequired={true}
                                  htmlFor={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].installmentCoverage`}
                                >
                                  تغطية القسط
                                </FLabel>
                                <FInputField
                                  value={tradeInfo.installmentCoverage}
                                  type={'number'}
                                  id={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].installmentCoverage`}
                                  name={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].installmentCoverage`}
                                  onChange={Formik.handleChange}
                                />
                              </EFormWrapper>
                              <EFormWrapper>
                                <FLabel
                                  isRequired={true}
                                  htmlFor={`productInstallments[${index}].maxNumberOfRelatives`}
                                >
                                  عدد الاقارب
                                </FLabel>
                                <FInputField
                                  value={
                                    Formik.values.productInstallments[index]
                                      .maxNumberOfRelatives
                                  }
                                  type={'number'}
                                  id={`productInstallments[${index}].maxNumberOfRelatives`}
                                  name={`productInstallments[${index}].maxNumberOfRelatives`}
                                  onChange={e => {
                                    Formik.setFieldValue(
                                      `productInstallments[${index}].maxNumberOfRelatives`,
                                      e.target.value,
                                    )
                                  }}
                                />
                              </EFormWrapper>
                              <EFormWrapper>
                                <FLabel
                                  isRequired={true}
                                  htmlFor={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].commission`}
                                >
                                  عمولة القسط
                                </FLabel>
                                <FInputField
                                  value={tradeInfo.commission}
                                  type={'number'}
                                  id={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].commission`}
                                  name={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].commission`}
                                  onChange={Formik.handleChange}
                                />
                              </EFormWrapper>
                            </div>

                            <div
                              className={
                                ' grid w-full grid-cols-1 gap-2 rounded border  bg-gray-100 p-3 dark:bg-gray-600'
                              }
                            >
                              {tradeInfo.productInstallmentTradeInfoDocuments.map(
                                (document, documentIndex) => (
                                  <div key={documentIndex}>
                                    {!document.deleted && (
                                      <div className={'flex items-end gap-1'}>
                                        <div className={'flex-grow'}>
                                          <EFormWrapper>
                                            <FLabel
                                              isRequired={true}
                                              htmlFor={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].productInstallmentTradeInfoDocuments[${documentIndex}].gigProductName`}
                                            >
                                              اسم الوثيقة
                                            </FLabel>
                                            <FInputField
                                              type='text'
                                              id={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].productInstallmentTradeInfoDocuments[${documentIndex}].gigProductName`}
                                              name={`productInstallments[${index}].productInstallmentTradeInfos[${tradeInfoIndex}].productInstallmentTradeInfoDocuments[${documentIndex}].gigProductName`}
                                              value={document.gigProductName}
                                              onChange={Formik.handleChange}
                                            />
                                          </EFormWrapper>
                                        </div>

                                        <EFormWrapper>
                                          <FButton
                                            disabled={
                                              tradeInfo
                                                .productInstallmentTradeInfoDocuments
                                                .length === 1
                                            }
                                            onClick={() => {
                                              handleRemoveProductInstallmentTradeInfoDocuments(
                                                index,
                                                tradeInfoIndex,
                                                documentIndex,
                                              )
                                            }}
                                            type='button'
                                          >
                                            <BiTrash />
                                          </FButton>
                                        </EFormWrapper>
                                      </div>
                                    )}
                                  </div>
                                ),
                              )}
                              <div className={'w-fit'}>
                                <FButton
                                  onClick={() => {
                                    handleAddExtraProductInstallmentTradeInfoDocuments(
                                      index,
                                      tradeInfoIndex,
                                    )
                                  }}
                                  type='button'
                                >
                                  <FIconWrapper>
                                    <span>اضافة وثيقة</span>
                                    <BiPlusCircle />
                                  </FIconWrapper>
                                </FButton>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <ECard>
                <ECard.Header>
                  <span>البيانات المطلوبة من العميل</span>
                </ECard.Header>
                <ECard.Body>
                  <div className={'grid grid-cols-1 items-center gap-3'}>
                    {Formik.values.productCustomerInfos?.map(
                      (productCustomerInfo, productCustomerInfosIndex) => {
                        return (
                          <div
                            key={productCustomerInfosIndex}
                            className={
                              productCustomerInfo.deleted
                                ? 'hidden'
                                : 'flex items-center gap-2'
                            }
                          >
                            <div className={'flex w-full items-end gap-2'}>
                              <EFormWrapper className={'w-full'}>
                                <FInputField
                                  type='text'
                                  id={`productCustomerInfos[${productCustomerInfosIndex}].inputNameInReport`}
                                  name={`productCustomerInfos[${productCustomerInfosIndex}].inputNameInReport`}
                                  value={productCustomerInfo.inputNameInReport}
                                  onChange={Formik.handleChange}
                                />
                              </EFormWrapper>
                              <EFormWrapper className={'w-full'}>
                                <FInputField
                                  type='text'
                                  placeholder={'ادخل النوع'}
                                  id={`productCustomerInfos[${productCustomerInfosIndex}].inputType`}
                                  name={`productCustomerInfos[${productCustomerInfosIndex}].inputType`}
                                  value={productCustomerInfo.inputType}
                                  onChange={Formik.handleChange}
                                />
                              </EFormWrapper>
                              <EFormWrapper>
                                <FButton
                                  btnType={'danger'}
                                  type={'button'}
                                  onClick={() => {
                                    handleRemoveProductCustomerInfo(
                                      productCustomerInfosIndex,
                                    )
                                  }}
                                >
                                  <BiTrash />
                                </FButton>
                              </EFormWrapper>
                            </div>
                          </div>
                        )
                      },
                    )}
                  </div>
                </ECard.Body>
              </ECard>

              <FButton type={'submit'}>تعديل المنتج</FButton>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default EditProductVersionTwo
