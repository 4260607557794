import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import JsonView from 'react18-json-view'
import DisableCallsModal from './DisableCallsModal'
import FButton from '../../../components/FButton'
import { toast } from 'react-toastify'

const Calls = () => {
  const [size, setSize] = useState(50) // The number of items per page
  const [page, setPage] = useState(0) // Current page state
  const [selectedCalls, setSelectedCalls] = useState([])

  const [callStatus, setCallStatus] = useState('NEW')

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['calls', page, callStatus], // include page and callStatus in queryKey to refetch when they change
    queryFn: async () => {
      const response = await axiosInstance.get('/call', {
        params: {
          page: page, // Use the current page state
          size: size,
          callStatus: callStatus,
        },
      })
      return response.data
    },
  })

  const bulkCallUpdate = async () => {
    try {
      await axiosInstance.post('call/updateBulkCalls', {
        callIds: selectedCalls,
        callStatus: 'NOT_NEEDED',
      })
      toast.success('Calls updated successfully')
    } catch (e) {
      toast.error('Failed to update calls')
    }
  }

  useEffect(() => {
    refetch().then(() => {
      setSelectedCalls([])
    })
  }, [callStatus, page]) // Refetch when callStatus or page changes

  const handleSelectCall = callId => {
    setSelectedCalls(prevSelected =>
      prevSelected.includes(callId)
        ? prevSelected.filter(id => id !== callId)
        : [...prevSelected, callId],
    )
  }

  if (isLoading) {
    return <>loading ...</>
  }

  return (
    <>
      <DisableCallsModal />
      {selectedCalls.length > 0 && (
        <div
          className={
            'border shadow h-fit bg-white rounded-lg p-3 fixed bottom-[40px] z-20 border-gray-300   left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          }
        >
          <div className={'flex gap-2 items-center justify-center'}>
            <FButton btnType={'danger'}>Disable Calls</FButton>
            <FButton>Clear Assignments</FButton>
            <div className={'flex gap-2 justify-center items-center'}>
              <span>Selected</span>
              <span className={' text-white px-2 rounded bg-red-500'}>
                {selectedCalls.length}
              </span>
            </div>
          </div>
        </div>
      )}
      <h1>Calls</h1>
      <select
        style={{
          direction: 'ltr',
        }}
        value={callStatus}
        onChange={e => setCallStatus(e.target.value)}
      >
        <option value=''>جميع المكالمات</option>
        <option value='NEW'>جديد (لم يتم تعين موظف لهذا الإتصال)</option>
        <option value='ASSIGNED'>تم تعيين موظف لهذا الإتصال</option>
      </select>
      <JsonView src={selectedCalls} />
      {moment().toISOString()}
      <table className='min-w-full border-collapse border border-gray-200'>
        <thead>
          <tr>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'></th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Call id
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Creation Date
            </th>

            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Call Status
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Call Date
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Assign Date
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Last Interaction Date
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Number of Call Issues
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Customer Name
            </th>
            <th className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
              Representative
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.callSchedules.map(call => (
            <tr
              key={call.id}
              className={`${
                selectedCalls.includes(call.id) ? 'bg-gray-200' : ''
              }`}
            >
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                <input
                  type='checkbox'
                  checked={selectedCalls.includes(call.id)}
                  onChange={() => handleSelectCall(call.id)}
                />
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                <Link to={`/calls/details/${call.id}`}>{call.id}</Link>
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                {moment(call.creationDate).format('DD-MM-YYYY')}
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                {call.callStatus}
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                {moment(call.callDate).format('DD-MM-YYYY')}
              </td>{' '}
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                {moment(call.assignDate).format('DD-MM-YYYY')}
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                {call.lastInteractionDate ?? 'N/A'}
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                {call.numberOfCallIssues}
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                <Link
                  className={'flex flex-col'}
                  to={`/customers/details/${call?.customer?.phoneNumber}`}
                >
                  <span className={'text-sm'}> {call?.customer?.name}</span>
                  <small className={'text-xs text-gray-500'}>
                    {call?.customer?.phoneNumber}
                  </small>
                </Link>
              </td>
              <td className='border border-gray-200 px-4 py-2 whitespace-nowrap'>
                <Link
                  className={'flex flex-col'}
                  to={`/customers/details/${call?.user?.email}`}
                >
                  <span className={'text-sm'}> {call?.user?.name}</span>
                  <small className={'text-xs text-gray-500'}>
                    {call?.user?.email}
                  </small>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex justify-between mt-4'>
        <FButton
          onClick={() => setPage(prevPage => Math.max(prevPage - 1, 0))}
          disabled={page === 0} // Disable if on the first page
        >
          Previous
        </FButton>
        <span>
          Page {page + 1} of {data?.totalNumberOfPages}
        </span>
        <FButton
          onClick={() =>
            setPage(prevPage =>
              prevPage + 1 < data?.totalNumberOfPages ? prevPage + 1 : prevPage,
            )
          }
          disabled={page + 1 >= data?.totalNumberOfPages} // Disable if on the last page
        >
          Next
        </FButton>
      </div>
    </>
  )
}

export default Calls
