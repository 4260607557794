import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import FCol from '../../../components/FCol'
import moment from 'moment'
import FRow from '../../../components/FRow'
import FAvatar from '../../../components/FAvatar'
import FProductTag from '../../../components/FProductTag'

const CallLogs = ({ callId }) => {
  const { data, loading } = useQuery({
    queryKey: ['callLogs'],
    queryFn: () =>
      axiosInstance.get(`call/archive?callId=${callId}&page=0&size=10`),
  })

  const archieveSourceMap = {
    MANUAL_AGENT_ASSIGNED: 'تعيين يدوي لمسؤول المكالمة',
    MANUAL_EDIT: 'تعديل يدوي',
    NEW: 'تم إنشاء المكالمة',
    ORACLE_SHEET: 'ملف الأوراكل',
  }
  const callStatusMap = {
    ASSIGNED: 'معين',
    UNREACHABLE: 'غير متاح',
    DONE: 'منتهي',
    NEW: 'جديد',
  }

  if (loading) return <p>loading</p>
  else
    return (
      <div
        className={'min-w-full rounded-md border border-gray-300 p-3 shadow-sm'}
      >
        <FCol>
          {data?.data?.archiveCallsSchedule?.map((schedule, index) => (
            <div
              key={index}
              className={
                'flex  min-w-fit justify-between gap-2 rounded-md border border-gray-300 bg-gray-50 p-2 shadow-sm'
              }
            >
              <FRow className={'w-5/12 min-w-fit'}>
                <p>
                  <span className={'text-sm'}>
                    {moment(schedule.archiveDate).format('DD/MM/YYYY')}{' '}
                    {schedule.archiveTime}
                  </span>
                </p>
                <FRow>
                  <div className={'flex flex-col'}>
                    <span className={'text-sm'}>{schedule.archiveByEmail}</span>
                    <span className={'text-xs text-gray-800'}>
                      {archieveSourceMap[schedule.archiveSource] ||
                        schedule.archiveSource}
                    </span>
                  </div>
                  <FAvatar name={'admin admin'} />
                </FRow>
              </FRow>
              <FCol className={'w-2/12 min-w-fit'}>
                <small>حالة المكالمة</small>
                <FProductTag
                  productName={
                    callStatusMap[schedule.callStatus] || schedule.callStatus
                  }
                  color={'red'}
                />
              </FCol>
              <FRow className={'w-5/12'}>
                {schedule.user && (
                  <FRow>
                    <span className={'text-sm'}>المسؤول عن المكالمة</span>
                    <FRow>
                      <FCol>
                        <span className={'text-sm'}> {schedule.user.name}</span>
                        <span className={'text-xs'}>
                          {' '}
                          {schedule.user.email}
                        </span>
                      </FCol>
                      <FAvatar name={schedule.user.name} />
                    </FRow>
                  </FRow>
                )}
              </FRow>

              {schedule.callAgentInteractionType}
            </div>
          ))}
        </FCol>
      </div>
    )
}
export default CallLogs
