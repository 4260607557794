import FModal from '../../../components/FModal'
import FInputField from '../../../components/FInputField'
import { useState } from 'react'
import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'
import FIconWrapper from '../../../components/FIconWrapper'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import { useCustomAxios } from '../../../Hooks/useAxios'

const UploadCustomerSheet = ({
  isModalOpen,
  setIsModalOpen,
  callBackFunction,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState('')

  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/product/listAllProducts?page=0&size=100`,
    })

  //upload file to server
  const handleFileUpload = () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      responseType: 'blob', // Set the response type to blob
    }

    axiosInstance
      .post(
        `/upload/uploadCustomers${
          selectedProduct === 'all' ? '' : `?productId=${selectedProduct}`
        }`,
        formData,
        config,
      )
      .then(response => {
        setIsLoading(false)
        callBackFunction()
        setIsModalOpen(false)
        toast.success('تم رفع الملف بنجاح')
        const modifiedFile = response.data
        // Create a URL for the modifiedFile blob
        const fileUrl = URL.createObjectURL(modifiedFile)

        // Create a temporary anchor element
        const downloadLink = document.createElement('a')
        downloadLink.href = fileUrl
        downloadLink.download = [
          'تاكيد توريد بيانات العملاء بتاريخ',
          new Date().toLocaleDateString(),
        ]
          .join(' ')
          .concat('.xlsx') // Set the desired file name with .xlsx extension

        // Append the anchor element to the document body
        document.body.appendChild(downloadLink)

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        // Clean up by removing the anchor element and revoking the URL
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(fileUrl)
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err.response.data.message)
      })
  }

  return (
    <>
      <FModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        title={'توريد ملف العملاء'}
      >
        <EFormWrapper>
          <FLabel>المنتجات</FLabel>
          <select
            className={'input-style'}
            value={selectedProduct}
            onChange={e => setSelectedProduct(e.target.value)}
          >
            <option value={''}>اختر المنتج</option>
            <option value={'all'}>الكل</option>
            {response?.products.map(product => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
        </EFormWrapper>
        <FInputField
          type={'file'}
          label={'اختر ملف'}
          onChange={e => {
            setFile(e.target.files[0])
          }}
        />
        <div className={'mt-2 flex gap-2'}>
          <FButton
            disabled={selectedProduct === ''}
            onClick={() => handleFileUpload()}
          >
            <FIconWrapper>
              <ESpinner isVisible={isLoading} />
              توريد
            </FIconWrapper>
          </FButton>
          <FButton btnType={'secondary'} onClick={() => setIsModalOpen(false)}>
            إلغاء
          </FButton>
        </div>
      </FModal>
    </>
  )
}
export default UploadCustomerSheet
