import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { HiOutlinePlusCircle } from 'react-icons/hi'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useCustomAxios } from '../../../Hooks/useAxios'
import moment from 'moment'
import FProductTag from '../../../components/FProductTag'

const ListProductCancelation = () => {
  const [page, setPage] = useState(0)
  const [allFilters, setAllFilters] = React.useState([])
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])
  const handleFilterParams = () => {
    let query = ''
    filterOptions.map(option => {
      if (option.key !== '') {
        query += `&${option.key}=${option.value}`
      }
    })
    return query
  }
  let navigate = useNavigate()
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/product/listAllCancellationProducts?page=${page}&size=10${handleFilterParams()}`,
    })

  useEffect(() => {
    Refetch()
  }, [page])

  const handleAddProduct = () => navigate('')

  const cols = [
    {
      Header: 'بي تي سي',
      accessor: 'btc', // String-based value accessors!
    },
    {
      Header: 'الأسم',
      accessor: 'name', // String-based value accessors!
    },
    {
      Header: 'تاريخ الإضافة',
      accessor: 'creationDate', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return moment(Date(value).toLocaleString()).format(
          'MMMM d, YYYY, h:mm:ss a',
        )
      },
    },
    {
      Header: 'حالة  المنتج',
      accessor: 'deleted',
      cell: value =>
        !value ? (
          <FProductTag productName={'مفعل'} color='green' />
        ) : (
          <FProductTag productName={'غير مفعل'} color='red'>
            غير مفعل
          </FProductTag>
        ),
    },
  ]
  return (
    <>
      <div className={'f-row-between  flex items-center p-3'}>
        <span className={'text-xl font-medium'}>المنتجات</span>
        <NavLink to={'/product/cancel/add'}>
          <FButton>
            <FIconWrapper>
              <HiOutlinePlusCircle />
              إضافة منتج
            </FIconWrapper>
          </FButton>
        </NavLink>
      </div>
      <FCustomDataTable
        cols={cols}
        data={response ? response.products : []}
        isLoading={loading}
        page={page}
        setPage={setPage}
        size={2}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        totalNumberOfPages={totalNumberOfPages}
        Refetch={Refetch}
        rowFunction={row => navigate(`/productCancelEdit/${row.id}`)}
      />
    </>
  )
}
export default ListProductCancelation
