import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import { getProductInstallmentById } from '../../../helpers/utils'
import { NavLink } from 'react-router-dom'
import FCol from '../../../components/FCol'

const ReviewSub = () => {
  const [page, setPage] = useState(0) // Corrected the function name to useState

  const { data, isLoading, isError } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: () =>
      axiosInstance
        .get(
          `report/findUtilWithQueryParam?page=${page}&queryParam=WHERE%20c.needsReview%20%3D%20true&size=100&tableNameParam=CustomerSubscription`,
        )
        .then(res => res.data),
  })

  if (isLoading) return <div>Loading...</div>
  else
    return (
      <>
        <div className='flex items-center justify-between border-b bg-white p-3'>
          <span>مراجعة الاشتراكات</span>
        </div>
        <FCol className={'items-center justify-center'}>
          <div
            className={'flex flex-col gap-2'}
            style={{
              maxWidth: '900px',
            }}
          >
            <div className='relative my-5'>
              <div
                className='absolute inset-0 flex items-center'
                aria-hidden='true'
              >
                <div className='w-full border-t border-gray-300'></div>
              </div>
              <div className='relative flex justify-start'>
                <span className='bg-white pl-2 text-sm text-gray-500'>
                  اشتراكات تحتاج مراجعة
                </span>
              </div>
            </div>
            {data.results.map((item, index) => (
              <NavLink
                to={`/subscription/${item.id}`}
                key={index}
                className='grid cursor-pointer grid-cols-3 items-center justify-between rounded border bg-white p-3 shadow-sm hover:bg-gray-50'
              >
                <span className={'text-sm'}>{item.customer?.name}</span>
                <span className={'text-sm'}>{item.customer?.phoneNumber}</span>
                <span>
                  {
                    getProductInstallmentById(item.productInstallmentId)
                      ?.installmentDesc
                  }
                </span>
                <span className={'text-sm'}>{item.subscriptionId}</span>
                <span className={'text-sm'}>{item.subscriptionName}</span>
              </NavLink>
            ))}
          </div>
        </FCol>
      </>
    )
}

export default ReviewSub
