import { TbHandStop, TbReportAnalytics, TbSmartHome } from 'react-icons/tb'
import {
  HiChevronLeft,
  HiOutlineNewspaper,
  HiOutlineUpload,
  HiOutlineUserGroup,
} from 'react-icons/hi'
import FFlexWrapper from '../FFlexWrapper'
import React, { useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import FNavLink from '../FNavLink'
import { FaRegEye, FaRegUser, FaTasks } from 'react-icons/fa'
import { UseLocalStorageContent } from '../../Hooks/useLocalStorageContent'
import useWindowDimensions from '../../Hooks/useWindowDimensions'
import { AiOutlineHistory, AiOutlineSchedule } from 'react-icons/ai'
import { IoMdRemoveCircleOutline } from 'react-icons/io'
import { getAuthorities, getRoutePathByName } from '../../helpers/utils'
import { RiMoneyDollarCircleLine, RiMoneyPoundCircleLine } from 'react-icons/ri'
import { BiPhoneCall, BiSearchAlt } from 'react-icons/bi'
import FIconWrapper from '../FIconWrapper'
import { useNavigate } from 'react-router-dom'
import CustomerSearchModal from './CustomerSearchModal'
import { CgTemplate } from 'react-icons/cg'
import FMenu from '../FMenu'
import FAvatar from '../FAvatar'
import { HiOutlineBuildingStorefront } from 'react-icons/hi2'
import { GoServer } from 'react-icons/go'

const SideBar = ({ isSideBarOpen, setIsSideBarOpen, onClickOutside }) => {
  const { name } = UseLocalStorageContent()

  const sidebarRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [onClickOutside])

  const [isCollapse, setCollapse] = useState(false)

  const { authority } = UseLocalStorageContent()
  const { width } = useWindowDimensions()
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)

  const navigate = useNavigate()
  return (
    <>
      <CustomerSearchModal
        isSearchModalOpen={isSearchModalOpen}
        setIsSearchModalOpen={setIsSearchModalOpen}
        inputRef={sidebarRef}
      />
      <div
        ref={sidebarRef}
        style={
          width < 768
            ? { right: isSideBarOpen ? '0px' : '-230px', position: 'absolute' }
            : { position: 'relative' }
        }
        className={'z-20'}
      >
        <div
          style={
            isCollapse
              ? { width: '70px', minWidth: '70px' }
              : { width: '230px' }
          }
          className={`${
            !authority.includes('ADMIN_AUTHORITY')
              ? 'bg-gray-900'
              : 'hidden bg-orient-500'
          }  f-col h-full `}
        >
          <div className={'py-2 px-4'}>
            <div className={'f-col gap-1  '}>
              <div
                onClick={() => setIsSearchModalOpen(true)}
                className={
                  'flex cursor-pointer items-center justify-between rounded border border-orient-600 bg-orient-400 px-3 py-1.5 text-orient-200 shadow-sm  hover:text-orient-50'
                }
              >
                <FIconWrapper className={''}>
                  <span className={''}>بحث</span>
                  <BiSearchAlt className={''} />
                </FIconWrapper>
                <div className={' '}>
                  <span>CTRL +k</span>
                </div>
              </div>

              <br />
              <div
                style={{
                  minHeight: 'calc(100vh - 230px)',

                  maxHeight: '60px',
                  overflowY: 'auto',
                }}
                className={'overflow-y-auto  '}
              >
                {authority.includes('AGENT_AUTHORITY') && (
                  <FNavLink setIsSideBarOpen={setIsSideBarOpen} to={'/'}>
                    <TbSmartHome style={{ minWidth: '15px' }} size={25} />
                    <span
                      className={`text-xs font-bold ${
                        isCollapse ? 'hidden' : ''
                      }`}
                    >
                      {t('dashboard')}
                    </span>
                  </FNavLink>
                )}

                {authority.includes('ADMIN_AUTHORITY') && (
                  <>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={'/distribution'}
                    >
                      <AiOutlineSchedule
                        style={{ minWidth: '15px' }}
                        size={25}
                      />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        توزيع المكالمات
                      </span>
                    </FNavLink>
                    <FNavLink setIsSideBarOpen={setIsSideBarOpen} to={'/calls'}>
                      <BiPhoneCall style={{ minWidth: '15px' }} size={25} />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        المكالمات
                      </span>
                    </FNavLink>
                  </>
                )}

                {authority.includes('ADMIN_AUTHORITY') && (
                  <FNavLink
                    setIsSideBarOpen={setIsSideBarOpen}
                    to={getRoutePathByName('callsList')}
                  >
                    <AiOutlineHistory style={{ minWidth: '15px' }} size={25} />
                    <span
                      className={`text-xs font-bold ${
                        isCollapse ? 'hidden' : ''
                      }`}
                    >
                      سجل المكالمات
                    </span>
                  </FNavLink>
                )}

                <FNavLink setIsSideBarOpen={setIsSideBarOpen} to={'/customer'}>
                  <FaRegUser size={25} />
                  <span
                    className={`text-xs font-bold ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    العملاء
                  </span>
                </FNavLink>
                <FNavLink
                  setIsSideBarOpen={setIsSideBarOpen}
                  to={getRoutePathByName('subscriptionsList')}
                >
                  <RiMoneyDollarCircleLine size={25} />
                  <span
                    className={`text-xs font-bold ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    الأشتراكات
                  </span>
                </FNavLink>

                {authority.includes('ADMIN_AUTHORITY') && (
                  <>
                    <FNavLink setIsSideBarOpen={setIsSideBarOpen} to={'/claim'}>
                      <TbHandStop style={{ minWidth: '15px' }} size={25} />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        التعويضات
                      </span>
                    </FNavLink>{' '}
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={'/review'}
                    >
                      <FaRegEye style={{ minWidth: '15px' }} size={25} />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        مراجعة الاشتراكات
                      </span>
                    </FNavLink>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('listCallTypes')}
                    >
                      <BiPhoneCall style={{ minWidth: '15px' }} size={25} />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        انواع المكالمات
                      </span>
                    </FNavLink>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('listTasks')}
                    >
                      <FaTasks style={{ minWidth: '15px' }} size={25} />

                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        المهام
                      </span>
                    </FNavLink>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('OracleUpload')}
                    >
                      <HiOutlineUpload style={{ minWidth: '15px' }} size={25} />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        توريد البيانات
                      </span>
                    </FNavLink>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('reports')}
                    >
                      <TbReportAnalytics
                        style={{ minWidth: '15px' }}
                        size={25}
                      />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        التقارير
                      </span>
                    </FNavLink>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('reportsTemplates')}
                    >
                      <CgTemplate style={{ minWidth: '15px' }} size={25} />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        قوالب التقارير
                      </span>
                    </FNavLink>
                  </>
                )}
                {authority.includes('ADMIN_AUTHORITY') && (
                  <>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('stores')}
                    >
                      <HiOutlineBuildingStorefront
                        style={{ minWidth: '15px' }}
                        size={25}
                      />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        الوكلاء
                      </span>
                    </FNavLink>
                    <FNavLink
                      setIsSideBarOpen={setIsSideBarOpen}
                      to={getRoutePathByName('history')}
                    >
                      <AiOutlineHistory
                        style={{ minWidth: '15px' }}
                        size={25}
                      />
                      <span
                        className={`text-xs font-bold ${
                          isCollapse ? 'hidden' : ''
                        }`}
                      >
                        التقارير المحملة
                      </span>
                    </FNavLink>
                  </>
                )}

                <FNavLink
                  setIsSideBarOpen={setIsSideBarOpen}
                  to={getRoutePathByName('residuals')}
                >
                  <RiMoneyPoundCircleLine
                    style={{ minWidth: '15px' }}
                    size={25}
                  />
                  <span
                    className={`text-xs font-bold ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    المعلقات
                  </span>
                </FNavLink>

                {authority.includes('ADMIN_AUTHORITY') && (
                  <FNavLink setIsSideBarOpen={setIsSideBarOpen} to={'/agent'}>
                    <HiOutlineUserGroup
                      style={{ minWidth: '15px' }}
                      size={25}
                    />
                    <span
                      className={`text-xs font-bold ${
                        isCollapse ? 'hidden' : ''
                      }`}
                    >
                      فريق العمل{' '}
                    </span>
                  </FNavLink>
                )}
                <FNavLink setIsSideBarOpen={setIsSideBarOpen} to={'/product'}>
                  <HiOutlineNewspaper style={{ minWidth: '15px' }} size={25} />
                  <span
                    className={`text-xs font-bold ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    المنتجات
                  </span>
                </FNavLink>
                <FNavLink
                  setIsSideBarOpen={setIsSideBarOpen}
                  to={'/productCancelList'}
                >
                  <IoMdRemoveCircleOutline
                    style={{ minWidth: '15px' }}
                    size={25}
                  />
                  <span
                    className={`text-xs font-bold ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    إلغاء أشتراك المنتجات
                  </span>
                </FNavLink>

                {authority.includes('ADMIN_AUTHORITY') && (
                  <FNavLink
                    setIsSideBarOpen={setIsSideBarOpen}
                    to={getRoutePathByName('pending')}
                  >
                    <GoServer style={{ minWidth: '15px' }} size={25} />
                    <span
                      className={`text-xs font-bold ${
                        isCollapse ? 'hidden' : ''
                      }`}
                    >
                      تنزيلات الخادم
                    </span>
                  </FNavLink>
                )}
              </div>
            </div>
          </div>
          <div className={'mx-5 mt-5 flex w-full items-start  justify-start  '}>
            <FMenu>
              <div className={' flex    gap-2'}>
                <FAvatar name={name} />
                <div className={' '}>
                  <span
                    className={`block whitespace-nowrap text-left text-sm  text-white  ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    {name}
                  </span>
                  <span
                    className={`block text-left text-xs font-bold leading-tight text-white  ${
                      isCollapse ? 'hidden' : ''
                    }`}
                  >
                    {getAuthorities(
                      JSON.parse(localStorage.getItem('authority'))[0],
                    )}
                  </span>
                </div>
              </div>
            </FMenu>
          </div>

          <div
            onClick={() => setCollapse(!isCollapse)}
            className={
              'mt-auto cursor-pointer border-t border-t-orient-400 p-4 text-white'
            }
          >
            <FFlexWrapper>
              <HiChevronLeft
                className={`${isCollapse && '-rotate-180 transform'}`}
                size={25}
              />
              <span className={`text-sm ${isCollapse ? 'hidden' : ''}`}>
                Collapse
              </span>
            </FFlexWrapper>
          </div>
        </div>
      </div>
    </>
  )
}
export default SideBar
