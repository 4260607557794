import FAvatar from '../../components/FAvatar'

const Help = () => (
  <div className={'p-3'}>
    <div className={'flex items-center gap-2 p-3'}>
      <FAvatar name={'Mohamed Ismail'} />
      <div className={'flex flex-col'}>
        <span className={'text-lg'}>Mohamed Ismail</span>
        <div className={'flex gap-2'}>
          <span className={'text-lg leading-3'}>R45</span>
          <span className={'text-lg leading-3'}>16</span>
        </div>
      </div>
    </div>
    <div className={'flex gap-2'}>
      <div className={'w-4/12'}>
        <div
          className={
            'primary-shadow rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <span className={'text-lg font-semibold'}>Sales Target</span>
        </div>
      </div>
      <div className={'w-48/12'}>
        <div
          className={
            'primary-shadow rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <span>Merchants</span>
        </div>
      </div>
    </div>
  </div>
)
export default Help
