import SideBar from './SideBar'
import { SITE_LOGO } from '../../env'
import { HiMenu } from 'react-icons/hi'
import { ToastContainer } from 'react-toastify'
import React from 'react'
import FButton from '../FButton'
import MobileMenu from './MobileMenu'
import useThemeDetector from '../../Hooks/useThemeDetector'

const Footer = () => (
  <div
    className={
      'sticky bottom-0 mt-auto hidden items-center justify-between border-t bg-white p-3 dark:bg-gray-900 lg:flex'
    }
  >
    <span className={'text-xs font-medium  text-gray-500 dark:text-gray-100  '}>
      V.0.0.1
    </span>
    <img width={'65'} src={SITE_LOGO} />
    <span
      className={'text-xs font-medium  text-gray-500 dark:text-gray-100   '}
    >
      Contact us
    </span>
  </div>
)
const Layout = props => {
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(false)
  const isDarkMode = useThemeDetector()

  const noPaddingRoutes = [
    '/settings',
    '/settings/details',
    '/settings/security',
    '/customers',
    '/customers/details/',
  ]
  const checkNoPaddingRoutes = route =>
    noPaddingRoutes.filter(singleRoute => singleRoute.includes(route))
      .length === 0

  /* useEffect(() => {
    axiosInstance.get("http://localhost:3000/getSidebarColor").then((res) => {
      console.log(res.data)
    })
  }, [])*/
  let isCollapse = false
  return (
    <div>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div
        style={{
          backgroundColor: isDarkMode ? '#ffffff' : '#ffffff',
        }}
        className='flex max-h-screen min-h-screen w-full flex-grow flex-col bg-orient-500   sm:flex-row'
      >
        {isSideBarOpen && (
          <div
            className={'sidebar-overlay absolute z-20 h-screen w-screen'}
          ></div>
        )}

        <SideBar
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
          onClickOutside={() => setIsSideBarOpen(false)}
        />
        <main
          role='main'
          className={`flex  max-h-screen min-h-screen w-full flex-col overflow-y-auto ${
            isSideBarOpen && 'blur-xs'
          }`}
        >
          {/*<div className={`sidebar-separator ${props.sidebar_status === false ?"hidden":""}`}> </div>*/}
          {/*<Header className={""}/>*/}
          <div className={'flex grow flex-col'}>
            <div
              className={
                'sticky top-0  z-10 flex hidden flex-row-reverse items-center  justify-between border border-b  bg-gray-50 p-3  dark:bg-gray-700'
              }
            >
              <div className={'hidden'}>
                {/*<FPopOver
                  button={
                    <div
                      className={
                        "flex h-9 w-9 items-center justify-center rounded-full  border border-orient-700 bg-orient-500 text-white"
                      }
                    >
                      <HiPlusCircle size={20} />
                    </div>
                  }
                />*/}
              </div>
              <FButton
                className={'block lg:hidden'}
                onClick={() => setIsSideBarOpen(true)}
              >
                <HiMenu size={25} />
              </FButton>
            </div>
            <div className={'md:p5 h-full grow p-0'}>{props.children}</div>
            {/**/}
          </div>
          <Footer />
          <MobileMenu />
        </main>
      </div>
    </div>
  )
}
export default Layout
