const FInputField = ({
  onChange,
  value,
  name,
  type,
  className,
  ref,
  ...props
}) => (
  <>
    <input
      ref={ref}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className={`${className} input-style`}
      // autoComplete={'off'}
      {...props}
    />
  </>
)
export default FInputField
