import FModal from '../../../components/FModal'
import React from 'react'
import { useFormik } from 'formik'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FRow from '../../../components/FRow'
import FButton from '../../../components/FButton'
import { logFormData } from '../../../helpers/utils'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'

const ElectronicResponseModal = ({ isOpen, setIsOpen }) => {
  const [isUploading, setIsUploading] = React.useState(false)
  const handleOracleUploadFile = values => {
    setIsUploading(true)

    const formData = new FormData()
    formData.append('file', values.file)
    logFormData(formData)
    axiosInstance
      .post('report/gigExtractionResponseFileForCover', formData, {
        responseType: 'blob', // Set the response type to blob
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        const modifiedFile = response.data

        // Create a URL for the modifiedFile blob
        const fileUrl = URL.createObjectURL(modifiedFile)

        // Create a temporary anchor element
        const downloadLink = document.createElement('a')
        downloadLink.href = fileUrl

        const fileName = `تاكيد رفع الملحق بتاريخ ${new Date().toLocaleDateString()}.xlsx`
        downloadLink.setAttribute('download', fileName) // Set the desired file name with .xlsx extension

        // Append the anchor element to the document body
        document.body.appendChild(downloadLink)

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        // Clean up by removing the anchor element and revoking the URL
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(fileUrl)
        setFileUploadResponse(e.data.list)
        setIsUploading(false)
        toast.success('تم رفع الملف بنجاح')
      })
      .catch(err => {
        setIsUploading(false)
        console.log(err)
      })
  }

  const Formik = useFormik({
    initialValues: {
      file: '',
    },
    onSubmit: values => {
      handleOracleUploadFile(values)
    },
    validate: value => {
      const errors = {}
      if (!value.file) {
        errors.file = 'File name is required'
      }

      // Check if the value contains a date in "dd-mm-yyyy" format and the word "oracle"
      const regex = /^(?=.*\d{1,2}-\d{1,2}-\d{4})(?=.*اصدار الكتروني).*$/
      if (!regex.test(value.file.name)) {
        errors.file =
          'يجب ان يحتوي اسم الملف علي تاريخ بصيخة dd-mm-yyyy و كلمة اصدار الكتروني'
      }
      return errors
    },
  })

  return (
    <>
      <FModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'رفع الرد الإلكتروني'}
      >
        <form onSubmit={Formik.handleSubmit} noValidate>
          <EFormWrapper>
            <FLabel htmlFor={'file'}>الملف</FLabel>
            <FInputField
              type='file'
              id='file'
              name='file'
              onChange={event => {
                Formik.setFieldValue('file', event.currentTarget.files[0])
              }}
              onBlur={Formik.handleBlur}
            />
            {Formik.errors.file && Formik.touched.file && (
              <div className='mt-1 text-xs text-red-500'>
                {Formik.errors.file}
              </div>
            )}
          </EFormWrapper>
          <FRow>
            <FButton type='submit'>
              <FIconWrapper>
                <ESpinner isVisible={isUploading} />
                رفع
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              type={'button'}
              onClick={() => setIsOpen(false)}
            >
              إلغاء
            </FButton>
          </FRow>
        </form>
      </FModal>
    </>
  )
}
export default ElectronicResponseModal
