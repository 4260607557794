import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCustomAxios } from '../../../Hooks/useAxios'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import xlsx from '../../../assets/images/xls.png'
import FAvatar from '../../../components/FAvatar'
import FCol from '../../../components/FCol'
import moment from 'moment'
import 'moment/locale/ar'

moment.locale('en')
const ListHistory = () => {
  const [page, setPage] = useState(0)
  const [allFilters, setAllFilters] = React.useState([])
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])
  const handleFilterParams = () => {
    let query = ''
    filterOptions.map(option => {
      if (option.key !== '') {
        query += `&${option.key}=${option.value}`
      }
    })
    return query
  }
  let navigate = useNavigate()
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/report/listAllExportedReports?page=${page}&size=20${handleFilterParams()}`,
    })

  useEffect(() => {
    Refetch()
  }, [page])

  const handleAddProduct = () => navigate('/product/add')

  const cols = [
    {
      Header: 'الاسم',
      accessor: 'fileName', // String-based value accessors!
      cell: (_, row) => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return (
          <a
            target={'_blank'}
            className={'flex items-center gap-1 underline'}
            href={row.uri}
          >
            <img src={xlsx} width={30} />
            <FCol>
              {row.fileName}
              {row.sizeInBytes > 0 && (
                <span className={'text-xs text-gray-500'}>
                  {row.sizeInBytes} MB
                </span>
              )}
            </FCol>
          </a>
        )
      },
    },
    {
      Header: 'تاريخ التنزيل',
      accessor: 'exportedAt', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        //check if difference between now and the date is less than 24 hours

        return (
          <span>
            {moment().subtract(1, 'days').isBefore(moment(value))
              ? moment(value).locale('ar').fromNow()
              : moment(value).locale('ar').format('dddd, Do MMMM , h:mm:ss a')}
          </span>
        )
      },
    },
    {
      Header: 'المستخدم',
      accessor: 'exporteBy', // String-based value accessors!
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return (
          <div className={'flex items-center gap-2'}>
            <FAvatar width={30} className={'rounded-full'} name={value.name} />
            <div className={'flex flex-col'}>
              <span className={'block font-semibold'}> {value.name}</span>
              <span className={'text-xs leading-3'}> {value.email}</span>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <div className={'f-row-between  flex items-center p-3'}>
        <span className={'text-xl font-medium'}>التقارير المحملة</span>
      </div>
      <FCustomDataTable
        cols={cols}
        data={response ? response.records : []}
        isLoading={loading}
        page={page}
        setPage={setPage}
        size={2}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        totalNumberOfPages={totalNumberOfPages}
        Refetch={Refetch}
      />
    </>
  )
}
export default ListHistory
