const TableLoader = () => (
  <>
    <div>
      <div className=' w-ful animate-pulse border-blue-300  p-10 '>
        <div className=' flex space-x-4'>
          <div className='flex-1 space-y-6 py-1'>
            <div className='h-5 rounded bg-slate-200'></div>
            <div className='space-y-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='col-span-2 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
              </div>
              <div className='h-5 rounded bg-slate-200'></div>
            </div>
            <div className='space-y-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='col-span-2 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
              </div>
              <div className='h-5 rounded bg-slate-200'></div>
            </div>
            <div className='space-y-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='col-span-2 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
              </div>
              <div className='h-5 rounded bg-slate-200'></div>
            </div>
            <div className='space-y-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='col-span-2 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
                <div className='col-span-1 h-5 rounded bg-slate-200'></div>
              </div>
              <div className='h-5 rounded bg-slate-200'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)
export default TableLoader
