import FButton from '../../../components/FButton'
import React from 'react'
import FModal from '../../../components/FModal'
import FRow from '../../../components/FRow'
import FLabel from '../../../components/FLabel'
import EFormWrapper from '../../../components/EFormWrapper'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FInputField from '../../../components/FInputField'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import FAvatar from '../../../components/FAvatar'
import { getProductById } from '../../../helpers/utils'

const ClaimReport = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const statusOptions = [
    { value: 'claimConfirmed', label: 'موافق' },
    { value: 'claimExpired', label: 'منتهي الصلاحية' },
    { value: 'claimRejected', label: 'مرفوض' },
    { value: 'claimPending', label: 'قيد الانتظار' },
  ]
  const Formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      lastUpdateStartDate: '',
      lastUpdateEndDate: '',
      status: '',
      productId: '',
      forwardReceipt: [],
    },
    validationSchema: Yup.object({
      startDate: Yup.string().required('تاريخ البداية مطلوب'),
      endDate: Yup.string()
        .required('تاريخ النهاية مطلوب')
        .test(
          'is-greater',
          'يجب أن يكون تاريخ النهاية أكبر من  أو يساوي تاريخ البداية',
          function (value) {
            return new Date(value) >= new Date(this.parent.startDate)
          },
        ),
      status: Yup.string().required('حالة المطالبة مطلوبة'),
    }),
    onSubmit: values => {
      axiosInstance
        .get('Compensation/downloadReport', {
          params: {
            fromCreationDate: new Date(values.startDate).toISOString(),
            toCreationDate: new Date(values.endDate).toISOString(),
            productId: values.productId,
            ...(values.status === 'claimConfirmed'
              ? { claimConfirmed: true }
              : { claimConfirmed: false }),
            ...(values.status === 'claimExpired'
              ? { claimExpired: true }
              : { claimExpired: false }),
            ...(values.status === 'claimRejected'
              ? { claimRejected: true }
              : { claimRejected: false }),
          },
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url

          const fileNameArrayConstructor = [
            'مطالبات من',
            new Date(values.startDate).toLocaleDateString('ar-EG'),
            'الى',
            new Date(values.endDate).toLocaleDateString('ar-EG'),
          ]
          if (values.productId) {
            fileNameArrayConstructor.push('للمنتج')
            fileNameArrayConstructor.push(getProductById(values.productId).name)
          }

          link.setAttribute(
            'download',
            fileNameArrayConstructor.join(' ') + '.xlsx',
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          toast.error('something went wrong')
        })
    },
  })

  const Dividers = () => {
    return (
      <div className='relative'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-300' />
        </div>
        <div className='relative flex justify-center'>
          <span className='bg-gray-50 px-2 text-sm text-gray-500'>أو </span>
        </div>
      </div>
    )
  }

  const animatedComponents = makeAnimated()

  return (
    <>
      <FModal isOpen={isOpen} setIsOpen={setIsOpen} title={'تقرير المطالبات'}>
        <form onSubmit={Formik.handleSubmit}>
          <div className={'rounded-lg border bg-gray-50 p-3'}>
            <div className={'grid grid-cols-2 gap-2'}>
              <EFormWrapper>
                <FLabel htmlFor={'startDate'}>
                  تاريخ البداية <span className={'text-red-500'}>*</span>
                </FLabel>
                <FInputField
                  max={new Date().toISOString().split('T')[0]}
                  type={'date'}
                  name={'startDate'}
                  id={'startDate'}
                  value={Formik.values.startDate}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                <EFormInvalidInput
                  errors={Formik.errors}
                  touched={Formik.touched}
                  FieldName={'startDate'}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={'endDate'}>
                  تاريخ النهاية <span className={'text-red-500'}>*</span>
                </FLabel>
                <FInputField
                  max={new Date().toISOString().split('T')[0]}
                  type={'date'}
                  name={'endDate'}
                  id={'endDate'}
                  value={Formik.values.endDate}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                <EFormInvalidInput
                  errors={Formik.errors}
                  touched={Formik.touched}
                  FieldName={'endDate'}
                />
              </EFormWrapper>
            </div>
            <Dividers />
            <div className={'grid grid-cols-2 gap-2'}>
              <EFormWrapper>
                <FLabel htmlFor={'lastUpdateStartDate'}>تاريخ اخر تحديث</FLabel>
                <FInputField
                  max={new Date().toISOString().split('T')[0]}
                  type={'date'}
                  name={'lastUpdateStartDate'}
                  id={'lastUpdateStartDate'}
                  value={Formik.values.lastUpdateStartDate}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                <EFormInvalidInput
                  errors={Formik.errors}
                  touched={Formik.touched}
                  FieldName={'lastUpdateStartDate'}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={'lastUpdateEndDate'}>تاريخ اخر تحديث</FLabel>
                <FInputField
                  max={new Date().toISOString().split('T')[0]}
                  type={'date'}
                  name={'lastUpdateEndDate'}
                  id={'lastUpdateEndDate'}
                  value={Formik.values.lastUpdateEndDate}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                />
                <EFormInvalidInput
                  errors={Formik.errors}
                  touched={Formik.touched}
                  FieldName={'lastUpdateEndDate'}
                />
              </EFormWrapper>
            </div>
          </div>

          <EFormWrapper>
            <FLabel>
              حالة المطالبة <span className={'text-red-500'}>*</span>
            </FLabel>
            <select
              name={'status'}
              value={Formik.values.status}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              className={'input-style'}
            >
              <option value={''}>اختر حالة المطالبة</option>
              {statusOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <EFormInvalidInput
              errors={Formik.errors}
              touched={Formik.touched}
              FieldName={'status'}
            />
          </EFormWrapper>

          <EFormWrapper>
            <FLabel>مشاركةالتقرير عن طريق البريد الالكتروني مع </FLabel>
            <Select
              components={{
                MultiValueContainer: ({ children, innerRef, innerProps }) => (
                  <div
                    className={'border bg-red-600'}
                    ref={innerRef}
                    {...innerProps}
                  >
                    {children}
                  </div>
                ),
                MultiValue: ({ children, data, innerProps, innerRef }) => (
                  <div
                    ref={innerRef}
                    {...innerProps}
                    className={'flex items-center'}
                  >
                    <FRow
                      className={
                        'm-0.5  rounded-lg border border-gray-300 p-1 shadow-sm'
                      }
                    >
                      <FAvatar width={30} name={data.label} />
                      <div className={'flex flex-col'}>
                        <small className={'text-sm'}>{data.meta.name}</small>
                        <small className={'text-xs leading-3'}>
                          {data.meta.email}
                        </small>
                      </div>
                    </FRow>
                  </div>
                ),
                Option: ({
                  children,
                  innerRef,
                  innerProps,
                  data,
                  ...props
                }) => (
                  <div ref={innerRef} {...innerProps} {...props}>
                    <FRow
                      className={'my-0.5 cursor-pointer p-1 hover:bg-gray-50'}
                    >
                      <FAvatar width={30} name={data.label} />
                      <div className={'flex flex-col'}>
                        <small className={'text-sm'}>{data.meta.name}</small>
                        <small className={'text-xs leading-3'}>
                          {data.meta.email}
                        </small>
                      </div>
                    </FRow>
                  </div>
                ),
              }}
              onBlur={Formik.handleBlur}
              placeholder={'المستندات المراد ارسالها'}
              isMulti={true}
              name={'forwardReceipt'}
              options={JSON.parse(localStorage.getItem('users')).map(user => ({
                value: user.name,
                label: user.email,
                meta: user,
              }))}
              value={Formik.values.forwardReceipt}
              onChange={selectedOption => {
                Formik.setFieldValue('forwardReceipt', selectedOption)
              }}
            />
          </EFormWrapper>
          <EFormWrapper>
            <FLabel>المنتج</FLabel>
            <select
              name={'productId'}
              value={Formik.values.productId}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              className={'input-style'}
            >
              <option>اختر المنتج</option>
              {JSON.parse(localStorage.getItem('products')).map(
                (product, index) => (
                  <option key={index} value={product.id}>
                    {product.name}
                  </option>
                ),
              )}
            </select>
            <EFormInvalidInput
              errors={Formik.errors}
              touched={Formik.touched}
              FieldName={'productId'}
            />
          </EFormWrapper>
          <FRow className={'mt-2'}>
            <FButton onClick={Formik.handleSubmit} type={'submit'}>
              تحميل التقرير
            </FButton>

            <FButton
              type={'button'}
              btnType={'secondary'}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              غلق
            </FButton>
          </FRow>
        </form>
      </FModal>
      <FButton type={'button'} onClick={() => setIsOpen(true)}>
        تقرير المطالبات
      </FButton>
    </>
  )
}
export default ClaimReport
