import React from 'react'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { FiMinus, FiPlus } from 'react-icons/fi'

const ProductCompletionTrails = ({ trailsBlock, setTrailsBlock }) => {
  const handSetTrailsBlock = (e, index, trailIndex = null) => {
    let { name, value } = e.target
    let newTrailsBlock = Object.assign({}, trailsBlock)
    if (name === 'trialCount') {
      let trail = Object.assign({}, { trailTime: '' })
      let array = new Array(parseInt(value)).fill(trail)
      newTrailsBlock.trails[index]['trialTimes'] = array
    }
    if (name === 'trialTimes' && trailIndex !== null) {
      let items = [...newTrailsBlock.trails[index]['trialTimes']]
      let item = { ...items[trailIndex] }
      item['trailTime'] = value
      items[trailIndex] = item
      newTrailsBlock.trails[index]['trialTimes'][trailIndex] = item
    }
    if (name !== 'trialTimes') {
      newTrailsBlock.trails[index][name] = value
    }
    setTrailsBlock(newTrailsBlock)
  }
  const handleAddExtraBlock = () => {
    let newTrailsBlock = { ...trailsBlock }
    newTrailsBlock.trails.push({
      trialCount: '',
      trialGap: '',
      trialTimes: [],
    })
    setTrailsBlock(newTrailsBlock)
  }
  const handleRemoveExtraBlock = () => {
    let newTrailsBlock = { ...trailsBlock }
    newTrailsBlock.trails.pop()
    setTrailsBlock(newTrailsBlock)
  }

  return (
    <>
      <span className={'my-5 block font-medium'}>
        محاولات إكمال بيانات المنتج
      </span>
      {trailsBlock.trails.map((item, index) => (
        <div className={'mb-5 flex items-end justify-end gap-5'} key={index}>
          <div
            className={
              'grid flex-grow grid-cols-2 gap-5   rounded border  bg-gray-100 px-3 dark:bg-gray-600  '
            }
          >
            <SFormWrapper className={'w-full'}>
              <FLabel htmlFor={`trialCount${item}`}> عدد المرات </FLabel>
              <FInputField
                type='number'
                min={1}
                max={20}
                id={`trialCount${item}`}
                name={'trialCount'}
                value={item.trialCount}
                onChange={e => handSetTrailsBlock(e, index)}
                placeholder={'أدخل عدد المرات'}
              />
            </SFormWrapper>
            <SFormWrapper className={'w-full'}>
              <FLabel htmlFor={'trialGap'}> الأيام من تاريخ السحب </FLabel>
              <FInputField
                type='text'
                name={'trialGap'}
                id={'trialGap'}
                value={item.trialGap}
                onChange={e => handSetTrailsBlock(e, index)}
                placeholder={'أدخل الأيام من تاريخ السحب'}
              />
            </SFormWrapper>
            <div className={'col-span-2 grid grid-cols-3 gap-2'}>
              {item.trialTimes.map((time, trialIndex) => (
                <div className={'w-full'} key={trialIndex}>
                  <SFormWrapper className={'w-full'}>
                    <FLabel htmlFor={`trialTime_${index}_${trialIndex}`}>
                      {` توقيت المكلمة رقم (${trialIndex + 1})`}{' '}
                    </FLabel>
                    <FInputField
                      type='time'
                      name={'trialTimes'}
                      id={`trialTime_${index}_${trialIndex}`}
                      value={time.trailTime}
                      onChange={e => handSetTrailsBlock(e, index, trialIndex)}
                      placeholder={'أدخل الوقت'}
                    />
                  </SFormWrapper>
                </div>
              ))}
            </div>
          </div>
          <div className={'flex gap-1'}>
            {
              <FButton type={'button'} onClick={() => handleAddExtraBlock()}>
                <FiPlus size={20} />
              </FButton>
            }
            {index !== trailsBlock.trails.length - 1 && (
              <FButton type={'button'} onClick={() => handleRemoveExtraBlock()}>
                <FiMinus size={20} />
              </FButton>
            )}
          </div>
        </div>
      ))}
    </>
  )
}
export default ProductCompletionTrails
