import FModal from '../../../components/FModal'
import FInfo from '../../../components/FInfo'
import { Formik } from 'formik'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import { t } from 'i18next'
import FInputField from '../../../components/FInputField'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import FInvalidServerValidation from '../../../components/FInvalidServerValidation'
import FButton from '../../../components/FButton'
import ESpinner from '../../../components/ESpinner'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import FIconWrapper from '../../../components/FIconWrapper'

const AgentInvitation = ({
  isAgentInviteModalOpen,
  setIsAgentInviteModalOpen,
  Refetch,
}) => {
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [isInvalidServerValidation, setIsInvalidServerValidation] = useState('')
  const [invitationResponse, setInvitationResponse] = useState(null)
  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('البريد الإلكتورني مطلوب')
      .email('البريد الإلكتروني غير صحيح'),
    role: Yup.string().required('الدور الوظيفي مطلوب'),
  })
  const callback = () => {
    setIsInvalidServerValidation('')
    setInvitationResponse(null)
  }
  const handleRequestBody = values => {
    const requestBody = {
      email: values.email.toLowerCase(),
      authorities: [values.role],
    }
    return requestBody
  }
  const handleFormSubmission = values => {
    setIsInvalidServerValidation('')
    setIsRequestLoading(true)
    axiosInstance
      .post('user/inviteNewUser', handleRequestBody(values))
      .then(response => {
        setIsRequestLoading(false)

        toast.success('تم إرسال الطلب بنجاح')
        setInvitationResponse(response.data)
        Refetch()
      })
      .catch(error => {
        setIsRequestLoading(false)
        setIsInvalidServerValidation(`${error.response.data.message}`)
      })
  }
  return (
    <>
      <FModal
        callbackFunction={callback}
        isOpen={isAgentInviteModalOpen}
        setIsOpen={setIsAgentInviteModalOpen}
        title={'دعوة  مستخدم جديد'}
      >
        <FInfo
          message={
            'سوف يتم إرسال بريد الكرتوني للمستخدم الجديد لتفعيل الحساب و إكمال البيانات الشخصية'
          }
        />
        <Formik
          validationSchema={ValidationSchema}
          initialValues={{
            email: '',
            role: '',
          }}
          onSubmit={handleFormSubmission}
        >
          {({
            handleSubmit,
            handleBlur,
            values,
            errors,
            touched,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={'responsive-wrapping flex gap-2 '}>
                <SFormWrapper className={'w-full lg:w-8/12'}>
                  <FLabel htmlFor={'email'}> {t('email')} </FLabel>
                  <FInputField
                    type='text'
                    name={'email'}
                    id={'email'}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'أدخل البريد الإلكتروني'}
                  />
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'email'}
                  />
                </SFormWrapper>
                <SFormWrapper className={'w-full lg:w-4/12'}>
                  <FLabel htmlFor={'role'}>الدور الوظيفي </FLabel>
                  <select
                    className={
                      'rounded  border border-gray-300 py-1.5  text-sm'
                    }
                    id={'role'}
                    name={'role'}
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={''}> أختر الدور الظيفي</option>
                    <option value={'ADMIN_AUTHORITY'}> مدير</option>
                    <option value={'AGENT_AUTHORITY'}> موظف خدمة عملاء</option>
                  </select>
                  <EFormInvalidInput
                    touched={touched}
                    errors={errors}
                    FieldName={'role'}
                  />
                </SFormWrapper>
              </div>
              <FInvalidServerValidation
                InvalidServerValidation={isInvalidServerValidation}
              />
              {invitationResponse?.['emailParagraphs']?.[3] && (
                <>
                  <p
                    className={
                      'my-2 rounded border border-green-400 bg-green-50 p-1 text-right text-sm'
                    }
                  >
                    قم باراسل الرابط التالي للمستخدم الجديد لتفعيل الحساب و
                    إكمال البيانات الشخصية
                  </p>
                  <span
                    className={'text-sm leading-3 text-green-700 underline'}
                  >
                    {invitationResponse?.['emailParagraphs']?.[3]}
                  </span>
                </>
              )}
              <div className={'flex gap-2'}>
                <FButton type={'submit'}>
                  <FIconWrapper>
                    <ESpinner isVisible={isRequestLoading} />
                    دعوة
                  </FIconWrapper>
                </FButton>
                <FButton
                  btnType={'secondary'}
                  type={'button'}
                  onClick={() => setIsAgentInviteModalOpen(false)}
                >
                  إلغاء
                </FButton>
              </div>
            </form>
          )}
        </Formik>
      </FModal>
    </>
  )
}
export default AgentInvitation
