import FModal from '../../../components/FModal'
import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import React from 'react'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const CancelSubModal = ({ setIsCancelModalOpen, isCancelModalOpen, id }) => {
  const handleCancelSubscription = values => {
    const data = {
      cancellationReason: values.cancellationReason,
      cancellationNotes: values.cancellationNotes,
    }
    axiosInstance
      .delete(`/customersubscription/${id}`, {
        data: data,
      })
      .then(() => {
        setIsCancelModalOpen(false)
        toast.success('تم الغاء الاشتراك بنجاح')
        Refetch()
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const Formik = useFormik({
    initialValues: {
      cancellationReason: 'العميـل لا يرغب في الاستكمال',
      cancellationNotes: '',
    },
    onSubmit: values => {
      handleCancelSubscription(values)
    },
  })

  return (
    <>
      <FModal
        setIsOpen={setIsCancelModalOpen}
        isOpen={isCancelModalOpen}
        title={'الغاء الأشتراك'}
      >
        <form onSubmit={Formik.handleSubmit}>
          <EFormWrapper>
            <FLabel htmlFor={'cancellationReason'}>سبب الالغاء</FLabel>
            <FInputField
              type={'text'}
              name={'cancellationReason'}
              onChange={Formik.handleChange}
              value={Formik.values.cancellationReason}
              id={'cancellationReason'}
            />
          </EFormWrapper>
          <EFormWrapper>
            <FLabel htmlFor={'cancellationNotes'}>ملاحظات</FLabel>
            <textarea
              className={'input-style'}
              name={'cancellationNotes'}
              onChange={Formik.handleChange}
              value={Formik.values.cancellationNotes}
              id={'cancellationNotes'}
            />
          </EFormWrapper>

          <div className={'mt-5 flex gap-2'}>
            <FButton type={'submit'} btnType={'danger'}>
              الغاء الأشتراك
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                {
                  setCancelRequestPayload({
                    reason: '',
                    note: '',
                  })
                  setIsCancelModalOpen(false)
                }
              }}
            >
              الغاء
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default CancelSubModal
