import FModal from '../../../components/FModal'
import React from 'react'
import FButton from '../../../components/FButton'
import FRow from '../../../components/FRow'

const RejectModal = ({ isOpen, setIsOpen, Form, refetch }) => {
  return (
    <>
      <FModal title={'رفض التعويض'} isOpen={isOpen} setIsOpen={setIsOpen}>
        <textarea
          className={'input-style'}
          name={'claimRejectReason'}
          value={Form.values.claimRejectReason}
          onChange={Form.handleChange}
          onBlur={Form.handleBlur}
          placeholder={'سبب الرفض'}
        />
        <FRow className={'mt-4'}>
          <FButton
            type={'button'}
            onClick={() => {
              Form.setFieldValue('claimConfirmed', null)
              Form.setFieldValue('claimExpired', null)
              Form.setFieldValue('claimRejected', true)
              Form.handleSubmit()
              setIsOpen(false)
            }}
          >
            حفظ الرفض
          </FButton>
          <FButton onClick={() => setIsOpen(false)} btnType={'secondary'}>
            الغاء
          </FButton>
        </FRow>
      </FModal>
    </>
  )
}
export default RejectModal
