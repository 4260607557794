import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import { FiMinus, FiPlus } from 'react-icons/fi'
import React, { useState } from 'react'
import ProductTrades from './ProductTrades'

const productPackages = ({ productDetails, setProductDetails }) => {
  const [tradeInfo, setTradeInfo] = useState([
    {
      tradeName: '',
      gigProductName: '',
    },
  ])

  const handleProductDetailsChange = (e, index) => {
    const { name, value } = e.target
    const values = [...productDetails]
    values[index][name].value = value
    setProductDetails(values)
  }

  const handleAddExtraBlock = () => {
    let newProductDetails = [...productDetails]
    newProductDetails.push({
      installmentPrice: {
        value: '',
        errors: '',
      },
      installmentDetails: {
        value: '',
        errors: '',
      },
      installmentDuration: {
        value: '',
        errors: '',
      },
      installmentCoverage: {
        value: '',
        errors: '',
      },
      installmentCommission: {
        value: '',
        errors: '',
      },
    })
    setProductDetails(newProductDetails)
  }
  const handleRemoveExtraBlock = index => {
    let newProductDetails = [...productDetails]
    newProductDetails.splice(index, 1)
    setProductDetails(newProductDetails)
  }

  return (
    <div>
      <span className={'my-3 block'}>باقات المنتج</span>
      {productDetails.map((item, index) => (
        <div key={index} className={'flex items-end justify-end gap-2  '}>
          <div
            className={
              'mb-2 flex grid flex-grow grid-cols-4 gap-5 rounded border  bg-gray-100 px-3 dark:bg-gray-600'
            }
          >
            <SFormWrapper className={'w-full '}>
              <FLabel htmlFor={'installmentPrice'}> تكلفة القسط </FLabel>
              <FInputField
                type='text'
                name={'installmentPrice'}
                id={'installmentPrice'}
                value={item.installmentPrice?.value || ''}
                onChange={e => handleProductDetailsChange(e, index)}
                placeholder={'أدخل تكلفة القسط'}
              />
            </SFormWrapper>
            <SFormWrapper className={'w-full '}>
              <FLabel htmlFor={`installmentDetails${index}`}>
                تفاصيل القسط
              </FLabel>
              <FInputField
                type='text'
                name={'installmentDetails'}
                id={`installmentDetails${index}`}
                value={item.installmentDetails?.value || ''}
                onChange={e => handleProductDetailsChange(e, index)}
                placeholder={'أدخل تفاصيل القسط'}
              />
            </SFormWrapper>
            <SFormWrapper className={'w-full '}>
              <FLabel htmlFor={`installmentDuration${index}`}>مدة القسط</FLabel>
              <select
                className={'input-style'}
                name={'installmentDuration'}
                id={`installmentDuration${index}`}
                value={item.installmentDuration?.value || ''}
                onChange={e => handleProductDetailsChange(e, index)}
              >
                <option value={''}>اختر مدة القسط</option>
                <option value={'MONTHLY'}>شهري</option>
                <option value={'QUARTERLY'}>ربع سنوي</option>
                <option value={'BIANNUAL'}>نص سنوي</option>
                <option value={'ANNUAL'}>سنوي</option>
              </select>
            </SFormWrapper>
            <SFormWrapper className={'w-full '}>
              <FLabel htmlFor={`installmentCoverage${index}`}>
                التغطية التأمينية
              </FLabel>
              <FInputField
                type='text'
                id={`installmentCoverage${index}`}
                name={'installmentCoverage'}
                value={item.installmentCoverage?.value || ''}
                onChange={e => handleProductDetailsChange(e, index)}
                placeholder={'أدخل التغطية التأمينية'}
              />
            </SFormWrapper>
            <SFormWrapper className={'w-full '}>
              <FLabel htmlFor={`installmentCommission${index}`}>
                قيمة العمولة
              </FLabel>
              <FInputField
                type='text'
                id={`installmentCommission${index}`}
                name={'installmentCommission'}
                value={item.installmentCommission?.value || ''}
                onChange={e => handleProductDetailsChange(e, index)}
                placeholder={'أدخل قيمة العمولة'}
              />
            </SFormWrapper>
            <div className={'col-span-3'}>
              <ProductTrades
                setTradeInfo={setTradeInfo}
                tradeInfo={tradeInfo}
              />
            </div>
          </div>
          <div className={' flex gap-1 py-3'}>
            {
              <FButton type={'button'} onClick={() => handleAddExtraBlock()}>
                <FiPlus size={20} />
              </FButton>
            }
            {index !== productDetails.length - 1 && (
              <FButton type={'button'} onClick={() => handleRemoveExtraBlock()}>
                <FiMinus size={20} />
              </FButton>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
export default productPackages
