import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import moment from 'moment'
import { useEffect, useState } from 'react'
import FInputField from '../../../components/FInputField'

const Dashboard = () => {
  const [from, setFrom] = useState(moment().subtract(1, 'days').toISOString())
  const [to, setTo] = useState(moment().toISOString())
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['dashboard'],
    queryFn: async () => {
      const res = await axiosInstance.get('Stats/findAllDailyStats', {
        params: {
          fromDate: moment(from).toISOString(),
          toDate: moment(to).toISOString(),
        },
      })
      return res.data
    },
  })

  useEffect(() => {
    refetch().then(r => console.log(r))
  }, [from, to])

  if (isLoading) return <div>Loading...</div>
  return (
    <div className={'p-3'}>
      <div className={'grid grid-cols-2 gap-2'}>
        <FInputField
          type='date'
          value={from}
          onChange={e => setFrom(e.target.value)}
        />
        <FInputField
          type='date'
          value={to}
          onChange={e => setTo(e.target.value)}
        />
      </div>
      <span>
        {moment(from).format('YYYY-MM-DD')} - {moment(to).format('YYYY-MM-DD')}
      </span>
      <div className={'grid grid-cols-2 gap-2'}>
        {data.results.map((item, index) => {
          return (
            <div
              key={index}
              className={
                'flex flex-col gap-2 justify-center  items-center border shadow-sm border-gray-300'
              }
            >
              <h1>{item.arabicKey}</h1>
              <h1>{item.value || 0}</h1>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Dashboard
