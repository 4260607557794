import React, { useState } from 'react'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import * as Yup from 'yup'
import ProductCompletionTrails from './ProductCompletionTrails'
import AgentDetails from './AgentDetails'
import { BasicInfoValidationSchema } from './ProductValidation'
import { put } from '../../../helpers/utils'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ESpinner from '../../../components/ESpinner'
import FIconWrapper from '../../../components/FIconWrapper'
import ProductPackages from './ProductPackages'

const ProductAdd = () => {
  const navigation = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [tradeInfo, setTradeInfo] = useState([
    {
      tradeName: '',
      gigProductName: '',
    },
  ])

  const [trailsBlock, setTrailsBlock] = useState({
    trails: [
      {
        trialCount: '',
        trialGap: '',
        trialTimes: [],
      },
    ],
  })
  const [productDetails, setProductDetails] = useState([
    {
      installmentPrice: {
        value: '',
        errors: '',
      },
      installmentDetails: {
        value: '',
        errors: '',
      },
      installmentDuration: {
        value: '',
        errors: '',
      },
      installmentCoverage: {
        value: '',
        errors: '',
      },
      installmentCommission: {
        value: '',
        errors: '',
      },
    },
  ])
  const [agentInfoPackage, setAgentInfoPackage] = useState({
    agentPackage: [
      {
        filedName: '',
        reportFiledName: '',
        isNullable: false,
        includeNumberOnly: false,
        includeTextOnly: false,
        isLengthRequired: false,
        minLength: 0,
        maxLength: 0,
      },
    ],
  })
  const [__productBasicInfo, __setProductBasicInfo] = useState({
    productBasicInfo: {
      productName: {
        value: '',
        errors: '',
      },
      productTradeName: {
        value: '',
        errors: '',
      },
      productBTC: {
        value: '',
        errors: '',
      },
    },
  })
  let productDetailsSchema = Yup.object().shape({
    productDetails: Yup.array().of(
      Yup.object().shape({
        installmentPrice: Yup.object().shape({
          value: Yup.string()
            .required('التكلفة مطلوبة')
            .length(5, 'التكلفة يجب أن تكون أقل من 5 أرقام'),
        }),
      }),
    ),
  })

  const handleRequestBody = () => {
    //destructing productBasicInfo values
    let { productName, productBTC } = __productBasicInfo.productBasicInfo
    //Rename productDetails keys name
    let productInstallments = []
    productDetails.map(item => {
      productInstallments.push({
        installmentCost: item.installmentPrice.value,
        installmentDesc: item.installmentDetails.value,
        installmentPeriod: item.installmentDuration.value,
        installmentCoverage: item.installmentCoverage.value,
      })
    })
    //Rename agentInfoPackage keys name
    let productCustomerInfos = []
    agentInfoPackage.agentPackage.map(item => {
      productCustomerInfos.push({
        inputName: item.filedName,
        canBeEmpty: item.isNullable,
        containsOnlyNumbers: item.includeNumberOnly,
        containsOnlyLetters: item.includeTextOnly,
        allowCountChars: item.isLengthRequired,
        minChars: item.minLength,
        maxChars: item.maxLength,
      })
    })
    //Rename trailsBlock keys name
    let productRetrials = []
    trailsBlock.trails.map(item => {
      productRetrials.push({
        retrialCount: item.trialCount,
        day: item.trialGap,
        productRetrialDate: item.trialTimes.map(trail => ({
          dialDate: trail.trailTime + ':00',
        })),
      })
    })
    const requestBody = {
      productName: productName.value,
      btc: productBTC.value,
      productTradeInfo: tradeInfo.map(item => ({
        tradeName: item.tradeName,
        gigProductName: item.gigProductName,
      })),

      productInstallments: productInstallments,
      productRetrials,
      productCustomerInfos: productCustomerInfos,
      productType: 'PRODUCT',
      merchant: false,
    }
    return requestBody
  }
  const handleProductBasicInfoChange = e => {
    const { name, value } = e.target
    __setProductBasicInfo({
      ...__productBasicInfo,
      productBasicInfo: {
        ...__productBasicInfo.productBasicInfo,
        [name]: { value: value, errors: '' },
      },
    })
  }
  const validateProductBasicInfo = async () => {
    const basicInfoValidationResult = await BasicInfoValidationSchema.validate(
      __productBasicInfo,
      { abortEarly: false },
    ).catch(err => {
      return err
    })
    if (basicInfoValidationResult instanceof Error) {
      const allErrors = basicInfoValidationResult.inner.reduce(
        (errors, currentValidation) =>
          Object.assign(errors, {
            [currentValidation.path]: currentValidation.errors[0], //first error is enough for this demo
          }),
        {},
      )
      let __productBasicInfoCopy = { ...__productBasicInfo }
      for (let i = 0; i < Object.keys(allErrors).length; i++) {
        let errorPath = Object.keys(allErrors)[i].replace('.value', '.errors')
        put(
          __productBasicInfoCopy,
          errorPath,
          allErrors[Object.keys(allErrors)[i]],
        )
      }
      __setProductBasicInfo(__productBasicInfoCopy)
    }
  }
  const handleFormSubmission = async e => {
    e.preventDefault()
    await validateProductBasicInfo()
    handleRequestBody()
    setIsLoading(true)
    axiosInstance
      .post('/product/createNewProduct', handleRequestBody())
      .then(res => {
        setIsLoading(false)
        toast.success('تم إضافة المنتج بنجاح')
        navigation('/product')
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        toast.error(err.response.data.message)
      })
  }

  return (
    <div>
      <div className={'border-b p-3'}>
        <span className={'text-xl font-medium'}>إضافة منتج جديد</span>
      </div>
      <div className={'flex '}>
        <div className={'flex-grow'}>
          <form className={'bg-white p-5 dark:bg-gray-900 '}>
            <span className={'block font-medium '}>تفاصيل المنتج</span>
            <div className={'flex gap-2 '}>
              <SFormWrapper className={'w-6/12'}>
                <FLabel htmlFor={'productName'}> أسم المنتج </FLabel>
                <FInputField
                  type='text'
                  name={'productName'}
                  id={'productName'}
                  value={__productBasicInfo.productBasicInfo.productName.value}
                  onChange={e => handleProductBasicInfoChange(e)}
                  placeholder={'أدخل أسم المنتج'}
                />
                <small className={'text-right font-medium text-red-700'}>
                  {__productBasicInfo.productBasicInfo.productName.errors}{' '}
                </small>
              </SFormWrapper>
              <SFormWrapper className={'w-6/12'}>
                <FLabel htmlFor={'productBTC'}> BTC المنتج </FLabel>
                <FInputField
                  type='text'
                  name={'productBTC'}
                  id={'productBTC'}
                  value={__productBasicInfo.productBasicInfo.productBTC.value}
                  onChange={e => handleProductBasicInfoChange(e)}
                  placeholder={'أدخل بي تي سي المنتج'}
                />
                <small className={'text-right font-medium text-red-700'}>
                  {__productBasicInfo.productBasicInfo.productBTC.errors}{' '}
                </small>
              </SFormWrapper>
            </div>
            {/*  <ProductTrades setTradeInfo={setTradeInfo} tradeInfo={tradeInfo} />*/}
            <ProductPackages
              productDetails={productDetails}
              setProductDetails={setProductDetails}
            />
            <ProductCompletionTrails
              trailsBlock={trailsBlock}
              setTrailsBlock={setTrailsBlock}
            />
            <AgentDetails
              agentInfoPackage={agentInfoPackage}
              setAgentInfoPackage={setAgentInfoPackage}
            />
            <FButton type={'submit'} onClick={handleFormSubmission}>
              <FIconWrapper>
                <ESpinner isVisible={isLoading} />
                إضافة
              </FIconWrapper>
            </FButton>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ProductAdd
