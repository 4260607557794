import React from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getProductByBTC } from '../../../helpers/utils'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'

export default function CustomerRefunds({ customerPhoneNumber }) {
  const navigate = useNavigate()
  const { data, isLoading } = useQuery({
    queryKey: ['Refunds'],
    queryFn: () =>
      axiosInstance
        .get(
          `ResidualFees/listAllRefundFees?customerPhoneNumber=${customerPhoneNumber}&page=0&size=10`,
        )
        .then(res => res.data),
  })

  const columns = [
    {
      wrap: true,
      name: 'btc',
      selector: row => getProductByBTC(row.btc)?.name,
      sortable: true,
    },
    {
      name: 'كود العمليه',
      selector: row => row.transactionCode,
      width: '200px',
    },
    {
      name: 'المبلغ',
      selector: row => row.amount,
      width: '200px',
    },

    {
      width: '200px',
      name: 'تاريخ  الاسترجاع',
      selector: row => moment(row.refundDate).format('DD/MM/YYYY HH:mm'),
      sortable: true,
    },
  ]

  if (isLoading) return <div>loading...</div>
  else
    return (
      <div>
        <DataTable
          striped
          highlightOnHover
          resizable // Enable column resizing
          title={<span className='text-center'>العمليات المرتجعه للعميل</span>}
          noDataComponent={
            <span className='border text-center'>
              لا يوجد عمليات مرتجعه لهذا العميل
            </span>
          }
          columns={columns}
          pointerOnHover
          responsive
          direction='rtl'
          data={data?.results ?? []}
          pagination
        />
      </div>
    )
}
