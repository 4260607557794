import React from 'react'
import { useFormik } from 'formik'
import EFormWrapper from '../../../components/EFormWrapper'
import FInputField from '../../../components/FInputField'
import FLabel from '../../../components/FLabel'
import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../api/requister'
import FRow from '../../../components/FRow'
import FButton from '../../../components/FButton'
import ESpinner from '../../../components/ESpinner'
import { toast } from 'react-toastify'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import * as Yup from 'yup'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import FIconWrapper from '../../../components/FIconWrapper'
import { Link, useNavigate } from 'react-router-dom'

const AddClaim = () => {
  const redirectTo = useNavigate()
  const { data, isLoading } = useQuery({
    queryKey: ['getClaim'],
    queryFn: () =>
      axiosInstance
        .get('/Compensation/listAllCompensationTypes?page=0&size=10')
        .then(res => res.data),
  })
  const mutation = useMutation({
    mutationFn: values =>
      axiosInstance
        .post('Compensation/editCompensation', values)
        .then(res => res.data),

    onSuccess: response => {
      toast.success('تم اضافة التعويض بنجاح')

      redirectTo(`/claim/${response.bean.id}`)
    },
    onError: error => {
      toast.error(
        `حدث خطأ اثناء اضافة التعويض, من فضلك حاول مرة اخري ${
          error?.response?.data?.message || ''
        }`,
        {
          autoClose: 5000,
        },
      )
    },
  })

  const Form = useFormik({
    initialValues: {
      name: '',
      nationalId: '',
      phoneNumber: '',
      insuranceDocumentNumber: '',
      insuranceCompany: '',
      insuranceAmount: '',
      claimType: '',
      product: '',
      claimTypeDocument: [],
      serialNumber: '',
      accidentDate: '',
    },

    onSubmit: values => {
      const requestBody = {
        compensation: {
          productId: parseInt(values.product),
          compensationTypeId: data?.results[values.claimType].id,
          customer: {
            name: values.name,
            nationalId: values.nationalId,
            phoneNumber: values.phoneNumber,
          },
          serialNumber: values.serialNumber,
          accidentDate: values.accidentDate,
          documentNumber: values.insuranceDocumentNumber,
          insuranceCompany: values.insuranceCompany,
          insuranceAmount: values.insuranceAmount,
          claimAmount: values.claimAmount,
        },

        answers: values.claimTypeDocument.map(item => ({
          compensationTypeRequirementId: item.id,
          isDataExists: item.isExists,
          dateOfData: item.date,
          reasonOfNotExists: item.note,
        })),
      }

      mutation.mutate(requestBody)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('من فضلك ادخل الاسم'),
      nationalId: Yup.string().required('من فضلك ادخل الرقم القومي'),
      phoneNumber: Yup.string().required('من فضلك ادخل رقم الهاتف'),
      insuranceDocumentNumber: Yup.string().required(
        'من فضلك ادخل رقم وثيقة التأمين',
      ),
      claimType: Yup.string().required('من فضلك اختر نوع التعويض'),
      claimAmount: Yup.number().min(
        0,
        'من فضلك ادخل مبلغ المطالبة المالية اكبر من صفر',
      ),
      product: Yup.string().required('من فضلك اختر المنتج'),
      insuranceCompany: Yup.string().required('من فضلك ادخل شركة التأمين'),
      insuranceAmount: Yup.number()
        .required('من فضلك ادخل مبلغ التأمين')
        .min(0, 'من فضلك ادخل مبلغ التأمين اكبر من صفر'),
    }),
  })
  if (isLoading) return <div>loading</div>
  else
    return (
      <div>
        <FRow
          className={'items-center  justify-between p-3 border-b bg-gray-50'}
        >
          <span className={'text-lg font-medium'}>اضافة مطالبة</span>
          <FRow>
            <Link to={'/claim'}>
              <FButton>رجوع</FButton>
            </Link>
          </FRow>
        </FRow>
        <div className={'flex flex-col items-center justify-center'}>
          <form className={'    p-10'} onSubmit={Form.handleSubmit}>
            <span className={'font-semibold'}>المعلومات الشخصية</span>
            <div className={'grid grid-cols-4 gap-3'}>
              <EFormWrapper>
                <FLabel>الاسم</FLabel>
                <FInputField
                  label={'الاسم'}
                  name={'name'}
                  placeholder={'الاسم'}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.name}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'name'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel>الرقم القومي</FLabel>
                <FInputField
                  label={'الرقم القومي'}
                  name={'nationalId'}
                  placeholder={'الرقم القومي'}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.nationalId}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'nationalId'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel>رقم الهاتف</FLabel>
                <PhoneInput
                  countries={['EG']}
                  defaultCountry={'EG'}
                  limitMaxLength={true}
                  label={'رقم الهاتف'}
                  name={'phoneNumber'}
                  placeholder={'رقم الهاتف'}
                  onChange={value => Form.setFieldValue('phoneNumber', value)}
                  onBlur={Form.handleBlur}
                  value={Form.values.phoneNumber}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'phoneNumber'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel>رقم وثيقة التأمين</FLabel>
                <FInputField
                  label={'رقم وثيقة التأمين'}
                  name={'insuranceDocumentNumber'}
                  placeholder={'رقم وثيقة التأمين'}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.insuranceDocumentNumber}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'insuranceDocumentNumber'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel>شركة التأمين</FLabel>
                <FInputField
                  label={'شركة التأمين'}
                  name={'insuranceCompany'}
                  placeholder={'شركة التأمين'}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.insuranceCompany}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'insuranceCompany'}
                  touched={Form.touched}
                />
              </EFormWrapper>

              <EFormWrapper>
                <FLabel htmlFor={'insuranceAmount'}>مبلغ التأمين</FLabel>
                <FInputField
                  id={'insuranceAmount'}
                  type={'number'}
                  min={0}
                  name={'insuranceAmount'}
                  placeholder={'مبلغ التأمين'}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.insuranceAmount}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'insuranceAmount'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={'claimAmount'}>مبلغ المطالبة المالية</FLabel>
                <FInputField
                  id={'claimAmount'}
                  type={'number'}
                  min={0}
                  name={'claimAmount'}
                  placeholder={'مبلغ المطالبة المالية'}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  value={Form.values.claimAmount}
                />
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'claimAmount'}
                  touched={Form.touched}
                />
              </EFormWrapper>
            </div>
            <div className={'grid grid-cols-2 gap-3'}>
              <EFormWrapper>
                <FLabel htmlFor={'claimType'}>نوع التعويض</FLabel>
                <select
                  onChange={e => {
                    Form.setFieldValue('claimType', e.target.value)
                    let claimType =
                      data?.results[e.target.value].compensationTypeRequirements
                    claimType.map(item => {
                      item['isExists'] = false
                      item['note'] = ''
                      item['date'] = ''
                    })
                    Form.setFieldValue('claimTypeDocument', claimType)
                  }}
                  onBlur={Form.handleBlur}
                  value={Form.values.claimType}
                  name={'claimType'}
                  id={'claimType'}
                >
                  <option>اختر نوع التعويض</option>
                  {data?.results.map((item, index) => (
                    <option key={index} value={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'claimType'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              <EFormWrapper>
                <FLabel htmlFor={'product'}>المنتج</FLabel>
                <select
                  value={Form.values.product}
                  onChange={Form.handleChange}
                  onBlur={Form.handleBlur}
                  name={'product'}
                  id={'product'}
                >
                  <option value={''}>اختر المنتج</option>
                  {localStorage.getItem('products') &&
                    JSON.parse(localStorage.getItem('products')).map(
                      (item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ),
                    )}
                </select>
                <EFormInvalidInput
                  errors={Form.errors}
                  FieldName={'product'}
                  touched={Form.touched}
                />
              </EFormWrapper>
              {Form.values.product === '94864' && (
                <EFormWrapper>
                  <FLabel htmlFor={'serialNumber'}>
                    سريال الجهاز (اختياري)
                  </FLabel>
                  <FInputField
                    id={'serialNumber'}
                    name={'serialNumber'}
                    placeholder={'سريال الجهاز'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.serialNumber}
                  />
                </EFormWrapper>
              )}
              {Form.values.product === '133' && (
                <EFormWrapper>
                  <FLabel htmlFor={'accidentDate'}>تاريخ الحادث</FLabel>
                  <FInputField
                    type={'date'}
                    id={'accidentDate'}
                    name={'accidentDate'}
                    placeholder={'تاريخ الحادث'}
                    onChange={Form.handleChange}
                    onBlur={Form.handleBlur}
                    value={Form.values.accidentDate}
                  />
                </EFormWrapper>
              )}
            </div>
            <EFormWrapper>
              <span className={'font-semibold'}>المرفقات</span>

              {Form.values.claimTypeDocument?.map((item, index) => (
                <div
                  key={index}
                  className={' rounded-md border bg-gray-50 p-3 shadow-sm'}
                >
                  <span className={'mb-2 block font-medium'}>{item.name}</span>
                  <FRow className={'items-center gap-4'}>
                    <FRow>
                      <div className={'flex items-center gap-1'}>
                        <FLabel
                          htmlFor={`claimTypeDocument[${index}].isExists`}
                        >
                          تمت رفعه
                        </FLabel>
                        <FInputField
                          id={`claimTypeDocument[${index}].isExists`}
                          className={'h-4 w-4'}
                          type={'radio'}
                          name={`claimTypeDocument[${index}].isExists`}
                          value={true}
                          checked={
                            Form.values.claimTypeDocument[index].isExists ===
                            true
                          }
                          onChange={() =>
                            Form.setFieldValue(
                              `claimTypeDocument[${index}].isExists`,
                              true,
                            )
                          }
                          onBlur={Form.handleBlur}
                          label={'موجود'}
                        />
                      </div>
                      <div className={'flex items-center gap-1'}>
                        <FLabel
                          htmlFor={`claimTypeDocument[${index}].notExists`}
                        >
                          لم يتم تقديمه
                        </FLabel>
                        <FInputField
                          id={`claimTypeDocument[${index}].notExists`}
                          className={'h-4 w-4'}
                          type={'radio'}
                          name={`claimTypeDocument[${index}].isExists`}
                          value={false}
                          checked={
                            Form.values.claimTypeDocument[index].isExists ===
                            false
                          }
                          onChange={() =>
                            Form.setFieldValue(
                              `claimTypeDocument[${index}].isExists`,
                              false,
                            )
                          }
                          onBlur={Form.handleBlur}
                          label={'غير موجود'}
                        />
                      </div>
                    </FRow>

                    <FInputField
                      type={'date'}
                      max={new Date().toISOString().split('T')[0]}
                      name={`claimTypeDocument[${index}].date`}
                      onChange={Form.handleChange}
                      onBlur={Form.handleBlur}
                      label={'تاريخ'}
                    />
                    <EFormInvalidInput
                      errors={Form.errors}
                      FieldName={`claimTypeDocument[${index}].date`}
                      touched={Form.touched}
                    />

                    <textarea
                      rows={1}
                      className={'input-style'}
                      name={`claimTypeDocument[${index}].note`}
                      onChange={Form.handleChange}
                      onBlur={Form.handleBlur}
                      placeholder={'ملاحظات بخصوص الملف'}
                    />
                  </FRow>
                </div>
              ))}
            </EFormWrapper>
            <FButton disabled={mutation.isLoading} type={'submit'}>
              <FIconWrapper>
                <span>إضافة</span>
                <ESpinner isVisible={mutation.isLoading} />
              </FIconWrapper>
            </FButton>
          </form>
        </div>
      </div>
    )
}
export default AddClaim
