import EFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import FModal from '../../../components/FModal'
import React from 'react'
import { axiosInstance } from '../../../api/requister'
import ESpinner from '../../../components/ESpinner'
import { toast } from 'react-toastify'
import MapOracleFileModal from './MapOracleFileModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

const UploadOracleFile = ({
  setIsUploadModalOpen,
  isUploadModalOpen,
  onChangeCallBack,
}) => {
  const [fileValidation, setFileValidation] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [loadingPercentage, setLoadingPercentage] = React.useState(0)
  const [isUploading, setIsUploading] = React.useState(false)
  const [fileUploadResponse, setFileUploadResponse] = React.useState([])

  const handleOracleUploadFile = values => {
    setIsUploading(true)
    const formData = new FormData()
    formData.append('file', values.file)
    axiosInstance
      .post('upload/uploadOracleFile', formData, {
        responseType: 'blob', // Set the response type to blob
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        const modifiedFile = response.data

        // Create a URL for the modifiedFile blob
        const fileUrl = URL.createObjectURL(modifiedFile)

        // Create a temporary anchor element
        const downloadLink = document.createElement('a')
        downloadLink.href = fileUrl
        const fileNameArray = ['رد ملف الدفع oracle']
        fileNameArray.push('بتاريخ')
        fileNameArray.push(moment().format('DD-MM-YYYY'))
        downloadLink.download = fileNameArray.join(' ').concat('.xlsx')
        // Append the anchor element to the document body
        document.body.appendChild(downloadLink)

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        // Clean up by removing the anchor element and revoking the URL
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(fileUrl)
        setFileUploadResponse(e.data.list)
        setIsUploading(false)
        toast.success('تم رفع الملف بنجاح')
      })
      .catch(err => {
        setIsUploading(false)
        console.log(err)
      })
  }

  // const validationSchema = Yup.object().shape({
  //   file: Yup.string()
  //     .required('يجب ان يحتوي اسم الملف علي تاريخ')
  //     .matches(
  //       /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}.*oracle.*$/,
  //       'يجب ان يحتوي اسم الملف علي تاريخ بصيخة dd-mm-yyyy و كلمة oracle',
  //     ),
  // })

  const validationSchema = Yup.object().shape({
    file: Yup.string()
      .required('File name is required')
      .matches(
        /^(.*\d{1,2}-\d{1,2}-\d{4}.*oracle.*)$/,
        'File name must contain a date in "dd-mm-yyyy" format and the word "oracle"',
      ),
  })

  const Formik = useFormik({
    initialValues: {
      file: '',
    },
    onSubmit: values => {
      handleOracleUploadFile(values)
    },
    validate: value => {
      const errors = {}
      if (!value.file) {
        errors.file = 'File name is required'
      }

      // Check if the value contains a date in "dd-mm-yyyy" format and the word "oracle"
      const regex = /^(?=.*\d{1,2}-\d{1,2}-\d{4})(?=.*oracle).*$/
      if (!regex.test(value.file.name)) {
        errors.file =
          'يجب ان يحتوي اسم الملف علي تاريخ بصيخة dd-mm-yyyy و كلمة oracle'
      }
      return errors
    },
  })

  const [isMapModalOpen, setIsMapModalOpen] = React.useState(false)
  return (
    <>
      <MapOracleFileModal
        isOpen={isMapModalOpen}
        setIsOpen={setIsMapModalOpen}
      />
      <div
        className={
          'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
        }
      >
        <div className={'flex flex-col'}>
          <span className={'font-semibold  text-gray-700 dark:text-gray-100  '}>
            تهية ملف الدفع{' '}
          </span>
          <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
            يمكن تهيئة ملف الدفع من خلال تحميل ملف الدفع الخاص بك
          </span>
        </div>
        <div className={'mr-auto flex gap-2'}>
          <FButton onClick={() => setIsMapModalOpen(true)}>ربط الملف</FButton>
        </div>
      </div>

      <FModal
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        title={'توريد ملف جديد'}
      >
        <form onSubmit={Formik.handleSubmit}>
          <EFormWrapper>
            <FLabel
              htmlFor='formFile'
              className='form-label mb-2 inline-block  text-gray-700 dark:text-gray-100  '
            >
              أختر ملف
            </FLabel>
            <FInputField
              type='file'
              id='formFile'
              name='file'
              onBlur={() => {
                Formik.setFieldTouched('file', true)
              }}
              onChange={e => {
                Formik.setFieldValue('file', e.target.files[0])
              }}
            />
            {Formik.errors.file && Formik.touched.file ? (
              <small className='text-right text-sm font-medium text-red-500'>
                {Formik.errors.file}
              </small>
            ) : null}
          </EFormWrapper>

          <EFormWrapper>
            <div className={'flex gap-2'}>
              <FButton
                type={'submit'}
                btnType={'success'}
                disabled={isUploading}
              >
                <FIconWrapper>
                  <ESpinner isVisible={isUploading} />
                  رفع الملف
                  <span>{`${
                    loadingPercentage !== 0 && loadingPercentage !== 100
                      ? loadingPercentage + '% '
                      : ''
                  }`}</span>
                </FIconWrapper>
              </FButton>
              <FButton
                onClick={() => setIsUploadModalOpen(false)}
                type={'button'}
                btnType={'secondary'}
              >
                إلغاء
              </FButton>
            </div>
          </EFormWrapper>
        </form>
      </FModal>
    </>
  )
}
export default UploadOracleFile
