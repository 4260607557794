import FModal from '../../components/FModal'
import { useFormik } from 'formik'
import SFormWrapper from '../../components/EFormWrapper'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import EFormInvalidInput from '../../components/EFormInvalidInput'
import FButton from '../../components/FButton'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import ESpinner from '../../components/ESpinner'
import FIconWrapper from '../../components/FIconWrapper'
import axios from 'axios'
import { BASE_URL } from '../../env'

const AgentBasicInfo = () => {
  const navigation = useNavigate()
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  let { uuid } = useParams()

  useEffect(() => {
    localStorage.clear()
  }, [])

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('يجب ادخال الأسم بالكامل'),
    fawryId: Yup.string().required('يجب ادخال رقم الفوري'),
    password: Yup.string().required('يجب ادخال كلمة المرور'),
  })

  const handleRequestBody = values => ({
    invitationId: uuid,
    name: values.name,
    fawryId: values.fawryId,
    password: values.password,
    confirmPassword: values.password,
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      fawryId: '',
      password: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: values => {
      setIsLoading(true)
      const requestBody = handleRequestBody(values)
      axios
        .post(`${BASE_URL}user/acceptUserInvitation`, requestBody)
        .then(res => {
          setIsLoading(false)
          setTimeout(() => {
            setIsRegistrationModalOpen(false)
            return navigation('/login')
          }, 3000)

          toast.success('تم تفعيل الحساب بنجاح. سوف يتم توجيهك لتسجيل الدخول')
        })
        .catch(err => {
          setIsLoading(false)
          toast.error(err.response.data.message)
        })
    },
  })

  return (
    <>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <FModal
        isOpen={isRegistrationModalOpen}
        isDismissible={false}
        setIsOpen={setIsRegistrationModalOpen}
        title={'تسجيل مستخدم جديد'}
      >
        <form onSubmit={formik.handleSubmit}>
          <SFormWrapper>
            <FLabel htmlFor={'name'}> الاسم </FLabel>
            <FInputField
              type='text'
              name={'name'}
              id={'name'}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'أدخل الاسم'}
            />
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'name'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FLabel htmlFor={'fawryId'}> كود فوري </FLabel>
            <FInputField
              type='text'
              name={'fawryId'}
              id={'fawryId'}
              value={formik.values.fawryId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'أدخل كود فوري الخاص بك'}
            />
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'fawryId'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FLabel htmlFor={'password'}> كلمة المرور </FLabel>
            <FInputField
              type='password'
              name={'password'}
              id={'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'أدخل كلمة المرور'}
            />
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'password'}
            />
          </SFormWrapper>
          <FButton type={'submit'}>
            <FIconWrapper>
              <ESpinner isVisible={isLoading} />
              تسجيل
            </FIconWrapper>
          </FButton>
        </form>
      </FModal>
    </>
  )
}
export default AgentBasicInfo
