import { useCustomAxios } from '../../../Hooks/useAxios'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import FModal from '../../../components/FModal'
import FButton from '../../../components/FButton'
import FAvatar from '../../../components/FAvatar'
import ECard from '../../../components/ECard'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import EFormInvalidInput from '../../../components/EFormInvalidInput'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getAuthorities, getNested } from '../../../helpers/utils'
import { toast } from 'react-toastify'
import Deactivate from './Deactivate'
import FIconWrapper from '../../../components/FIconWrapper'
import { MdCancelScheduleSend } from 'react-icons/md'
import { axiosInstance } from '../../../api/requister'
import ESpinner from '../../../components/ESpinner'
import moment from 'moment'
import FInfo from '../../../components/FInfo' // theme css file
import 'react-toggle/style.css' // for ES6 modules
import Toggle from 'react-toggle'
import FProductTag from '../../../components/FProductTag'

const AgentEdit = () => {
  const [isInVacation, setIsInVacation] = useState(false)
  let { email } = useParams()
  let navigate = useNavigate()
  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `/user/${email}`,
  })
  useEffect(() => {
    if (response) {
      setIsInVacation(response.userDto.holiday)
    }
  }, [response])

  const handleSubmitUserVacation = () =>
    axiosInstance
      .post(`/user/${email}/${!isInVacation}/markAsHoliday`)
      .then(() => {
        toast.success('تم تغيير الحالة بنجاح')
        setIsVacationModalOpen(false)
        Refetch()
      })
      .catch(() => toast.error('حدث خطأ اثناء تغيير الحالة'))
  const [isAbortAccountModalOpen, setIsAbortAccountModalOpen] = useState(false)
  const [isVacationModalOpen, setIsVacationModalOpen] = useState(false)
  const callBack = () => {
    setIsAbortAccountModalOpen(false)
    toast.success('تم تعطيل الحساب بنجاح')
    Refetch()
  }
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('الأسم مطلوب'),
    fawry_id: Yup.string().required('كود فوري مطلوب'),
    role: Yup.string().required('الدور مطلوب'),
    email: Yup.string().required('البريد الالكتروني مطلوب'),
  })

  const handleRequestBody = values => {
    let requestBody = {
      name: values.name,
      fawryId: values.fawry_id,
      email: values.email,
    }
    if (values.password) {
      requestBody.password = values.password
      requestBody.confirmPassword = values.password
    }
    return requestBody
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: getNested(response, 'userDto', 'name'),
      password: '',
      fawry_id: getNested(response, 'userDto', 'fawryId'),
      role: getNested(response, 'userDto', 'authorities')[0],
      email: getNested(response, 'userDto', 'email'),
    },
    validationSchema: ValidationSchema,
    onSubmit: values =>
      axiosInstance
        .post(`/user/${email}`, handleRequestBody(values))
        .then(res => {
          toast.success('تم تعديل البيانات بنجاح')
          Refetch()
          //navigate('/agent')
        })
        .catch(err => toast.error(err.response.data.message)),
  })
  const handleInviteCancel = () =>
    axiosInstance
      .delete(`/user/${email}/cancelInvitation`)
      .then(res => {
        toast.success('تم إلغاء الدعوة بنجاح')
        navigate('/agent')
      })
      .catch(err => {
        toast.error('حدث خطأ ما')
      })

  const getEmptyTag = text => {
    if (text === null || text === undefined || text === '') {
      return (
        <span
          style={{ lineHeight: '20px' }}
          className='my-1 block w-fit rounded border border-gray-300  bg-gray-100 px-2  py-0 text-xs font-bold text-gray-500  dark:bg-gray-600 dark:text-gray-100  '
        >
          غير محدد
        </span>
      )
    }
    return text
  }
  const handleSelect = ranges => {
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ])
  if (!loading)
    return (
      <>
        <FModal
          setIsOpen={setIsVacationModalOpen}
          isAutoWidth={true}
          isOpen={isVacationModalOpen}
          title={'أضافة اجازة'}
        >
          <FInfo
            message={
              ' بوضع هذا المستخدم في وضع الأجازة لن يسمح له بأجراء أي مكالمة خلال هذه الفترة'
            }
          />
          <div className={'f-row-between py-5'}>
            <label
              className={
                'select-none text-lg font-semibold hover:cursor-pointer'
              }
              htmlFor={'cheese-status'}
            >
              وضع الأجازة
            </label>

            <Toggle
              id='cheese-status'
              className={'bg-orient-500'}
              defaultChecked={isInVacation}
              onChange={() => handleSubmitUserVacation()}
            />
          </div>

          {/*<div className={" my-3"}>*/}
          {/*  <SFormWrapper>*/}
          {/*    <FLabel>أختر فترة الأجازة</FLabel>*/}
          {/*    <DateRangePicker*/}
          {/*      showMonthAndYearPickers={false}*/}
          {/*      minDate={new Date()}*/}
          {/*      className="rounded border shadow-sm"*/}
          {/*      locale={arEG}*/}
          {/*      ranges={dateRange}*/}
          {/*      onChange={(item) => setDateRange([item.selection])}*/}
          {/*    />*/}
          {/*  </SFormWrapper>*/}
          {/*</div>*/}
          <div className={'flex gap-3'}>
            <FButton btnType={'button'}>أضافة</FButton>
            <FButton
              type={'button'}
              onClick={() => setIsVacationModalOpen(false)}
              btnType={'secondary'}
            >
              إلغاء
            </FButton>
          </div>
        </FModal>
        <FModal
          title={'تعطيل حساب المستخدم'}
          isOpen={isAbortAccountModalOpen}
          setIsOpen={setIsAbortAccountModalOpen}
        >
          <span className={'mb-5 block text-right'}>
            هل أنت متأكد بتعطيل الحساب الخاص ب Abdelrahman Reda
          </span>
          <div className={'flex gap-3'}>
            <Deactivate email={email} callBack={callBack}>
              تعطيل الحساب
            </Deactivate>
            <FButton
              type={'button'}
              onClick={() => setIsAbortAccountModalOpen(false)}
              btnType={'secondary'}
            >
              إلغاء
            </FButton>
          </div>
        </FModal>
        <div
          className={
            'flex items-center justify-between bg-white px-2 dark:bg-gray-900'
          }
        >
          <div className={'sticky top-0 flex gap-2 p-3'}>
            <NavLink className={'text-gray-400'} to={'/agent'}>
              فريق العمل
            </NavLink>
            <span className={'text-gray-400'}>/</span>
            <NavLink className={'font-semibold text-orient-500'} to={'/agent'}>
              {email}
            </NavLink>
          </div>
          <div className={'flex gap-2'}>
            {!getNested(response, 'userDto', 'credentialsExists') && (
              <FButton onClick={() => handleInviteCancel()} btnType={'danger'}>
                <FIconWrapper>
                  <MdCancelScheduleSend />
                  الغاء الدعوة
                </FIconWrapper>
              </FButton>
            )}
            <FButton
              onClick={() => setIsVacationModalOpen(true)}
              btnType={'secondary'}
            >
              <span>أيام العطلات</span>
            </FButton>
          </div>
        </div>
        <div
          className={'flex gap-24 rounded border bg-white p-5 dark:bg-gray-900'}
        >
          <div className={'flex items-center gap-3'}>
            <FAvatar
              width={60}
              name={
                getNested(response, 'userDto', 'name') === ''
                  ? getNested(response, 'userDto', 'email')
                  : getNested(response, 'userDto', 'name')
              }
            />
            <div className={'flex flex-col'}>
              {isInVacation && (
                <FProductTag productName={'وضع الأجازة'} color={'red'} />
              )}
              <span className={'text-sm'}>
                {getEmptyTag(getNested(response, 'userDto', 'name'))}
              </span>
              <span className={'text-sm'}>
                {getNested(response, 'userDto', 'email')}
              </span>
            </div>
          </div>
          <div className={'flex flex-col'}>
            <span className={'text-sm  text-gray-800 dark:text-white '}>
              المسمى وظيفي
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              {getAuthorities(getNested(response, 'userDto', 'authorities')[0])}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <span className={'text-sm  text-gray-800 dark:text-white '}>
              معرف فوري
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              {getEmptyTag(getNested(response, 'userDto', 'fawryId'))}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <span className={'text-sm  text-gray-800 dark:text-white '}>
              آخر تسجيل دخول
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              {getEmptyTag(
                moment(
                  new Date(getNested(response, 'userDto', 'lastLoginDate')),
                ).format('MMMM d, YYYY, h:mm:ss a'),
              )}
            </span>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <ECard>
            <ECard.Header>
              <span className={'text-lg'}>تعديل البيانات الشخصية</span>
            </ECard.Header>
            <ECard.Body>
              <div className={'flex gap-7'}>
                <div className={'w-6/12'}>
                  <SFormWrapper>
                    <FLabel htmlFor='name'>الاسم</FLabel>
                    <FInputField
                      id='name'
                      name='name'
                      type='text'
                      placeholder='الاسم'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    <EFormInvalidInput
                      touched={formik.touched}
                      errors={formik.errors}
                      FieldName={'name'}
                    />
                  </SFormWrapper>
                  <span className={'text-lg font-medium'}>
                    الخصوصية و الأمان
                  </span>
                  <SFormWrapper>
                    <FLabel htmlFor='password'>تعين كلمة سر جديدة</FLabel>
                    <FInputField
                      id='password'
                      name='password'
                      type='password'
                      placeholder='كلمة المرور'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <EFormInvalidInput
                      touched={formik.touched}
                      errors={formik.errors}
                      FieldName={'password'}
                    />
                  </SFormWrapper>
                  <SFormWrapper>
                    <FLabel htmlFor='fawry_id'>معرف فوري</FLabel>
                    <FInputField
                      id='fawry_id'
                      name='fawry_id'
                      placeholder='معرف فوري'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fawry_id}
                    />
                    <EFormInvalidInput
                      touched={formik.touched}
                      errors={formik.errors}
                      FieldName={'fawry_id'}
                    />
                  </SFormWrapper>
                </div>
                <div
                  className={
                    'relative mt-5 w-6/12 rounded border border-dashed border-red-500 p-5'
                  }
                >
                  <span
                    className={
                      'absolute -top-3 -right-1 bg-white px-2 text-lg font-medium dark:bg-gray-900 '
                    }
                  >
                    الحساب
                  </span>
                  <div
                    className={
                      'flex items-center gap-2 rounded-sm border border-red-500 bg-red-50 p-2 text-red-500'
                    }
                  >
                    <HiOutlineInformationCircle />
                    <span>|</span>
                    <span className={'text-sm'}>
                      عليك توخي الحذر اثناء إجراء أي تعديلات في هذه المنطقة
                    </span>
                  </div>
                  <EFormInvalidInput
                    touched={formik.touched}
                    errors={formik.errors}
                    FieldName={'userName'}
                  />

                  <SFormWrapper>
                    <FLabel htmlFor='role'>الدور</FLabel>
                    <select
                      className={
                        'w-full rounded-md border border-gray-300 py-1'
                      }
                      name={'role'}
                      id={'role'}
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value={''}> أختر الدور الظيفي</option>
                      <option value={'HEAD_MANAGER_AUTHORITY'}> مدير</option>
                      <option value={'SUPERVISOR_AUTHORITY'}> مشرف</option>
                      <option value={'AGENT_AUTHORITY'}>
                        {' '}
                        موظف خدمة عملاء{' '}
                      </option>
                      <option value={'SALES_REP_AUTHORITY'}>
                        {' '}
                        موظف مبيعات{' '}
                      </option>
                    </select>
                    <EFormInvalidInput
                      touched={formik.touched}
                      errors={formik.errors}
                      FieldName={'role'}
                    />
                  </SFormWrapper>
                  <SFormWrapper>
                    <FLabel htmlFor='email'>البريد الالكتروني</FLabel>
                    <FInputField
                      id='email'
                      name='email'
                      type='email'
                      placeholder='البريد الالكتروني'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <EFormInvalidInput
                      touched={formik.touched}
                      errors={formik.errors}
                      FieldName={'email'}
                    />
                  </SFormWrapper>
                  <FButton
                    onClick={() => setIsAbortAccountModalOpen(true)}
                    btnType={'danger'}
                    type={'button'}
                  >
                    تعطيل الحساب
                  </FButton>
                </div>
              </div>
            </ECard.Body>
            <ECard.Footer>
              <FButton type={'submit'}>حفظ</FButton>
            </ECard.Footer>
          </ECard>
        </form>
      </>
    )
  else
    return (
      <div className={'f-col-center-center h-full  border'}>
        <ESpinner isVisible={true} size={45} />
        <span className={'mt-5 text-sm'}>جاري التحميل ...</span>
      </div>
    )
}
export default AgentEdit
