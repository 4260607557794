import FButton from '../../../components/FButton'
import FFlexWrapper from '../../../components/FFlexWrapper'
import { HiUpload } from 'react-icons/hi'
import React from 'react'
import { SiOracle } from 'react-icons/si'
import FIconWrapper from '../../../components/FIconWrapper'
import UploadOracleFile from './UploadOracleFile'

const OracleList = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false)

  return (
    <>
      <UploadOracleFile
        isUploadModalOpen={isUploadModalOpen}
        setIsUploadModalOpen={setIsUploadModalOpen}
      />

      <div
        className={
          'flex  items-center justify-between border-b bg-white p-6 dark:bg-gray-800'
        }
      >
        <div>
          <FIconWrapper>
            <SiOracle />
            <span className={'text-md block  font-semibold'}>
              توريد بيانات اوريكل
            </span>
          </FIconWrapper>

          <span
            className={
              'block text-sm  font-medium  text-gray-500 dark:text-gray-100  '
            }
          >
            قم بإضافةو عرض جميع البيانات التي سحبها من قاعدة بيانات أويكل
          </span>
        </div>
        <FButton onClick={() => setIsUploadModalOpen(true)}>
          <FFlexWrapper>
            <HiUpload />
            <span className={'mr-1 ml-1 text-sm'}> إضافة ملف جديد</span>
          </FFlexWrapper>
        </FButton>
      </div>
    </>
  )
}
export default OracleList
