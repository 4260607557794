import { useCustomAxios } from '../../../Hooks/useAxios'
import EFormWrapper from '../../../components/EFormWrapper'
import SFormWrapper from '../../../components/EFormWrapper'
import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { axiosInstance } from '../../../api/requister'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import { Formik } from 'formik'
import { getRoutePathByName } from '../../../helpers/utils'
import FButton from '../../../components/FButton'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { FiMinus, FiPlus } from 'react-icons/fi'
import {
  handleAddExtraInsuredMember,
  handleRemoveExtraInsuredMember,
} from './utils'

const AddSubscription = () => {
  const navigate = useNavigate()
  const { response, loading } = useCustomAxios({
    method: 'GET',
    url: `/product/listAllProducts?page=0&size=1000`,
  })
  const [installmentDocuments, setInstallmentDocuments] = useState([])

  const [insuredMembers, setInsuredMembers] = useState([
    {
      name: '',
      ssn: '',
      gender: '',
      relationToPolicyHolder: '',
    },
  ])
  const [selectedProduct, setSelectedProduct] = useState('')

  const durations = {
    QUARTERLY: 'ربع سنوي',
    MONTHLY: 'شهري',
    ANNUAL: 'سنوي',
    HALF_YEARLY: 'نصف سنوي',
  }

  const customerAxiosResponse = useCustomAxios({
    method: 'GET',
    url: `/customer?page=${0}&size=1000`,
  })
  customerAxiosResponse.response?.customers.map(customer => {
    return {
      value: customer.phoneNumber,
      label: customer.name,
    }
  })
  const loadOptions = (inputValue, callback) => {
    axiosInstance
      .get(
        `/customer?page=${0}&size=${
          inputValue === '' ? 5 : 100
        }&phoneNumber=${inputValue}`,
      )
      .then(res => {
        const options = res.data.customers.map(customer => {
          return {
            value: customer.phoneNumber,
            label: customer.name + ' - ' + customer.phoneNumber,
          }
        })
        callback(options)
      })
      .catch(err => {})
  }
  const getInitialValues = productInfo => {
    let initialValues = {
      customerPhoneNumber: '',
      installmentPackage: '',
    }
    productInfo.productCustomerInfos.map(productCustomerInfo => {
      initialValues[`key_${productCustomerInfo.id}`] = ''
    })

    return initialValues
  }
  const handleRequestBody = values => {
    let requestBody = {
      productId: response.products[selectedProduct].id,
      productInstallmentId: values.installmentPackage,
      customerPhoneNumber: values.customerPhoneNumber,
      answers: [],
    }
    Object.keys(values).map(key => {
      if (
        key !== 'product' &&
        key !== 'installmentPackage' &&
        key !== 'customerPhoneNumber'
      ) {
        requestBody.answers.push({
          productCustomerInfoId: key.split('_')[1],
          answer: values[key],
        })
      }
    })
    let productInstallmentTradeInfoDocuments = []
    installmentDocuments.map(productInstallmentTradeInfo => {
      if (productInstallmentTradeInfo.value !== '') {
        productInstallmentTradeInfoDocuments.push({
          documentNumber: productInstallmentTradeInfo.value,
          productInstallmentTradeInfoDocumentId: productInstallmentTradeInfo.id,
        })
      }
    })

    requestBody.customerSubscriptionDocumentDtos =
      productInstallmentTradeInfoDocuments

    return requestBody
  }

  const handleAddMedicalSubscriptionRelatives = subscriptionId => {
    axiosInstance
      .post(`/customersubscription/relative`, {
        customerSubscriptionId: subscriptionId,
        customerSubscriptionRelatives: insuredMembers.map(insuredMember => {
          return {
            name: insuredMember.name,
            nationalId: insuredMember.ssn,
            gender: insuredMember.gender,
            relationship: insuredMember.relationToPolicyHolder,
          }
        }),
      })
      .then(() => {
        toast.success('Relatives added successfully')
      })
      .catch(() => {
        toast.error('Something went wrong')
      })
  }

  const handleDocumentNumberSelection = index => {
    //clear InstallmentDocuments array
    let documents = []
    response.products[selectedProduct].productInstallments
      .filter(installment => installment.id === parseInt(index))[0]
      .productInstallmentTradeInfos.map(tradeInfo => {
        tradeInfo.productInstallmentTradeInfoDocuments.map(document => {
          documents.push({
            id: document.id,
            name: document.gigProductName,
            value: '',
          })
        })
      })
    setInstallmentDocuments(documents)
  }

  const handleFormSubmission = values => {
    axiosInstance
      .post('customersubscription', handleRequestBody(values))
      .then(res => {
        if (parseInt(selectedProduct) === 2) {
          handleAddMedicalSubscriptionRelatives(
            res.data.customerSubscription.id,
          )
        }
        toast.success('تمت الاضافة بنجاح')
        navigate(getRoutePathByName('subscriptionsList'))
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }
  if (loading) {
    return <div>loading...</div>
  } else {
    return (
      <>
        <div
          className={
            'primary-shadow flex justify-between border-b bg-white p-3 dark:bg-gray-900'
          }
        >
          <span className={'text-lg font-semibold'}>اضافة اشتراك</span>
        </div>
        <div className={'p-5'}>
          <EFormWrapper className={'w-10/12'}>
            <FLabel>المنتج</FLabel>
            <select
              name='product'
              onChange={e => {
                setSelectedProduct(e.target.value)
                setInstallmentDocuments([])
              }}
              className={'input-style'}
            >
              <option value={''}>اختر المنتج</option>
              {response.products.map((product, index) => {
                return (
                  <option key={product.id} value={index}>
                    {product.name}
                  </option>
                )
              })}
            </select>
          </EFormWrapper>
          {selectedProduct !== '' && (
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={getInitialValues(
                  response.products[selectedProduct],
                )}
                onSubmit={handleFormSubmission}
              >
                {({
                  handleSubmit,
                  handleBlur,
                  values,
                  handleChange,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={'w-full rounded-md'}
                  >
                    <EFormWrapper className={'w-10/12'}>
                      <FLabel>العميل</FLabel>
                      <AsyncSelect
                        placeholder={'اختر العميل'}
                        defaultOptions
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={e => {
                          setFieldValue('customerPhoneNumber', e.value)
                        }}
                      />
                    </EFormWrapper>
                    {selectedProduct === '2' && (
                      <EFormWrapper>
                        <div
                          className={
                            'rounded border  bg-gray-100 p-3 dark:bg-gray-600 '
                          }
                        >
                          {insuredMembers.map((insuredMember, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  'grid grid-cols-5 items-end justify-end gap-2'
                                }
                              >
                                <EFormWrapper>
                                  <FLabel>الاسم</FLabel>
                                  <FInputField
                                    name={`key_${index}`}
                                    onChange={e => {
                                      const newInsuredMembers =
                                        insuredMembers.map(
                                          (insuredMember, i) => {
                                            if (i === index) {
                                              return {
                                                ...insuredMember,
                                                name: e.target.value,
                                              }
                                            } else {
                                              return insuredMember
                                            }
                                          },
                                        )
                                      setInsuredMembers(newInsuredMembers)
                                    }}
                                    value={insuredMember.name}
                                    className={'input-style'}
                                  />
                                </EFormWrapper>
                                <EFormWrapper>
                                  <FLabel>رقم الهوية</FLabel>
                                  <FInputField
                                    name={`key_${index}`}
                                    onChange={e => {
                                      const newInsuredMembers =
                                        insuredMembers.map(
                                          (insuredMember, i) => {
                                            if (i === index) {
                                              return {
                                                ...insuredMember,
                                                ssn: e.target.value,
                                              }
                                            } else {
                                              return insuredMember
                                            }
                                          },
                                        )
                                      setInsuredMembers(newInsuredMembers)
                                    }}
                                    value={insuredMember.ssn}
                                    className={'input-style'}
                                  />
                                </EFormWrapper>
                                <EFormWrapper>
                                  <FLabel>النوع</FLabel>
                                  <select
                                    name={`key_${index}`}
                                    onChange={e => {
                                      const newInsuredMembers =
                                        insuredMembers.map(
                                          (insuredMember, i) => {
                                            if (i === index) {
                                              return {
                                                ...insuredMember,
                                                gender: e.target.value,
                                              }
                                            } else {
                                              return insuredMember
                                            }
                                          },
                                        )
                                      setInsuredMembers(newInsuredMembers)
                                    }}
                                    className={'input-style'}
                                  >
                                    <option value={''}>اختر النوع</option>
                                    <option value={'MALE'}>ذكر</option>
                                    <option value={'FEMALE'}>انثى</option>
                                  </select>
                                </EFormWrapper>
                                <EFormWrapper>
                                  <FLabel>العلاقة بالمشترك</FLabel>
                                  {JSON.stringify(insuredMember)}
                                  <select
                                    name={`key_${index}`}
                                    onChange={e => {
                                      const newInsuredMembers =
                                        insuredMembers.map(
                                          (insuredMember, i) => {
                                            if (i === index) {
                                              return {
                                                ...insuredMember,
                                                relationToPolicyHolder:
                                                  e.target.value,
                                              }
                                            } else {
                                              return insuredMember
                                            }
                                          },
                                        )
                                      setInsuredMembers(newInsuredMembers)
                                    }}
                                    value={insuredMember.relationToPolicyHolder}
                                    className={'input-style'}
                                  >
                                    <option value={''}>اختر العلاقة</option>
                                    <option value={'w'}>زوجة</option>
                                    <option value={'d'}>ابنة</option>
                                    <option value={'s'}>ابن</option>
                                  </select>
                                </EFormWrapper>
                                <EFormWrapper>
                                  <div className={'flex items-center gap-2'}>
                                    <FButton
                                      type={'button'}
                                      onClick={() => {
                                        handleAddExtraInsuredMember(
                                          setInsuredMembers,
                                          insuredMembers,
                                        )
                                      }}
                                    >
                                      <FiPlus size={20} />
                                    </FButton>
                                    {index !== 0 && (
                                      <FButton
                                        type={'button'}
                                        onClick={() => {
                                          handleRemoveExtraInsuredMember(
                                            index,
                                            insuredMembers,
                                            setInsuredMembers,
                                          )
                                        }}
                                      >
                                        <FiMinus size={20} />
                                      </FButton>
                                    )}
                                  </div>
                                </EFormWrapper>
                              </div>
                            )
                          })}
                        </div>
                      </EFormWrapper>
                    )}
                    <EFormWrapper className={'w-10/12'}>
                      <FLabel>الباقة</FLabel>
                      <select
                        className={'input-style'}
                        name='installmentPackage'
                        onChange={e => {
                          setFieldValue('installmentPackage', e.target.value)
                          setInstallmentDocuments([])
                          handleDocumentNumberSelection(e.target.value)
                        }}
                        value={values.installmentPackage}
                      >
                        <option value={''}>اختر الباقة</option>
                        {response.products[
                          selectedProduct
                        ].productInstallments.map(productPackage => {
                          return (
                            <option
                              key={productPackage.id}
                              value={productPackage.id}
                            >
                              {'وثيقة '}
                              {durations[productPackage.installmentType]}{' '}
                              {productPackage.installmentDesc}{' '}
                            </option>
                          )
                        })}
                      </select>
                    </EFormWrapper>
                    {installmentDocuments.length > 0 && (
                      <EFormWrapper className={'w-10/12'}>
                        <div>المستندات</div>
                        <div
                          className={
                            'flex gap-2 rounded border  bg-gray-100 p-3 dark:bg-gray-600 '
                          }
                        >
                          {installmentDocuments.map(
                            (installmentDocument, index) => (
                              <div
                                className={'flex flex-col gap-2'}
                                key={index}
                              >
                                <FLabel htmlFor={installmentDocument.id}>
                                  {installmentDocument.name}
                                </FLabel>
                                <FInputField
                                  type={'text'}
                                  id={installmentDocument.id}
                                  name={`installmentDocument_${installmentDocument.id}`}
                                  onChange={e => {
                                    const newInstallmentDocuments =
                                      installmentDocuments.map(
                                        (installmentDocument, i) => {
                                          if (i === index) {
                                            return {
                                              ...installmentDocument,
                                              value: e.target.value,
                                            }
                                          } else {
                                            return installmentDocument
                                          }
                                        },
                                      )
                                    setInstallmentDocuments(
                                      newInstallmentDocuments,
                                    )
                                  }}
                                  value={installmentDocument.value}
                                />
                              </div>
                            ),
                          )}
                        </div>
                      </EFormWrapper>
                    )}

                    <div className={'grid grid-cols-4 gap-2 border p-5'}>
                      {response.products[
                        selectedProduct
                      ].productCustomerInfos.map(productCustomerInfo => (
                        <div key={productCustomerInfo.id}>
                          <SFormWrapper>
                            <FLabel
                              htmlFor={`key_${productCustomerInfo.id}`}
                              className={'w-full'}
                            >
                              {productCustomerInfo.inputNameInReport}
                            </FLabel>
                            <FInputField
                              name={`key_${productCustomerInfo.id}`}
                              id={`key_${productCustomerInfo.id}`}
                              type={'text'}
                              placeholder={`أدخل ${productCustomerInfo.inputNameInReport}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values[`key_${productCustomerInfo.id}`]}
                            />
                          </SFormWrapper>
                        </div>
                      ))}
                    </div>
                    <FButton type={'submit'}>اضافة</FButton>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </>
    )
  }
}
export default AddSubscription
