import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import React from 'react'

const ProductTrades = ({ tradeInfo, setTradeInfo }) => {
  const handleAddExtraField = () => {
    setTradeInfo([
      ...tradeInfo,
      {
        tradeName: '',
        gigProductName: '',
      },
    ])
  }
  const handleRemoveExtraField = index => {
    const values = [...tradeInfo]
    values.splice(index, 1)
    setTradeInfo(values)
  }

  const handleTradeInfoChange = (e, index) => {
    const { name, value } = e.target
    const values = [...tradeInfo]
    values[index][name] = value
    setTradeInfo(values)
  }

  return (
    <>
      <span>أنواع الوثائق</span>
      {tradeInfo.map((trade, index) => (
        <div key={index} className={'my-3 flex gap-2'}>
          <div
            className={
              'flex-grow items-end justify-end rounded border  bg-gray-100 p-3 dark:bg-gray-600'
            }
          >
            <div className={'grid grid-cols-2 gap-2'}>
              <SFormWrapper>
                <FLabel htmlFor={`productDocumentType${index}`}>
                  نوع الوثيقة
                </FLabel>
                <FInputField
                  id={`productDocumentType${index}`}
                  name={'tradeName'}
                  placeholder={'نوع الوثيقة'}
                  value={trade.tradeName}
                  onChange={e => handleTradeInfoChange(e, index)}
                />
              </SFormWrapper>
              <SFormWrapper>
                <FLabel htmlFor={`productGIGDocumentType${index}`}>
                  نوع الوثيقة في جي أي جي
                </FLabel>
                <FInputField
                  id={`productGIGDocumentType${index}`}
                  name={'gigProductName'}
                  value={trade.gigProductName}
                  placeholder={'نوع الوثيقة في جي أي جي'}
                  onChange={e => handleTradeInfoChange(e, index)}
                />
              </SFormWrapper>
            </div>
          </div>
          {/*<div className={"flex items-end gap-2 "}>
            <FButton type={"button"} onClick={() => handleAddExtraField()}>
              <FiPlus size={20} />
            </FButton>
            {tradeInfo.length > 1 && (
              <FButton type={"button"} onClick={() => handleRemoveExtraField(index)}>
                <FiMinus size={20} />
              </FButton>
            )}
          </div>*/}
        </div>
      ))}
    </>
  )
}
export default ProductTrades
