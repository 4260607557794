export const handleAddExtraInsuredMember = (
  setInsuredMembers,
  insuredMembers,
) => {
  setInsuredMembers([
    ...insuredMembers,
    {
      name: '',
      ssn: '',
      gender: '',
      relationToPolicyHolder: '',
    },
  ])
}

export const handleRemoveExtraInsuredMember = (
  index,
  insuredMembers,
  setInsuredMembers,
) => {
  const newInsuredMembers = insuredMembers.filter((_, i) => i !== index)
  setInsuredMembers(newInsuredMembers)
}
