import forbidden from '../../assets/images/access_den.png'
import { Link } from 'react-router-dom'

const ForbiddenPage = () => (
  <>
    <div className='f-col-center-center h-screen'>
      <img src={forbidden} width={650} className={''} />
      <span className={'text-5xl font-bold text-orient-500'}>عذراً !</span>
      <span className={'mt-4    text-lg text-gray-600'}>
        الصفحة التي تحاول الوصول إليها مقيد الوصول
      </span>
      <span className={'text    text-gray-600'}>
        يرجى الرجوع إلى مسؤول النظام الخاص بك
      </span>

      <Link
        to={'/'}
        className={
          'mt-5 flex justify-center  rounded bg-orient-500 p-1.5 text-sm text-gray-600 text-white shadow-sm ring-orient-500 hover:bg-orient-700 focus:ring-2 focus:ring-offset-2 disabled:bg-orient-300'
        }
      >
        الرجوع إلى الصفحة الرئيسية
      </Link>
    </div>
  </>
)
export default ForbiddenPage
