import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../api/requister'

const Deactivate = ({ email, callBack }) => {
  const handleAccountAbort = () =>
    axiosInstance
      .post(`user/${email}/deactivate`)
      .then(res => callBack())
      .catch(err => console.log(err))

  return (
    <>
      <FButton
        type={'button'}
        btnType={'danger'}
        onClick={() => handleAccountAbort()}
      >
        تعطيل الحساب
      </FButton>
    </>
  )
}
export default Deactivate
