import OracleList from '../views/Admin/Oracle/OracleList'
import Help from '../views/Help/help'
import ListSubscriptions from '../views/Admin/Subscriptions/ListSubscriptions'
import AddSubscription from '../views/Admin/Subscriptions/AddSubscription'
import Dashboard from '../views/Dashboard/Dashboard'
import Reports from '../views/Admin/Reports'
import ResidualsList from '../views/Admin/Residuals/residualsList'
import SettleResidual from '../views/Admin/Residuals/SettleResidual'
import ListHistory from '../views/Admin/RportDonwloadHistory/ListHistory'
import ListCall from '../views/Admin/Calls/ListCall'
import ReportsTemplates from '../views/Admin/Reports/ReportsTemplates'
import ListCallTypes from '../views/Admin/CallTypes/ListCallTypes'
import ViewSubs from '../views/Admin/Subscriptions/ViewSubs'
import Stores from '../views/Admin/Stores/Stores'
import ReviewSub from '../views/Admin/ReviewSub/ReviewSub'
import AddClaim from '../views/Admin/cliams/AddClaim'
import ListClaim from '../views/Admin/cliams/ListClaim'
import EditClaim from '../views/Admin/cliams/EditClaim'
import Pendings from '../views/Admin/Pendings/Pendings'
import Calls from '../views/Admin/CallsListings/Calls'
import ListTasks from '../views/Admin/Tasks/ListTasks'

export const AppRoutes = [
  { path: '/oracle', element: <OracleList />, name: 'OracleUpload' },
  { path: '/help', element: <Help />, name: 'help' },
  { path: '/Dashboard', element: <Dashboard />, name: 'dashboard' },

  {
    path: '/subscription',
    element: <ListSubscriptions />,
    name: 'subscriptionsList',
  },
  {
    path: '/listcalls',
    element: <ListCall />,
    name: 'callsList',
  },
  {
    path: '/claim',
    element: <ListClaim />,
  },
  {
    path: '/claim/add',
    element: <AddClaim />,
  },
  {
    path: '/claim/:id',
    element: <EditClaim />,
  },
  {
    path: '/subscription/add',
    element: <AddSubscription />,
    name: 'subscriptionsAdd',
  },
  {
    path: '/subscription/:id',
    element: <ViewSubs />,
    name: 'viewSubscription',
  },
  {
    path: '/subscriptionDup/:id',
    element: <ViewSubs />,
    name: 'viewSubscriptionAnother',
  },
  {
    path: '/newSubscription/:id',
    element: <ViewSubs />,
    name: 'viewSubscriptionAnother',
  },
  {
    path: '/settle/:id/:residual',
    element: <SettleResidual />,
    name: 'settle',
  },

  { path: '/reports', element: <Reports />, name: 'reports' },
  {
    path: '/templates',
    element: <ReportsTemplates />,
    name: 'reportsTemplates',
  },
  {
    path: '/stores',
    element: <Stores />,
    name: 'stores',
  },
  {
    path: '/review',
    element: <ReviewSub />,
    name: 'stores',
  },
  { path: '/call-types', element: <ListCallTypes />, name: 'listCallTypes' },
  { path: '/residual', element: <ResidualsList />, name: 'residuals' },
  { path: '/history', element: <ListHistory />, name: 'history' },
  { path: '/pending', element: <Pendings />, name: 'pending' },
  { path: '/calls', element: <Calls />, name: 'calls' },
  { path: '/tasks', element: <ListTasks />, name: 'listTasks' },
]
