import FModal from '../../components/FModal'
import { useFormik } from 'formik'
import SFormWrapper from '../../components/EFormWrapper'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import EFormInvalidInput from '../../components/EFormInvalidInput'
import FButton from '../../components/FButton'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ESpinner from '../../components/ESpinner'
import FIconWrapper from '../../components/FIconWrapper'
import axios from 'axios'
import { BASE_URL } from '../../env'

const SalesBasicInfo = () => {
  const navigation = useNavigate()
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  let { uuid } = useParams()

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('يجب ادخال الأسم بالكامل'),
    password: Yup.string().required('يجب ادخال كلمة المرور'),
    team: Yup.string().required('يجب تحديد الفريق'),
    phone: Yup.string()
      .required('يجب ادخال رقم الهاتف')
      .matches(/^[0-9]+$/, 'يجب ان يكون رقم الهاتف ارقام فقط')
      .length(11, 'يجب ان يكون رقم الهاتف 11 رقم'),
  })

  const handleRequestBody = values => ({
    invitationId: uuid,
    name: values.name,
    phoneNumber: values.phone,
    salesRepTeam: values.team,
    password: values.password,
    confirmPassword: values.password,
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      team: '',
      password: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: values => {
      setIsLoading(true)
      const requestBody = handleRequestBody(values)
      axios
        .post(`${BASE_URL}user/acceptSalesRepUserInvitation`, requestBody)
        .then(res => {
          setIsLoading(false)
          setTimeout(() => {
            setIsRegistrationModalOpen(false)
            return navigation('/login')
          }, 3000)

          toast.success('تم تفعيل الحساب بنجاح. سوف يتم توجيهك لتسجيل الدخول')
        })
        .catch(err => {
          setIsLoading(false)
          toast.error(err.response.data.message)
        })
    },
  })

  return (
    <>
      <FModal
        isOpen={isRegistrationModalOpen}
        isDismissible={false}
        setIsOpen={setIsRegistrationModalOpen}
        title={'تسجيل مبيعات مستخدم جديد'}
      >
        <form onSubmit={formik.handleSubmit}>
          <SFormWrapper>
            <FLabel htmlFor={'name'}> الاسم </FLabel>
            <FInputField
              type='text'
              name={'name'}
              id={'name'}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'أدخل الاسم'}
            />
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'name'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FInputField
              type='text'
              name={'phone'}
              id={'phone'}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'أدخل  رقم الهاتف'}
            />
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'phone'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FLabel htmlFor={'team'}> الفريق </FLabel>
            <select
              value={formik.values.team}
              name={'team'}
              className={'input-style'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value=''>أختر الفريق</option>
              <option value='SME'>SME</option>
              <option value='DISTRIBUTION'>Distribution التوريع</option>
              <option value='FAWRY_PLUS'>فوري بلس</option>
              <option value='OTHER'>أخري</option>ي
            </select>
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'team'}
            />
          </SFormWrapper>
          <SFormWrapper>
            <FLabel htmlFor={'password'}> كلمة المرور </FLabel>
            <FInputField
              type='password'
              name={'password'}
              id={'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'أدخل كلمة المرور'}
            />
            <EFormInvalidInput
              touched={formik.touched}
              errors={formik.errors}
              FieldName={'password'}
            />
          </SFormWrapper>
          <FButton type={'submit'}>
            <FIconWrapper>
              <ESpinner isVisible={isLoading} />
              تسجيل
            </FIconWrapper>
          </FButton>
        </form>
      </FModal>
    </>
  )
}
export default SalesBasicInfo
