import { Popover } from '@headlessui/react'
import FInputField from './FInputField'
import EFormWrapper from './EFormWrapper'
import FButton from './FButton'
import { axiosInstance } from '../api/requister'
import { useState } from 'react'
import ESpinner from './ESpinner'
import FIconWrapper from './FIconWrapper'
import { toast } from 'react-toastify'
import { BadgeCheck, HandCoins, MessageCircleX } from 'lucide-react'

const FCustomPopOver = ({ button, callId, callBack, title, children }) => {
  const [scheduleDate, setScheduleDate] = useState('')
  const [isScheduleRequestLoading, setIsScheduleRequestLoading] =
    useState(false)

  const [customCallInteraction, setCustomCallInteraction] = useState('')
  const handleRescheduleCall = (callId, close) => {
    setIsScheduleRequestLoading(true)
    axiosInstance
      .post(`call/${callId}/reschedule`, {
        scheduledTo: scheduleDate,
      })
      .then(res => {
        setIsScheduleRequestLoading(false)

        callBack()
        close()
        toast.success('تم تحديد موعد جديد للمكالمة')
      })
      .catch(err => {
        toast.error(err.response.data.message)
        setIsScheduleRequestLoading(false)
      })
  }
  return (
    <Popover className='relative'>
      <Popover.Button>{button}</Popover.Button>
      <Popover.Panel className='absolute left-0 top-10 z-10  min-w-max rounded border bg-white shadow-lg dark:bg-gray-900 '>
        {({ close }) => (
          <div className='flex flex-col gap-2'>
            {title && (
              <div className={'border-b p-3'}>
                <span className='block whitespace-nowrap text-right text-sm  text-gray-700 dark:text-gray-100  '>
                  {title}
                </span>
              </div>
            )}
            <div className={'flex flex-col p-3'}>
              <div className={'flex flex-col gap-2'}>
                <FButton btnType={'success'}>
                  <FIconWrapper>
                    <BadgeCheck size={20} />
                    تم اكتمال البيانات
                  </FIconWrapper>
                </FButton>
                <FButton btnType={'success'}>
                  <FIconWrapper>
                    <HandCoins size={20} />
                    تم البيع
                  </FIconWrapper>
                </FButton>
                <FButton btnType={'danger'}>
                  <FIconWrapper>
                    <MessageCircleX size={20} />
                    رفض
                  </FIconWrapper>
                </FButton>
              </div>
              <EFormWrapper className={'w-full'}>
                <FInputField
                  value={customCallInteraction}
                  onChange={e => setCustomCallInteraction(e.target.value)}
                  placeholder={'ادخل نص الاجراء'}
                  type={'text'}
                />
              </EFormWrapper>
              {customCallInteraction !== '' && (
                <FButton onClick={() => handleRescheduleCall(callId, close)}>
                  <FIconWrapper>
                    <ESpinner isVisible={isScheduleRequestLoading} />
                    حفظ
                  </FIconWrapper>
                </FButton>
              )}
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  )
}
export default FCustomPopOver
