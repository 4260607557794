import fawryGig from '../../../assets/images/fawry-gig.png'
import suspendedMachine from '../../../assets/images/suspendedMachiene.png'
import activedMachine from '../../../assets/images/activeMachiene.5be43f56c2bec78762af.png'
import fawryOnly from '../../../assets/images/fawry-only-report-logo.png'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai'
import fawryGov from '../../../assets/images/fawry-financial.png'
import React from 'react'
import { axiosInstance } from '../../../api/requister'
import FModal from '../../../components/FModal'
import FInputField from '../../../components/FInputField'
import ESpinner from '../../../components/ESpinner'
import { toast } from 'react-toastify'
import moment from 'moment'
import FLabel from '../../../components/FLabel'
import EFormWrapper from '../../../components/EFormWrapper'

const InternalReports = () => {
  const reportMap = {
    suspendedMachinates: {
      name: 'المكائن المعلقة',
      uploadPath: 'report/uploadSuspendedMachines',
    },
    financialOperationResponse: {
      name: 'رفع تقرير تأكيد قسم Financial Operations',
      uploadPath: 'report/financialOperationResponseFile',
    },
    activeFIBInsuranceCRM: {
      name: 'أكمال تأمين الفيب',
      uploadPath: '/report/activeFIBInsuranceCRM',
    },
  }
  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false)
  const [selectedReport, setSelectedReport] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [isInternalReportModalOpen, setIsInternalReportModalOpen] =
    React.useState(false)
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndData] = React.useState('')
  const [file, setFile] = React.useState('')
  const [isDownloadReportDownloading, setIsDownloadReportDownloading] =
    React.useState(false)
  const [
    reportEndPointAndResponseFileName,
    setReportEndPointAndResponseFileName,
  ] = React.useState({
    endPoint: '',
    responseFileName: '',
  })
  const [optionalEmail, setOptionalEmail] = React.useState('')

  const handleReportUpload = () => {
    setIsLoading(true)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    const formData = new FormData()
    formData.append('file', file)
    axiosInstance
      .post(reportMap[selectedReport].uploadPath, formData, config)
      .then(res => {
        setIsLoading(false)
        setIsUploadModalOpen(false)
        toast.success('تم رفع الملف بنجاح')
      })
      .catch(err => {
        setIsLoading(false)
        setIsUploadModalOpen(false)
        toast.error(err.response.data.message)
      })
  }

  const handleReportDownload = (path, name) => {
    setIsDownloadReportDownloading(true)
    axiosInstance
      .get(path, {
        responseType: 'blob',
      })
      .then(res => {
        setIsDownloadReportDownloading(false)
        setStartDate('')
        setEndData('')
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click()
        setIsInternalReportModalOpen(false)
      })
      .catch(err => {
        setIsDownloadReportDownloading(false)
        toast.error('حدث خطا')
      })
  }

  const handlePath = attributesWithValues => {
    let urlParams = '&'
    attributesWithValues.forEach(attr => {
      if (attr.attributeValue !== '') {
        urlParams += `${attr.attributeName}=${attr.attributeValue}&`
      }
    })
    return urlParams
  }
  return (
    <>
      <FModal
        callbackFunction={() => {
          setStartDate('')
          setEndData('')
        }}
        isOpen={isInternalReportModalOpen}
        setIsOpen={setIsInternalReportModalOpen}
        title='تقارير داخلية'
      >
        <span className={'block text-right text-lg font-medium'}>
          قم بتحديد تاريخ البداية و النهاية
        </span>
        <div className={'grid grid-cols-2 gap-2'}>
          <EFormWrapper className={'col-span-2'}>
            <FLabel htmlFor={'email'}>البريد الالكتروني</FLabel>
            <FInputField
              name={'email'}
              placeholder={'اختياري'}
              id={'email'}
              type={'email'}
              value={optionalEmail}
              onChange={e => setOptionalEmail(e.target.value)}
            />
          </EFormWrapper>

          <EFormWrapper>
            <FLabel htmlFor={'startDate'}>تاريخ البداية</FLabel>
            <FInputField
              name={'startDate'}
              max={moment().format('YYYY-MM-DD')}
              id={'startDate'}
              type='date'
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
            />
          </EFormWrapper>
          <EFormWrapper>
            <FLabel htmlFor={'endDate'}>تاريخ النهاية</FLabel>
            <FInputField
              type='date'
              name={'endDate'}
              max={moment().format('YYYY-MM-DD')}
              id={'endDate'}
              value={endDate}
              onChange={e => setEndData(e.target.value)}
            />
          </EFormWrapper>
        </div>
        <div className={'flex items-center gap-2'}>
          <FButton
            onClick={() => {
              handleReportDownload(
                `${reportEndPointAndResponseFileName.endPoint}${handlePath([
                  {
                    attributeName: 'startDate',
                    attributeValue: moment(startDate).format('DD/MM/YYYY'),
                  },
                  {
                    attributeName: 'endDate',
                    attributeValue: moment(endDate).format('DD/MM/YYYY'),
                  },
                  {
                    attributeName: 'emailToReceiveTheReport',
                    attributeValue: optionalEmail,
                  },
                ])}`,
                reportEndPointAndResponseFileName.responseFileName,
              )
            }}
          >
            <FIconWrapper>
              <ESpinner isVisible={isDownloadReportDownloading} />
              تحميل
            </FIconWrapper>
          </FButton>
          <FButton
            btnType={'secondary'}
            onClick={() => {
              setIsInternalReportModalOpen(false)
            }}
          >
            إلغاء
          </FButton>
        </div>
      </FModal>

      <FModal
        title={reportMap[selectedReport]?.name}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
      >
        <FInputField
          type='file'
          id='formFile'
          onChange={e => setFile(e.target.files[0])}
        />
        <div className={'mt-2 flex gap-2'}>
          <FButton
            onClick={() => {
              handleReportUpload()
            }}
          >
            <FIconWrapper>
              <ESpinner isVisible={isLoading} />
              رفع
            </FIconWrapper>
          </FButton>
          <FButton
            btnType={'secondary'}
            onClick={() => setIsUploadModalOpen(false)}
          >
            إلغاء
          </FButton>
        </div>
      </FModal>
      <div className={'flex h-full flex-col items-start gap-2 p-5'}>
        <span
          className={
            'text-lg font-semibold  text-gray-700 dark:text-gray-100  '
          }
        >
          التقارير الداخليه
        </span>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryOnly} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              رفع تقرير FIB
            </span>
          </div>
          <FButton
            onClick={() => {
              setSelectedReport('activeFIBInsuranceCRM')
              setIsUploadModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineUpload />
              <span className={'text-sm'}>رفع التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGig} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              رفع تقرير التأكيد من قسم العمليات المالية
            </span>
          </div>
          <FButton
            onClick={() => {
              setSelectedReport('financialOperationResponse')
              setIsUploadModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineUpload />
              <span className={'text-sm'}>رفع التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={suspendedMachine} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              رفع تقرير المكاينات المعطلة
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              قم بأضافة التقرير الخاص بالماكينات المعطلة و المشتركة ف منتج
              التجار
            </span>
          </div>
          <FButton
            onClick={() => {
              setSelectedReport('suspendedMachinates')
              setIsUploadModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineUpload />
              <span className={'text-sm'}> رفع التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>

        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={activedMachine} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تنزيل تقرير المكاينات المفعلة
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              قم بتنزيل التقرير الخاص بالماكينات المفعلة و المشتركة ف منتج
              التجار
            </span>
          </div>
          <FButton
            onClick={() =>
              handleReportDownload(
                '/report/download?excelReportType=MACHINE_IDS_REPORT',
                `تقرير ماكينات التجار المفعلة ${moment().format('DD-MM-YYYY')}`,
              )
            }
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>

        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryOnly} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تنزيل تقرير ال Residuals
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              قم بتنزيل التقرير الخاص بالمعامالات المتبقية
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=RESIDUALS_FOR_PRODUCT_REPORT',
                responseFileName: `تقرير المعاملات المرتجعة ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>

        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGov} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              تنزيل سجل الوثائق و العمولات
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              يمكنك تنزيل التقرير الخاص باصدار الوثائق المرسل من فوري لجي أي جي
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=DOCUMENTS_AND_COMMISSIONS_RECORDS',
                responseFileName: `سجل الوثائق و العمولات ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGov} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              سجل الإلغاءات
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=CANCELLATIONS_EXPORT',
                responseFileName: `سجل الإلغاءات ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryGov} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              سجل الاشتراكات
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              تنزيل التقرير الخاص بالاشتراكات المفعلة لكل منتج تأميني
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=ACTIVE_PRODUCT_DOCUMENTS',
                responseFileName: `سجل الاشتراكات المفعلة ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryOnly} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              سجل طلب SMS
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              تنزيل التقرير الخاص بطلبات الرسائل القصيرة
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=CUSTOMERS_NEEDS_SMS',
                responseFileName: `سجل طلب SMS ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryOnly} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              سجل الشكاوي
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              تنزيل التقرير الخاص بالشكاوي المقدمة من العملاء
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=CUSTOMERS_SEND_THE_GIG_DOCUMENT',
                responseFileName: `سجل الاشتراكات المفعلة ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
        <div
          className={
            'flex w-full items-center justify-start gap-5 rounded border bg-white p-3 dark:bg-gray-900'
          }
        >
          <img src={fawryOnly} width={150} alt='fawryGig' />
          <div className={'flex flex-col'}>
            <span
              className={'font-semibold  text-gray-700 dark:text-gray-100  '}
            >
              سجل اداء الموظفين
            </span>
            <span className={'text-sm  text-gray-500 dark:text-gray-100  '}>
              تنزيل التقرير الخاص باداء الموظفين
            </span>
          </div>
          <FButton
            onClick={() => {
              setReportEndPointAndResponseFileName({
                endPoint:
                  '/report/download?excelReportType=AGENTS_PERFORMANCE_REPORT',
                responseFileName: `سجل اداء الموظفين ${moment().format(
                  'DD-MM-YYYY',
                )}`,
              })
              setIsInternalReportModalOpen(true)
            }}
            className={'mr-auto'}
          >
            <FIconWrapper>
              <AiOutlineDownload />
              <span className={'text-sm'}> تنزيل التقرير</span>
            </FIconWrapper>
          </FButton>
        </div>
      </div>
    </>
  )
}
export default InternalReports
