import { useNavigate, useParams } from 'react-router-dom'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { useEffect, useState } from 'react'
import SFormWrapper from '../../../components/EFormWrapper'
import FLabel from '../../../components/FLabel'
import FInputField from '../../../components/FInputField'
import ProductCompletionTrails from '../Products/ProductCompletionTrails'
import FButton from '../../../components/FButton'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
///api/v1/product/getProduct?id=1

const EditProductCancelation = () => {
  const { id } = useParams()
  const { response, loading } = useCustomAxios({
    method: 'GET',
    url: `/product/getProduct?id=${id}`,
  })
  const productAxiosResponse = useCustomAxios({
    method: 'GET',
    url: '/product/listAllProducts?page=0&size=1000000000',
  })
  const navigation = useNavigate()
  useEffect(() => {
    if (response) {
      setFormState({
        form: {
          cancellationName: response.product.name,
          cancellationBTC: response.product.btc,
          product: response.product.idOFCancelledProduct,
        },
      })
      setTrailsBlock({
        trails: response.product.productRetrials.map(trail => ({
          trialCount: trail.retrialCount,
          trialGap: trail.day,
          id: trail.id,
          trialTimes: trail.productRetrialDate.map(time => ({
            trailTime: time.dialDate,
            id: time.id,
          })),
        })),
      })
    }
  }, [response])

  const [formState, setFormState] = useState({
    form: {
      cancellationName: '',
      cancellationBTC: '',
      product: '',
    },
  })
  const [trailsBlock, setTrailsBlock] = useState({
    trails: [
      {
        trialCount: '',
        trialGap: '',
        trialTimes: [],
      },
    ],
  })
  const handleBasicCancelationInfo = e => {
    const { name, value } = e.target
    setFormState({
      form: {
        ...formState.form,
        [name]: value,
      },
    })
  }
  const handleEditProductCancelation = () => {
    const requestBody = {
      id,
      productName: formState.form.cancellationName,
      btc: formState.form.cancellationBTC,
      productType: 'CANCELLATION',
      idOFCancelledProduct: parseInt(formState.form.product),
      productRetrials: trailsBlock.trails.map(trail => ({
        id: trail.id,
        retrialCount: trail.trialCount,
        day: trail.trialGap,
        productRetrialDate: trail.trialTimes.map(time => ({
          dialDate: time.trailTime + ':00',
          id: time.id,
        })),
      })),
    }
    axiosInstance
      .post('/product/editCancellationForProduct', requestBody)
      .then(() => {
        toast.success('تم تعديل الإلغاء بنجاح')
        navigation('/productCancelList')
      })
      .catch(err => toast.error(err.response.data.message))
  }
  if (loading || productAxiosResponse.loading) return <div>Loading...</div>
  else
    return (
      <div>
        <div className={'p-5'}>
          <div className={'grid grid-cols-3 gap-2'}>
            <SFormWrapper>
              <FLabel>الأسم</FLabel>
              <FInputField
                value={formState.form.cancellationName}
                name={'cancellationName'}
                onChange={handleBasicCancelationInfo}
                type={'text'}
                placeholder={'مثال: إلغاء الأشتراك في منتج التجار'}
              />
            </SFormWrapper>
            <SFormWrapper>
              <FLabel>بي تي سي الألغاء</FLabel>
              <FInputField
                value={formState.form.cancellationBTC}
                name={'cancellationBTC'}
                onChange={handleBasicCancelationInfo}
                type={'text'}
              />
            </SFormWrapper>
            <SFormWrapper>
              <FLabel>المنتج</FLabel>
              <select
                value={formState.form.product}
                onChange={handleBasicCancelationInfo}
                name={'product'}
                className={'input-style'}
              >
                <option value={''}>أختر منتج</option>
                {productAxiosResponse.response.products.map(
                  (product, index) => (
                    <option key={index} value={product.id}>
                      {product.name}
                    </option>
                  ),
                )}
              </select>
            </SFormWrapper>
          </div>
          <ProductCompletionTrails
            setTrailsBlock={setTrailsBlock}
            trailsBlock={trailsBlock}
          />
          <SFormWrapper>
            <FButton onClick={handleEditProductCancelation}>إضافة</FButton>
          </SFormWrapper>
        </div>
      </div>
    )
}
export default EditProductCancelation
