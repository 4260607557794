import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { axiosInstance } from '../../../api/requister'
import { JsonToTable } from 'react-json-to-table'
import FButton from '../../../components/FButton'
import FRow from '../../../components/FRow'

const Pendings = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page')) || 0
  const size = 10 // Define the size of each page

  const { data, isLoading, error } = useQuery({
    queryKey: ['getClaims', page],
    cacheTime: 0,
    queryFn: () =>
      axiosInstance
        .get(`report/listAllExportedReports?page=${page}&size=${size}`)
        .then(res => res.data),
    keepPreviousData: true, // This keeps the previous data while new data is being fetched
  })

  const handleNextPage = () => {
    setSearchParams({ page: page + 1 })
  }

  const handlePreviousPage = () => {
    setSearchParams({ page: Math.max(page - 1, 0) })
  }

  useEffect(() => {
    // This effect ensures the page is reset to 0 when component mounts
    if (!searchParams.get('page')) {
      setSearchParams({ page: 0 })
    }
  }, [searchParams, setSearchParams])

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <>
      <div className={'overflow-x-auto bg-white border shadow-sm border'}>
        <JsonToTable
          json={data?.records?.map(item => {
            return {
              exportExcelType: item.exportExcelType,
              exportedAt: new Date(item.exportedAt).toLocaleString(),
              fileName: item.fileName,
              id: item.id,
              status: item.status,
              totalCount: item.totalCount,
              processedCount: item.processedCount,
              responseUri: item.responseUri,
              type: item.type,
              uri: item.uri,
              exporteBy: item.exporteBy.email,
            }
          })}
        />
      </div>
      <FRow
        className={
          'justify-between mt-2  p-2 bg-white border-b shadow-sm border'
        }
      >
        <FRow>
          <FButton onClick={handlePreviousPage} disabled={page === 0}>
            السابق
          </FButton>

          <FButton
            onClick={handleNextPage}
            disabled={data?.records?.length < size}
          >
            التالي
          </FButton>
        </FRow>
        <span>صفحة {page + 1}</span>
      </FRow>
    </>
  )
}

export default Pendings
