import { Formik } from 'formik'
import SFormWrapper from '../../../components/EFormWrapper'
import FInputField from '../../../components/FInputField'
import FButton from '../../../components/FButton'
import React, { useEffect, useState } from 'react'
import FLabel from '../../../components/FLabel'
import * as Yup from 'yup'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { camelize } from '../../../helpers/utils'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'
import { axiosInstance } from '../../../api/requister'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const CustomerEdit = () => {
  const { phoneNumber } = useParams()
  const [chosenProduct, setChosenProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: '/product/listAllProducts?page=0&size=1000',
    })
  const customerAxiosCallBack = useCustomAxios({
    method: 'GET',
    url: `/customer/getCustomer?phoneNumber=${phoneNumber}`,
  })

  useEffect(() => {
    if (customerAxiosCallBack.response) {
      setChosenProduct(customerAxiosCallBack.response.product.id)
    }
  }, [customerAxiosCallBack.response])

  const ValidationSchema = Yup.object().shape({
    // CustomerName: Yup.string().required("الاسم مطلوب"),
    // product: Yup.string().required("المنتج مطلوب"),
    // CustomerPhoneNumber: Yup.string().required("رقم الهاتف مطلوب"),
  })

  const customValidation = values => {
    let errors = {}
    // if (hasMedicalInsurance){
    //     if (values.firstSubscriberName !== "" && values.firstSubscriberNationalID === ""  ){
    //         errors.firstSubscriberNationalID="this field is required"
    //     }
    // }
    return errors
  }

  const getInitialValues = productCustomerInfos => {
    let initialValues = {
      customerName: customerAxiosCallBack.response?.customer.name,
      customerPhone: customerAxiosCallBack.response?.customer.phoneNumber,
    }

    productCustomerInfos.map(productCustomerInfo => {
      initialValues[`key_${productCustomerInfo.id}`] =
        productCustomerInfo.inputAnswerValue
    })

    return initialValues
  }

  const handleRequestBody = values => {
    let requestBody = {
      id: customerAxiosCallBack.response?.customer.id,
      customerName: values.customerName,
      customerPhone: values.customerPhone,
      productId: response.products[chosenProduct]['id'],
    }
    let customerInfoAnswers = []
    Object.keys(values).map(key => {
      if (key.includes('key_')) {
        customerInfoAnswers.push({
          productCustomerInfo: {
            id: parseInt(key.split('_')[1]),
          },
          inputAnswerValue: values[key],
        })
      }
    })
    requestBody.customerInfoAnswers = customerInfoAnswers
    return requestBody
  }
  const handleFormSubmission = (values, e) => {
    setIsLoading(true)
    axiosInstance
      .post('/customer/addCustomer', handleRequestBody(values))
      .then(res => {
        setIsLoading(false)
        toast.success('تم إضافة العميل بنجاح')
        navigate('/customers')
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err.response.data.message)
      })
  }
  if (loading || customerAxiosCallBack.loading) {
    return <div>loading...</div>
  } else {
    const customerResponse = customerAxiosCallBack.response
    return (
      <>
        <div className={'px-5 pt-5'}>
          <span className={'mb-2 block text-sm'}>أختر المنتج التأميني</span>
          <div className={'flex flex-wrap justify-start gap-1 p-1'}>
            {JSON.stringify(chosenProduct)}
            {response.products.map((product, index) => (
              <div
                onClick={() => setChosenProduct(product.id)}
                className={`rounded border-2 bg-white p-2 hover:cursor-pointer dark:bg-gray-900 ${
                  chosenProduct === product.id &&
                  'border-orient-700 bg-orient-500 text-white'
                }`}
                key={product.btc}
              >
                <span className={'whitespace-nowrap'}> {product.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={'h-screen w-full p-5'}>
          {chosenProduct !== null && (
            <div className={''}>
              <Formik
                validationSchema={ValidationSchema}
                enableReinitialize={true}
                initialValues={getInitialValues(
                  customerAxiosCallBack.response.product.productCustomerInfos,
                )}
                validate={customValidation}
                onSubmit={handleFormSubmission}
              >
                {({
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={'w-full rounded-md'}
                  >
                    {JSON.stringify(values)}
                    <SFormWrapper className={'w-10/12'}>
                      <FLabel htmlFor={'customerName'} className={'w-full'}>
                        الاسم
                      </FLabel>
                      <FInputField
                        name={'customerName'}
                        id={'customerName'}
                        type={'text'}
                        placeholder={'أدخل الاسم'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.customerName}
                      />
                    </SFormWrapper>
                    <SFormWrapper className={'w-10/12'}>
                      <FLabel htmlFor={'customerPhone'} className={'w-full'}>
                        رقم الهاتف
                      </FLabel>
                      <FInputField
                        name={'customerPhone'}
                        id={'customerPhone'}
                        type={'text'}
                        placeholder={'أدخل رقم الهاتف'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.customerPhone}
                      />
                    </SFormWrapper>

                    {customerAxiosCallBack.response.product.productCustomerInfos.map(
                      productCustomerInfo => (
                        <div key={productCustomerInfo.id} className={'w-full'}>
                          <SFormWrapper className={'w-10/12'}>
                            <FLabel
                              htmlFor={`key_${camelize(
                                productCustomerInfo.id,
                              )}`}
                              className={'w-full'}
                            >
                              {productCustomerInfo.inputName}
                            </FLabel>
                            <FInputField
                              name={`key_${camelize(productCustomerInfo.id)}`}
                              id={`key_${camelize(productCustomerInfo.id)}`}
                              type={
                                productCustomerInfo.containsOnlyLetters
                                  ? 'text'
                                  : 'number'
                              }
                              placeholder={`أدخل ${productCustomerInfo.inputName}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values[
                                  `key_${camelize(productCustomerInfo.id)}`
                                ]
                              }
                            />
                          </SFormWrapper>
                        </div>
                      ),
                    )}
                    <div>
                      <FButton type={'submit'}>
                        <FIconWrapper>
                          <span>أضافة</span>
                          <ESpinner isVisible={isLoading} />
                        </FIconWrapper>
                      </FButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </>
    )
  }
}
export default CustomerEdit
