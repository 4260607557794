import { AppRoutes } from '../Routes/AppRoutes'
import moment from 'moment'
import { jwtDecode } from 'jwt-decode'

export const decodeJwtToken = token => jwtDecode(token)

export const put = function (obj, path, val) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  let stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path

    // Create new array
    let output = []

    // Split to an array with dot notation
    path.split('.').forEach(function (item, index) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key)
        }
      })
    })

    return output
  }

  // Convert the path to an array if not already
  path = stringToPath(path)

  // Cache the path length and current spot in the object
  let length = path.length
  let current = obj

  path.forEach(function (key, index) {
    // If this is the last item in the loop, assign the value
    if (index === length - 1) {
      current[key] = val
    }

    // Otherwise, update the current place in the object
    else {
      // If the key doesn't exist, create it
      if (!current[key]) {
        current[key] = {}
      }

      // Update the current place in the objet
      current = current[key]
    }
  })
}

export const getNested = (obj, ...args) => {
  let res = args.reduce((obj, level) => obj && obj[level], obj)
  return res === null || res === undefined ? '' : res
}

/**
 * Converts a date string from "yyyy-mm-dd" format to "dd-mm-yyyy" format.
 *
 * @param {string} inputDate - The date string in "yyyy-mm-dd" format.
 * @returns {string} The date string in "dd-mm-yyyy" format.
 */
export const convertDateFormat = inputDate => {
  // Split the date string by "-"
  let parts = inputDate.split('-')

  // Rearrange the parts to form the new date format
  return parts[2] + '-' + parts[1] + '-' + parts[0]
}

export const staticProducts = JSON.parse(
  localStorage.getItem('products') || '[]',
)

const AUTHORITIES = {
  HEAD_MANAGER_AUTHORITY: 'مدير',
  ADMIN_AUTHORITY: 'مسؤول النظام',
  AGENT_AUTHORITY: 'موظف خدمة عملاء',
  SALES_REP_AUTHORITY: 'مندوب مبيعات',
  CUSTOMER_AUTHORITY: 'عميل',
}
export const getAuthorities = authority => AUTHORITIES[authority]
export const logFormData = formData => {
  console.table(Object.fromEntries(formData)) // another representation in table form
}

export const isJSONParsable = obj => {
  try {
    JSON.parse(obj)
  } catch (e) {
    return false
  }
  return true
}

export const camelize = str => {
  return str
  // .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
  //   return index === 0 ? word.toLowerCase() : word.toUpperCase()
  // })
  // .replace(/\s+/g, "")
}
export const getRoutePathByName = name =>
  AppRoutes.find(route => route.name === name).path

export const getProductById = id => {
  const products = JSON.parse(localStorage.getItem('products'))
  const product = products.find(
    product => parseInt(product.id) === parseInt(id),
  )
  return product
}

export const getProductByBTC = btc => {
  const products = JSON.parse(localStorage.getItem('products'))
  const product = products.filter(product => product.btc === btc)
  if (product.length === 1) {
    return product[0]
  }
  if (product.length === 2) {
    return products.find(
      product => product.disclaimerAmount !== 5 && product.btc === btc,
    )
  }

  return product
}

export const getProductInstallmentById = id => {
  const products = JSON.parse(localStorage.getItem('products'))
  for (let i = 0; i < products.length; i++) {
    for (let j = 0; j < products[i].productInstallments.length; j++) {
      if (products[i].productInstallments[j].id === id) {
        return products[i].productInstallments[j]
      }
    }
  }
}

export const convertDateIntoReadable = lastLoginDate => {
  const now = moment()
  const lastLoginMoment = moment(lastLoginDate)

  const diffInDays = now.diff(lastLoginMoment, 'days')
  const diffInHours = now.diff(lastLoginMoment, 'hours')
  const diffInMinutes = now.diff(lastLoginMoment, 'minutes')

  if (diffInDays === 0) {
    if (diffInMinutes < 60) {
      return `${diffInMinutes} دقيقة مضت`
    } else {
      return lastLoginMoment.format('h:mm A')
    }
  } else if (diffInDays === 1) {
    return `أمس ${lastLoginMoment.format('h:mm A')}`
  } else if (diffInDays <= 7) {
    return lastLoginMoment.format('dddd h:mm A')
  } else {
    return `${diffInDays} أيام مضت`
  }
}

export const getBeginningOfDay = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to zero
  return date.toISOString()
}

export const formatDateWithArabicDayName = dateString => {
  const daysOfWeekArabic = [
    'الأحد',
    'الاثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ]

  const date = new Date(dateString)
  const currentDate = new Date()

  const dayDifference = (currentDate - date) / (1000 * 60 * 60 * 24)

  if (dayDifference === 0) {
    return 'اليوم'
  } else if (dayDifference === 1) {
    return 'أمس'
  }

  const dayName = daysOfWeekArabic[date.getDay()]

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${dayName} ${day}-${month}-${year}`
}
export const findAgenTByEmail = email => {
  const agents = JSON.parse(localStorage.getItem('users'))
  const agent = agents.find(agent => agent.email === email)
  return agent
}

export const extractAndFormatNameFromEmail = email => {
  const [firstName, lastName] = email
    .match(/^([^@]+)\.([^@]+)@/)
    .slice(1)
    .map(name => name.charAt(0).toUpperCase() + name.slice(1))
  return `${firstName} ${lastName}`
}

export const validURL = str => {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(str)
}

export const localDateToISO = date => {
  const isoDateString =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2) +
    'T' +
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2) +
    ':' +
    ('0' + date.getSeconds()).slice(-2) +
    '.' +
    ('00' + date.getMilliseconds()).slice(-3) +
    'Z'

  return isoDateString
}
