import React, { useEffect, useState } from 'react'
import { HiOutlineUserAdd } from 'react-icons/hi'
import FFlexWrapper from '../../../components/FFlexWrapper'
import FButton from '../../../components/FButton'
import AgentInvitation from './AgentInvitation'
import { useCustomAxios } from '../../../Hooks/useAxios'
import FCustomDataTable from '../../../components/FCustomDataTable/FCustomDataTable'
import { useNavigate } from 'react-router-dom'
import FProductTag from '../../../components/FProductTag'
import { convertDateIntoReadable, getAuthorities } from '../../../helpers/utils'

const AgentList = () => {
  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState([])
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 0,
      value: '',
      key: '',
    },
  ])

  const handleFilterParams = () => {
    let query = ''
    filterOptions.map(option => {
      if (option.key !== '') {
        query += `&${option.key}=${option.value}`
      }
    })
    return query
  }

  let navigate = useNavigate()

  const { response, loading, error, totalNumberOfPages, Refetch } =
    useCustomAxios({
      method: 'GET',
      url: `/user/?page=${page}&size=20`,
    })
  useEffect(() => {
    Refetch()
  }, [page])

  const [isAgentInviteModalOpen, setIsAgentInviteModalOpen] = useState(false)

  const cols = [
    {
      Header: 'معرف فوري',
      accessor: 'fawryId', // String-based value accessors!
    },
    {
      Header: 'الأسم',
      accessor: 'name', // String-based value accessors!
    },
    {
      Header: 'البريد الالكتروني',
      accessor: 'email', // String-based value accessors!
    },
    {
      Header: 'رقم الهاتف',

      accessor: '  phoneNumber',
    },
    {
      Header: 'الدور',
      accessor: 'authorities',
      cell: value => getAuthorities(value[0]),
    },
    {
      Header: 'آخر تسجيل دخول',
      accessor: 'lastLoginDate',
      cell: value => {
        //convert date format to be yyyy-mm-dd hh:mm:ss
        return convertDateIntoReadable(value)
      },
    },
    {
      Header: 'حالة الحساب',
      accessor: 'enabled',
      cell: value =>
        value ? (
          <FProductTag productName={'مفعل'} color='green' />
        ) : (
          <FProductTag productName={'غير مفعل'} color='red'>
            غير مفعل
          </FProductTag>
        ),
    },
    {
      Header: 'تم قبول الدعوة',
      accessor: 'credentialsExists',
      cell: value =>
        value ? (
          <FProductTag productName={'مكتمل'} color='green' />
        ) : (
          <FProductTag productName={'غير مكتمل'} color='orange'>
            غير مفعل
          </FProductTag>
        ),
    },
    {
      Header: 'النشاط',
      accessor: 'holiday',
      cell: value =>
        !value ? (
          <FProductTag productName={'متاح'} color='green' />
        ) : (
          <FProductTag productName={'غير متاح'} color='red'>
            غير مفعل
          </FProductTag>
        ),
    },
  ]

  return (
    <>
      <AgentInvitation
        Refetch={Refetch}
        setIsAgentInviteModalOpen={setIsAgentInviteModalOpen}
        isAgentInviteModalOpen={isAgentInviteModalOpen}
      />
      <div className={'p-3'}>
        <span>المستخدمين</span>
      </div>
      <div
        className={
          'flex  items-center justify-between border border-b bg-white p-6  dark:bg-gray-900'
        }
      >
        <div className={'w-full'}>
          <div className={'f-row-between w-full'}>
            <span className={'text-md block  font-semibold'}>
              إدارة المستخدمين
            </span>
            <FButton onClick={() => setIsAgentInviteModalOpen(true)}>
              <FFlexWrapper>
                <HiOutlineUserAdd />
                <span className={'mr-1 ml-1 text-xs'}>دعوة مستخدم</span>
              </FFlexWrapper>
            </FButton>
          </div>
          <span
            className={
              'mt-3 block  text-sm font-medium  text-gray-500 dark:text-gray-100   lg:mt-0'
            }
          >
            قم بإضافة وإدارة جميع المستخدمين لديك من هنا. يمكنك تعيين دور لهم
            وتحديد تفاصيل الاتصال بهم ووضعهم في فرق
          </span>
        </div>
      </div>

      <FCustomDataTable
        cols={cols}
        data={response ? response.userDtoList : []}
        isLoading={loading}
        page={page}
        setPage={setPage}
        size={2}
        Refetch={Refetch}
        setFilter={setFilter}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        filter={filter}
        totalNumberOfPages={totalNumberOfPages}
        rowFunction={row => navigate(`/agent/${row.email}`)}
      />

      <div></div>
    </>
  )
}
export default AgentList
