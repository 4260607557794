const EFormInvalidInput = ({ touched, errors, FieldName }) => (
  <>
    {touched[FieldName] === true ? (
      <small className={'text-right font-semibold text-red-700'}>
        {errors[FieldName]}
      </small>
    ) : (
      ''
    )}
  </>
)
export default EFormInvalidInput
